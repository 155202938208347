const OrganisationKeys = {
  ORG_DATA: 'organisation-data',
  ORG_GROUPS: 'organisation-groups',
  ORG_PURCHASED_CAT: 'organisation-purchased-cat',
  ORG_OVERVIEW: 'organisation-overview',
  ORG_PURCHASED_OPTIONS: 'organisation-purchased-options'
}

const CustomGroupKeys = {
  SAVED_GROUPS: 'saved-groups'
}

const ReportKeys = {
  SHARE_REPORT: 'share-report',
  SAVED_REPORTS: 'saved-reports',
  SAVED_REPORT: 'saved-report'
}

export const QueryKeys = {
  ...OrganisationKeys,
  ...ReportKeys,
  ...CustomGroupKeys
}
