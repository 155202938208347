export enum RoleEnum {
  GLOBAL_SUPER_ADMIN = 'global_super_admin',
  SUPER_ADMIN = 'super_admin',
  ORG_ADMIN = 'org_admin',
  MEMBER = 'member'
}

export const roleToText = {
  [RoleEnum.GLOBAL_SUPER_ADMIN]: 'Global Super Admin',
  [RoleEnum.SUPER_ADMIN]: 'Admin',
  [RoleEnum.ORG_ADMIN]: 'Admin',
  [RoleEnum.MEMBER]: 'Member'
}
export const rolesList = [
  RoleEnum.GLOBAL_SUPER_ADMIN,
  RoleEnum.SUPER_ADMIN,
  RoleEnum.ORG_ADMIN,
  RoleEnum.MEMBER
]
