import { Menu } from 'antd'
import React from 'react'
import { CSVLink } from 'react-csv'
import { FaTable } from 'react-icons/fa'

interface Props {
  label: string
  dataSource: any
  fileName: string
  handleCsvClick: () => void
}

export default ({ label, dataSource, fileName, handleCsvClick }: Props) => {
  return (
    <>
      <div data-testid={'btn-csv-download'} onClick={handleCsvClick}>
        <Menu.Item icon={<FaTable />}>{label}</Menu.Item>
      </div>

      <CSVLink
        id="csv-download"
        data={dataSource || ''}
        filename={fileName || 'table.csv'}
        style={{ textDecoration: 'none', display: 'none' }}
      />
    </>
  )
}
