import {
  LeftBorderWrapper,
  LightTitle
} from 'components/elements/SectionTitle/styles'
import React from 'react'

const MenuSectionTitle = ({ text, visible }) => {
  return (
    <LeftBorderWrapper style={{ display: visible && 'none' }}>
      <LightTitle>{text}</LightTitle>
    </LeftBorderWrapper>
  )
}

export default MenuSectionTitle
