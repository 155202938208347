import CardComponent from 'components/elements/CardComponent'
import EllipsisText from 'components/elements/EllipsisText'
import { IReportTemplate } from 'pages/reports/reports'
import React from 'react'

const ReportCard = ({ item }: { item: IReportTemplate }) => {
  const to = item.path || '#'

  if (item.isHidden) return null

  return (
    <CardComponent
      to={to}
      item={item}
      DescriptionComponent={
        <EllipsisText text={item.description} maxLine={3} />
      }
      footerMinHeight={130}
    />
  )
}

export default ReportCard
