import { periodPreset } from 'components/charts/ReportParameterSelector/PeriodSelector/types'
import { IReportMetadata } from 'components/page/ReportPage/types'
import { ChartActions } from 'data/actions'
import { ChartSelector } from 'data/selectors'
import { separator } from 'pages/reports/reports/common/helpers'
import { useEffect } from 'react'
import { useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { QueryKeys } from 'services/api/keys'
import { getReportsViaId } from 'services/api/requests/reports/common'
import { toRollingPeriod } from 'services/helpers/toPeriod'

export const handleLoadReport = async (
  dispatch,
  refetchData,
  periodsList,
  queryReportId,
  reportName,
  updateReportMetadata
) => {
  const { data: newData } = await refetchData()
  const selectedPeriodKey = newData.period.selected
  const selectedCustomPeriod = newData.period.custom.find(
    i => i.key === selectedPeriodKey
  )

  let rollingType, numberOfPeriod, periodType, datePointer
  if (selectedCustomPeriod) {
    rollingType = selectedCustomPeriod.rollingType
    numberOfPeriod = selectedCustomPeriod.numberOfPeriods
    periodType = selectedCustomPeriod.periodType
    datePointer = selectedCustomPeriod?.datePointer
  } else {
    const selections = selectedPeriodKey.split(separator)
    numberOfPeriod = selections[0]
    periodType = selections[1]
    rollingType = selections[2]
  }

  updateReportMetadata(newData)

  const rollingPeriod = toRollingPeriod({
    startDate: datePointer && +datePointer,
    descendingPeriods: periodsList,
    rollingType,
    numberOfPeriod,
    periodType
  })

  const isCustom = !!reportName || !!queryReportId
  const canSave =
    (isCustom && newData.isEditable) || newData.isCreator || !isCustom
  const canShare = isCustom && (newData.isCreator || newData.isEditable)

  dispatch(ChartActions.setReportPermissions({ canSave, canShare }))

  dispatch(ChartActions.addCustomPeriods(newData.period.custom))

  dispatch(
    ChartActions.setPeriod({
      key: selectedPeriodKey,
      content: rollingPeriod
    })
  )

  dispatch(
    ChartActions.rehydrateChartParameters({
      ...newData,
      period: {
        key: selectedPeriodKey,
        content: rollingPeriod
      }
    })
  )

  return newData
}

export const useLoadData = (
  queryReportId,
  reportName,
  updateReportMetadata
): [any, boolean, boolean] => {
  const dispatch = useDispatch()
  const period = useSelector(ChartSelector.selectedPeriod)
  const periodsList = useSelector(ChartSelector.periodsList)

  const {
    data: returnData,
    isLoading,
    refetch,
    isFetching
  } = useQuery<IReportMetadata>(
    QueryKeys.SAVED_REPORT,
    () => getReportsViaId(queryReportId),
    { initialData: { reportId: queryReportId } }
  )

  useEffect(() => {
    let periodKey = period.key
    if (!periodsList.length) return
    if (queryReportId) {
      dispatch(ChartActions.clearCustomPeriods())
      ;(async () => {
        const data = await handleLoadReport(
          dispatch,
          refetch,
          periodsList,
          queryReportId,
          reportName,
          updateReportMetadata
        )
        if (data) {
          periodKey = data.period.selected
        }
      })()
    } else {
      dispatch(
        ChartActions.setReportPermissions({ canSave: true, canShare: false })
      )
    }

    return () => {
      if (periodsList.length) {
        if (queryReportId) {
          dispatch(ChartActions.clearCustomPeriods())
          if (!periodPreset.map(i => i.key).includes(periodKey)) {
            const newKey = periodPreset[0].key
            const [numberOfPeriod, periodType, rollingType] = newKey.split(
              separator
            )
            dispatch(
              ChartActions.setPeriod({
                key: newKey,
                content: toRollingPeriod({
                  numberOfPeriod,
                  periodType,
                  rollingType,
                  descendingPeriods: periodsList
                })
              })
            )
          }
        }
      }
    }
  }, [queryReportId, periodsList.length])

  return [refetch, isLoading, isFetching]
}
