import { CenteredWrapper } from 'components/elements/SectionTitle/styles'
import React from 'react'

const TableTitle = () => (
  <CenteredWrapper>
    <p>TABLE</p>
  </CenteredWrapper>
)

export default TableTitle
