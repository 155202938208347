import { ChartSelector } from 'data/selectors'
import usePreselectGroups from 'pages/reports/reports/common/reportHooks/usePreselectGroup'
import usePreselectPeriod from 'pages/reports/reports/common/reportHooks/usePreselectPeriod'
import usePreselectProducts, {
  selectAllProductItem
} from 'pages/reports/reports/common/reportHooks/usePreselectProduct'
import { cleanProductQuery } from 'pages/reports/utils/cleanBody'
import { Dispatch, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  PeriodToDropdownData,
  SelectDataProps,
  SelectDataPropsWithColor,
  StringToSelectDataProps
} from 'services/helpers/dropdownUtils'

const useStandardSelectable = (): UseStandardSelectable => {
  const query = useSelector(ChartSelector.chartQuerySelector)
  const groups = useSelector(ChartSelector.customGroups)

  const groupDropdownWithColor = groups.map(
    ({ id, color }): SelectDataPropsWithColor => ({
      ...StringToSelectDataProps(id),
      color
    })
  )
  const productsMenu = groups[0].products.map(i => ({
    key: String(i),
    value: cleanProductQuery([i])[0]
  }))
  const selectableProductsMenu = [selectAllProductItem, ...productsMenu]

  const [
    selectableViewOptions,
    setSelectableViewOptions
  ] = useState<StandardSelectableOptions>({
    timePeriods: [],
    groups: [],
    products: []
  })

  useEffect(() => {
    const timePeriods = query?.[0]?.periods
      ?.slice()
      .reverse()
      .map(PeriodToDropdownData)
    setSelectableViewOptions(prevOption => ({ ...prevOption, timePeriods }))
  }, [query])

  useEffect(() => {
    setSelectableViewOptions(prevOption => ({
      ...prevOption,
      groups: groupDropdownWithColor,
      products: selectableProductsMenu
    }))
  }, [groups])

  const [selectedPeriod, setSelectedPeriod] = usePreselectPeriod(
    selectableViewOptions
  )
  const [selectedGroups] = usePreselectGroups(selectableViewOptions)
  const [selectedProducts, setSelectedProducts] = usePreselectProducts(
    selectableViewOptions
  )

  return {
    selectableViewOptions,
    selectedPeriod,
    setSelectedPeriod,
    selectedGroups,
    selectedProducts,
    setSelectedProducts
  }
}

export interface UseStandardSelectable {
  selectableViewOptions: StandardSelectableOptions
  selectedPeriod: SelectDataProps
  setSelectedPeriod: Dispatch<SelectDataProps>
  selectedGroups: SelectDataProps[]
  selectedProducts: SelectDataProps[]
  setSelectedProducts: Dispatch<SelectDataProps[]>
}

export interface StandardSelectableOptions {
  timePeriods: SelectDataProps[]
  groups: SelectDataPropsWithColor[]
  products: SelectDataProps[]
}

export default useStandardSelectable
