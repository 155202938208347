import * as Sentry from '@sentry/react'
import { Modal } from 'antd'
import { DialogKeys } from 'components/dialogs/index'
import { CancelIcon } from 'components/elements/SvgIcon/styles'
import { DialogActions } from 'data/actions'
import React from 'react'
import { useDispatch } from 'react-redux'
import { SPACE } from 'services/styles'
import { ButtonHeight } from 'services/styles/misc'

export default ({
  error,
  errorMessage
}: {
  error?: Error
  errorMessage?: string
}) => {
  const dispatch = useDispatch()

  const handleOK = () => {
    !errorMessage && Sentry.captureException(error)
    dispatch(DialogActions.hideDialog({ key: DialogKeys.QUERY_ERROR }))
  }
  return (
    <Modal
      title="Error"
      visible
      closeIcon={
        <CancelIcon
          width={ButtonHeight.SMALL}
          height={ButtonHeight.SMALL}
          style={{ marginTop: SPACE.tiny * 2.75 }}
        />
      }
      onOk={handleOK}
      onCancel={handleOK}
      okButtonProps={{ style: { width: '100%' } }}
      width={400}
      cancelButtonProps={{ hidden: true }}
    >
      <p style={{ fontSize: 18 }}>
        {errorMessage ||
          'It looks like there is an error. Our team has been notified.'}
      </p>
    </Modal>
  )
}
