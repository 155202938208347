import { SPACE } from 'services/styles'
import styled from 'styled-components'

export const UlNoStyle = styled.ul`
  list-style-type: none;
  padding-left: 0px;
  margin: 0px ${SPACE.small}px;
`
export const LiRow = styled.li`
  display: flex;
  align-items: start;
`
export const TextWithSpacing = styled.p`
  display: inline-block;
  padding: 0;
  margin: 0;
`

export const Separator = styled.hr`
  background: transparent;
  margin-top: 0px;
  margin-bottom: 0px;
`
