import { StepperDropdown } from 'components/dialogs/CustomGroup/common/StepperDropdown'
import CustomColorPicker from 'components/elements/CustomColorPicker'
import FlexContainer from 'components/elements/FlexContainer'
import React, { FC } from 'react'
import { BreakLine, ColumnWrapper, GroupNameInput, WarningText } from './style'

interface SelectListProps {
  orgOptions?: any
  setGroupName: (text: string) => void
  groupName: string
  color: string
  setColor: (text: string) => void
  selectedProducts: string[]
  handleProductChange: (i: string[]) => void
}

const SelectList: FC<SelectListProps> = ({
  orgOptions,
  setGroupName,
  groupName,
  color,
  setColor,
  selectedProducts,
  handleProductChange
}) => (
  <ColumnWrapper>
    <FlexContainer justifyContent="flex-start" alignItems="center">
      <CustomColorPicker color={color} setColor={setColor} />
      <GroupNameInput
        id={'custom-group-name-input'}
        value={groupName}
        onChange={e => setGroupName(e.target.value)}
        placeholder="Please type in a group name"
        style={{
          border: groupName?.length > 0 ? '' : 'red 1px solid'
        }}
      />
    </FlexContainer>
    {!groupName.length && <WarningText>Group name is required</WarningText>}

    <BreakLine />

    <StepperDropdown
      orgOptions={orgOptions}
      selectedProducts={selectedProducts}
      handleProductChange={handleProductChange}
    />
  </ColumnWrapper>
)

export default SelectList
