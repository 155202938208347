import {
  Chart,
  ChartArea,
  ChartAxisDefaults,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartCategoryAxisTitle,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
  ChartValueAxis,
  ChartValueAxisItem
} from '@progress/kendo-react-charts'
import { Col } from 'antd/lib/grid'
import SharedChartTooltip from 'components/charts/SharedChartTooltip'
import camelCase from 'lodash/camelCase'
import startCase from 'lodash/startCase'
import { SELECT_ALL_PRODUCT_KEY } from 'pages/reports/reports/common/reportHooks/usePreselectProduct'
import React, { useState } from 'react'
import { toSentenceCase, toStartCase } from 'services/helpers/canonicalization'
import { COLOR } from 'services/styles'
import { getFontStyle } from 'services/styles/font'
import { ChartGroupRow, GraphTitle } from '../style'
import {
  ChartOptions,
  ChartTypes,
  DemographicsBreakdownBy,
  SelectedMetrics
} from '../types'
import IndexedVisual from './indexedVisual'

interface ChartGroupProps {
  data: any
  type: ChartTypes
  shouldShowIndex: boolean
  selectedMetrics: SelectedMetrics
}

export default ({
  data,
  type,
  shouldShowIndex,
  selectedMetrics
}: ChartGroupProps) => {
  const [hiddenIndices, setHiddenIndices] = useState([])
  const selectedBreakdownById = selectedMetrics.breakdownBy.key
  if (!data) return null
  const dataBy = data[selectedBreakdownById]
  const dataForThisChart = dataBy
    ?.find(i => camelCase(i.metric) === type)
    ?.data.map((i, idx) => ({
      ...i,
      visible: !hiddenIndices.includes(idx)
    }))

  if (!dataForThisChart) return null

  const handleLegendClick = event => {
    const itemResult = event.seriesIndex
    if (hiddenIndices.includes(itemResult)) {
      setHiddenIndices(hiddenIndices.filter(i => i !== itemResult))
    } else {
      setHiddenIndices([...hiddenIndices, itemResult])
    }
  }

  const graphTitle = startCase(type)

  const chartAxisFont = getFontStyle(14)

  const isProductDrillDownEnabled =
    selectedMetrics[ChartOptions.PRODUCT].value !== SELECT_ALL_PRODUCT_KEY

  let selectedKey = toSentenceCase(SELECT_ALL_PRODUCT_KEY)

  if (isProductDrillDownEnabled) {
    const selectedBrands = (
      selectedMetrics[ChartOptions.PRODUCT].value + ''
    ).split('>')

    selectedKey = toStartCase(selectedBrands[selectedBrands.length - 1])
  }

  return (
    <>
      <GraphTitle>{graphTitle}</GraphTitle>
      <ChartGroupRow>
        <Col span={14}>
          <Chart transitions={false} onLegendItemClick={handleLegendClick}>
            <ChartLegend position="left" reverse={true} />
            <ChartArea background={'transparent'} />
            <SharedChartTooltip />
            <ChartValueAxis>
              <ChartValueAxisItem
                narrowRange={false}
                min={0}
                max={1}
                labels={chartAxisFont}
              />
            </ChartValueAxis>
            <ChartCategoryAxis>
              <ChartCategoryAxisItem
                categories={['Selected Group']}
                labels={{
                  ...chartAxisFont,
                  content: () => selectedKey
                }}
              >
                <ChartCategoryAxisTitle text="Group" visible={false} />
              </ChartCategoryAxisItem>
            </ChartCategoryAxis>
            <ChartAxisDefaults
              line={{ visible: false }}
              majorGridLines={{ color: COLOR.lightGrey + '20' }}
            />
            <ChartSeries>
              {dataForThisChart.map(i => (
                <ChartSeriesItem
                  key={i.value}
                  border={{ width: 0 }}
                  type="column"
                  data={[i.current]}
                  stack={{ type: '100%' }}
                  notes={{ label: { content: () => i.value } }}
                  name={i.value}
                  visible={i.visible}
                />
              ))}
            </ChartSeries>
          </Chart>
        </Col>
        <Col span={10}>
          <Chart transitions={false}>
            <ChartValueAxis>
              <ChartValueAxisItem
                visible={false}
                narrowRange={false}
                min={0}
                max={1}
              />
            </ChartValueAxis>
            <ChartArea margin={{ top: 12 }} background={'transparent'} />
            <ChartLegend visible={false} />
            <SharedChartTooltip visible={!shouldShowIndex} />
            <ChartCategoryAxis>
              <ChartCategoryAxisItem
                categories={
                  shouldShowIndex
                    ? [
                        selectedBreakdownById !==
                        DemographicsBreakdownBy.BY_BUYERS_PANEL
                          ? 'Indexed against Alcohol Pop.'
                          : 'Indexed against Total Panel'
                      ]
                    : [
                        selectedBreakdownById !==
                        DemographicsBreakdownBy.BY_BUYERS_PANEL
                          ? 'Alcohol Pop.'
                          : 'Total Panel'
                      ]
                }
                labels={chartAxisFont}
              >
                <ChartCategoryAxisTitle text="Group" visible={false} />
              </ChartCategoryAxisItem>
            </ChartCategoryAxis>
            <ChartAxisDefaults
              line={{ visible: false }}
              majorGridLines={{ color: COLOR.lightGrey + '20' }}
            />
            <ChartSeries>
              {dataForThisChart.map(i => (
                <ChartSeriesItem
                  key={i.value}
                  type="column"
                  color={shouldShowIndex && 'transparent'}
                  visual={shouldShowIndex && IndexedVisual}
                  data={[shouldShowIndex ? i.indexed : i.benchmark]}
                  stack={{ type: '100%' }}
                  notes={{ label: { content: () => i.value } }}
                  name={i.value}
                  visible={i.visible}
                />
              ))}
            </ChartSeries>
          </Chart>
        </Col>
      </ChartGroupRow>
    </>
  )
}
