import { Dropdown } from 'antd'
import { SortIcon } from 'components/elements/SvgIcon/styles'
import React from 'react'
import { COLOR } from 'services/styles'
import { SortButton, SpaceAround } from './styles'

const DropdownMenu = ({ menuComponent, text }) => {
  return (
    <Dropdown
      trigger={['click']}
      overlay={menuComponent}
      placement="bottomRight"
    >
      <SortButton icon={<SortIcon fill={COLOR.textGrey} />}>
        <SpaceAround>{text}</SpaceAround>
      </SortButton>
    </Dropdown>
  )
}

export default DropdownMenu
