import { ChartSelector } from 'data/selectors'
import { ReportMeta } from 'pages/reports/reportMeta'
import { SELECT_ALL_PRODUCT_KEY } from 'pages/reports/reports/common/reportHooks/usePreselectProduct'
import { NoneDrillDown } from 'pages/reports/reports/common/types'
import { cleanProductQuery } from 'pages/reports/utils/cleanBody'
import { Dispatch, useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import dataFallback from 'services/api/requests/common/dataFallback'
import { getPurchaseDriversApi } from 'services/api/requests/reports/purchaseDrivers'
import {
  DefaultDropdownState,
  PeriodToDropdownData,
  SelectDataProps,
  SelectDataPropsWithColor,
  StringToSelectDataProps
} from 'services/helpers/dropdownUtils'
import { CombineChainsMapping } from 'services/helpers/toChain'
import { paramsToPeriodsMatrix, separator } from '../common/helpers'
import formatData from './formatData'
import { FormattedData, ParamsSelectors, SelectableViewOptions } from './types'

export const useSelectableMetrics = () => {
  const query = useSelector(ChartSelector.chartQuerySelector)
  const groups = useSelector(ChartSelector.customGroups)
  const groupDropdownWithColor = groups.map(
    ({ id, color }): SelectDataPropsWithColor => ({
      ...StringToSelectDataProps(id),
      color
    })
  )
  const [
    selectableViewOptions,
    setSelectableViewOptions
  ] = useState<SelectableViewOptions>({ timePeriods: [], groups: [] })

  // when available periods change
  useEffect(() => {
    const timePeriods = query?.[0]?.periods?.map(PeriodToDropdownData)
    setSelectableViewOptions(prevOption => ({ ...prevOption, timePeriods }))
  }, [query])

  // when available groups change
  useEffect(() => {
    setSelectableViewOptions(prevOption => ({
      ...prevOption,
      groups: groupDropdownWithColor
    }))
  }, [groups])

  return selectableViewOptions
}

type useFilterQueriesOptions = {
  selectedLeftMetricId?: string
  selectedRightMetricId?: string
  drillDown?: SelectDataProps
  selectedProducts?: SelectDataProps[]
}

const useFilterQueries = (
  key: string,
  selectedGroups: SelectDataProps[],
  options?: useFilterQueriesOptions
) => {
  const {
    selectedLeftMetricId,
    selectedRightMetricId,
    drillDown,
    selectedProducts
  } = options

  const isRetailersDrillDownEnabled = drillDown.key !== NoneDrillDown

  const isProductsDrillDownEnabled = !selectedProducts.find(
    i => i.key === SELECT_ALL_PRODUCT_KEY
  )

  const chartQuery = useSelector(ChartSelector.chartQuerySelector)

  let selectedReports = chartQuery.filter(item =>
    selectedGroups.find(group => group.key === item.id)
  )

  if (isProductsDrillDownEnabled) {
    selectedReports = selectedReports.reduce(
      (acc, group) => [
        ...acc,
        ...group.products.reduce(
          (acc, product) =>
            selectedProducts.find(
              i => cleanProductQuery([i.key])[0] === product
            )
              ? [
                  ...acc,
                  {
                    ...group,
                    products: [product],
                    id: `${group.id}|${group.name}|${product}`
                  }
                ]
              : acc,
          []
        )
      ],
      []
    )
  }

  if (isRetailersDrillDownEnabled) {
    selectedReports = selectedReports.map(group => ({
      ...group,
      chains: CombineChainsMapping[drillDown.key]
    }))

    selectedReports = selectedReports.reduce(
      (acc, group) => [
        ...acc,
        ...group.chains.map(chain => ({
          ...group,
          chains: [chain],
          id: `${group.id}${
            isProductsDrillDownEnabled ? '' : `|${group.name}`
          }|${chain}`
        }))
      ],
      []
    )
  }

  const props = selectedReports.map(i => ({
    ...i,
    periods: [selectedLeftMetricId, selectedRightMetricId]
  }))
  const queries = paramsToPeriodsMatrix(props)

  return [selectedReports.map(i => ({ ...i, key })), queries]
}

export const useLoadData = (
  setIsLoading: Dispatch<boolean>,
  selections: ParamsSelectors
) => {
  const {
    selectedGroups,
    leftMetric,
    rightMetric,
    drillDown,
    selectedProducts
  } = selections
  const selectedLeftMetricId = leftMetric.key
  const selectedRightMetricId = rightMetric.key

  const dispatch = useDispatch()

  const [selectedReports, queries] = useFilterQueries(
    ReportMeta.PURCHASE_DRIVERS.key,
    selectedGroups,
    { selectedLeftMetricId, selectedRightMetricId, drillDown, selectedProducts }
  )

  const { isFetching, isLoading, data } = useQuery(
    [selectedReports, queries],
    () => getPurchaseDriversApi(queries, dispatch),
    { enabled: !!selectedLeftMetricId && !!selectedRightMetricId }
  )

  const fallbackData = dataFallback(data)

  useEffect(() => setIsLoading(isLoading || isFetching), [
    isLoading,
    isFetching
  ])

  return useFormatData(
    { rawData: fallbackData, isLoading: isLoading || isFetching },
    { rightMetric, leftMetric, selectedGroups, drillDown, selectedProducts }
  )
}

export const useAutoSelectGroup = (
  availableGroups: SelectableViewOptions
): [SelectDataProps[], Dispatch<SelectDataProps[]>] => {
  const [selectedGroups, setSelectedGroups] = useState<SelectDataProps[]>([])

  useEffect(() => {
    setSelectedGroups(availableGroups.groups)
  }, [availableGroups.groups])
  return [selectedGroups, setSelectedGroups]
}

export const useAutoSelectPeriod = (
  timePeriods: SelectDataProps[]
): [
  SelectDataProps,
  Dispatch<SelectDataProps>,
  SelectDataProps,
  Dispatch<SelectDataProps>
] => {
  const [leftMetric, setLeftMetric] = useState(DefaultDropdownState)
  const [rightMetric, setRightMetric] = useState(DefaultDropdownState)

  useEffect(() => {
    if (timePeriods && timePeriods.length) {
      const timePeriodIds = timePeriods.map(i => i.key)
      if (!leftMetric.key || !timePeriodIds.includes(leftMetric.key)) {
        setLeftMetric(timePeriods[0])
      }
      if (!rightMetric.key || !timePeriodIds.includes(rightMetric.key)) {
        setRightMetric(timePeriods[timePeriods.length - 1])
      }
    }
  }, [timePeriods])

  return [leftMetric, setLeftMetric, rightMetric, setRightMetric]
}

type QueryDataProps = {
  rawData: any
  isLoading: boolean
}

export const useFormatData = (
  { rawData, isLoading }: QueryDataProps,
  params: ParamsSelectors
): FormattedData[] => {
  const {
    selectedGroups,
    leftMetric,
    rightMetric,
    drillDown,
    selectedProducts
  } = params
  const [groupsData, setGroupsData] = useState<FormattedData[]>([])
  const groups = useSelector(ChartSelector.customGroups)

  const isProductDrillDownEnabled = !selectedProducts.find(
    i => i.key === SELECT_ALL_PRODUCT_KEY
  )

  const isRetailersDrillDownEnabled = drillDown.key !== NoneDrillDown

  const cleanGroupSignature = key => {
    let dataKey = key
    let cleanKey = key
    let metric, name, productAndChain
    if (isRetailersDrillDownEnabled || isProductDrillDownEnabled) {
      ;[dataKey, metric] = key.split(separator)
      ;[cleanKey, name, ...productAndChain] = dataKey.split('|')
      name = `${productAndChain
        .map(i => `[${cleanProductQuery([i])[0].replace(/_/g, ' ')}]`)
        .join(' ')}`
    }

    const color = groups.find(i => i.id === cleanKey)?.color
    return [dataKey, name, color]
  }

  const drillDownGroups = rawData.reduce((acc, data) => {
    const [dataKey, name, color] = cleanGroupSignature(data.key)

    const newGroup = {
      key: dataKey,
      value: name,
      color
    }
    const isGroupExist = acc.find(item => item?.value === newGroup?.value)
    return isGroupExist ? acc : [...acc, newGroup]
  }, [])

  useEffect(() => {
    const groupData =
      isRetailersDrillDownEnabled || isProductDrillDownEnabled
        ? drillDownGroups
        : selectedGroups.map(i => ({ ...i, value: SELECT_ALL_PRODUCT_KEY }))

    if (!isLoading) {
      const formattedData = groupData.map(
        (group): FormattedData => {
          const currData = rawData?.find(
            i => i.key === `${group?.key}${separator}${leftMetric?.key}`
          )

          const compData = rawData?.find(
            i => i.key === `${group?.key}${separator}${rightMetric?.key}`
          )

          return {
            group,
            warningLevel: Math.max(
              currData?.warningLevel,
              compData?.warningLevel
            ),
            color: group.color,
            tree: formatData({
              benchmark: currData,
              target: compData
            })
          }
        }
      )

      setGroupsData(formattedData)
    }
  }, [
    isLoading,
    leftMetric,
    rightMetric,
    selectedGroups,
    drillDown,
    selectedProducts
  ])

  return groupsData
}
