import moment from 'moment'
import { StringToSelectDataProps } from 'services/helpers/dropdownUtils'

export enum DateModeEnum {
  START = 'start',
  END = 'end',
  LATEST = 'latest'
}

export enum ToggleEnum {
  YES = 'Yes',
  NO = 'No'
}

export interface CustomPeriodModalProps {
  prefilledPeriod?: { id?: string; key?: string; value?: string }
}

export const initialCustomPeriod = {
  dateMode: undefined,
  startDate: null,
  name: `Custom period - ${moment().format('DD-MM-YYYY')}`,
  periodType: 1,
  rollingType: 1,
  selectedNumberOfPeriod: StringToSelectDataProps(12)
}

export const initDateMode = [
  DateModeEnum.START,
  DateModeEnum.END,
  DateModeEnum.LATEST
].map(StringToSelectDataProps)

export const AdvancedMode = [ToggleEnum.YES, ToggleEnum.NO].map(
  StringToSelectDataProps
)

export const initialNumberOfPeriod = '99'
