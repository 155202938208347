import { COLOR } from 'services/styles'
import styled from 'styled-components'

export const Wrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: ${COLOR.dirtyWhite};
  z-index: 999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
`
