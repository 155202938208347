import { useEffect, useState } from 'react'

const useGetDimension = () => {
  const [width, setWidth] = useState(0)
  const [height, setHeight] = useState(0)

  const updateScreenSize = () => {
    setWidth(window.innerWidth)
    setHeight(window.innerHeight)
  }

  useEffect(() => {
    updateScreenSize()
    window.addEventListener('resize', updateScreenSize)
    return () => window.removeEventListener('resize', updateScreenSize)
  }, [])

  return [width, height]
}

export default useGetDimension
