import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const StyledLink = styled(Link)`
  text-decoration: none;

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }

  color: ${(props: StyledLinkProps) =>
    (props.noColor && 'white') || 'inherit'} !important;
`

interface StyledLinkProps {
  noColor?: boolean
}

export default props => <StyledLink {...props} />
