import { Tooltip } from 'antd'
import { TooltipProps } from 'antd/lib/tooltip'
import {
  HeaderCell,
  StyledCell,
  StyledTable
} from 'components/elements/Table/styles'
import React, { CSSProperties, ReactNode } from 'react'
import { SPACE } from 'services/styles'

interface cellProps {
  onClick?: () => void
  data?: any
  field?: string
  label?: string
  render?: React.ReactNode
  visible?: boolean
  cellStyle?: CSSProperties
}

interface rowProps {
  onClick?: () => void
  rowStyle?: CSSProperties
  children?: ReactNode
  tooltipProps?: TooltipProps & React.RefAttributes<unknown>
}

interface headerProps {
  titles: string[]
  sticky?: boolean
  headerStyle?: CSSProperties
}

const Table = props => (
  <StyledTable {...props} style={{ ...props.style }}>
    {props.children}
  </StyledTable>
)

const Row = (props: rowProps) => {
  return (
    <Tooltip
      key={props?.tooltipProps?.key}
      placement={props?.tooltipProps?.placement}
      title={props?.tooltipProps?.title}
    >
      <tr
        onClick={props.onClick}
        style={{
          backgroundColor: 'white',
          height: SPACE.large,
          ...props.rowStyle
        }}
      >
        {props.children}
      </tr>
    </Tooltip>
  )
}

const Header = ({ titles, headerStyle, sticky }: headerProps) => {
  return (
    <thead>
      <tr>
        {titles.map((title, index) => (
          <HeaderCell
            key={index}
            style={{
              position: sticky ? 'sticky' : 'static',
              ...headerStyle
            }}
          >
            {title}
          </HeaderCell>
        ))}
      </tr>
    </thead>
  )
}

const Cell = ({
  data,
  field,
  label,
  cellStyle,
  render,
  onClick,
  visible = true
}: cellProps) => {
  const visibility = visible ? 'visible' : 'hidden'
  return (
    <StyledCell style={cellStyle} onClick={onClick}>
      {render ? (
        visible ? (
          render
        ) : (
          <span />
        )
      ) : (
        <span style={{ visibility }}>{label || data[field]}</span>
      )}
    </StyledCell>
  )
}

Table.Header = Header
Table.Row = Row
Table.Cell = Cell

export default Table
