import { ChartSelector } from 'data/selectors'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getTooltipMessage } from './helpers'

const useSelectorTooltipText = isCustom => {
  const { canSave, canShare } = useSelector(ChartSelector.savedReportState)
  const customGroups = useSelector(ChartSelector.customGroups)

  const [tooltipMessage, setTooltipMessage] = useState({
    save: null,
    saveNew: null,
    share: null,
    download: null
  })

  useEffect(() => {
    setTooltipMessage({
      save: getTooltipMessage({
        type: 'SAVE',
        permission: !canSave,
        requirements: !customGroups.length
      }),
      saveNew: getTooltipMessage({
        type: 'SAVE',
        requirements: !customGroups.length
      }),
      share: getTooltipMessage({
        type: 'SHARE',
        permission: !canShare,
        requirements: !customGroups.length || !isCustom
      }),
      download: getTooltipMessage({
        type: 'DOWNLOAD',
        requirements: !customGroups.length
      })
    })
  }, [customGroups.length, canSave, canShare])

  return tooltipMessage
}

export default useSelectorTooltipText
