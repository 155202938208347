import { useQuery } from 'react-query'
import { QueryKeys } from 'services/api/keys'
import { getOrgGroupsApi } from 'services/api/requests/organisation/groups'
import { getPurchasedOptionsApi } from 'services/api/requests/organisation/purchasedCategories'

export const useLoadOrgGroups = orgId =>
  useQuery(QueryKeys.ORG_GROUPS, () => getOrgGroupsApi(orgId))

export const useLoadOrgOptions = orgId =>
  useQuery([QueryKeys.ORG_PURCHASED_CAT, QueryKeys.ORG_PURCHASED_OPTIONS], () =>
    getPurchasedOptionsApi(orgId)
  )
