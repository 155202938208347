import startCase from 'lodash/startCase'
import { CombineChainsEnum } from 'services/helpers/toChain'

export const NoneDrillDown = 'NONE'

export const initialDrillDownState = {
  key: NoneDrillDown,
  value: 'None'
}

export const DrillByList = [
  NoneDrillDown,
  CombineChainsEnum.SINGLE_BANNER,
  CombineChainsEnum.RETAILER_GROUP,
  CombineChainsEnum.CHANNEL
].map(i => ({
  key: i,
  value: startCase(i.toLowerCase())
}))

export const invalidDrillDownTooltip =
  'Drill down is only available for All banners selections. Please edit/remove selections to use this feature'
