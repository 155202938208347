import AnimatedVisibility from 'components/elements/AnimatedVisibility'
import DefaultLoader from 'components/elements/DefaultLoader'
import { Wrapper } from 'components/page/PageLoader/style'
import { UserSelector } from 'data/selectors'
import 'data/user/userObservable'
import React from 'react'
import { useSelector } from 'react-redux'
import { SPACE } from 'services/styles'
import logo from 'static/logo-with-name-black.png'

export default () => {
  const isAppReady = useSelector(UserSelector.isAppReady)
  /* app is not ready until firebase and backend is ready */
  return (
    <AnimatedVisibility>
      {!isAppReady && (
        <Wrapper>
          <img
            src={logo}
            style={{ height: 80, margin: SPACE.large }}
            alt="logo"
          />
          <DefaultLoader />
        </Wrapper>
      )}
    </AnimatedVisibility>
  )
}
