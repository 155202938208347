import {
  ChartActionTypes,
  ChartCustomGroupState,
  ChartCustomPeriods,
  ChartOptionsState,
  initialChartCustomGroups,
  initialChartOptions,
  initialSavedReportState,
  initialSelectedChartOptions,
  SavedReportState,
  SelectedChartOptionsState
} from 'data/chart/types'
import produce from 'immer'
import { combineReducers } from 'redux'
import { handleActions } from 'redux-actions'
import { GraphColors } from 'services/styles/color'
import { v4 as uuidv4 } from 'uuid'

const options = handleActions<ChartOptionsState, any>(
  {
    [ChartActionTypes.LOAD_OPTIONS_SUCCESSFUL]: (state, { payload }) =>
      produce(state, draftState => {
        const { retailers, periods, products, buyersGroups } = payload
        draftState.chains = retailers
        draftState.products = products
        draftState.buyerGroups = buyersGroups
        draftState.periods = periods
      }),
    [ChartActionTypes.RESET_CHART_PARAMETERS]: state =>
      produce(state, draftState => {
        draftState.buyerGroups = []
        draftState.products = []
        draftState.chains = []
      })
  },
  initialChartOptions
)

const customGroups = handleActions<ChartCustomGroupState, any>(
  {
    [ChartActionTypes.ADD_CUSTOM_GROUP]: (state, { payload }) =>
      produce(state, draftState => {
        return [...draftState, { ...payload, id: payload?.id || uuidv4() }]
      }),
    [ChartActionTypes.EDIT_CUSTOM_GROUP]: (state, { payload }) =>
      produce(state, draftState => {
        const { id, group: updatedGroup } = payload
        return draftState.map(group => (group.id === id ? updatedGroup : group))
      }),
    [ChartActionTypes.RESET_CHART_PARAMETERS]: () => initialChartCustomGroups,
    [ChartActionTypes.DELETE_CUSTOM_GROUP]: (state, { payload }) =>
      produce(state, draftState => {
        const { id } = payload
        return draftState.filter(group => group.id !== id)
      }),
    [ChartActionTypes.DUPLICATE_CUSTOM_GROUP]: (state, { payload }) =>
      produce(state, draftState => {
        const copyOfCustomGroup = draftState.find(group => group.id === payload)
        if (copyOfCustomGroup) {
          draftState.push({
            ...copyOfCustomGroup,
            name: `Copy of ${copyOfCustomGroup.name}`,
            color: GraphColors[(draftState.length + 1) % 12],
            id: uuidv4()
          })
        }
        return draftState
      }),
    [ChartActionTypes.REHYDRATE_CHART_PARAMETERS]: (state, { payload }) =>
      produce(state, draftState => {
        return payload.customGroups
      })
  },
  initialChartCustomGroups
)

const selectedChartOptions = handleActions<SelectedChartOptionsState, any>(
  {
    [ChartActionTypes.SET_PERIOD]: (state, { payload }) =>
      produce(state, draftState => {
        draftState.period = { ...draftState.period, ...payload }
      }),
    [ChartActionTypes.REHYDRATE_CHART_PARAMETERS]: (state, { payload }) =>
      produce(state, draftState => {
        draftState.period = {
          key: payload.period.key,
          content: payload.period.content
        }
        return draftState
      })
  },
  initialSelectedChartOptions
)

const customPeriods = handleActions<ChartCustomPeriods, any>(
  {
    [ChartActionTypes.ADD_CUSTOM_PERIODS]: (state, { payload }) =>
      produce(state, draftState => [...draftState, ...payload]),
    [ChartActionTypes.ADD_CUSTOM_PERIOD]: (state, { payload }) =>
      produce(state, draftState => [...draftState, payload]),
    [ChartActionTypes.EDIT_CUSTOM_PERIOD]: (state, { payload }) =>
      produce(state, draftState => {
        const { id, period: updatedPeriod } = payload
        return draftState.map(period =>
          period.key === id ? updatedPeriod : period
        )
      }),
    [ChartActionTypes.DELETE_CUSTOM_PERIOD]: (state, { payload }) =>
      produce(state, draftState => {
        const { id } = payload
        return draftState.filter(period => period.key !== id)
      }),
    [ChartActionTypes.CLEAR_CUSTOM_PERIOD]: state => produce(state, () => [])
  },
  []
)

const savedReportState = handleActions<SavedReportState, any>(
  {
    [ChartActionTypes.SET_REPORT_PERMISSIONS]: (state, { payload }) =>
      produce(state, draftState => {
        draftState = payload
        return draftState
      })
  },
  initialSavedReportState
)

const reducer = combineReducers<CombinedChartState>({
  options,
  selectedChartOptions,
  customGroups,
  customPeriods,
  savedReportState
})

export interface CombinedChartState {
  readonly options: ChartOptionsState
  readonly selectedChartOptions: SelectedChartOptionsState
  readonly customPeriods: ChartCustomPeriods
  readonly customGroups: ChartCustomGroupState
  readonly savedReportState: SavedReportState
}

export default reducer
