import { COLOR, SPACE } from 'services/styles'
import styled from 'styled-components'
import { PencilIcon } from '../SvgIcon/styles'

interface ColorDotProps {
  color: string
}

export const ColorBox = styled.span`
  height: 35px;
  width: 35px;
  background-color: ${(props: ColorDotProps) =>
    props.color ? props.color : COLOR.antdGrey};
  border-radius: 50%;
  display: inline-block;

  &:hover {
    cursor: pointer;
  }
`

export const Container = styled.div`
  display: flex;
  align-items: center;
`

export const Wrapper = styled.div`
  width: 40px;
  margin-right: ${SPACE.tiny}px;
`

export const AbsoluteIcon = styled(PencilIcon)`
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(80%, 70%);
`
