import startCase from 'lodash/startCase'
import { SelectDataProps } from 'services/helpers/dropdownUtils'

export interface TrendedMetricsResponse {
  purchaseUnits: number
  purchaseValue: number
  rawCount: number
  warningLevel: number
  key: string
  period: string
}

export enum TrendedMetricsEnum {
  PURCHASE_VALUE = 'PURCHASE_VALUE',
  PURCHASE_UNITS = 'PURCHASE_UNITS',
  SHARE_BY_VALUE = 'SHARE_BY_VALUE',
  SHARE_BY_OCCASIONS = 'SHARE_BY_OCCASIONS'
}

export enum TrendedMetricsDataKey {
  PURCHASE_UNITS = 'purchaseUnits',
  PURCHASE_VALUE = 'purchaseValue',
  SHARE_BY_VALUE = 'shareByValue',
  SHARE_BY_OCCASIONS = 'shareByOccasions'
}

export const TrendedMetricsToUnit = {
  [TrendedMetricsEnum.PURCHASE_UNITS]: 'Index',
  [TrendedMetricsEnum.PURCHASE_VALUE]: 'Index',
  [TrendedMetricsEnum.SHARE_BY_VALUE]: 'Percent',
  [TrendedMetricsEnum.SHARE_BY_OCCASIONS]: 'Percent'
}

export const TrendedMetricToKey = {
  [TrendedMetricsEnum.PURCHASE_UNITS]: TrendedMetricsDataKey.PURCHASE_UNITS,
  [TrendedMetricsEnum.PURCHASE_VALUE]: TrendedMetricsDataKey.PURCHASE_VALUE,
  [TrendedMetricsEnum.SHARE_BY_VALUE]: TrendedMetricsDataKey.SHARE_BY_VALUE,
  [TrendedMetricsEnum.SHARE_BY_OCCASIONS]:
    TrendedMetricsDataKey.SHARE_BY_OCCASIONS
}

export const initialDropdownState: SelectDataProps = {
  value: startCase(TrendedMetricsEnum.PURCHASE_VALUE.toLowerCase()),
  key: TrendedMetricsEnum.PURCHASE_VALUE
}

export const trendedMetricEnumList = Object.values(TrendedMetricsEnum).map(
  i => ({
    key: i,
    value: startCase(i.toLowerCase())
  })
)
