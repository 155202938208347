import { ChartSelector } from 'data/selectors'
import { SELECT_ALL_PRODUCT_KEY } from 'pages/reports/reports/common/reportHooks/usePreselectProduct'
import { NoneDrillDown } from 'pages/reports/reports/common/types'
import { useSelector } from 'react-redux'
import { SelectDataProps } from 'services/helpers/dropdownUtils'
import { getCleanGroupSignature } from '../common/helpers'
import {
  FormattedData,
  TrendedMetricsEnumToKey,
  TrendedMetricsMultipleEnum,
  TrendedMetricsMultipleResponse
} from './types'

const generateDataset = (
  customGroups,
  formattedData,
  metricKey,
  drillDownEnabled: boolean,
  productDrillDownEnabled: boolean
) =>
  Object.entries<{
    data: TrendedMetricsMultipleResponse[]
  }>(formattedData).map(([key, value]) => {
    const [name, color] = getCleanGroupSignature(
      customGroups,
      key,
      drillDownEnabled,
      productDrillDownEnabled
    )

    return {
      dataset: _formatData(value.data, metricKey),
      color: drillDownEnabled || productDrillDownEnabled ? undefined : color,
      label: name
    }
  })

const _formatData = (
  rawData: TrendedMetricsMultipleResponse[],
  selectedMetrics: TrendedMetricsMultipleEnum | string
): FormattedData[] => {
  const correctedName = TrendedMetricsEnumToKey[selectedMetrics]
  return rawData.map(i => ({
    key: selectedMetrics,
    period: i.period,
    value: i[correctedName],
    warningLevel: i.warningLevel
  }))
}

interface FormattedDataset {
  label: string
  color: string
  dataset: FormattedData[]
}

type useFilterRawDataMetrics = {
  leftMetric: SelectDataProps
  rightMetric: SelectDataProps
  drillDown: SelectDataProps
  selectedProducts: SelectDataProps[]
}

export const useFilterRawData = (
  rawData: TrendedMetricsMultipleResponse[],
  selectedGroups: SelectDataProps[],
  options: useFilterRawDataMetrics
): [
  TrendedMetricsMultipleResponse[][],
  FormattedDataset[],
  FormattedDataset[]
] => {
  const { leftMetric, rightMetric, drillDown, selectedProducts } = options

  const customGroups = useSelector(ChartSelector.customGroups)

  const formattedData = rawData.reduce(
    (acc, curr) =>
      selectedGroups.find(item => curr.key.includes(item.key))
        ? {
            ...acc,
            [curr.key]: { data: [...(acc[curr.key]?.data || []), curr] }
          }
        : acc,
    {}
  )

  const isRetailerDrillDown = drillDown.key !== NoneDrillDown

  const isProductDrillDownEnabled = !selectedProducts.find(
    i => i.key === SELECT_ALL_PRODUCT_KEY
  )

  const filteredData =
    selectedGroups.map(group =>
      rawData?.filter(data => data.key.includes(group.key))
    ) || []

  const leftData = generateDataset(
    customGroups,
    formattedData,
    leftMetric.key,
    isRetailerDrillDown,
    isProductDrillDownEnabled
  )

  const rightData = generateDataset(
    customGroups,
    formattedData,
    rightMetric.key,
    isRetailerDrillDown,
    isProductDrillDownEnabled
  )

  return [filteredData, leftData, rightData]
}
