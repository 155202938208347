import { Button } from 'antd'
import { COLOR, SPACE } from 'services/styles'
import { FontSize } from 'services/styles/misc'
import styled from 'styled-components'

export const SpaceAround = styled.span`
  padding: 0px ${SPACE.tiny}px;
`

export const SortButton = styled(Button)`
  background-color: transparent;
  color: ${COLOR.textGrey};
  height: ${SPACE.large}px;
  font-size: ${FontSize.MEDIUM}px;
  border: 0;
  padding: 0;
  box-shadow: none;

  margin-right: ${SPACE.tiny}px;

  &:hover,
  &:focus {
    color: ${COLOR.textGrey};
    background-color: transparent;
  }
`
