import { Select, Tooltip } from 'antd'
import { SelectProps } from 'antd/lib/select'
import React, {
  CSSProperties,
  Dispatch,
  ReactNode,
  SetStateAction
} from 'react'
import {
  SelectDataProps,
  SelectDataPropsWithId
} from 'services/helpers/dropdownUtils'

interface props extends SelectProps<any> {
  customClass?: string
  selectableMenu: SelectDataPropsWithId[]
  placeholder?: string
  item: SelectDataProps
  setItem: Dispatch<SetStateAction<SelectDataProps>>
  disabledIds?: any[]
  style?: CSSProperties
  disabled?: boolean
  tooltip?: ReactNode | string
}

const SelectMenu = ({
  customClass,
  placeholder,
  item,
  setItem,
  selectableMenu,
  disabledIds,
  style,
  disabled,
  tooltip,
  ...rest
}: props) => {
  return (
    <Tooltip
      placement="bottom"
      overlayStyle={{ whiteSpace: 'normal' }}
      title={tooltip}
    >
      <Select
        {...rest}
        disabled={disabled}
        placeholder={placeholder}
        value={item.value}
        style={{
          width: '100%',
          minWidth: 150,
          ...style
        }}
        className={customClass}
        onChange={(_, option: SelectDataProps) => setItem(option)}
        dropdownAlign={{ overflow: { adjustY: 0 } }}
        defaultActiveFirstOption={false}
      >
        {selectableMenu.map(i => (
          <Select.Option
            disabled={disabledIds?.includes(i.key)}
            key={i.key + ''}
            value={i.value}
          >
            {i.value}
          </Select.Option>
        ))}
      </Select>
    </Tooltip>
  )
}

export default SelectMenu
