import * as Sentry from '@sentry/react'
import axios from 'axios'
import axiosRetry from 'axios-retry'
import { store } from 'data'
import { firebaseGetCurrentUser } from 'services/firebase'
import camelize from 'services/helpers/objectCasing/camelize'
import snakeize from 'services/helpers/objectCasing/snakeize'

axios.interceptors.request.use(async req => {
  const token = await firebaseGetCurrentUser()?.getIdToken()
  const user = store.getState()?.user
  const viewAs = user?.viewAs && { 'X-View-As': user?.viewAs?.id }
  req.headers = {
    ...req.headers,
    Authorization: `Bearer ${token}`,
    ...viewAs
  }
  if (req.data) req.data = snakeize(req.data)
  return req
})

axios.interceptors.response.use(
  res => {
    if (res.data) res.data = camelize(res.data)
    return res
  },
  error => {
    error instanceof Error && Sentry.captureException(error)
    return Promise.reject(error)
  }
)

axiosRetry(axios, { retries: 3 })
