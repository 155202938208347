import { getter } from '../accessor'
import {
    isBlank,
    isDate,
    isFunction,
    isNumeric,
    isPresent,
    isString
} from '../utils'
import { isCompositeFilterDescriptor } from './filter-descriptor.interface'

var logic = {
    'or': {
        concat: function(acc, fn) { return function(a) { return acc(a) || fn(a) } },
        identity: function() { return false }
    },
    'and': {
        concat: function(acc, fn) { return function(a) { return acc(a) && fn(a) } },
        identity: function() { return true }
    }
}
var operatorsMap = {
    contains: function(a, b) { return (a || '').indexOf(b) >= 0 },
    doesnotcontain: function(a, b) { return (a || '').indexOf(b) === -1 },
    doesnotendwith: function(a, b) { return (a || '').indexOf(b, (a || '').length - (b || '').length) < 0 },
    doesnotstartwith: function(a, b) { return (a || '').lastIndexOf(b, 0) === -1 },
    endswith: function(a, b) { return (a || '').indexOf(b, (a || '').length - (b || '').length) >= 0 },
    eq: function(a, b) { return a === b },
    gt: function(a, b) { return a > b },
    gte: function(a, b) { return a >= b },
    isempty: function(a) { return a === '' },
    isnotempty: function(a) { return a !== '' },
    isnotnull: function(a) { return isPresent(a) },
    isnull: function(a) { return isBlank(a) },
    lt: function(a, b) { return a < b },
    lte: function(a, b) { return a <= b },
    neq: function(a, b) { return a != b },
    startswith: function(a, b) { return (a || '').lastIndexOf(b, 0) === 0 }
}
var dateRegExp = /^\/Date\((.*?)\)\/$/
var convertValue = function(value, ignoreCase) {
    if (value != null && isString(value)) {
        var date = dateRegExp.exec(value)
        if (date) {
            return new Date(+date[1]).getTime()
        } else if (ignoreCase) {
            return value.toLowerCase()
        }
    } else if (value != null && isDate(value)) {
        return value.getTime()
    }
    return value
}
var typedGetter = function(prop, value, ignoreCase) {
    if (!isPresent(value)) {
        return prop
    }
    var acc = prop
    if (isString(value)) {
        var date = dateRegExp.exec(value)
        if (date) {
            value = new Date(+date[1])
        } else {
            acc = function(a) {
                var x = prop(a)
                if (typeof x === 'string' && ignoreCase) {
                    return x.toLowerCase()
                } else {
                    return isNumeric(x) ? x + '' : x
                }
            }
        }
    }
    if (isDate(value)) {
        return function(a) {
            var x = acc(a)
            return isDate(x) ? x.getTime() : x
        }
    }
    return acc
}
var transformFilter = function(_a) {
    var field = _a.field, ignoreCase = _a.ignoreCase, value = _a.value,
      operator = _a.operator
    field = !isPresent(field) ? function(a) { return a } : field
    ignoreCase = isPresent(ignoreCase) ? ignoreCase : true
    var itemProp = typedGetter(isFunction(field) ? field : getter(field, true), value, ignoreCase)
    value = convertValue(value, ignoreCase)
    var op = isFunction(operator) ? operator : operatorsMap[operator]
    return function(a) { return op(itemProp(a), value, ignoreCase) }
}
/**
 * @hidden
 */
export var transformCompositeFilter = function(filter) {
    var combiner = logic[filter.logic]
    return filter.filters
      .filter(isPresent)
      .map(function(x) { return isCompositeFilterDescriptor(x) ? transformCompositeFilter(x) : transformFilter(x) })
      .reduce(combiner.concat, combiner.identity)
}
