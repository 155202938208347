import EditRoleDialog from 'components/dialogs/Organisation/EditRoleDialog'
import ShareDialog from 'components/dialogs/ShareDialog'
import CreateOrEditCustomGroup from './CustomGroup/CreateOrEditCustomGroup'
import GroupsBrowser from './CustomGroup/GroupsBrowser'
import CustomPeriod from './CustomPeriod'
import DrillDownDialog from './DrillDownDialog'
import GenericDeleteDialog from './Generic/deleteDialog'
import GenericSaveDialog from './Generic/saveDialog'
import InviteDialog from './Organisation/InviteDialog'
import PurchasedCategoriesDialog from './Organisation/PurchasedCategoriesDialog'
import ResendInvitationDialog from './Organisation/ResendInvitation'
import QueryErrorDialog from './QueryErrorDialog'
import WarningDialog from './WarningDialog'

export enum DialogTypes {
  REPORT = 'REPORT',
  SELECTION = 'SELECTION',
  FILE = 'FILE',
  USER = 'USER'
}

export const DialogKeys = {
  RENAME_REPORT: 'RENAME_REPORT',
  SAVE_GENERIC: 'SAVE_GENERIC',
  DELETE_GENERIC: 'DELETE_GENERIC',
  QUERY_ERROR: 'QUERY_ERROR',
  CREATE_OR_EDIT_CUSTOM_GROUP: 'CREATE_OR_EDIT_CUSTOM_GROUP',
  WARNING: 'WARNING',
  GROUPS_BROWSER: 'GROUPS_BROWSER',
  CUSTOM_PERIOD: 'CUSTOM_PERIOD',
  INVITE: 'INVITE',
  RESEND_INVITATION: 'RESEND_INVITATION',
  EDIT_ROLE: 'EDIT_ROLE',
  SHARE: 'SHARE',
  PURCHASED_CATEGORIES: 'BILLING',
  DRILL_DOWN_SELECT_RETAILER: 'DRILL_DOWN'
}

export {
  GenericSaveDialog,
  GenericDeleteDialog,
  QueryErrorDialog,
  CreateOrEditCustomGroup,
  WarningDialog,
  GroupsBrowser,
  InviteDialog,
  ResendInvitationDialog,
  EditRoleDialog,
  ShareDialog,
  PurchasedCategoriesDialog,
  CustomPeriod,
  DrillDownDialog
}
