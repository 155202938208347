import React from 'react'
import { TypeAttributes } from '../@types/common'
import Tooltip from '../Tooltip'
import Whisper from '../Whisper'

interface Props {
  message?: React.ReactNode;
  children?: React.ReactElement | ((props: any, ref) => React.ReactElement);
  placement?: TypeAttributes.Placement | TypeAttributes.Placement4;
}

export default function appendTooltip ({ message, ...rest }: Props) {
  return <Whisper speaker={<Tooltip>{message}</Tooltip>} {...rest} />
}
