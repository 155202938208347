import { getter } from './accessor'
import { isDate, isNumeric, isPresent } from './utils'

var valueToString = function(value) {
    value = isPresent(value) && value.getTime ? value.getTime() : value
    return value + ''
}
/**
 * @hidden
 */
export var groupCombinator = function(field) {
    var prop = getter(field, true)
    var position = 0
    return function(agg, value) {
        agg[field] = agg[field] || {}
        var groupValue = prop(value)
        var key = valueToString(groupValue)
        var values = agg[field][key] || { __position: position++, aggregates: {}, items: [], value: groupValue }
        values.items.push(value)
        agg[field][key] = values
        return agg
    }
}
/**
 * @hidden
 */
export var expandAggregates = function(result) {
    if (result === void 0) { result = {} }
    Object.keys(result).forEach(function(field) {
        var aggregates = result[field]
        Object.keys(aggregates).forEach(function(aggregate) {
            aggregates[aggregate] = aggregates[aggregate].result()
        })
    })
    return result
}
var aggregatesFuncs = function(name) {
    return ({
        average: function() {
            var value = 0
            var count = 0
            return {
                calc: function(curr) {
                    if (isNumeric(curr)) {
                        value += curr
                        count++
                    } else {
                        value = curr
                    }
                },
                result: function() { return isNumeric(value) ? value / count : value }
            }
        },
        count: function() {
            var state = 0
            return {
                calc: function() { return state++ },
                result: function() { return state }
            }
        },
        max: function() {
            var state = Number.NEGATIVE_INFINITY
            return {
                calc: function(value) {
                    state = isNumeric(state) || isDate(state) ? state : value
                    if (state < value && (isNumeric(value) || isDate(value))) {
                        state = value
                    }
                },
                result: function() { return state }
            }
        },
        min: function() {
            var state = Number.POSITIVE_INFINITY
            return {
                calc: function(value) {
                    state = isNumeric(state) || isDate(state) ? state : value
                    if (state > value && (isNumeric(value) || isDate(value))) {
                        state = value
                    }
                },
                result: function() { return state }
            }
        },
        sum: function() {
            var state = 0
            return {
                calc: function(value) { return state += value },
                result: function() { return state }
            }
        }
    }[name]())
}
/**
 * @hidden
 */
export var aggregatesCombinator = function(descriptors) {
    var functions = descriptors.map(function(descriptor) {
        var fieldAccessor = getter(descriptor.field, true)
        var aggregateName = (descriptor.aggregate || '').toLowerCase()
        var aggregateAccessor = getter(aggregateName, true)
        return function(state, value) {
            var fieldAggregates = state[descriptor.field] || {}
            var aggregateFunction = aggregateAccessor(fieldAggregates)
              || aggregatesFuncs(aggregateName)
            aggregateFunction.calc(fieldAccessor(value))
            fieldAggregates[descriptor.aggregate] = aggregateFunction
            state[descriptor.field] = fieldAggregates
            return state
        }
    })
    return function(state, value) { return functions.reduce(function(agg, calc) { return calc(agg, value) }, state) }
}
/**
 * @hidden
 * Adds the value to the `arr` and produces a new array.
 *
 * > The original array will be modified.
 */
export var concat = function(arr, value) {
    arr.push(value)
    return arr
}
/**
 * @hidden
 * Returns a reducer that will apply the specified transformation to the value.
 */
export var map = function(transform) { return (function(reduce) { return (function(acc, curr, index) { return reduce(acc, transform(curr, index)) }) }) }
/**
 * @hidden
 * Returns a reducer that will filter out items which do not match the
 *   `Predicate`.
 */
export var filter = function(predicate) { return (function(reduce) { return (function(acc, curr) { return predicate(curr) ? reduce(acc, curr) : acc }) }) }
/**
 * @hidden
 */
export var isTransformerResult = function(source) {
    return isPresent(source.__value)
}
var reduced = function(x) {
    if (isTransformerResult(x)) {
        return x
    }
    return {
        __value: x,
        reduced: true
    }
}
/**
 * @hidden
 * Returns a reducer that will take the specified number of items.
 */
export var take = function(count) { return (function(reduce) { return (function(acc, curr) { return count-- > 0 ? reduce(acc, curr) : reduced(acc) }) }) }
/**
 * @hidden
 * Returns a reducer that will take the specified number of items.
 */
export var takeWhile = function(predicate) { return (function(reduce) { return (function(acc, curr) { return predicate(curr) ? reduce(acc, curr) : reduced(acc) }) }) }
/**
 * @hidden
 * Returns a reducer that will skip the specified number of items.
 */
export var skip = function(count) { return (function(reduce) { return (function(acc, curr) { return count-- <= 0 ? reduce(acc, curr) : acc }) }) }
/**
 * @hidden
 * Transforms the data by applying the supplied transformer.
 */
export var exec = function(transform, initialValue, data) {
    var result = initialValue
    for (var idx = 0, length_1 = data.length; idx < length_1; idx++) {
        result = transform(result, data[idx], idx)
        if (isTransformerResult(result)) {
            result = result.__value
            break
        }
    }
    return result
}
