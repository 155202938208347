import { ExclamationCircleFilled } from '@ant-design/icons'
import { Col, Modal, Row } from 'antd'
import {
  LoaderBackground,
  LoaderWrapper
} from 'components/charts/CustomGroupsList/style'
import SelectList from 'components/dialogs/CustomGroup/common/SelectList'
import { WarningTag } from 'components/dialogs/CustomGroup/CreateOrEditCustomGroup/style'
import { DialogKeys, WarningDialog } from 'components/dialogs/index'
import { defaultCompactColors } from 'components/elements/CustomColorPicker/types'
import DefaultLoader from 'components/elements/DefaultLoader'
import FlexContainer from 'components/elements/FlexContainer'
import StandardButton from 'components/elements/StandardStyledButton'
import { CancelIcon } from 'components/elements/SvgIcon/styles'
import { DialogActions } from 'data/actions'
import { ChartSelector } from 'data/selectors'
import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import handleBannerChange from 'services/helpers/handleBannerChange'
import handleBuyerGroupChange from 'services/helpers/handleBuyerGroupChange'
import handleProductChange from 'services/helpers/handleProductChange'
import { SPACE } from 'services/styles'
import { ButtonHeight } from 'services/styles/misc'
import './style.scss'
import { AlertMessage, CreateCustomGroupProps } from './types'

const modalWidth = 10

const CreateOrEditCustomGroup: FC<CreateCustomGroupProps> = ({
  title,
  onCancel,
  onComplete,
  prefilledGroup = undefined,
  handleEditGroup,
  handleCreateGroup,
  selectableOptions,
  isFetching
}) => {
  const dispatch = useDispatch()

  const [isLoading, setIsLoading] = useState(false)
  const customGroups = useSelector(ChartSelector.customGroups)
  const [disabledBuyerGroupsValues, setDisabledBuyerGroupsValues] = useState([])
  const [disabledBannerValues, setDisabledBannerValues] = useState([])
  const [selectedProducts, setSelectedProducts] = useState<string[]>(
    prefilledGroup?.products || []
  )

  const [selectedBanners, setSelectedBanners] = useState<string[]>(
    prefilledGroup?.chains || []
  )

  const [selectedBuyerGroups, setSelectedBuyerGroups] = useState<string[]>(
    prefilledGroup?.buyerGroups || []
  )

  const [groupName, setGroupName] = useState<string>(
    prefilledGroup?.name || `Group ${customGroups.length + 1}`
  )

  const [color, setColor] = useState<string>(prefilledGroup?.color)

  const [draftId, setDraftId] = useState<string>(null)

  const handleComplete = newGroup => {
    if (onComplete) return onComplete(newGroup)
    dispatch(
      DialogActions.hideDialog({ key: DialogKeys.CREATE_OR_EDIT_CUSTOM_GROUP })
    )
  }
  const handleCancel = () => {
    if (onCancel) return onCancel()
    dispatch(
      DialogActions.hideDialog({ key: DialogKeys.CREATE_OR_EDIT_CUSTOM_GROUP })
    )
  }

  const updateEditGroupModal = newGroup => {
    dispatch(
      DialogActions.showDialog({
        key: DialogKeys.CREATE_OR_EDIT_CUSTOM_GROUP,
        component: (
          <CreateOrEditCustomGroup
            title="Edit Saved Selection"
            prefilledGroup={newGroup}
            onCancel={onCancel}
            onComplete={onComplete}
            handleEditGroup={handleEditGroup}
          />
        )
      })
    )
  }

  useEffect(() => {
    _handleBuyerGroupChange(prefilledGroup?.buyerGroups || [])
    _handleBannerChange(prefilledGroup?.chains || [])

    if (!color) {
      const usedColors = customGroups.map(i => i.color.toUpperCase())
      const preselectColor =
        defaultCompactColors.filter(i => !usedColors.includes(i))?.[0] ||
        '#009CE0'
      setColor(preselectColor)
    }
  }, [])

  const handleConfirm = (onOk, text, newGroup, destroyOnComplete) => {
    const onConfirmClick = async () => {
      await onOk(newGroup)
      !destroyOnComplete && updateEditGroupModal(newGroup)
    }
    const onCancelClick = () => updateEditGroupModal(newGroup)

    dispatch(
      DialogActions.showDialog({
        key: DialogKeys.WARNING,
        component: (
          <WarningDialog
            title="Confirmation"
            text={text}
            onOk={onConfirmClick}
            onCancel={onCancelClick}
          />
        )
      })
    )
  }

  const handleCreateNewGroup = async ({
    destroyOnComplete
  }: {
    destroyOnComplete: boolean
  }) => {
    const newGroup = {
      name: groupName,
      products: selectedProducts,
      chains: selectedBanners,
      buyerGroups: selectedBuyerGroups,
      color
    }
    setIsLoading(true)
    const data = await handleCreateGroup(draftId, newGroup)
    !draftId && setDraftId(data?.id)
    destroyOnComplete && handleComplete(data)
  }

  const _handleEditGroup = async ({
    destroyOnComplete
  }: {
    destroyOnComplete: boolean
  }) => {
    const onOk = async newGroup => {
      setIsLoading(true)
      const data = await handleEditGroup(prefilledGroup.id, newGroup)
      destroyOnComplete && handleComplete(data)
    }
    handleConfirm(
      onOk,
      'Saving this selection will update other report(s) that uses this' +
        ' selection. Are you sure?',
      {
        ...prefilledGroup,
        name: groupName,
        products: selectedProducts,
        chains: selectedBanners,
        buyerGroups: selectedBuyerGroups,
        color
      },
      destroyOnComplete
    )
  }

  const _handleBuyerGroupChange = (newChange: string[]) => {
    handleBuyerGroupChange(
      newChange,
      setSelectedBuyerGroups,
      setDisabledBuyerGroupsValues
    )
  }

  const _handleProductChange = (i: string[]) =>
    handleProductChange(selectedProducts, i, setSelectedProducts)

  const _handleBannerChange = (newChange: string[]) =>
    handleBannerChange(newChange, setSelectedBanners, setDisabledBannerValues)

  const handleOnComplete = ({
    destroyOnComplete
  }: {
    destroyOnComplete: boolean
  }) => {
    if (!groupName.length) return alert(AlertMessage.missingGroupName)
    if (selectedBanners.length === 0) {
      return alert(AlertMessage.noBannerSelected)
    }
    if (selectedBuyerGroups.length === 0) {
      return alert(AlertMessage.noBuyerGroupSelected)
    }

    prefilledGroup.id
      ? _handleEditGroup({ destroyOnComplete })
      : handleCreateNewGroup({ destroyOnComplete })
  }
  return (
    <Modal
      title={title}
      visible
      onCancel={handleCancel}
      footer={
        <FlexContainer>
          <WarningTag icon={<ExclamationCircleFilled />} color="warning">
            If you can’t find your products here, try broadening your period
            selection
          </WarningTag>
          <StandardButton
            data-testid="btn-Save Changes"
            onClick={() => handleOnComplete({ destroyOnComplete: true })}
            text="Save Changes"
            style={{ height: ButtonHeight.MEDIUM }}
          />
        </FlexContainer>
      }
      closeIcon={
        <CancelIcon
          width={ButtonHeight.SMALL}
          height={ButtonHeight.SMALL}
          style={{ marginTop: SPACE.tiny * 2.75 }}
        />
      }
      width={`calc(100vw - ${modalWidth}vw)`}
      cancelButtonProps={{ hidden: true }}
      bodyStyle={{ paddingTop: SPACE.large / 2 }}
      centered
    >
      {(isLoading || isFetching) && (
        <>
          <LoaderBackground />
          <LoaderWrapper>
            <DefaultLoader withLoadingText />
          </LoaderWrapper>
        </>
      )}

      <Row>
        <Col span={24}>
          <SelectList
            orgOptions={selectableOptions}
            setGroupName={setGroupName}
            groupName={groupName}
            color={color}
            setColor={setColor}
            selectedProducts={selectedProducts}
            handleProductChange={_handleProductChange}
          />
        </Col>
      </Row>
    </Modal>
  )
}

export default CreateOrEditCustomGroup
