import { Modal } from 'antd'
import { DialogKeys } from 'components/dialogs/index'
import { CancelIcon } from 'components/elements/SvgIcon/styles'
import { DialogActions } from 'data/actions'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { SPACE } from 'services/styles'
import { ButtonHeight } from 'services/styles/misc'

export default ({ title, onCancel, onOk, text }: Props) => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)

  const handleOnOk = async () => {
    setIsLoading(true)
    await onOk()
    dispatch(DialogActions.hideDialog({ key: DialogKeys.WARNING }))
  }

  const handleOnCancel = () => {
    onCancel && onCancel()
    dispatch(DialogActions.hideDialog({ key: DialogKeys.WARNING }))
  }

  return (
    <Modal
      title={title || 'Warning'}
      visible
      closeIcon={
        <CancelIcon
          width={ButtonHeight.SMALL}
          height={ButtonHeight.SMALL}
          style={{ marginTop: SPACE.tiny * 2.75 }}
        />
      }
      onOk={handleOnOk}
      confirmLoading={isLoading}
      okText={'Continue'}
      onCancel={handleOnCancel}
      cancelButtonProps={{ type: 'text' }}
      okButtonProps={{ style: { width: '50%' } }}
      width={450}
    >
      <p style={{ fontSize: 18 }}>{text}</p>
    </Modal>
  )
}

interface Props {
  title?: string
  onCancel?: () => void
  onOk: () => void
  text: string
}
