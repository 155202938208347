import { Breadcrumb, Space } from 'antd'
import { UserSelector } from 'data/selectors'
import OrgGroups from 'pages/organisation/details/components/groups'
import OrgPurchasedCategories from 'pages/organisation/details/components/purchasedCategories'
import OrgUsers from 'pages/organisation/details/components/users'
import { StyledBreadcrumb } from 'pages/organisation/details/styles'
import { useLoadData } from 'pages/organisation/hooks'
import { DBUsersProps, OrganisationProps } from 'pages/types'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Link, useHistory, useLocation } from 'react-router-dom'
import routes from 'routes'
import { SPACE } from 'services/styles'

const OrganisationDetails = () => {
  const location = useLocation<OrganisationProps>()
  const history = useHistory()
  const currentAccount = useSelector(UserSelector.userSelector)

  const { isLoading, data } = useLoadData(location?.state?.id)

  useEffect(() => {
    if (!location?.state) {
      history.replace(routes.organisation())
    }
  }, [location])

  if (!location?.state) return null

  const { roles, users, isAdmin, isSuperAdmin } =
    !isLoading && (data as DBUsersProps)

  const orgState = location?.state
  return (
    <>
      <StyledBreadcrumb>
        <Breadcrumb.Item>
          <Link to={routes.organisation()}>Organisation</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <span>{orgState?.name}</span>
        </Breadcrumb.Item>
      </StyledBreadcrumb>
      <Space direction="vertical" size={SPACE.large}>
        <OrgUsers
          orgState={orgState}
          data={users}
          roles={roles}
          currentUser={currentAccount}
          permission={isAdmin}
        />
        <OrgGroups orgId={orgState?.id} permission={isAdmin} />
        <OrgPurchasedCategories
          orgId={orgState?.id}
          permission={isSuperAdmin}
        />
      </Space>
    </>
  )
}

export default OrganisationDetails
