import axios from 'axios'
import config from 'config'
import pathToApi from 'services/helpers/pathToApi'

export const resetPasswordApi = async email => {
  const url = pathToApi(config.dashboardBaseUrl)
  const data = await axios.post(url('/user/password-reset'), email)
  return data
}

export const requestPasswordResetApi = async ({ id, ...props }) => {
  const url = pathToApi(config.dashboardBaseUrl)
  const data = await axios.post(url(`/user/password-reset/${id}`), props)
  return data
}
