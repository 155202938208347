import axios from 'axios'
import { DialogKeys, QueryErrorDialog } from 'components/dialogs'
import config from 'config'
import { DialogActions } from 'data/actions'
import { AlertMessage } from 'pages/reports/reports/crossPurchase/types'
import React from 'react'
import { cancelQuery } from 'services/api/requests/common/cancelQuery'
import pathToApi from 'services/helpers/pathToApi'
import { v4 as uuidv4 } from 'uuid'

const CancelToken = axios.CancelToken
let cancel

export const getCrossPurchaseApi = async (props: any[], dispatch: any) => {
  const newQueryId = uuidv4()
  try {
    cancel && cancel()
    const formattedProps = props.reduce(
      (acc, prop) =>
        prop.chains.length < 4
          ? [...acc, { ...prop, name: prop?.id || newQueryId }]
          : acc,
      []
    )
    props.find(prop => prop.chains.length > 3) &&
      dispatch(
        DialogActions.showDialog({
          key: DialogKeys.QUERY_ERROR,
          component: (
            <QueryErrorDialog
              errorMessage={AlertMessage.NOT_SUPPORT_OVER_THREE_RETAILERS}
            />
          )
        })
      )

    const url = pathToApi(config.dashboardBaseUrl)
    const { data } = await axios.post(
      url(`/query/reports/cross-purchase?query_id=${newQueryId}`),
      formattedProps,
      {
        cancelToken: new CancelToken(c => (cancel = c))
      }
    )
    return data
  } catch (e) {
    if (axios.isCancel(e)) {
      await cancelQuery(newQueryId)
      return null
    }
    dispatch(
      DialogActions.showDialog({
        key: DialogKeys.QUERY_ERROR,
        component: <QueryErrorDialog error={e} />
      })
    )
    return []
  }
}
