import { COLOR, SPACE } from 'services/styles'
import styled from 'styled-components'

export const MetricRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const MetricText = styled.h3`
  margin-bottom: 0;
  margin-left: ${SPACE.large};
  margin-right: ${SPACE.large};
`

export const SelectLabel = styled.p`
  margin-bottom: 0;
`

export const SelectError = styled.p`
  margin-top: ${SPACE.tiny};
  color: ${COLOR.danger};
  text-align: center;
`
