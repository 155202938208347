import { Col, Row, Skeleton } from 'antd'
import React from 'react'
import { BORDER_RADIUS, SPACE } from 'services/styles'

const singleRowHeight = 40
const numberOfRows = 8

const LoadingRows = () => (
  <Row>
    <Col span={24}>
      {[...Array(numberOfRows)].map((e, i) => (
        <div key={i} style={{ margin: `${SPACE.tiny}px 0px` }}>
          <Skeleton.Avatar
            style={{
              width: '100%',
              height: singleRowHeight,
              borderRadius: BORDER_RADIUS.medium
            }}
            active
            size={'small'}
            shape="square"
          />
        </div>
      ))}
    </Col>
  </Row>
)

export default LoadingRows
