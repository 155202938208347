import { Dropdown, Menu } from 'antd'
import { DialogKeys, ShareDialog } from 'components/dialogs'
import {
  DownloadIcon,
  DuplicateIcon,
  PencilIcon,
  StarIcon
} from 'components/elements/SvgIcon/styles'
import ButtonWithTooltip from 'components/page/ReportPage/header/components/ButtonWithTooltip'
import useSelectorTooltipText from 'components/page/ReportPage/header/hooks'
import { PageTitle } from 'components/page/StandardPage/style'
import { DialogActions } from 'data/actions'
import { ChartSelector } from 'data/selectors'
import { getReportFromKey, getReportRouteFromKey } from 'pages/reports/reports'
import React from 'react'
import {
  FaRegFileExcel,
  FaRegFileImage,
  FaRegShareSquare
} from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { COLOR, SPACE } from 'services/styles'
import CSVItem from '../export/csv'
import { IReportMetadata } from '../types'
import { ButtonRow, HoverDiv, OuterWrapper, Wrapper } from './styles'

export default ({
  exportFileName,
  title,
  dataSource,
  handleSaveClick,
  handleSaveAsNew,
  isCustom,
  handleRenameClick,
  handleExcelClick,
  handleCsvClick,
  resetState,
  handlePNGClick,
  reportMetadata,
  description
}: Props) => {
  const dispatch = useDispatch()
  const { canSave, canShare } = useSelector(ChartSelector.savedReportState)

  const handleShareClick = () =>
    canShare &&
    dispatch(
      DialogActions.showDialog({
        key: DialogKeys.SHARE,
        component: <ShareDialog />
      })
    )

  const renderTitle = () => {
    if (canShare) {
      return (
        <HoverDiv onClick={handleRenameClick}>
          <PageTitle>{title}</PageTitle>
          <PencilIcon style={{ fontSize: 20, marginLeft: SPACE.small }} />
        </HoverDiv>
      )
    }
    return <PageTitle>{title}</PageTitle>
  }

  const renderSubtitle = () => {
    return (
      <>
        from{' '}
        <Link
          to={{ pathname: getReportRouteFromKey(reportMetadata.reportKey) }}
          onClick={resetState}
        >
          {getReportFromKey(reportMetadata.reportKey)?.title}
        </Link>
      </>
    )
  }

  const downloadMenu = (
    <Menu>
      <Menu.Item icon={<FaRegFileImage />} onClick={handlePNGClick}>
        PNG
      </Menu.Item>
      <Menu.Item
        data-testid={'btn-excel-download'}
        icon={<FaRegFileExcel />}
        onClick={handleExcelClick}
      >
        Excel
      </Menu.Item>
      <CSVItem
        label={'CSV'}
        handleCsvClick={handleCsvClick}
        fileName={exportFileName}
        dataSource={dataSource}
      />
    </Menu>
  )

  const tooltipMessage = useSelectorTooltipText(isCustom)

  return (
    <OuterWrapper>
      <Wrapper>
        {renderTitle()}
        <ButtonRow>
          <ButtonWithTooltip
            tooltipText={tooltipMessage.save}
            text={'Save'}
            disabled={!!tooltipMessage.save}
            icon={
              <StarIcon
                fill={tooltipMessage.save ? COLOR.disabled : undefined}
                style={{ fontSize: 18, marginRight: SPACE.tiny }}
              />
            }
            onClick={canSave && handleSaveClick}
          />

          <ButtonWithTooltip
            data-testid={'btn-Save as New'}
            tooltipText={tooltipMessage.saveNew}
            text={'Save as New'}
            disabled={!!tooltipMessage.saveNew}
            icon={
              <DuplicateIcon
                fill={tooltipMessage.saveNew ? COLOR.disabled : undefined}
                style={{ fontSize: 18, marginRight: SPACE.tiny }}
              />
            }
            onClick={handleSaveAsNew}
          />

          <ButtonWithTooltip
            tooltipText={tooltipMessage.share}
            text={'Share'}
            disabled={!!tooltipMessage.share}
            icon={
              <FaRegShareSquare
                style={{ fontSize: 18, marginRight: SPACE.tiny }}
              />
            }
            onClick={handleShareClick}
          />

          <Dropdown
            trigger={['click']}
            children={
              <ButtonWithTooltip
                data-testid={'trigger-download-dropdown'}
                tooltipText={tooltipMessage.download}
                text={'Download'}
                icon={
                  <DownloadIcon
                    fill={tooltipMessage.download ? COLOR.disabled : undefined}
                    style={{ fontSize: 18, marginRight: SPACE.tiny }}
                  />
                }
                disabled={!!tooltipMessage.download}
              />
            }
            overlay={downloadMenu}
            arrow={false}
            disabled={!!tooltipMessage.download}
          />
        </ButtonRow>
      </Wrapper>
      {isCustom && renderSubtitle()}
      {/*
       temporary disable description
       {description && <ReportDescription>{description}</ReportDescription>}
       */}
    </OuterWrapper>
  )
}

interface Props {
  exportFileName: string
  title: string
  dataSource?: any
  handleSaveClick: () => void
  handleSaveAsNew: () => void
  isCustom: boolean
  handleRenameClick: () => void
  handleCsvClick: () => void
  handleExcelClick: () => void
  handlePNGClick: () => void
  resetState: () => void
  reportMetadata: IReportMetadata
  description?: string
}
