import { GridContainer } from 'components/charts/CustomGroups/style'
import FlexContainer from 'components/elements/FlexContainer'
import { ChartSelector } from 'data/selectors'
import React from 'react'
import { useSelector } from 'react-redux'
import CustomGroupTag from './CustomGroupTag'

export interface Props {
  onComplete: () => void
}

export default () => {
  const groups = useSelector(ChartSelector.customGroups)

  return (
    <div style={{ width: '100%' }}>
      <FlexContainer flexDirection="row" justifyContent="flex-start">
        <GridContainer>
          {Object.entries(groups).map(([key, item], _) => (
            <CustomGroupTag key={key} group={item} />
          ))}
        </GridContainer>
      </FlexContainer>
    </div>
  )
}
