import React from 'react'
import { toStartCase } from 'services/helpers/canonicalization'
import { changeTextIfLowNumber } from 'services/helpers/checkIsLowNumber'
import styled from 'styled-components'

const RenderCenteredWithWarning = props => (
  <td>
    <CenteredWrapper>
      {changeTextIfLowNumber(
        toStartCase(props.dataItem[props.field]),
        props.dataItem.warningLevel
      )}
    </CenteredWrapper>
  </td>
)

const CenteredWrapper = styled.span`
  text-align: center !important;
  margin: -8px -12px;
  padding: 8px 12px;
  line-height: inherit;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  outline: 0;
`

const CenteredWithWarning = props => <RenderCenteredWithWarning {...props} />

export default CenteredWithWarning
