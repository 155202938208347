import React from 'react'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import './style.scss'

const AnimatedVisibility = ({ children }) => (
  <ReactCSSTransitionGroup transitionName="fade">
    {children}
  </ReactCSSTransitionGroup>
)

export default AnimatedVisibility
