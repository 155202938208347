import { ChartSelector } from 'data/selectors'
import { ReportMeta } from 'pages/reports/reportMeta'
import { selectAllProductItem } from 'pages/reports/reports/common/reportHooks/usePreselectProduct'
import { NoneDrillDown } from 'pages/reports/reports/common/types'
import {
  ChartOptions,
  DefaultSelectableMetricsState,
  DemographicsApiMetricResponse,
  DemographicsApiResponse,
  SelectableMetrics,
  SelectedMetrics
} from 'pages/reports/reports/demographics/types'
import { cleanProductQuery } from 'pages/reports/utils/cleanBody'
import React, { Dispatch, useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { getDemographicsApi } from 'services/api/requests/reports/demographics'
import { toStartCase } from 'services/helpers/canonicalization'
import {
  PeriodToDropdownData,
  SelectDataProps
} from 'services/helpers/dropdownUtils'
import { CombineChainsMapping } from 'services/helpers/toChain'
import { separator, useFilterQueries } from '../common/helpers'

export const useLoadData = (
  setIsLoading: Dispatch<boolean>,
  selectedPeriod: SelectDataProps,
  selectedGroup: SelectDataProps,
  selectedProduct: SelectDataProps,
  drillDown: SelectDataProps
): DemographicsApiResponse => {
  const dispatch = useDispatch()

  const queries = useFilterQueries(
    ReportMeta.DEMOGRAPHICS.key,
    [selectedGroup],
    {
      hasPeriodFilter: true,
      selectedPeriod,
      drillDown,
      selectedProducts: [selectedProduct]
    }
  )

  const { isLoading, isFetching, data } = useQuery<DemographicsApiResponse>(
    queries,
    () => getDemographicsApi(queries, dispatch)
  )

  const formattedData =
    data &&
    Object.assign(
      {},
      ...Object.entries(data?.[0]).map(([key, item], _) => {
        const metricData = item as DemographicsApiMetricResponse[]
        return { [key]: metricData.filter(i => i.metric !== 'age_group') }
      })
    )

  useEffect(() => setIsLoading(isLoading || isFetching), [
    isLoading,
    isFetching
  ])

  return formattedData || {}
}

export const useSelectableMetrics = (drillDown: SelectDataProps) => {
  const groups = useSelector(ChartSelector.customGroups)
  const chartQuery = useSelector(ChartSelector.chartQuerySelector)

  const [selectableMetrics, setSelectableMetrics] = useState<SelectableMetrics>(
    DefaultSelectableMetricsState
  )

  const reducedPeriods = chartQuery.reduce(
    (acc, query) => [...acc, ...query.periods],
    []
  )

  let drillDownGroups

  const productsMenu = groups[0].products.map(i => ({
    key: String(i),
    value: cleanProductQuery([i])[0]
  }))
  const selectableProductsMenu = [selectAllProductItem, ...productsMenu]

  useEffect(() => {
    const loadedGroups = groups.map(i => ({
      key: i.id,
      value: i.name
    }))

    const isRetailerDrillDownEnabled = drillDown.key !== NoneDrillDown

    if (isRetailerDrillDownEnabled) {
      drillDownGroups = groups.reduce(
        (acc, group) => [
          ...acc,
          ...CombineChainsMapping[drillDown.key].map(chain => ({
            value: `[${toStartCase(chain)}]`,
            key: `${group.id}${separator}${group.name}${separator}${chain}`
          }))
        ],
        []
      )
    }

    setSelectableMetrics({
      ...selectableMetrics,
      [ChartOptions.GROUP]: isRetailerDrillDownEnabled
        ? drillDownGroups
        : loadedGroups,
      [ChartOptions.PERIOD]: reducedPeriods
        .slice()
        .reverse()
        .map(PeriodToDropdownData)
        .filter((v, i, a) => a.findIndex(t => t.key === v.key) === i),
      [ChartOptions.PRODUCT]: selectableProductsMenu
    })
  }, [chartQuery, drillDown])

  return selectableMetrics
}

export const usePreselectGroup = (
  selectableMetrics: SelectableMetrics,
  setSelectedMetrics: (key: ChartOptions, data: SelectDataProps) => void,
  selectedMetrics: SelectedMetrics
) => {
  useEffect(() => {
    // preselect groups
    if (
      !selectableMetrics[ChartOptions.GROUP]
        .map(i => i.key)
        .includes(selectedMetrics[ChartOptions.GROUP].key)
    ) {
      const firstGroup = selectableMetrics[ChartOptions.GROUP][0]
      setSelectedMetrics(ChartOptions.GROUP, firstGroup)
    }

    // preselect period
    const selectablePeriods = selectableMetrics[ChartOptions.PERIOD]
    if (selectablePeriods.length) {
      if (
        !selectedMetrics[ChartOptions.PERIOD].key ||
        !selectableMetrics[ChartOptions.PERIOD]
          .map(i => i.key)
          .includes(selectedMetrics[ChartOptions.PERIOD].key)
      ) {
        setSelectedMetrics(
          ChartOptions.PERIOD,
          selectableMetrics[ChartOptions.PERIOD][0]
        )
      }
    }
  }, [selectableMetrics])
}
