import { COLOR, SPACE } from 'services/styles'
import styled from 'styled-components'

export const MetricRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ChartTitle = styled.h3`
  margin: ${SPACE.small / 1.5}px;
  color: ${COLOR.dark};
  text-align: center;
  font-size: 18px;
`
