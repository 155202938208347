import { SPACE } from 'services/styles'
import { BORDER_RADIUS, ButtonHeight } from 'services/styles/misc'
import styled from 'styled-components'

export const GroupTagWrapper = styled.div`
  height: ${ButtonHeight.MEDIUM}px;
  border-radius: ${BORDER_RADIUS.standard * 3}px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: ${SPACE.tiny}px;
  margin-bottom: ${SPACE.tiny}px;

  &:hover {
    cursor: pointer;
  }
`

export const TextWidthEllipsis = styled.h6`
  width: 100%;
  color: white;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  padding-left: ${SPACE.small}px;
`
