import { Rule } from 'rc-field-form/lib/interface'

const passwordValidationRules = [
  {
    required: true,
    message: 'Password is required'
  },
  {
    min: 8,
    message: 'Password should be of minimum 8 characters length'
  },
  {
    message: 'Must contain alphanumeric & a special character',
    pattern: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&.+_<>:^])[A-Za-z\d@$!%.*#?&_+<>^:]{8,}$/
  }
] as Rule[]

const emailValidationRules = [
  {
    type: 'email',
    required: true,
    message: 'Enter a valid email'
  }
] as Rule[]

export { passwordValidationRules, emailValidationRules }
