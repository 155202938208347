import startCase from 'lodash/startCase'
import { ReportNames } from 'pages/reports/reportMeta'
import { SelectDataProps } from 'services/helpers/dropdownUtils'

export interface IFormattedData {
  dataValue: string[]
  dataCategories: string[]
  id: string
  tableData: any
  name: string
  color: string
}

export type ParamsSelectors = {
  drillDown?: SelectDataProps
  selectedGroups: SelectDataProps[]
  selectedProducts: SelectDataProps[]
  selectedPeriod: SelectDataProps | null
  selectedMetric: SelectDataProps
  selectedGroupBy: SelectDataProps
}

export interface IFormattedDatasets {
  datasets: IFormattedData[]
  datasetsTable: any
}

export enum TopBrandsMetricsEnum {
  WITHOUT_CATEGORY = 'WITHOUT_CATEGORY',
  WITH_CATEGORY = 'WITH_CATEGORY'
}

export enum TopBrandsGroupByEnum {
  BY_BRAND = 'BY_BRAND',
  BY_CATEGORY = 'BY_CATEGORY'
}

export const InitialTopBrandsMetricState = {
  key: TopBrandsMetricsEnum.WITHOUT_CATEGORY,
  value: startCase(TopBrandsMetricsEnum.WITHOUT_CATEGORY.toLowerCase())
}

export const InitialTopBrandsGroupByState = {
  key: TopBrandsGroupByEnum.BY_BRAND,
  value: startCase(TopBrandsGroupByEnum.BY_BRAND.toLowerCase())
}

export const TopBrandsMetricEnumList = Object.values(TopBrandsMetricsEnum).map(
  i => ({
    key: i,
    value: startCase(i.toLowerCase())
  })
)

export const TopBrandsGroupByEnumList = Object.values(TopBrandsGroupByEnum).map(
  i => ({
    key: i,
    value: startCase(i.toLowerCase())
  })
)

export const ReportTableColumnTitle = {
  [ReportNames.REPERTOIRE]: { percentage: '% of buying households' },
  [ReportNames.IN_BASKET]: { percentage: '% of baskets' }
}
