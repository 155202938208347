import { ExcelExport } from '@progress/kendo-react-excel-export'
import { GridColumn as DataColumn } from '@progress/kendo-react-grid'
import DataGridSortable from 'components/charts/DataGridSortable'
import { InvisibleCell } from 'components/elements/TableCells'
import WrapHeaderCell from 'components/elements/TableCells/WrapHeaderCell'
import { formatTooltipData } from 'pages/reports/reports/crossPurchase/helpers'
import React from 'react'
import { changeTextIfLowNumber } from 'services/helpers/checkIsLowNumber'

const RenderDataTable = ({ data, dataTableRef, fullData, fileName }) => {
  const dataGridData = data.map(i => ({
    ...i,
    key: changeTextIfLowNumber(
      formatTooltipData(i.key.join('|')),
      i.warningLevel
    )
  }))
  return (
    <ExcelExport data={fullData} ref={dataTableRef} fileName={fileName}>
      <DataGridSortable data={dataGridData}>
        <DataColumn
          field="group"
          title="Group"
          width={0}
          cell={InvisibleCell}
        />
        <DataColumn field="key" title="Retailers" />
        <DataColumn
          field="data"
          title="Penetration"
          format="{0:n1} %"
          headerCell={WrapHeaderCell}
          width={110}
        />
      </DataGridSortable>
    </ExcelExport>
  )
}

const MemoizedRenderTable = React.memo(RenderDataTable)
export default MemoizedRenderTable
