import axios from 'axios'
import config from 'config'
import pathToApi from 'services/helpers/pathToApi'
import { DBSavedReports } from './types'

export const getReportsViaId = async (reportId?: string) => {
  const url = pathToApi(config.dashboardBaseUrl)
  const { data } = await axios.get(
    url(`/user/saved-reports${reportId ? `?report_id=${reportId}` : ''}`)
  )
  return data as DBSavedReports
}

export const createReportApi = async ({ rawData }) => {
  let formattedProps = rawData
  if (rawData?.id) {
    formattedProps = { ...rawData, source: rawData.id }
  }
  const url = pathToApi(config.dashboardBaseUrl)
  const { data } = await axios.post(url('/user/saved-reports'), formattedProps)
  return data
}

export const updateReportApi = async ({ rawData, reportId }) => {
  const url = pathToApi(config.dashboardBaseUrl)
  const { data } = await axios.put(
    url(`/user/saved-reports/${reportId}`),
    rawData
  )
  return data
}

export const deleteReport = async ({ id }) => {
  const url = pathToApi(config.dashboardBaseUrl)
  const { data } = await axios.delete(url(`/user/saved-reports/${id}`))
  return data
}
