import { useUpdateSelectionsMenu } from 'components/charts/ReportParameterSelector/PeriodSelector/hooks'
import PeriodSelectMenu from 'components/charts/ReportParameterSelector/PeriodSelector/PeriodSelectMenu'
import { CustomPeriod, DialogKeys } from 'components/dialogs'
import FlexContainer from 'components/elements/FlexContainer'
import StandardButton from 'components/elements/StandardStyledButton'
import { CalendarIcon } from 'components/elements/SvgIcon/styles'
import { ChartActions, DialogActions } from 'data/actions'
import { ChartSelector } from 'data/selectors'
import { separator } from 'pages/reports/reports/common/helpers'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SelectDataProps } from 'services/helpers/dropdownUtils'
import { toRollingPeriod } from 'services/helpers/toPeriod'
import { SPACE } from 'services/styles'

const PeriodSelector = () => {
  const dispatch = useDispatch()
  const periods = useSelector(ChartSelector.periodsList)
  const selectedPeriod = useSelector(ChartSelector.selectedPeriod)

  const selectionMenu = useUpdateSelectionsMenu()

  const handleDispatchDialog = (key, component: React.ReactElement) =>
    dispatch(DialogActions.showDialog({ key, component }))

  const handleSelectItem = (value: SelectDataProps) => {
    const [
      numberOfPeriod,
      periodType,
      rollingType,
      startDate
    ] = value.key.split(separator)

    const rollingPeriod = toRollingPeriod({
      startDate: Number(startDate),
      descendingPeriods: periods,
      rollingType,
      numberOfPeriod,
      periodType
    })

    dispatch(
      ChartActions.setPeriod({
        key: String(value.value),
        content: rollingPeriod
      })
    )
  }

  const handleOpenCustomPeriod = () =>
    handleDispatchDialog(DialogKeys.CUSTOM_PERIOD, <CustomPeriod />)

  return (
    <FlexContainer>
      <PeriodSelectMenu
        selectableMenu={selectionMenu}
        item={{ key: selectedPeriod.key }}
        setItem={handleSelectItem}
        customClass="bg-white"
      />
      <StandardButton
        onClick={handleOpenCustomPeriod}
        style={{
          margin: `0px ${SPACE.tiny}px`,
          width: SPACE.large,
          paddingTop: SPACE.tiny / 2
        }}
        icon={<CalendarIcon />}
      />
    </FlexContainer>
  )
}
export default PeriodSelector
