import React from 'react'
import styled from 'styled-components'

const RenderInvisibleCell = props => (
  <td>
    <InvisibleWrapper>{props.dataItem[props.field]}</InvisibleWrapper>
  </td>
)

const InvisibleWrapper = styled.span`
  text-align: center;
  margin: -8px -12px;
  padding: 8px 12px;
  line-height: inherit;
  display: block;
  white-space: initial;
  word-break: break-word;
  outline: 0;
  opacity: 0;
  overflow: hidden;
  height: 0;
  width: 0;
`

const InvisibleCell = props => <RenderInvisibleCell {...props} />

export default InvisibleCell
