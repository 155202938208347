import { Tooltip } from 'antd'
import CollapseDivider from 'components/dialogs/CustomPeriod/components/PeriodTable/CollapseDivider'
import PeriodsSummary from 'components/dialogs/CustomPeriod/components/PeriodTable/PeriodsSummary'
import {
  IndicatorCell,
  ScrollableTable,
  VerticalCellWrapper,
  VerticalText
} from 'components/dialogs/CustomPeriod/components/style'
import {
  formatTableData,
  isAvailablePeriodsSufficient
} from 'components/dialogs/CustomPeriod/helpers'
import {
  useIsStartDateValid,
  useScrollToLastCell
} from 'components/dialogs/CustomPeriod/hooks'
import { ErrorMessageBlock } from 'components/dialogs/CustomPeriod/style'
import { DateModeEnum } from 'components/dialogs/CustomPeriod/types'
import Table from 'components/elements/Table'
import React from 'react'
import { dateToWeekEnding, toRollingPeriod } from 'services/helpers/toPeriod'
import useToggle from 'services/hooks/useToggle'
import { COLOR, SPACE } from 'services/styles'

const defaultBorderColor = `1px solid ${COLOR.textGrey}`

const renderYearsHeader = years => (
  <thead>
    <tr style={{ backgroundColor: COLOR.antdGrey }}>
      {Object.entries<{ text: string; monthsInYear: string[] }>(years).map(
        ([year, value]) =>
          !!value.monthsInYear.length && (
            <th
              key={year}
              colSpan={value.monthsInYear.length}
              style={{ border: defaultBorderColor }}
            >
              {value.text}
            </th>
          )
      )}
    </tr>
  </thead>
)

interface PeriodsTableProps {
  mode: string
  data: any[]
  dataPeriodType: number
  dataRolling: number
  numberOfBlock: number
  selectedCell?: number
  onCellClick: (i) => void
}

// require: ascending periods
const PeriodsTable = ({
  mode,
  data,
  dataPeriodType,
  dataRolling,
  numberOfBlock,
  selectedCell,
  onCellClick
}: PeriodsTableProps) => {
  const presetLatestDate = mode === DateModeEnum.LATEST
  const isEndDateMode = mode === DateModeEnum.END

  const [showSummary, setShowSummary] = useToggle(false)

  const error = useIsStartDateValid(
    mode,
    data,
    selectedCell,
    undefined,
    dataRolling,
    numberOfBlock,
    dataPeriodType
  )
  useScrollToLastCell()

  const { years, months } = formatTableData(data)

  const selectedIndex = data.findIndex(i => i === selectedCell)

  const selectedRollingPeriods = toRollingPeriod({
    startDate: selectedCell,
    numberOfPeriod: numberOfBlock,
    periodType: dataPeriodType,
    rollingType: dataRolling,
    descendingPeriods: data.slice().reverse()
  })

  const isCellActive = element =>
    selectedRollingPeriods.flat().includes(element) &&
    (presetLatestDate || selectedCell)

  return (
    <>
      <ScrollableTable id={'scrollable-table'}>
        {renderYearsHeader(years)}
        <Table.Header
          titles={months}
          headerStyle={{
            border: defaultBorderColor,
            textAlign: 'center',
            backgroundColor: COLOR.dark,
            color: 'white'
          }}
        />
        <tbody>
          <Table.Row key={'table-date-selector'}>
            {data.map((i, index) => {
              const startOfPeriods = isEndDateMode
                ? index + 1 - dataPeriodType - (numberOfBlock - 1) * dataRolling
                : index
              const active = isCellActive(i)
              const isSelectable =
                !presetLatestDate &&
                isAvailablePeriodsSufficient(
                  mode,
                  data,
                  i,
                  dataPeriodType,
                  numberOfBlock,
                  dataRolling
                )
              return (
                <Table.Cell
                  key={i}
                  onClick={() => {
                    isSelectable && onCellClick(data[startOfPeriods])
                    selectedIndex === startOfPeriods && onCellClick(undefined)
                  }}
                  render={
                    <Tooltip
                      placement="bottom"
                      overlayStyle={{ whiteSpace: 'normal' }}
                      title={
                        presetLatestDate
                          ? ''
                          : !isSelectable &&
                            'Periods range is not sufficient for analysis.Please' +
                              ' select earlier start date.'
                      }
                    >
                      <VerticalCellWrapper
                        className={isSelectable && 'available-cell'}
                        style={{
                          border:
                            selectedIndex !== -1 &&
                            selectedIndex === startOfPeriods
                              ? '2px solid black'
                              : defaultBorderColor,
                          color: !active && !isSelectable && COLOR.textGrey,
                          backgroundColor: active
                            ? presetLatestDate
                              ? COLOR.grey + '50'
                              : '#188ECB50'
                            : !isSelectable && COLOR.antdGrey,
                          cursor:
                            !isSelectable &&
                            !(selectedIndex === index) &&
                            'not-allowed'
                        }}
                      >
                        <VerticalText>4 WE {dateToWeekEnding[i]}</VerticalText>
                      </VerticalCellWrapper>
                    </Tooltip>
                  }
                />
              )
            })}
          </Table.Row>
          <Table.Row key={'date-group-by'} rowStyle={{ height: SPACE.medium }}>
            {data.map(i => {
              const active = isCellActive(i)
              const activeIndex = selectedRollingPeriods.flat().indexOf(i) + 1
              return (
                <IndicatorCell
                  key={i}
                  style={{
                    border: `${
                      active ? '1px solid white' : defaultBorderColor
                    }`,
                    backgroundColor:
                      !!active && (presetLatestDate ? COLOR.grey : '#188ECB')
                  }}
                >
                  {!!active &&
                    activeIndex % dataPeriodType === 0 &&
                    activeIndex / dataPeriodType}
                </IndicatorCell>
              )
            })}
          </Table.Row>
        </tbody>
      </ScrollableTable>
      {!error && (
        <div className="custom-period-summary">
          <CollapseDivider
            collapsed={showSummary}
            setCollapsed={setShowSummary}
          />
          {showSummary && <PeriodsSummary periods={selectedRollingPeriods} />}
        </div>
      )}
      {error && <ErrorMessageBlock>{error}</ErrorMessageBlock>}
    </>
  )
}

export default PeriodsTable
