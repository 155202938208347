import { Button } from 'antd'
import { FaTrashAlt } from 'react-icons/fa'
import { COLOR, SPACE } from 'services/styles'
import styled from 'styled-components'

export const DeleteButton = styled(FaTrashAlt)`
  color: ${COLOR.textGrey};

  &:hover {
    color: ${COLOR.danger};
  }
`

export const StyledButton = styled(Button)`
  padding-top: ${SPACE.tiny / 2}px;
  background: transparent;
`
