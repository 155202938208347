import axios from 'axios'
import config from 'config'
import pathToApi from 'services/helpers/pathToApi'

export const getUsersApi = async orgId => {
  const url = pathToApi(config.dashboardBaseUrl)
  const { data } = await axios.get(url(`/organisation/${orgId}/users`))
  return data
}

export const deleteUserApi = async ({ orgId, userId }) => {
  const url = pathToApi(config.dashboardBaseUrl)
  const { data } = await axios.delete(
    url(`/organisation/${orgId}/user/${userId}`)
  )
  return data
}

export const sendInvitationApi = async ({ orgId, email, role }) => {
  const formattedProps = { email, roleId: role }
  const url = pathToApi(config.dashboardBaseUrl)
  const { data } = await axios.post(
    url(`/organisation/${orgId}/user`),
    formattedProps
  )

  return data
}

export const changeRoleApi = async ({ orgId, userId, id }) => {
  const url = pathToApi(config.dashboardBaseUrl)
  const data = await axios.put(url(`/organisation/${orgId}/role/${userId}`), {
    id
  })
  return data
}

export const getOtherUsersApi = async reportId => {
  const url = pathToApi(config.dashboardBaseUrl)

  const { data } = await axios.get(url(`/user/saved-reports/users/${reportId}`))
  return data.map((i, index) => ({
    key: index,
    name: i.firstName + ' ' + i.lastName,
    ...i
  }))
}

export const updatePermissionApi = async ({ reportId, data: postData }) => {
  const url = pathToApi(config.dashboardBaseUrl)
  const { data } = await axios.put(
    url(`/user/saved-reports/share/${reportId}`),
    postData
  )
  return data
}
