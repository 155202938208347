import { StandardSelectableOptions } from 'pages/reports/reports/common/reportHooks/useStandardSelectable'
import { Dispatch, useEffect, useState } from 'react'
import {
  DefaultDropdownState,
  SelectDataProps
} from 'services/helpers/dropdownUtils'

const usePreselectPeriod = (
  selectable: StandardSelectableOptions
): [SelectDataProps, Dispatch<SelectDataProps>] => {
  const [selectedTimePeriod, setSelectedTimePeriod] = useState(
    DefaultDropdownState
  )
  const timePeriods = selectable.timePeriods
  useEffect(() => {
    if (timePeriods && timePeriods.length) {
      const timePeriodIds = timePeriods.map(i => i.key)
      if (
        !selectedTimePeriod.key ||
        !timePeriodIds.includes(selectedTimePeriod.key)
      ) {
        setSelectedTimePeriod(timePeriods[0])
      }
    }
  }, [timePeriods])

  return [selectedTimePeriod, setSelectedTimePeriod]
}

export default usePreselectPeriod
