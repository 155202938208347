import { ChartQuerySelectorProps } from 'data/chart/selectors'
import { separator } from 'pages/reports/reports/common/helpers'
import CopyrightText from 'services/helpers/copyrightText'
import { dateToWeekEnding } from 'services/helpers/toPeriod'
import { COLOR } from 'services/styles'

const SHEET_NAME = {
  mainSheet: 'Report Data',
  productGroupsSheet: 'Product Groups',
  periodSheet: 'Rolling Period'
}
const CATEGORIES = {
  retailers: 'Retailers',
  products: 'Products',
  buyersGroups: 'Buyers Groups'
}
const periodDescription = {
  numberOfPeriod: 'Number of periods',
  periodType: 'Period type',
  rollingType: 'Period rolling type',
  startDate: 'Selected period'
}

const periodKeys = ['numberOfPeriod', 'periodType', 'rollingType', 'startDate']

export default (
  tableRef: any,
  params: ChartQuerySelectorProps[],
  period: { id?: string; value: string | number; key: string }
) => {
  //  @ts-ignore
  const subSheet = {
    columns: [{ autoWidth: true }, { autoWidth: true }, { autoWidth: true }],
    rows: []
  }
  const periodSheet = {
    columns: [{ autoWidth: true }, { autoWidth: true }, { autoWidth: true }],
    rows: []
  }
  const tableOptions = tableRef?.current?.workbookOptions()
  tableOptions.sheets = [...tableOptions.sheets, subSheet, periodSheet]
  const mainPage = tableOptions.sheets[0]
  const subPage = tableOptions.sheets[1]
  const periodPage = tableOptions.sheets[2]
  const subRowBuffer = subPage.rows
  const periodRowBuffer = periodPage.rows

  mainPage.name = SHEET_NAME.mainSheet
  subPage.name = SHEET_NAME.productGroupsSheet
  periodPage.name = SHEET_NAME.periodSheet

  const footerRow = {
    height: 20,
    cells: [{ value: CopyrightText, fontSize: 15, colSpan: 10, wrap: true }]
  }
  const categoryRow = {
    cells: [
      { value: CATEGORIES.retailers, colSpan: 3 },
      { value: CATEGORIES.products, colSpan: 2 },
      { value: CATEGORIES.buyersGroups, colSpan: 2 }
    ]
  }

  params.forEach(item => {
    const groupNameRow = {
      cells: [{ value: item.name, colSpan: 7, textAlign: 'center', bold: true }]
    }
    subRowBuffer.push({}, groupNameRow, categoryRow)

    for (
      let i = 0;
      i <
      Math.max(
        item.chains.length,
        item.products.length,
        item.buyerGroups.length
      );
      i++
    ) {
      const cells = []
      if (i === 0) {
        cells.push({
          colSpan: 3,
          value: item.chains.length ? item.chains[i].toString() : 'All Banners'
        })
        cells.push({
          colSpan: 2,
          value: item.products.length
            ? item.products[i].toString()
            : 'All Products'
        })
        cells.push({
          colSpan: 2,
          value: item.buyerGroups.length
            ? item.buyerGroups[i].toString()
            : 'All Buyers'
        })
      } else {
        cells.push({ value: item.chains?.[i]?.toString(), colSpan: 3 })
        cells.push({ value: item.products?.[i]?.toString(), colSpan: 2 })
        cells.push({ value: item.buyerGroups?.[i]?.toString(), colSpan: 2 })
      }

      subRowBuffer.push({ cells })
    }
  })

  // period sheet
  const [
    numberOfPeriod,
    periodType,
    rollingType,
    startDate,
    dateMode
  ] = period.key.split(separator)

  const periodAtt: any = {
    numberOfPeriod,
    periodType,
    rollingType,
    startDate,
    dateMode
  }

  const periodNameRow = {
    cells: [
      { value: period.value, colSpan: 6, textAlign: 'center', bold: true }
    ]
  }
  periodRowBuffer.push({}, periodNameRow)
  const periodAttRows = periodKeys.reduce((acc, att) => {
    let description = periodDescription[att]
    let periodAttValue =
      att === 'periodType' || att === 'rollingType'
        ? `${Number(periodAtt[att]) * 4} weeks`
        : periodAtt[att]

    if (att === 'startDate') {
      if (!periodAtt?.startDate) {
        periodAttValue = 'latest'
        //  @ts-ignore
      } else if (!periodAtt?.dateMode === 'end') {
        description = description + ' (end)'
        periodAttValue = `4 WE ${dateToWeekEnding[periodAttValue]}`
      } else {
        description = description + ' (start)'
        periodAttValue = `4 WE ${dateToWeekEnding[periodAttValue]}`
      }
    }

    return [
      ...acc,
      {
        cells: [
          { value: description, colSpan: 4 },
          { value: periodAttValue, colSpan: 2 }
        ]
      }
    ]
  }, [])

  periodAttRows.map(row => periodRowBuffer.push(row))

  mainPage.frozenRows = 2

  drawCellBorders(subRowBuffer)

  drawCellBorders(periodRowBuffer)

  tableOptions.sheets.forEach(sheet => sheet.rows.push({}, {}, footerRow))
  //  @ts-ignore
  tableRef.current.save(tableOptions)
}

const drawCellBorders = table => {
  table.forEach(row => {
    row?.cells?.forEach(cell => {
      cell.borderTop = { color: COLOR.dark, size: 1 }
      cell.borderRight = { color: COLOR.dark, size: 1 }
      cell.borderBottom = { color: COLOR.dark, size: 1 }
      cell.borderLeft = { color: COLOR.dark, size: 1 }
      cell.verticalAlign = 'top'
      cell.wrap = true
    })
  })
}
