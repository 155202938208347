import { periodToText } from 'services/helpers/toPeriod'

export const DefaultDropdownState: SelectDataProps = {
  key: null,
  value: null
}

export interface SelectDataProps {
  key: string
  value: string | number
  children?: string | number
}

export interface SelectDataPropsWithId extends SelectDataProps {
  id?: string
}

export interface SelectDataPropsWithColor extends SelectDataProps {
  color?: string
}

export const StringToSelectDataProps = (
  text: string | number
): SelectDataProps => ({
  key: String(text),
  value: text
})

export const PeriodToDropdownData = (
  periodList: number[]
): SelectDataProps => ({
  key: periodList.join('_'),
  value: periodToText(periodList)
})

export const StringToDropdownDataWithFn = (
  text: string,
  textFunction?: (text: string) => string
): SelectDataProps => ({
  key: text,
  value: textFunction ? textFunction(text) : text
})
