import { COLOR, SPACE } from 'services/styles'
import styled from 'styled-components'

interface WrapperProps {
  noSidePadding?: boolean
}

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: auto;
  padding: ${SPACE.small * 1.5}px
    ${(props: WrapperProps) => (props.noSidePadding ? 0 : SPACE.large)}px;
  height: 100%;
`
export const LogoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const PageTitle = styled.h3`
  color: ${COLOR.dark};
  margin-bottom: 0;
`

export const CopyrightTextComponent = styled.p`
  margin-left: ${SPACE.small}px;
`
