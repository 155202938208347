import { ExcelExport } from '@progress/kendo-react-excel-export'
import { GridColumn as DataColumn } from '@progress/kendo-react-grid'
import DataGridSortable from 'components/charts/DataGridSortable'
import {
  BulletCell,
  InvisibleCell,
  LeftTextWithWarning
} from 'components/elements/TableCells'
import WrapHeaderCell from 'components/elements/TableCells/WrapHeaderCell'
import { ReportNames } from 'pages/reports/reportMeta'
import {
  ReportTableColumnTitle,
  TopBrandsGroupByEnum
} from 'pages/reports/reports/topBrands/types'
import React from 'react'
import { toProperCase } from 'services/helpers/canonicalization'
import { SelectDataProps } from 'services/helpers/dropdownUtils'

interface Props {
  reportName: ReportNames.IN_BASKET | ReportNames.REPERTOIRE
  data: any
  dataTableRef: any
  groupBy: SelectDataProps
  fullData: any
  fileName: any
}

const RenderDataTable = ({
  reportName,
  data,
  dataTableRef,
  groupBy,
  fullData,
  fileName
}: Props) => {
  const groupByKey =
    groupBy.key === TopBrandsGroupByEnum.BY_BRAND ? 'brand' : 'category'

  const dataGridData = data.map(i => ({
    ...i,
    [groupByKey]: toProperCase(i[groupByKey])
  }))

  return (
    <ExcelExport data={fullData} ref={dataTableRef} fileName={fileName}>
      <DataGridSortable
        style={{
          height: groupBy.key !== TopBrandsGroupByEnum.BY_CATEGORY && 400
        }}
        data={dataGridData}
      >
        <DataColumn
          field="group"
          title="Group"
          width={0}
          cell={InvisibleCell}
        />
        <DataColumn
          field={groupByKey}
          title={toProperCase(groupByKey)}
          cell={LeftTextWithWarning}
        />
        <DataColumn
          field="percentage"
          title={ReportTableColumnTitle[reportName].percentage}
          width={90}
          headerCell={WrapHeaderCell}
          format="{0:n1} %"
        />
        <DataColumn field="indexed" title="Indexed Value" format="{0:n0}" />
        <DataColumn
          field="indexed"
          title="Indexed"
          cell={BulletCell}
          width={160}
        />
      </DataGridSortable>
    </ExcelExport>
  )
}

const MemoizedRenderTable = React.memo(RenderDataTable)
export default MemoizedRenderTable
