import { RGB } from 'color-blend/dist/types'
import { CustomGroup } from 'data/chart/types'
import { VennDiagramData } from 'reaviz/dist/src/VennDiagram/VennDiagram'
import { SelectDataProps } from 'services/helpers/dropdownUtils'
import { AtomicChainsEnum, CombineChainsEnum } from 'services/helpers/toChain'

export type ParamsSelectors = {
  selectedPeriod: SelectDataProps
  selectedGroups: SelectDataProps[]
  drillDown: SelectDataProps
  selectedProducts: SelectDataProps[]
}

export interface FormatData {
  id: string
  datasets: ({ group: CustomGroup; warningLevel: number } & VennDiagramData)[]
  name: string
  color: string
}

export interface FormatDatasets {
  datasets: FormatData[]
  datasetsTable: any[]
}

export const AlertMessage = {
  NOT_SUPPORT_OVER_THREE_RETAILERS:
    'Retailer Cross-Purchase report does not currently support more than 3 retailers at a time'
}

const transparency = 0.7

export const RetailersToColor = {
  [AtomicChainsEnum.LIQUORLAND]: { r: 228, g: 12, b: 0, a: transparency },
  [AtomicChainsEnum.DAN_MURPHYS]: { r: 29, g: 67, b: 31, a: transparency },
  [AtomicChainsEnum.FIRST_CHOICE_LIQUOR]: {
    r: 8,
    g: 59,
    b: 117,
    a: transparency
  },
  [AtomicChainsEnum.BWS]: { r: 235, g: 96, b: 40, a: transparency },
  [AtomicChainsEnum.LIQUORLAND_ONLINE]: {
    r: 228,
    g: 12,
    b: 0,
    a: transparency
  },
  [AtomicChainsEnum.DAN_MURPHYS_ONLINE]: {
    r: 29,
    g: 67,
    b: 31,
    a: transparency
  },
  [AtomicChainsEnum.FIRST_CHOICE_LIQUOR_ONLINE]: {
    r: 8,
    g: 59,
    b: 117,
    a: transparency
  },
  [AtomicChainsEnum.VINTAGE_CELLARS]: {
    r: 28,
    g: 24,
    b: 30,
    a: transparency
  },
  [AtomicChainsEnum.BWS_ONLINE]: { r: 235, g: 96, b: 40, a: transparency },
  [CombineChainsEnum.ONLINE]: {
    r: 228,
    g: 12,
    b: 0,
    a: transparency
  },
  [CombineChainsEnum.BRICK_AND_MORTAR]: {
    r: 29,
    g: 67,
    b: 31,
    a: transparency
  },
  [CombineChainsEnum.ENDEAVOUR]: {
    r: 8,
    g: 59,
    b: 117,
    a: transparency
  },
  [CombineChainsEnum.COLES_LIQUOR]: { r: 235, g: 96, b: 40, a: transparency },
  [CombineChainsEnum.ALL_BANNERS]: { r: 235, g: 96, b: 40, a: transparency }
}

export const toColor = ({ r, g, b }: RGB) => `rgb(${r},${g},${b})`
