import { createContext } from 'react'

interface ISideBarContext {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
}

export const SideBarContext = createContext<ISideBarContext>({
  isOpen: true,
  setIsOpen: () => {}
})
