import React from 'react'

export const MESSAGE_STATUS_ICONS = {
  info: null,
  success: null,
  error: null,
  warning: null
}

export const PROGRESS_STATUS_ICON = {
  success: null,
  active: null,
  fail: null
}
