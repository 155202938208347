import { Row } from 'antd'
import { SPACE } from 'services/styles'
import styled from 'styled-components'

export const CARD_HEIGHT = 450

export const CardTitle = styled.h4`
  margin-bottom: ${SPACE.tiny}px;
`

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const Container = styled(Row)`
  margin-top: ${SPACE.small}px;
`
