import { Col, Modal } from 'antd'
import ParamSelectors from 'components/dialogs/CustomPeriod/components/ParamSelectors'
import PeriodsTable from 'components/dialogs/CustomPeriod/components/PeriodTable'
import {
  BoldTextInput,
  Container,
  RowWrapper
} from 'components/dialogs/CustomPeriod/style'
import {
  CustomPeriodModalProps,
  DateModeEnum,
  initDateMode,
  initialCustomPeriod,
  initialNumberOfPeriod
} from 'components/dialogs/CustomPeriod/types'
import { DialogKeys } from 'components/dialogs/index'
import SelectMenu from 'components/elements/SelectMenu'
import { CancelIcon } from 'components/elements/SvgIcon/styles'
import { ChartActions, DialogActions } from 'data/actions'
import { ChartSelector } from 'data/selectors'
import { separator } from 'pages/reports/reports/common/helpers'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  SelectDataProps,
  StringToSelectDataProps
} from 'services/helpers/dropdownUtils'
import { toRollingPeriod } from 'services/helpers/toPeriod'
import { SPACE } from 'services/styles'
import { ButtonHeight } from 'services/styles/misc'
import { v4 as uuidv4 } from 'uuid'
import { useIsStartDateValid, useSelectNumberOfPeriod } from './hooks'

const CustomPeriod = ({ prefilledPeriod }: CustomPeriodModalProps) => {
  let initialValue = initialCustomPeriod
  if (prefilledPeriod) {
    const [
      prefilledNumberOfPeriod,
      prefilledPeriodType,
      prefilledRollingType,
      prefilledStartDate,
      prefilledDateMode
    ] = prefilledPeriod?.key.split(separator)

    initialValue = {
      dateMode:
        prefilledDateMode !== DateModeEnum.END ? null : prefilledDateMode,
      startDate: +prefilledStartDate,
      name: prefilledPeriod?.value,
      periodType: +prefilledPeriodType,
      rollingType: +prefilledRollingType,
      selectedNumberOfPeriod: StringToSelectDataProps(prefilledNumberOfPeriod)
    }
  }
  const title = prefilledPeriod
    ? 'Edit custom period'
    : 'Create a custom period for this report'

  const dispatch = useDispatch()
  const periods = useSelector(ChartSelector.periodsList)

  const [startDate, setStartDate] = useState<number>(initialValue.startDate)
  const [name, setName] = useState(initialValue.name)
  const [periodType, setPeriodType] = useState<number>(initialValue.periodType)
  const [rollingType, setRollingType] = useState<number>(
    initialValue.rollingType
  )

  const [
    selectedNumberOfPeriod,
    setSelectedNumberOfPeriod
  ] = useState<SelectDataProps>(initialValue.selectedNumberOfPeriod)

  const [dateMode, setDateMode] = useState<SelectDataProps>(
    StringToSelectDataProps(
      initialValue.dateMode ||
        (initialValue.startDate ? DateModeEnum.START : DateModeEnum.LATEST)
    )
  )

  const [numberOfPeriodList, setNumberOfPeriodList] = useState<
    SelectDataProps[]
  >([StringToSelectDataProps(initialNumberOfPeriod)])

  const startDateError = useIsStartDateValid(
    dateMode.key,
    periods.slice().reverse(),
    startDate,
    setStartDate,
    rollingType,
    +selectedNumberOfPeriod.key,
    periodType
  )

  useSelectNumberOfPeriod(
    periods,
    periodType,
    rollingType,
    numberOfPeriodList,
    setNumberOfPeriodList,
    selectedNumberOfPeriod,
    setSelectedNumberOfPeriod
  )

  const handleHideDispatchDialog = (key: string) =>
    dispatch(DialogActions.hideDialog({ key }))

  const onCancel = () => {
    handleHideDispatchDialog(DialogKeys.CUSTOM_PERIOD)
  }

  const onOk = () => {
    if (startDateError.length !== 0) {
      return alert('Your input is invalid. Please select a valid input.')
    }

    const rollingPeriod = toRollingPeriod({
      descendingPeriods: periods,
      rollingType,
      numberOfPeriod: +selectedNumberOfPeriod.key,
      periodType,
      startDate
    })

    const customPeriodKey = prefilledPeriod?.id || uuidv4()

    const newCustomPeriod = {
      key: customPeriodKey,
      periodType,
      rollingType,
      numberOfPeriods: selectedNumberOfPeriod.key,
      datePointer: startDate,
      dateMode: startDate && dateMode.key === DateModeEnum.END && dateMode.key,
      name
    }

    !prefilledPeriod
      ? dispatch(ChartActions.addCustomPeriod(newCustomPeriod))
      : dispatch(
          ChartActions.editCustomPeriod(customPeriodKey, newCustomPeriod)
        )

    dispatch(
      ChartActions.setPeriod({
        content: rollingPeriod,
        key: customPeriodKey
      })
    )

    handleHideDispatchDialog(DialogKeys.CUSTOM_PERIOD)
  }

  return (
    <Modal
      title={title}
      visible
      closeIcon={
        <CancelIcon
          width={ButtonHeight.SMALL}
          height={ButtonHeight.SMALL}
          style={{ marginTop: SPACE.tiny * 2.75 }}
        />
      }
      centered
      confirmLoading={false}
      okText={'Save'}
      width={820}
      cancelButtonProps={{ type: 'text' }}
      onCancel={onCancel}
      onOk={onOk}
    >
      <>
        <BoldTextInput
          value={name}
          onChange={e => setName(e.target.value)}
          placeholder="Please type in a custom period name"
        />
        <Container>
          <ParamSelectors
            selectedNumberOfPeriod={selectedNumberOfPeriod}
            numberOfPeriodList={numberOfPeriodList}
            periodType={periodType}
            setPeriodType={setPeriodType}
            setSelectedNumberOfPeriod={setSelectedNumberOfPeriod}
            rollingType={rollingType}
            setRollingType={setRollingType}
          />
        </Container>
        <Container style={{ paddingBottom: SPACE.small }}>
          <RowWrapper
            gutter={16}
            style={{ width: '100%', marginTop: SPACE.small }}
          >
            <Col span={24}>
              Select{' '}
              <span>
                <SelectMenu
                  style={{ width: 90, minWidth: 'auto' }}
                  customClass="bg-white"
                  selectableMenu={initDateMode}
                  item={dateMode}
                  setItem={(value: SelectDataProps) => {
                    if (value.key === DateModeEnum.LATEST) {
                      setStartDate(null)
                    }
                    setDateMode(value)
                  }}
                />
              </span>{' '}
              period
            </Col>
          </RowWrapper>
          <PeriodsTable
            mode={dateMode.key}
            data={periods.slice().reverse()}
            dataRolling={rollingType}
            dataPeriodType={periodType}
            numberOfBlock={+selectedNumberOfPeriod.key}
            onCellClick={setStartDate}
            selectedCell={startDate}
          />
        </Container>
      </>
    </Modal>
  )
}
export default CustomPeriod
