import ReportPage from 'components/page/ReportPage'
import { ReportMeta, ReportNames } from 'pages/reports/reportMeta'
import CrossPurchase from 'pages/reports/reports/crossPurchase'
import Demographics from 'pages/reports/reports/demographics'
import PurchaseDrivers from 'pages/reports/reports/purchaseDrivers'
import InBasket from 'pages/reports/reports/topBrands/inBasket'
import Repertoire from 'pages/reports/reports/topBrands/repertoire'
import TrendedMetrics from 'pages/reports/reports/trendedMetrics'
import TrendedMetricsMultiple from 'pages/reports/reports/trendedMetricsMultiple'
import React, { FC } from 'react'
import routes from 'routes'
import CrossPurchaseImage from 'static/reports/cross-purchase.png'
import DemographicsImage from 'static/reports/demographics.png'
import InBasketImage from 'static/reports/in-basket.png'
import PurchaseDriversImage from 'static/reports/purchase-drivers.png'
import RepertoireImage from 'static/reports/repertoire.png'
import TrendedMetricsMultipleImage from 'static/reports/trended-metrics-multiple.png'
import TrendedMetricsImage from 'static/reports/trended-metrics.png'

interface ReportPageElementProps {
  reportName: ReportNames
  pageComponent: any
}

const ReportPageElement: FC<ReportPageElementProps> = ({
  reportName,
  pageComponent
}) => (
  <ReportPage
    title={ReportMeta[reportName].title}
    component={pageComponent}
    description={ReportMeta[reportName].description}
  />
)

const ReportList: IReportTemplate[] = [
  {
    ...ReportMeta.TRENDED_METRICS,
    component: () => (
      <ReportPageElement
        reportName={ReportNames.TRENDED_METRICS}
        pageComponent={TrendedMetrics}
      />
    ),
    path: routes.reports.trendedMetrics(),
    image: TrendedMetricsImage
  },
  {
    ...ReportMeta.TRENDED_METRICS_MULTIPLE,
    component: () => (
      <ReportPageElement
        reportName={ReportNames.TRENDED_METRICS_MULTIPLE}
        pageComponent={TrendedMetricsMultiple}
      />
    ),
    path: routes.reports.trendedMetricsMultiple(),
    image: TrendedMetricsMultipleImage
  },
  {
    ...ReportMeta.PURCHASE_DRIVERS,
    component: () => (
      <ReportPageElement
        reportName={ReportNames.PURCHASE_DRIVERS}
        pageComponent={PurchaseDrivers}
      />
    ),
    path: routes.reports.purchaseDrivers(),
    image: PurchaseDriversImage
  },
  {
    ...ReportMeta.DEMOGRAPHICS,
    component: () => (
      <ReportPageElement
        reportName={ReportNames.DEMOGRAPHICS}
        pageComponent={Demographics}
      />
    ),
    path: routes.reports.demographics(),
    image: DemographicsImage
  },
  {
    ...ReportMeta.IN_BASKET,
    component: () => (
      <ReportPageElement
        reportName={ReportNames.IN_BASKET}
        pageComponent={InBasket}
      />
    ),
    path: routes.reports.inBasket(),
    image: InBasketImage
  },
  {
    ...ReportMeta.REPERTOIRE,
    component: () => (
      <ReportPageElement
        reportName={ReportNames.REPERTOIRE}
        pageComponent={Repertoire}
      />
    ),
    path: routes.reports.repertoire(),
    image: RepertoireImage
  },
  {
    ...ReportMeta.CROSS_PURCHASE,
    component: () => (
      <ReportPageElement
        reportName={ReportNames.CROSS_PURCHASE}
        pageComponent={CrossPurchase}
      />
    ),
    path: routes.reports.crossPurchase(),
    image: CrossPurchaseImage
  }
]

export const getReportRouteFromKey = (key: string) =>
  ReportList.find(i => i.key === key)?.path

export const getReportKeyFromRoute = (path: string) =>
  ReportList.find(i => i.path === path)?.key

export const getReportFromKey = (key: string): IReportTemplate =>
  ReportList.find(i => i.key === key)

export interface IReportTemplate {
  title: string
  description: string
  path: string
  component: React.ComponentType
  image?: string
  isHidden?: boolean
  icon?: any
  key?: string
}

export default ReportList
