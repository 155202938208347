import {
  BreakLine,
  HighlightText,
  Wrapper
} from 'components/elements/CustomGroupTooltip/styles'
import { cleanBannersQuery } from 'pages/reports/utils/cleanBanners'
import { cleanProductQuery } from 'pages/reports/utils/cleanBody'
import React from 'react'
import { toStartCase } from 'services/helpers/canonicalization'

const CustomGroupTooltip = ({ group }: any) => {
  const toStringWithSpace = (array: any[]) =>
    array.toString().replace(/,/g, ', ')
  return (
    <Wrapper>
      <div>
        <HighlightText>Product(s):</HighlightText>
        <p>{toStringWithSpace(cleanProductQuery(group?.products))}</p>
      </div>
      <BreakLine />
      <div>
        <HighlightText>Banner(s):</HighlightText>
        <p>
          {toStringWithSpace(cleanBannersQuery(group?.chains).map(toStartCase))}
        </p>
      </div>
      <BreakLine />
      <div>
        <HighlightText>Buyer Group(s):</HighlightText>
        <p>{toStringWithSpace(group?.buyerGroups.map(toStartCase))}</p>
      </div>
    </Wrapper>
  )
}

export default CustomGroupTooltip
