import StandardPage from 'components/page/StandardPage'
import SavedReports from 'pages/savedReports/components/SavedReports'
import React from 'react'

const SavedReportsPage = () => {
  return (
    <StandardPage headerText="Saved Reports" pageTitle="Saved Reports">
      <SavedReports />
    </StandardPage>
  )
}

export default SavedReportsPage
