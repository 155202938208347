import {
  ChartTooltip,
  ChartValueAxis,
  ChartValueAxisItem,
  Sparkline
} from '@progress/kendo-react-charts'
import React from 'react'
import { COLOR } from 'services/styles'

const BulletCell = props => <RenderBulletCell {...props} />

const RenderBulletCell = props => {
  const value = props.dataItem[props.field]
  const renderTooltip = () => (
    <>
      <p>Current: {value > 400 ? '> 400' : value.toFixed(0)}</p>
      <p>Target: 100</p>
    </>
  )
  return (
    <td>
      <Sparkline
        style={{ width: '100%', overflowX: 'hidden' }}
        data={[[value, 100]]}
        type={'bullet'}
        seriesColors={['transparent']}
      >
        <ChartTooltip render={renderTooltip} />
        <ChartValueAxis>
          <ChartValueAxisItem
            majorGridLines={{ visible: false }}
            minorTicks={{ visible: false }}
            min={0}
            max={200}
            plotBands={[
              {
                from: 0,
                to: 200,
                color: COLOR.grey + '10'
              },
              {
                from: 0,
                to: value < 100 ? value : 100,
                color: COLOR.grey + '30'
              },
              {
                from: 100,
                to: value < 100 ? 100 : value < 200 ? value : 200,
                color: COLOR.seriesC
              }
            ]}
          />
        </ChartValueAxis>
      </Sparkline>
    </td>
  )
}

export default BulletCell
