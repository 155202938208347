import { useQuery } from 'react-query'
import { QueryKeys } from 'services/api/keys'
import { getReportsViaId } from 'services/api/requests/reports/common'
import { DBSavedReports } from 'services/api/requests/reports/types'

const useLoadData = user =>
  useQuery<DBSavedReports>(
    [QueryKeys.SAVED_REPORTS, user],
    () => getReportsViaId(),
    { initialData: { shared: [], user: [] } }
  )

export default useLoadData
