import { DialogSelector } from 'data/selectors'
import React from 'react'
import { useSelector } from 'react-redux'

const Overlay = ({ children }) => <>{children}</>

const Dialog = () => {
  const dialogs = useSelector(DialogSelector.dialogSelector)
  return <>{dialogs?.[0]?.component}</>
}
Overlay.Dialog = Dialog

export default Overlay
