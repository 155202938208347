import EmptyData from 'components/elements/EmptyData'
import LoadingRows from 'components/elements/LoadingRows'
import PageSectionTitle from 'components/elements/SectionTitle/PageSectionTitle'
import { UserSelector } from 'data/selectors'
import Organisations from 'pages/organisation/overview/components/organisations'
import useLoadData from 'pages/organisation/overview/hooks'
import React from 'react'
import { useSelector } from 'react-redux'
import { SPACE } from 'services/styles'
import { PAGE_FOOTER_HEIGHT } from 'services/styles/misc'

const OrganisationOverview = () => {
  const user = useSelector(UserSelector.userSelector)

  const { isLoading, data: organisations } = useLoadData(user)

  const isEmpty = !isLoading && !organisations.length
  return (
    <div style={{ minHeight: `calc(100% - ${PAGE_FOOTER_HEIGHT}px)` }}>
      <div
        style={{
          marginTop: SPACE.medium,
          marginBottom: SPACE.small
        }}
      >
        <PageSectionTitle text={'Organisations'} />
      </div>

      <div>
        {isLoading ? <LoadingRows /> : <Organisations data={organisations} />}
        {isEmpty && (
          <EmptyData
            descriptionComponent={<h6>No organisation is created</h6>}
          />
        )}
      </div>
    </div>
  )
}

export default OrganisationOverview
