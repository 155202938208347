import { Checkbox } from 'antd'
import React from 'react'
import { SelectDataProps } from 'services/helpers/dropdownUtils'
import { LiRow, Separator, TextWithSpacing, UlNoStyle } from './style'

export default ({ data, value, liStyle, onChange }: Props) => {
  const handleCheck = (newCheckedBox: SelectDataProps) => {
    if (value.some(i => i.key === newCheckedBox.key)) {
      return onChange(
        value.filter(i => i.key !== newCheckedBox.key),
        newCheckedBox.key
      )
    }
    return onChange([...value, newCheckedBox], newCheckedBox.key)
  }

  return (
    <UlNoStyle>
      {data.map((i, index) =>
        i.isSeparator ? (
          <Separator key={index.toString()} />
        ) : (
          <LiRow style={liStyle || {}} key={i.key}>
            <Checkbox
              style={{ margin: '1px 6px 11px' }} // rsuite space
              disabled={i.disabled}
              onClick={_ => !i.disabled && handleCheck(i)}
              checked={!!value.find(v => v.key === i.key)}
            />
            <TextWithSpacing onClick={() => handleCheck(i)}>
              {i.value}
            </TextWithSpacing>
          </LiRow>
        )
      )}
    </UlNoStyle>
  )
}

export interface Props {
  data: ListWithCheckboxDataProps[]
  onChange: (data: SelectDataProps[], updatedKey: string | null) => void
  value: ListWithCheckboxDataProps[]
  liStyle?: object
}

export interface ListWithCheckboxDataProps extends SelectDataProps {
  disabled?: boolean
  isSeparator?: boolean
  isAtomic?: boolean // flag for single retailers
}
