import { notification } from 'antd'
import { UserActions } from 'data/actions'
import { ChartSelector, UserSelector } from 'data/selectors'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { periodToText } from 'services/helpers/toPeriod'
import PeriodNotification, {
  PERIOD_UPDATE_NOTIFICATION
} from 'services/notification/periodNotification'

const useCheckKnownPeriods = () => {
  const dispatch = useDispatch()

  const knownPeriods = useSelector(UserSelector.knownPeriods)
  const periodsList = useSelector(ChartSelector.periodsList)
  const viewAs = useSelector(UserSelector.viewAsUserSelector)

  useEffect(() => {
    if (
      viewAs === null &&
      periodsList.length &&
      knownPeriods?.[0] !== periodsList[0]
    ) {
      PeriodNotification({
        periodText: periodToText([periodsList[0]]),
        onClose: () => {
          dispatch(UserActions.setKnownPeriods(periodsList))
          notification.close(PERIOD_UPDATE_NOTIFICATION)
        }
      })
    }
  }, [periodsList, viewAs])
}

export default useCheckKnownPeriods
