import React from 'react'
import { RefWrapper } from './style'
import ChartWatermark from './watermark'

const ChartRef = props => {
  return (
    <RefWrapper>
      <ChartWatermark />
      {props.children}
    </RefWrapper>
  )
}

export default ChartRef
