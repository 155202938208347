import CustomGroups from 'components/charts/CustomGroups'
import {
  BreakLine,
  RowWrapper,
  SelectorLabel
} from 'components/charts/ReportParameterSelector/style'
import PageSectionTitle from 'components/elements/SectionTitle/PageSectionTitle'
import StandardButton from 'components/elements/StandardStyledButton'
import { ChartSelector } from 'data/selectors'
import React from 'react'
import { useSelector } from 'react-redux'
import { ButtonHeight } from 'services/styles/misc'
import PeriodSelector from './PeriodSelector'

interface ReportParameterSelectorProps {
  openGroupsBrowser: () => void
}

const ReportParameterSelector = ({
  openGroupsBrowser
}: ReportParameterSelectorProps) => {
  const groups = useSelector(ChartSelector.customGroups)

  return (
    <RowWrapper>
      <PageSectionTitle text={'Settings'} />
      <BreakLine />
      <SelectorLabel>SELECT SAVED SELECTION</SelectorLabel>
      {groups.length ? (
        <CustomGroups />
      ) : (
        <StandardButton
          data-testid="btn-openGroupsBrowser"
          text="+ Select Saved Selection"
          style={{ height: ButtonHeight.MEDIUM }}
          onClick={openGroupsBrowser}
        />
      )}
      <BreakLine />
      <SelectorLabel>SELECT PERIOD</SelectorLabel>
      <PeriodSelector />
    </RowWrapper>
  )
}
export default ReportParameterSelector
