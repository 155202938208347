import { CustomGroup } from 'data/chart/types'
import { User } from 'data/user/types'
import { OrganisationProps } from 'pages/types'

export const AlertMessage = {
  groupNameExist: 'That group name exist, please select a new name',
  missingGroupName: 'Please fill in a group name',
  noBannerSelected: 'Please select at least one banner'
}

export enum SortKey {
  MODIFIED = 'updatedAt',
  CREATED = 'createdAt',
  NAME = 'name'
}

export const CustomGroupsSortKeyToText = {
  [SortKey.MODIFIED]: 'Modified',
  [SortKey.CREATED]: 'Created',
  [SortKey.NAME]: 'Name'
}

export interface SavedCustomGroupsProps {
  selectedGroups?: Partial<DBChartCustomGroup>[]
  isAnalysisPage?: boolean
  handleCheck?: (item: CustomGroup) => void
}

export interface EditSavedGroupProps {
  onCancel: () => void
  onComplete: () => void
  prefilledGroup: CustomGroup
  savedGroups: DBChartCustomGroup[]
}

export interface DBGroups {
  org: DBChartCustomGroup[]
  user: DBChartCustomGroup[]
}

export interface DBChartCustomGroup extends CustomGroup {
  isEditable: boolean
  isDeletable: boolean
  sharedOrgs: OrganisationProps[]
  type: string
  updatedAt: string
  createdAt?: string
  version: number
  user: User
}
