import { Button } from 'antd'
import FlexContainer from 'components/elements/FlexContainer'
import SelectMenu from 'components/elements/SelectMenu'
import useDrillDownSelectable from 'pages/reports/reports/common/reportHooks/useDrillDownSelectable'
import {
  ChartOptions,
  SelectableMetrics,
  SelectedMetrics
} from 'pages/reports/reports/demographics/types'
import React, { Dispatch } from 'react'
import { SelectDataProps } from 'services/helpers/dropdownUtils'
import { BORDER_RADIUS, COLOR, SPACE } from 'services/styles'
import { DrillByList, invalidDrillDownTooltip } from '../../common/types'

interface Props {
  selectableMetrics: SelectableMetrics
  selectedMetrics: SelectedMetrics
  setSelectedMetrics: (key: string, updatedState: SelectDataProps) => void
  shouldShowIndex: boolean
  setShouldShowIndex: () => void
  drillDown: SelectDataProps
  setDrillDown: Dispatch<SelectDataProps>
}

export default ({
  selectableMetrics,
  selectedMetrics,
  setSelectedMetrics,
  setShouldShowIndex,
  shouldShowIndex,
  drillDown,
  setDrillDown
}: Props) => {
  const selectableCustomPeriods = selectableMetrics.periods
  const selectedCustomPeriod = selectedMetrics.periods

  const selectableBreakdownBy = selectableMetrics.breakdownBy
  const selectedBreakdownBy = selectedMetrics.breakdownBy

  const setCustomPeriod = (event: any) =>
    setSelectedMetrics(ChartOptions.PERIOD, event)

  const setCustomBreakdownBy = (event: any) =>
    setSelectedMetrics(ChartOptions.BREAKDOWN_BY, event)

  const isDrillDownSelectable = useDrillDownSelectable(setDrillDown)

  return (
    <FlexContainer alignItems="start">
      <div style={{ marginRight: SPACE.tiny }}>
        <p style={{ margin: 0, visibility: 'hidden' }}>METRIC</p>
        <Button
          onClick={() => setShouldShowIndex()}
          style={{
            backgroundColor: COLOR.inputGrey,
            borderRadius: BORDER_RADIUS.small
          }}
        >
          {shouldShowIndex ? 'Hide Index' : 'Show Index'}
        </Button>
      </div>
      <div style={{ marginRight: SPACE.tiny }}>
        <p>DRILL DOWN</p>
        <SelectMenu
          disabled={isDrillDownSelectable}
          tooltip={isDrillDownSelectable && invalidDrillDownTooltip}
          selectableMenu={DrillByList}
          item={drillDown}
          setItem={setDrillDown}
        />
      </div>
      <div style={{ marginRight: SPACE.tiny }}>
        <p>PERIOD</p>
        <SelectMenu
          selectableMenu={selectableCustomPeriods}
          item={selectedCustomPeriod}
          setItem={setCustomPeriod}
        />
      </div>

      <div>
        <p>METRIC</p>
        <SelectMenu
          selectableMenu={selectableBreakdownBy}
          item={selectedBreakdownBy}
          setItem={setCustomBreakdownBy}
        />
      </div>
    </FlexContainer>
  )
}
