import {
  ChartTooltip,
  ChartTooltipProps,
  SharedTooltipContext
} from '@progress/kendo-react-charts'
import React, { FC } from 'react'
import { isChildren, isPrice } from 'services/helpers/axisFormatter'
import { CustomColorBar, tooltipColor, TooltipItemContainer } from './style'

const isPercent = (name): boolean =>
  /^(Share|High|Mid|Low|Penetration|[\d]|WA|VIC\/TAS|SA\/NT|QLD|NSW)/.test(name)

export const pointValue = (name: string, value: number): string => {
  if (isPercent(name)) {
    return value.toFixed(name.includes('Penetration') ? 0 : 1) + ' %'
  } else if (isPrice(name)) {
    return '$' + value.toFixed(2)
  } else if (isChildren(name)) {
    return value.toFixed(1) + ' %'
  } else {
    return value + ''
  }
}

const reverseIfDemographics = (points, categoryText) => {
  if (/^(Selected Group|Alcohol Pop|Total Panel)/.test(categoryText)) {
    return points.map(x => x).reverse()
  } else {
    return points
  }
}
const SharedTooltipComponent = (props: SharedTooltipContext) => {
  const { categoryText, points } = props
  const pointsArray = reverseIfDemographics(points, categoryText)
  return (
    <>
      <div>{categoryText}</div>
      {pointsArray.map((point, index) => {
        const pointIndex = point.categoryIndex
        const pointLabel = point.series.notes.label.content(pointIndex)
        return (
          <TooltipItemContainer key={index}>
            <CustomColorBar
              style={{ backgroundColor: `${point.series.color}` }}
            />
            {pointLabel}: {pointValue(point.series.name, point.value)}
          </TooltipItemContainer>
        )
      })}
    </>
  )
}

const CustomTooltip = context => <SharedTooltipComponent {...context} />

const SharedChartTooltip: FC<ChartTooltipProps> = props => (
  <ChartTooltip
    background={tooltipColor}
    shared={true}
    render={CustomTooltip}
    {...props}
  />
)

export default SharedChartTooltip
