import { OrganisationProps } from 'pages/types'

export enum UserActionTypes {
  SET_USER = 'USER/SET_USER',
  SET_FIREBASE_READY = 'USER/SET_FIREBASE_READY',
  IS_FETCHING_USER = 'USER/IS_FETCHING_USER',
  LOGOUT = 'USER/LOGOUT',
  FIREBASE_AUTH_STATE_CHANGE = 'USER/FIREBASE_AUTH_STATE_CHANGED',
  SET_VIEW_AS = 'USER/SET_VIEW_AS',
  SET_KNOWN_PERIODS = 'USER/SET_KNOWN_PERIODS'
}

interface DBRoleProps {
  description?: string
  id?: string
  name?: string
  permission?: any[]
  roleType?: string
  createdAt?: string
  updatedAt?: string
}

export interface UserState {
  user: User | null
  isFirebaseReady: boolean
  isFetchingUser: boolean
  knownPeriods: number[]
  viewAs: User | null
}

export interface User {
  companyName: string
  email?: string
  firstName?: string
  lastName?: string
  fullName?: string
  id?: string
  fUid?: string
  isValidated?: boolean
  role?: DBRoleProps
  organisation?: OrganisationProps
  createdAt?: string
  updatedAt?: string
  permission?: any[]
  loggedIn?: string
  isFirebaseReady?: boolean
}

export const initialUserState: UserState = {
  user: null,
  isFirebaseReady: false,
  isFetchingUser: false,
  knownPeriods: [],
  viewAs: null
}
