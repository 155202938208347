import { Checkbox, Modal, Table } from 'antd'
import { CancelIcon } from 'components/elements/SvgIcon/styles'
import { DialogActions } from 'data/actions'
import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { QueryKeys } from 'services/api/keys'
import {
  getOtherUsersApi,
  updatePermissionApi
} from 'services/api/requests/organisation/users'
import { useUpdateQuery } from 'services/hooks/useUpdateQuery'
import { Notification } from 'services/notification'
import { SPACE } from 'services/styles'
import { ButtonHeight } from 'services/styles/misc'
import { DialogKeys } from '..'

interface Item {
  key: number
  name: string
  email: string
  view: boolean
  edit: boolean
}

const NameColumnWidth = '25%'
const PermissionColumnWidth = 110

const ShareDialog = () => {
  const location = useLocation()
  const urlParams = new URLSearchParams(location.search)
  const queryReportId = urlParams.get('report_id')
  const {
    isLoading,
    data: initialData,
    refetch,
    isFetching
  } = useQuery(QueryKeys.SHARE_REPORT, () => getOtherUsersApi(queryReportId))
  const updatePermission = useUpdateQuery(
    QueryKeys.SHARE_REPORT,
    updatePermissionApi
  )

  const [data, setData] = useState<Item[]>(initialData || [])

  useEffect(() => {
    ;(async () => {
      const { data: newData } = await refetch()
      setData(newData)
    })()
  }, [initialData])

  const dispatch = useDispatch()

  const handleClose = () =>
    dispatch(DialogActions.hideDialog({ key: DialogKeys.SHARE }))

  const handleComplete = async () => {
    await updatePermission.mutateAsync(
      { reportId: queryReportId, data },
      {
        onSuccess: () => {
          handleClose()
          Notification({
            type: 'success',
            message: 'Shared report successfully'
          })
        }
      }
    )
  }

  const isEditAllChecked = data.every(i => i.edit === true)
  const isViewAllChecked = data.every(i => i.view === true)

  const handleViewAllClick = () => {
    const _handleViewAll = (value: boolean) =>
      setData(data.map(i => ({ ...i, view: !i.edit ? value : true })))
    _handleViewAll(!isViewAllChecked)
  }

  const handleEditAllClick = () => {
    const _handleEditAll = (value: boolean) =>
      setData(data.map(i => ({ ...i, edit: value, view: true })))
    _handleEditAll(!isEditAllChecked)
  }

  const handleEdit = ({ record, field }: { record: Item; field: string }) => {
    const newData = [...data]
    const newItem = newData[record.key]
    const editColumn = !newData[record.key][field]
    if (field === 'edit') {
      newData.splice(record.key, 1, {
        ...newItem,
        [field]: editColumn,
        view: true
      })
    } else {
      newData.splice(record.key, 1, {
        ...newItem,
        [field]: editColumn
      })
    }

    setData(newData)
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: NameColumnWidth,
      render: text => (
        <span style={{ whiteSpace: 'nowrap' }}>
          {text.replace(/undefined/g, '')}
        </span>
      )
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email'
    },
    {
      title: (
        <span>
          <Checkbox
            checked={isViewAllChecked}
            disabled={isEditAllChecked}
            onClick={handleViewAllClick}
            style={{ marginRight: SPACE.tiny }}
          />
          View
        </span>
      ),
      dataIndex: 'view',
      key: 'view',
      width: PermissionColumnWidth,
      render: (text, record) => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Checkbox
            disabled={record.edit}
            checked={record.view}
            onClick={() => handleEdit({ record, field: 'view' })}
          />
        </div>
      )
    },
    {
      title: (
        <span>
          <Checkbox
            checked={isEditAllChecked}
            onClick={handleEditAllClick}
            style={{ marginRight: SPACE.tiny }}
          />
          Edit
        </span>
      ),
      dataIndex: 'edit',
      key: 'edit',
      width: PermissionColumnWidth,
      render: (text, record) => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Checkbox
            checked={record.edit}
            onClick={() => handleEdit({ record, field: 'edit' })}
          />
        </div>
      )
    }
  ]

  return (
    <Modal
      title={'Share in organisation'}
      visible
      closeIcon={
        <CancelIcon
          width={ButtonHeight.SMALL}
          height={ButtonHeight.SMALL}
          style={{ marginTop: SPACE.tiny * 2.75 }}
        />
      }
      onOk={handleComplete}
      confirmLoading={updatePermission.isLoading}
      okText={'Share'}
      okButtonProps={{ style: { width: 125 } }}
      onCancel={handleClose}
      width={700}
      cancelButtonProps={{ hidden: true }}
    >
      <Table
        loading={isLoading || isFetching}
        columns={columns}
        dataSource={data}
        pagination={false}
        style={{ textAlign: 'left' }}
        scroll={{ y: 220 }}
      />
    </Modal>
  )
}

export default ShareDialog
