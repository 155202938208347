import { ChartSelector } from 'data/selectors'
import { useFilterQueries } from 'pages/reports/reports/common/helpers'
import { formatData } from 'pages/reports/reports/topBrands/helpers'
import {
  IFormattedDatasets,
  ParamsSelectors
} from 'pages/reports/reports/topBrands/types'
import { Dispatch, useEffect } from 'react'
import { useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import dataFallback from 'services/api/requests/common/dataFallback'

type QueryType = {
  key: string
  apiFunc: any
  handleLoading: Dispatch<boolean>
}

export const useLoadData = (
  queryProps: QueryType,
  selections: ParamsSelectors
): IFormattedDatasets => {
  const { selectedGroups, selectedPeriod, ...params } = selections
  const { key, apiFunc, handleLoading } = queryProps

  const dispatch = useDispatch()

  const customGroups = useSelector(ChartSelector.customGroups)

  const queries = useFilterQueries(key, selectedGroups, {
    hasPeriodFilter: true,
    selectedPeriod,
    drillDown: params.drillDown,
    selectedProducts: params.selectedProducts
  })

  const { isFetching, isLoading, data } = useQuery(queries, () =>
    apiFunc(queries, dispatch)
  )

  const fallbackData = dataFallback(data)

  useEffect(() => handleLoading(isLoading || isFetching), [
    isLoading,
    isFetching
  ])

  const formattedData = formatData(fallbackData, customGroups, {
    ...params,
    selectedGroups
  })

  return {
    datasets: formattedData,
    datasetsTable: formattedData.reduce(
      (acc, curr) => [...acc, ...curr.tableData],
      []
    )
  }
}
