import { Modal } from 'antd'
import SavedCustomGroups from 'components/charts/CustomGroupsList'
import { isGroupsEqual } from 'components/charts/CustomGroupsList/helpers'
import { DBChartCustomGroup } from 'components/charts/CustomGroupsList/type'
import { DialogKeys } from 'components/dialogs/index'
import { ChartActions, DialogActions } from 'data/actions'
import { ChartSelector } from 'data/selectors'
import React, { FC, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { GroupsBrowserProps } from './type'

const GroupsBrowser: FC<GroupsBrowserProps> = ({ onCancel, onComplete }) => {
  const modalHeight = 15
  const modalWidth = 25
  const dispatch = useDispatch()

  const currentGroups = useSelector(ChartSelector.customGroups)
  const [selectedGroups, setSelectedGroups] = useState<DBChartCustomGroup[]>([])

  const handleComplete = () => {
    if (onComplete) return onComplete()
    dispatch(DialogActions.hideDialog({ key: DialogKeys.GROUPS_BROWSER }))
  }

  const handleCancel = () => {
    if (onCancel) return onCancel()
    dispatch(DialogActions.hideDialog({ key: DialogKeys.GROUPS_BROWSER }))
  }

  const handleOnOK = () => {
    selectedGroups.map(group => {
      const { createdAt, updatedAt, version, ...formattedGroup } = group
      const foundGroup = currentGroups.find(
        current => current.id === formattedGroup.id
      )
      if (foundGroup) {
        return dispatch(
          ChartActions.editCustomGroup(foundGroup.id, formattedGroup)
        )
      }
      return dispatch(ChartActions.addCustomGroup(formattedGroup))
    })
    handleComplete()
  }

  const handleCheck = (item: DBChartCustomGroup) => {
    if (currentGroups.find(v => isGroupsEqual(v, item))) return
    setSelectedGroups(selectedGroups.find(i => i.id === item.id) ? [] : [item])
  }

  return (
    <Modal
      title=""
      closable={false}
      visible
      onOk={handleOnOK}
      onCancel={handleCancel}
      okText="Load Selection"
      okButtonProps={{ disabled: !selectedGroups.length }}
      cancelButtonProps={{ type: 'text' }}
      width={`calc(100vw - ${modalWidth}vw)`}
      bodyStyle={{ height: `calc(100% - ${modalHeight}vh)` }}
      centered
    >
      <SavedCustomGroups
        handleCheck={handleCheck}
        selectedGroups={selectedGroups}
        isAnalysisPage
      />
    </Modal>
  )
}

export default GroupsBrowser
