import { ExcelExport } from '@progress/kendo-react-excel-export'
import { GridColumn as DataColumn } from '@progress/kendo-react-grid'
import DataGridSortable from 'components/charts/DataGridSortable'
import { CenteredText } from 'components/elements/TableCells'
import WrapHeaderCell from 'components/elements/TableCells/WrapHeaderCell'
import { ChartSelector } from 'data/selectors'
import { NoneDrillDown } from 'pages/reports/reports/common/types'
import React from 'react'
import { useSelector } from 'react-redux'
import { changeTextIfLowNumber } from 'services/helpers/checkIsLowNumber'
import { periodToText } from 'services/helpers/toPeriod'
import { getCleanGroupSignature } from '../../common/helpers'
import { SELECT_ALL_PRODUCT_KEY } from '../../common/reportHooks/usePreselectProduct'
import { TrendedMetricsDataKey } from '../types'

const RenderDataTable = ({
  data,
  dataTableRef,
  fileName,
  drillDown,
  selectedProducts
}) => {
  const groups = useSelector(ChartSelector.customGroups)
  const selectedPeriods = useSelector(ChartSelector.selectedPeriod)

  const dataGridData = data?.map(i => {
    const rollingPeriod = selectedPeriods.content.find(period =>
      period.includes(+i.period)
    )

    const [groupName] = getCleanGroupSignature(
      groups,
      i.key,
      drillDown.key !== NoneDrillDown,
      !selectedProducts.find(i => i.key === SELECT_ALL_PRODUCT_KEY)
    )
    return {
      ...i,
      period: periodToText(rollingPeriod),
      key: changeTextIfLowNumber(groupName || 'Default', i.warningLevel)
    }
  })

  return (
    <ExcelExport data={dataGridData} ref={dataTableRef} fileName={fileName}>
      <DataGridSortable data={dataGridData}>
        <DataColumn field="period" title="Period" cell={CenteredText} />
        <DataColumn field="key" title="Groups" cell={CenteredText} />
        <DataColumn
          field={TrendedMetricsDataKey.PURCHASE_VALUE}
          title="Indexed Purchase Value"
          format="{0:n0}"
          headerCell={WrapHeaderCell}
        />
        <DataColumn
          field={TrendedMetricsDataKey.PURCHASE_UNITS}
          title="Indexed Purchase Units"
          format="{0:n0}"
          headerCell={WrapHeaderCell}
        />
        <DataColumn
          field={TrendedMetricsDataKey.SHARE_BY_VALUE}
          title="Share by Value"
          format="{0:n1}%"
          headerCell={WrapHeaderCell}
        />
        <DataColumn
          field={TrendedMetricsDataKey.SHARE_BY_OCCASIONS}
          title="Share by Occasions"
          format="{0:n1}%"
          headerCell={WrapHeaderCell}
        />
      </DataGridSortable>
    </ExcelExport>
  )
}

const MemoizedRenderTable = React.memo(RenderDataTable)
export default MemoizedRenderTable
