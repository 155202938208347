import { UserState } from 'data/user/types'
import { combineReducers } from 'redux'
import chart, { CombinedChartState } from './chart/reducer'
import dialog, { CombinedDialogState } from './dialog/reducer'
import user from './user/reducer'

export const state = combineReducers<AppReducerState>({
  user,
  dialog,
  chart
})

export interface AppReducerState {
  readonly user: UserState
  readonly dialog: CombinedDialogState
  readonly chart: CombinedChartState
}

export default state
