import StandardPage from 'components/page/StandardPage'
import OrganisationDetails from 'pages/organisation/details'
import OrganisationOverview from 'pages/organisation/overview'
import React from 'react'
import { Route } from 'react-router-dom'
import routes from 'routes'

const OrganisationPage = () => (
  <StandardPage pageTitle={'Organisation'} headerText="Organisation Settings">
    <Route
      exact
      path={routes.organisation()}
      component={OrganisationOverview}
    />

    <Route
      exact
      path={routes.organisation() + '/:id'}
      component={OrganisationDetails}
    />
  </StandardPage>
)

export default OrganisationPage
