import { AppReducerState } from 'data/reducers'
import { ChartSelector } from 'data/selectors'
import { createSelector } from 'reselect'

export const userSelector = (state: AppReducerState) =>
  state?.user?.viewAs || state.user.user

export const rootUserSelector = (state: AppReducerState) => state.user.user

export const viewAsUserSelector = (state: AppReducerState) => state.user.viewAs

export const knownPeriods = (state: AppReducerState) => state.user.knownPeriods

export const isFetchingUserSelector = (state: AppReducerState) =>
  state.user.isFetchingUser

export const isFirebaseReadySelector = (state: AppReducerState) =>
  state.user.isFirebaseReady

export const isAppReady = createSelector(
  userSelector,
  isFetchingUserSelector,
  isFirebaseReadySelector,
  ChartSelector.chartOptions,
  (user, isFetchingUser, isFireBaseReady, options) => {
    if (isFetchingUser || !isFireBaseReady) return false
    if (user?.id) {
      return (
        !!options.periods.length ||
        !!options.products.length ||
        !!options.chains.length ||
        !!options.buyerGroups.length
      )
    }
    return true
  }
)
