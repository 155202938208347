import React from 'react'

export enum DialogActionTypes {
  SHOW_DIALOG = 'SHOW_DIALOG',
  HIDE_DIALOG = 'HIDE_DIALOG'
}

export interface DialogState {
  dialogs: Dialog[]
}

export interface Dialog {
  key: string
  component?: React.ReactElement
}
