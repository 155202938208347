import {
  Chart,
  ChartArea,
  ChartAxisDefaults,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
  ChartValueAxis,
  ChartValueAxisItem
} from '@progress/kendo-react-charts'
import color from 'color'
import AnalysisWrapper from 'components/charts/AnalysisWrapper'
import { SectionWrapper } from 'components/charts/AnalysisWrapper/style'
import ChartRef from 'components/charts/ChartRef'
import LegendVisual from 'components/charts/LegendVisual'
import SampleSizeWarningText from 'components/charts/SampleSizeWarningText'
import SharedChartTooltip from 'components/charts/SharedChartTooltip'
import TableTitle from 'components/elements/SectionTitle/TableTitle'
import { ReportContext } from 'components/page/ReportPage/context'
import { ChartSelector } from 'data/selectors'
import { ReportMeta, ReportNames } from 'pages/reports/reportMeta'
import MultipleProductsSelector from 'pages/reports/reports/common/productSelector/multiple'
import useStandardSelectable from 'pages/reports/reports/common/reportHooks/useStandardSelectable'
import { initialDrillDownState } from 'pages/reports/reports/common/types'
import { useFilterRawData } from 'pages/reports/reports/trendedMetricsMultiple/helpers'
import {
  useLoadData,
  useLoadMetrics
} from 'pages/reports/reports/trendedMetricsMultiple/hooks'
import React, { useContext, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { isPercent, isPrice } from 'services/helpers/axisFormatter'
import { toStartCase } from 'services/helpers/canonicalization'
import { changeTextIfLowNumber } from 'services/helpers/checkIsLowNumber'
import { SelectDataProps } from 'services/helpers/dropdownUtils'
import useIsScreenLarge from 'services/hooks/useIsScreenLarge'
import { ChartHeight, COLOR, GridLineWidth, SPACE } from 'services/styles'
import { getFontStyle } from 'services/styles/font'
import ParameterSelector from './components/parameterSelector'
import MemoizedRenderTable from './components/table'
import MetricsMultipleChartTitle from './components/title'
import {
  notNone,
  TrendedMetricsAxisEnum,
  TrendedMetricsEnumToUnit
} from './types'

const TrendedMetricsMultiple = React.forwardRef((props, ref) => {
  //  @ts-ignore
  const { chartRef, tableRef } = ref
  //  @ts-ignore
  const { fileName } = props.exportFileProps
  const { isLoading, setIsLoading } = useContext(ReportContext)

  const [drillDown, setDrillDown] = useState<SelectDataProps>(
    initialDrillDownState
  )

  const {
    selectableViewOptions,
    selectedGroups,
    selectedProducts,
    setSelectedProducts
  } = useStandardSelectable()

  const memoizedSelectedGroups = useMemo(() => selectedGroups, [selectedGroups])

  const data = useLoadData(
    setIsLoading,
    memoizedSelectedGroups,
    selectedProducts,
    drillDown
  )

  const [
    leftMetric,
    setLeftMetric,
    rightMetric,
    setRightMetric
  ] = useLoadMetrics()

  const [filteredData, leftData, rightData] = useFilterRawData(
    data,
    selectedGroups,
    { leftMetric, rightMetric, drillDown, selectedProducts }
  )

  const periodLabels = useSelector(ChartSelector.periodLabels)
  const selectedPeriodKey = useSelector(ChartSelector.selectedPeriodKey)

  const isScreenLarge = useIsScreenLarge()

  const legendPosition = isScreenLarge ? 'right' : 'bottom'

  const legendWidth = isScreenLarge
    ? document
        .getElementsByClassName('k-chart-surface')?.[0]
        ?.children?.[0]?.children?.[1]?.children?.[4]?.getBoundingClientRect()
        ?.width + SPACE.medium
    : 0

  const renderAxisLabel = (e: any, metric) =>
    isPercent(metric.key) ? (e.value <= 100 ? e.value : '') : e.value

  const majorUnit = metric =>
    isPrice(metric.key) || isPercent(metric.key) ? 10 : undefined

  const renderParamSelector = () => (
    <ParameterSelector
      leftMetric={leftMetric}
      rightMetric={rightMetric}
      setLeftMetric={setLeftMetric}
      setRightMetric={setRightMetric}
      drillDown={drillDown}
      setDrillDown={setDrillDown}
    />
  )

  const renderProductsDrillDown = () => (
    <MultipleProductsSelector
      options={selectableViewOptions.products}
      value={selectedProducts}
      onChange={setSelectedProducts}
    />
  )

  const renderData = (metric, axisKey) => (item, index) =>
    notNone(metric.key) && (
      <ChartSeriesItem
        key={metric.key + index}
        type={axisKey === TrendedMetricsAxisEnum.RIGHT_AXIS ? 'line' : 'column'}
        data={item.dataset.map(i => i.value)}
        name={`${toStartCase(metric.key)} [${changeTextIfLowNumber(
          item.label,
          Math.max(...item.dataset.map(i => i.warningLevel))
        )}]`}
        notes={{
          label: {
            content: index =>
              changeTextIfLowNumber(
                item.label,
                item.dataset[index].warningLevel
              )
          }
        }}
        color={
          axisKey === TrendedMetricsAxisEnum.RIGHT_AXIS && item.color
            ? color(item.color).lighten(0.5).hex()
            : item.color
        }
        axis={axisKey}
      />
    )

  return (
    <>
      <AnalysisWrapper
        isLoading={isLoading}
        productsSelector={renderProductsDrillDown()}
        parameterSelector={renderParamSelector()}
      >
        <SectionWrapper ref={chartRef}>
          <ChartRef>
            <div className="hidden-text hidden">
              <MetricsMultipleChartTitle
                leftMetric={{
                  key: ReportMeta[ReportNames.TRENDED_METRICS_MULTIPLE].title,
                  value: null
                }}
                rightMetric={{ key: null, value: null }}
                legendWidth={legendWidth}
              />
            </div>
            <MetricsMultipleChartTitle
              leftMetric={leftMetric}
              rightMetric={rightMetric}
              legendWidth={legendWidth}
            />
            <Chart transitions={false} style={{ height: ChartHeight.STANDARD }}>
              <ChartArea background={'transparent'} />
              <ChartLegend
                position={legendPosition}
                labels={getFontStyle(15)}
                item={{
                  visual: e => LegendVisual({ ...e, numberOfWordsPerLine: 6 })
                }}
              />
              <SharedChartTooltip />
              <ChartCategoryAxis>
                <ChartCategoryAxisItem
                  title={{ text: `Period (${selectedPeriodKey?.value})` }}
                  labels={{
                    ...getFontStyle(14),
                    rotation: 'auto',
                    padding: SPACE.tiny
                  }}
                  categories={periodLabels}
                  axisCrossingValue={[0, 100]}
                />
              </ChartCategoryAxis>
              <ChartAxisDefaults
                majorGridLines={{ color: COLOR.lightGrey + '50' }}
                minorGridLines={{
                  color: COLOR.lightGrey + '50',
                  width: GridLineWidth.MINOR
                }}
              />
              <ChartSeries>
                {leftData.map(
                  renderData(leftMetric, TrendedMetricsAxisEnum.LEFT_AXIS)
                )}
                {rightData.map(
                  renderData(rightMetric, TrendedMetricsAxisEnum.RIGHT_AXIS)
                )}
              </ChartSeries>
              <ChartValueAxis>
                <ChartValueAxisItem
                  visible={notNone(leftMetric.key)}
                  title={{
                    text: TrendedMetricsEnumToUnit[leftMetric.key]
                  }}
                  labels={{
                    ...getFontStyle(14),
                    content: e => renderAxisLabel(e, leftMetric)
                  }}
                  name={TrendedMetricsAxisEnum.LEFT_AXIS}
                  majorUnit={majorUnit(leftMetric)}
                  minorGridLines={{ visible: true }}
                  narrowRange={false}
                  max={isPercent(leftMetric.key) ? 120 : null}
                />
                <ChartValueAxisItem
                  visible={notNone(rightMetric.key)}
                  title={{
                    text: TrendedMetricsEnumToUnit[rightMetric.key]
                  }}
                  name={TrendedMetricsAxisEnum.RIGHT_AXIS}
                  labels={{
                    ...getFontStyle(14),
                    content: e => renderAxisLabel(e, rightMetric)
                  }}
                  majorUnit={majorUnit(leftMetric)}
                  minorGridLines={{ visible: true }}
                  narrowRange={false}
                  max={isPercent(rightMetric.key) ? 120 : null}
                />
              </ChartValueAxis>
            </Chart>
          </ChartRef>
        </SectionWrapper>

        <SectionWrapper>
          <TableTitle />
          <MemoizedRenderTable
            data={filteredData}
            dataTableRef={tableRef}
            fileName={fileName}
            drillDown={drillDown}
            selectedProducts={selectedProducts}
          />
          <SampleSizeWarningText />
        </SectionWrapper>
      </AnalysisWrapper>
    </>
  )
})

export default TrendedMetricsMultiple
