import { Row } from 'antd'
import Form from 'antd/lib/form'
import Header from 'components/elements/RegisterHeader'
import StandardButton from 'components/elements/StandardStyledButton'
import { MessageBlock, StyledForm, StyledInput } from 'pages/auth/style'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import routes from 'routes'
import { resetPasswordApi } from 'services/api/requests/resetPassword'
import { emailValidationRules } from 'services/helpers/validationRules'
import { SPACE } from 'services/styles'
import { ButtonHeight } from 'services/styles/misc'
import logo from 'static/logo-with-name-black.png'

const ForgotPasswordComponent = () => {
  const history = useHistory()
  const [error, setError] = useState<string>('')
  const [isLoading, setLoading] = useState<boolean>(false)
  const [sentRequest, setSentRequest] = useState<boolean>(false)

  const handleRequestPassword = async ({ email }) => {
    setError('')
    setLoading(true)
    try {
      const response = await resetPasswordApi({ email })
      if (response) {
        setSentRequest(true)
      }
    } catch (e) {
      setError(e.message)
    }
    setLoading(false)
  }

  return (
    <>
      <img src={logo} style={{ height: 60, margin: SPACE.large }} alt="logo" />
      <Header
        title={'Forgot password'}
        subtitle={'To reset your password, please enter your email'}
      />
      {!sentRequest ? (
        <StyledForm
          key={'forgot-form'}
          name="basic"
          labelAlign="left"
          onFinish={handleRequestPassword}
          style={{ maxWidth: 400 }}
        >
          <Form.Item
            key={'forgot-form'}
            label={'Email'}
            name={'email'}
            validateFirst
            labelCol={{ span: 24, style: { padding: 0 } }}
            rules={emailValidationRules}
            style={{ marginBottom: SPACE.small }}
          >
            <StyledInput />
          </Form.Item>

          <Form.Item wrapperCol={{ span: 24 }}>
            <StandardButton
              style={{
                height: ButtonHeight.MEDIUM,
                width: 400
              }}
              text="Reset"
              htmlType="submit"
              isLoading={isLoading}
            />
          </Form.Item>

          <MessageBlock>{error}</MessageBlock>
        </StyledForm>
      ) : (
        <Row style={{ maxWidth: 400 }}>
          <p style={{ marginBottom: SPACE.small }}>
            We’ve sent you an email with a link to finish resetting your
            password.
          </p>
          <StandardButton
            style={{
              height: ButtonHeight.MEDIUM,
              width: 400
            }}
            text="Return to login"
            onClick={() => history.push(routes.auth.login())}
          />
        </Row>
      )}
    </>
  )
}

export default ForgotPasswordComponent
