import { BORDER_RADIUS, COLOR, SPACE } from 'services/styles'
import styled from 'styled-components'

export const LoaderWrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
`
export const LoaderBackground = styled(LoaderWrapper)`
  opacity: 0.9;
  background-color: ${COLOR.antdGrey};
`

export const SectionWrapper = styled.div`
  position: relative;
  padding: ${SPACE.large / 1.5}px ${SPACE.small}px;
  margin: ${SPACE.tiny}px 0px;
  border-radius: ${BORDER_RADIUS.standard * 3}px;
  background-color: white;
`

export const Wrapper = styled.div`
  padding: 0px ${SPACE.large}px;
  margin: ${SPACE.small}px 0px;
`
