import { useMutation, useQueryClient } from 'react-query'
import { MutationFunction } from 'react-query/types/core/types'
import { UseMutationResult } from 'react-query/types/react/types'

interface UpdateQueryProps<
  TData = unknown,
  TError = unknown,
  TVariables = void
> {
  queryKey: string
  mutationFn: MutationFunction<TData, TVariables>
}

export const useUpdateQuery = <
  TData = unknown,
  TError = unknown,
  TVariables = void
>(
  queryKey: string,
  mutationFn: MutationFunction<TData, TVariables>
): UseMutationResult<any> => {
  const queryClient = useQueryClient()

  return useMutation(mutationFn, {
    onSuccess: () => queryClient.invalidateQueries(queryKey)
  })
}
