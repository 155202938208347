import { Col, Timeline } from 'antd'
import { SummaryContainer } from 'components/dialogs/CustomPeriod/components/style'
import React from 'react'
import {
  dateToWeekEnding,
  toMomentDate,
  toWeekStartingMoment
} from 'services/helpers/toPeriod'

const PeriodsSummary = ({ periods }) => (
  <SummaryContainer gutter={16} justify={'start'}>
    <Col span={5} style={{ whiteSpace: 'nowrap' }}>
      <Timeline mode={'left'}>
        {periods.map((i, index) => (
          <Timeline.Item key={index} label={`Period ${index + 1}`}>
            {`${toWeekStartingMoment(dateToWeekEnding[i[0]])} - ${toMomentDate(
              dateToWeekEnding[i[i.length - 1]]
            )}`}
          </Timeline.Item>
        ))}
      </Timeline>
    </Col>
  </SummaryContainer>
)

export default PeriodsSummary
