import { User, UserActionTypes } from 'data/user/types'
import { createAction } from 'redux-actions'

export const setUser = (user: User) =>
  createAction(UserActionTypes.SET_USER)({ user })

export const setIsFetchingUser = (isFetchingUser: boolean) =>
  createAction(UserActionTypes.IS_FETCHING_USER)({ isFetchingUser })

export const setFirebaseIsReady = () =>
  createAction(UserActionTypes.SET_FIREBASE_READY)()

export const firebaseAuthStateChange = () =>
  createAction(UserActionTypes.FIREBASE_AUTH_STATE_CHANGE)()

export const setViewAs = (user: User) =>
  createAction(UserActionTypes.SET_VIEW_AS)({ user })

export const userLogout = () => createAction(UserActionTypes.LOGOUT)()

export const setKnownPeriods = (periods: number[]) =>
  createAction(UserActionTypes.SET_KNOWN_PERIODS)({ periods })
