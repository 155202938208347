import { Dispatch, SetStateAction } from 'react'
import { CombineChainsEnum } from 'services/helpers/toChain'

const handleBannerChange = (
  newChange: string[],
  setSelectedBanners: Dispatch<SetStateAction<string[]>>,
  setDisabledItemValues: Dispatch<SetStateAction<string[]>>
) => {
  const disabledList = []

  if (newChange.length === 0) {
    setSelectedBanners([])
    return setDisabledItemValues(disabledList)
  }

  if (newChange.includes(CombineChainsEnum.ALL_BANNERS)) {
    newChange = [CombineChainsEnum.ALL_BANNERS]
  }

  const filteredList = newChange.filter(i => i.includes('/'))
  let prefix
  if (filteredList.length === 0) prefix = newChange[0]
  else prefix = filteredList[0].split(' / ')[0]

  for (const eachEntry of [
    CombineChainsEnum.SINGLE_BANNER,
    CombineChainsEnum.RETAILER_GROUP,
    CombineChainsEnum.CHANNEL
  ]) {
    if (prefix !== eachEntry) disabledList.push(eachEntry)
  }

  setDisabledItemValues(Array.from(new Set(disabledList)))
  setSelectedBanners(newChange)
}

export default handleBannerChange
