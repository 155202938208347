import { GroupsWrapper } from 'components/charts/CustomGroupsList/style'
import EmptyData from 'components/elements/EmptyData'
import React from 'react'

const EmptyGroup = () => {
  return (
    <GroupsWrapper style={{ maxHeight: '60vh' }}>
      <EmptyData
        descriptionComponent={<h6>Your saved selections will show here</h6>}
      />
    </GroupsWrapper>
  )
}

export default EmptyGroup
