import { BORDER_RADIUS, COLOR, SPACE } from 'services/styles'
import { FontWeight } from 'services/styles/misc'
import styled from 'styled-components'

export const HeaderCell = styled.th`
  font-size: 16px;
  color: ${COLOR.textGrey};
  text-align: start;
  top: 0;
  z-index: 20;
`

export const StyledTable = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 ${SPACE.tiny}px;
`

export const StyledCell = styled.td`
  font-weight: ${FontWeight.BOLD};
  text-align: start;

  &:first-child {
    border-top-left-radius: ${BORDER_RADIUS.medium}px;
    border-bottom-left-radius: ${BORDER_RADIUS.medium}px;
  }

  &:last-child {
    border-top-right-radius: ${BORDER_RADIUS.medium}px;
    border-bottom-right-radius: ${BORDER_RADIUS.medium}px;
  }
`
