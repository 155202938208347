import { Button, Tag } from 'antd'
import FlexContainer from 'components/elements/FlexContainer'
import { BORDER_RADIUS, COLOR, SPACE } from 'services/styles'
import { ButtonHeight, FontSize, FontWeight } from 'services/styles/misc'
import styled from 'styled-components'

export const NextButtonRowWrapper = styled(FlexContainer)`
  padding: ${SPACE.small}px;
  padding-top: ${SPACE.tiny}px;
  background-color: transparent;
`

export const CloseButtonWrapper = styled.div`
  width: 30px;

  &:hover {
    cursor: pointer;
  }
`

export const GhostButton = styled(Button)`
  height: ${ButtonHeight.MEDIUM - SPACE.tiny / 2}px;
  border-color: ${COLOR.blueButton};
  color: ${COLOR.blueButton};
  padding-top: 0;
  padding-bottom: 0;
  font-size: 16px;
  font-weight: ${FontWeight.BOLD};
  border-radius: ${BORDER_RADIUS.standard * 3}px;
`

export const WarningTag = styled(Tag)`
  padding: ${SPACE.tiny / 2}px ${SPACE.tiny}px;
  font-size: ${FontSize.MEDIUM}px;
  border-width: 2px;
  border-radius: ${BORDER_RADIUS.small}px;
`
