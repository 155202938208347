import { COLOR, SPACE } from 'services/styles'
import styled from 'styled-components'

export const WarningText = styled.p`
  color: ${COLOR.danger};
`

export const Label = styled.p`
  margin-bottom: ${SPACE.tiny / 2}px;
`
