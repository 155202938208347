import { initialUserState, UserActionTypes, UserState } from 'data/user/types'
import produce from 'immer'
import { handleActions } from 'redux-actions'

const user = handleActions<UserState, any>(
  {
    [UserActionTypes.IS_FETCHING_USER]: (state, { payload }) => {
      const { isFetchingUser } = payload
      return produce<UserState>(state, draftState => {
        draftState.isFetchingUser = isFetchingUser
      })
    },
    [UserActionTypes.SET_USER]: (state, { payload }) => {
      const { user } = payload
      return produce<UserState>(state, draftState => {
        draftState.user = {
          ...user,
          fullName: `${user?.firstName} ${user?.lastName}`
        }
      })
    },
    [UserActionTypes.SET_FIREBASE_READY]: state => {
      return produce<UserState>(state, draftState => {
        draftState.isFirebaseReady = true
      })
    },
    [UserActionTypes.SET_VIEW_AS]: (state, { payload }) => {
      const { user } = payload
      const viewAs = user
        ? {
            ...user,
            fullName: `${user?.firstName} ${user?.lastName}`
          }
        : null
      return produce<UserState>(state, draftState => {
        draftState.viewAs = viewAs
      })
    },
    [UserActionTypes.SET_KNOWN_PERIODS]: (state, { payload }) => {
      const { periods } = payload
      return produce<UserState>(state, draftState => {
        draftState.knownPeriods = periods
      })
    }
  },
  initialUserState
)

export default user
