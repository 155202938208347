import { COLOR } from 'services/styles'
import { ReactComponent as CalendarSVG } from 'static/icons/calendar.svg'
import { ReactComponent as CancelSVG } from 'static/icons/cancel.svg'
import { ReactComponent as DownloadSVG } from 'static/icons/download.svg'
import { ReactComponent as DuplicateSVG } from 'static/icons/duplicate-black.svg'
import { ReactComponent as PencilSVG } from 'static/icons/pencil.svg'
import { ReactComponent as SortSVG } from 'static/icons/sort.svg'
import { ReactComponent as StarSVG } from 'static/icons/star.svg'
import styled from 'styled-components'

interface SVGprops {
  color?: string
  fill?: string
  width?: number
  height?: number
}

const defaultFill = COLOR.textGrey
const defaultHoverFill = COLOR.textDark

export const PencilIcon = styled(PencilSVG)`
  height: 14px;
  width: 14px;
  fill: ${(props: SVGprops) => props.color || defaultFill};

  transition: 0.3s linear;

  &:hover {
    cursor: pointer;
    fill: ${(props: SVGprops) => props.fill || defaultHoverFill};
  }
`

export const CancelIcon = styled(CancelSVG)`
  height: ${(props: SVGprops) => props.height || 14}px;
  width: ${(props: SVGprops) => props.width || 14}px;
  fill: ${(props: SVGprops) => props.color || defaultFill};

  transition: 0.3s linear;

  &:hover {
    cursor: pointer;
    fill: ${(props: SVGprops) => props.fill || defaultHoverFill};
  }
`

export const SortIcon = styled(SortSVG)`
  width: 22px;
  height: 15px;
  fill: ${(props: SVGprops) => props.color || defaultFill};

  &:hover {
    cursor: pointer;
    fill: ${(props: SVGprops) => props.fill || defaultHoverFill};
  }
`

export const StarIcon = styled(StarSVG)`
  height: 16px;
  width: 17px;
  fill: ${(props: SVGprops) => props.fill || defaultHoverFill};
`

export const CalendarIcon = styled(CalendarSVG)`
  height: 16px;
  width: 17px;
`

export const DuplicateIcon = styled(DuplicateSVG)`
  height: 16px;
  width: 17px;
  fill: ${(props: SVGprops) => props.fill || defaultHoverFill};
`

export const DownloadIcon = styled(DownloadSVG)`
  height: 14px;
  width: 14px;
  fill: ${(props: SVGprops) => props.fill || defaultHoverFill};
`
