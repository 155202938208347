import reports from 'routes/reports'

export enum ReportNames {
  TRENDED_METRICS = 'TRENDED_METRICS',
  TRENDED_METRICS_MULTIPLE = 'TRENDED_METRICS_MULTIPLE',
  PURCHASE_DRIVERS = 'PURCHASE_DRIVERS',
  DEMOGRAPHICS = 'DEMOGRAPHICS',
  IN_BASKET = 'IN_BASKET',
  REPERTOIRE = 'REPERTOIRE',
  CROSS_PURCHASE = 'CROSS_PURCHASE'
}

export const ReportMeta: IReportMeta = {
  [ReportNames.TRENDED_METRICS]: {
    title: 'Trended Metrics',
    description:
      'Analyse purchase index or market share across the trend of' +
      ' your choice for one or multiple products.',
    key: 'trended-metrics'
  },
  [ReportNames.TRENDED_METRICS_MULTIPLE]: {
    title: 'Trended Metrics (Multiple Facts)',
    description:
      'Visualise up to two metrics (basket penetration, buyer' +
      ' index, price, purchase index or market share) for one product' +
      ' selection over the trend of your choice.',
    key: 'trended-metrics-multiple'
  },
  [ReportNames.PURCHASE_DRIVERS]: {
    title: 'Purchase Drivers',
    description:
      'Understand what consumer metrics are driving the value' +
      ' growth or decline of a product (brokedown buyers, units per' +
      ' occasion, occasions and price).',
    key: 'purchase-drivers'
  },
  [ReportNames.DEMOGRAPHICS]: {
    title: 'Demographics',
    description:
      'Understand in which demographic segment (household size,' +
      ' income and age group) a product is over-indexing based on buyer,' +
      ' spend and units distribution.',
    key: 'demographics'
  },
  [ReportNames.IN_BASKET]: {
    title: 'In Basket',
    description:
      'Understand what brands are more often purchased in the same basket where another brand is present.',
    key: 'in-basket'
  },
  [ReportNames.REPERTOIRE]: {
    title: 'Repertoire',
    description:
      'Understand what brands are more often purchased in household where another brand is present.',
    key: 'repertoire'
  },
  [ReportNames.CROSS_PURCHASE]: {
    title: 'Retailer Cross Purchase',
    description:
      'Map out the shopper cross-purchase or exclusivity for up to three banners.',
    key: 'cross-purchase'
  }
}

export const disabledAgeGroupsReportRoutes = [
  reports.trendedMetrics(),
  reports.trendedMetricsMultiple(),
  reports.purchaseDrivers(),
  reports.demographics()
]

export type IReportMeta = {
  [key in ReportNames]: {
    title: string
    description: string
    key: string
  }
}
