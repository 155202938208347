import { ExcelExport } from '@progress/kendo-react-excel-export'
import { GridColumn as DataColumn } from '@progress/kendo-react-grid/dist/npm/GridColumn'
import DataGridSortable from 'components/charts/DataGridSortable'
import { CenteredText } from 'components/elements/TableCells'
import WrapHeaderCell from 'components/elements/TableCells/WrapHeaderCell'
import { ChartSelector } from 'data/selectors'
import { SELECT_ALL_PRODUCT_KEY } from 'pages/reports/reports/common/reportHooks/usePreselectProduct'
import { NoneDrillDown } from 'pages/reports/reports/common/types'
import React from 'react'
import { useSelector } from 'react-redux'
import { changeTextIfLowNumber } from 'services/helpers/checkIsLowNumber'
import { periodToText } from 'services/helpers/toPeriod'
import { getCleanGroupSignature } from '../../common/helpers'

const RenderDataTable = ({
  data,
  dataTableRef,
  fileName,
  drillDown,
  selectedProducts
}) => {
  const groups = useSelector(ChartSelector.customGroups)
  const selectedPeriods = useSelector(ChartSelector.selectedPeriod)

  const dataGridData = data.reduce((acc, curr) => [...acc, ...curr], [])
  const excelOutput = dataGridData.map(i => {
    const rollingPeriod = selectedPeriods.content.find(period =>
      period.includes(+i.period)
    )

    const [groupName] = getCleanGroupSignature(
      groups,
      i.key,
      drillDown.key !== NoneDrillDown,
      !selectedProducts.find(i => i.key === SELECT_ALL_PRODUCT_KEY)
    )

    return {
      ...i,
      canonicalPeriod: periodToText(rollingPeriod),
      key: changeTextIfLowNumber(groupName || 'Default', i.warningLevel)
    }
  })
  return (
    <ExcelExport data={excelOutput} ref={dataTableRef} fileName={fileName}>
      <DataGridSortable data={excelOutput}>
        <DataColumn
          field="canonicalPeriod"
          title="Period"
          sortable
          cell={CenteredText}
          width={100}
        />

        <DataColumn
          field="key"
          title="Groups"
          cell={CenteredText}
          headerCell={WrapHeaderCell}
        />
        <DataColumn
          field="buyers"
          title="Indexed Buyers"
          format="{0:n0}"
          sortable
          headerCell={WrapHeaderCell}
        />
        <DataColumn
          field="penetration"
          title="Penetration"
          format="{0:n0}%"
          sortable
          headerCell={WrapHeaderCell}
        />
        <DataColumn
          field="pricePerPack"
          title="Price per Pack"
          sortable
          format="{0:c}"
          headerCell={WrapHeaderCell}
        />
        <DataColumn
          field="shareByValue"
          title="Share by Value"
          format="{0:n1}%"
          headerCell={WrapHeaderCell}
        />
        <DataColumn
          field="shareByOccasions"
          title="Share by Occasions"
          format="{0:n1}%"
          headerCell={WrapHeaderCell}
        />
        <DataColumn
          field="purchaseValue"
          title="Indexed Purchase Value"
          format="{0:n0}"
          headerCell={WrapHeaderCell}
        />
        <DataColumn
          field="purchaseUnits"
          title="Indexed Purchase Units"
          format="{0:n0}"
          headerCell={WrapHeaderCell}
        />
        <DataColumn
          field="averageOccasions"
          title="Average Occasions"
          format="{0:n1}"
          headerCell={WrapHeaderCell}
        />
        <DataColumn
          field="spendPerOccasion"
          title="Spend per Occasion"
          format="{0:c}"
          headerCell={WrapHeaderCell}
        />
        <DataColumn
          field="unitsPerOccasion"
          title="Units per Occasion"
          format="{0:n1}"
          headerCell={WrapHeaderCell}
        />
        <DataColumn
          field="unitAwop"
          title="Unit AWOP"
          format="{0:n1}"
          headerCell={WrapHeaderCell}
        />
        <DataColumn
          field="valueAwop"
          title="Value AWOP"
          format="{0:c}"
          headerCell={WrapHeaderCell}
        />
      </DataGridSortable>
    </ExcelExport>
  )
}

const MemoizedRenderTable = React.memo(RenderDataTable)
export default MemoizedRenderTable
