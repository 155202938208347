import { Tooltip } from 'antd'
import { AlignedCenterButton } from 'components/page/ReportPage/header/components/styles'
import React, { useState } from 'react'

const ButtonWithTooltip = ({ tooltipText, text, ...buttonProps }) => {
  const { onClick, ...restProps } = buttonProps
  const [isLoading, setIsLoading] = useState(false)

  const handleClick = async () => {
    setIsLoading(true)
    await onClick()
    setIsLoading(false)
  }
  return (
    <Tooltip key={text} placement="bottomRight" title={tooltipText || ''}>
      <AlignedCenterButton
        loading={isLoading}
        type={'text'}
        {...restProps}
        onClick={handleClick}
      >
        {text}
      </AlignedCenterButton>
    </Tooltip>
  )
}

export default ButtonWithTooltip
