import { Modal } from 'antd'
import { DialogKeys, DialogTypes } from 'components/dialogs/index'
import { CancelIcon } from 'components/elements/SvgIcon/styles'
import { DialogActions } from 'data/actions'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { toProperCase } from 'services/helpers/canonicalization'
import { SPACE } from 'services/styles'
import { ButtonHeight } from 'services/styles/misc'

export default ({ onCancel, onOk, name, type, fromText }: Props) => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)

  const handleCancel = () => {
    if (onCancel) return onCancel()
    dispatch(DialogActions.hideDialog({ key: DialogKeys.DELETE_GENERIC }))
  }

  return (
    <Modal
      title={`Remove ${toProperCase(DialogTypes[type])}`}
      visible
      closeIcon={
        <CancelIcon
          width={ButtonHeight.SMALL}
          height={ButtonHeight.SMALL}
          style={{ marginTop: SPACE.tiny * 2.75 }}
        />
      }
      onOk={async () => {
        setIsLoading(true)
        await onOk()
      }}
      onCancel={handleCancel}
      confirmLoading={isLoading}
      okButtonProps={{ style: { width: '50%' } }}
      cancelButtonProps={{ type: 'text' }}
      okText={'Remove'}
      width={450}
    >
      <p style={{ fontSize: 18 }}>{`Are you sure you want to remove "${name}" ${
        fromText || 'from analysis'
      }?`}</p>
    </Modal>
  )
}

interface Props {
  onCancel?: () => void
  onOk: () => void
  fromText?: string
  name: string
  type: DialogTypes
}
