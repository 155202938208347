import { Input, Radio, Row } from 'antd'
import { BORDER_RADIUS, COLOR, SPACE } from 'services/styles'
import { FontWeight } from 'services/styles/misc'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: ${SPACE.tiny}px;
  border-radius: ${BORDER_RADIUS.medium}px;
  background-color: white;
  font-size: 17px;
`

export const RowWrapper = styled(Row)`
  margin-top: ${SPACE.tiny}px;
  margin-bottom: ${SPACE.tiny}px;

  margin-left: ${SPACE.tiny / 2}px !important;
  margin-right: ${SPACE.tiny / 2}px !important;
`

export const StyledRadio = styled(Radio)`
  font-size: 17px;
`

export const GuideMessage = styled.span`
  display: block;
  padding: 11px;
  padding-top: 0;
  line-height: 1.5;
  font-size: 14px;
  box-sizing: border-box;
`

export const ErrorMessageBlock = styled.span`
  display: block;
  padding: 11px;
  padding-top: 0;
  color: ${COLOR.danger};
  line-height: 1.5;
  font-size: 14px;
  box-sizing: border-box;
`

export const BoldTextInput = styled(Input)`
  font-size: 18px;
  font-weight: ${FontWeight.BOLD};
  height: ${SPACE.large}px;
  border-radius: ${BORDER_RADIUS.standard * 3}px;
`
