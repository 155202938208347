import { useMemo } from 'react'

import { TypeAttributes } from '../@types/common'
import useCustom from './useCustom'

function useToggleCaret (placement: TypeAttributes.Placement8 | TypeAttributes.Placement) {
  const { rtl } = useCustom('Dropdown')
  return useMemo(() => {
    switch (true) {
      case /^top/.test(placement):
        return null
      case /^right/.test(placement):
        return rtl ? null : null
      case /^left/.test(placement):
        return rtl ? null : null
      case /^bottom/.test(placement):
      default:
        return null
    }
  }, [placement, rtl])
}

export default useToggleCaret
