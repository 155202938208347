import { Text } from 'components/charts/SampleSizeWarningText/style'
import React from 'react'
import { SPACE } from 'services/styles'

export default () => (
  <Text style={{ marginTop: SPACE.small }}>
    {'*: Use with Caution'} &ensp; {'**: Use with high level of Caution'}
    &ensp; {'***: Not Robust'}
  </Text>
)
