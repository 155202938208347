export interface TreeData {
  value: string
  label: string
  children?: TreeData[]
  disabled?: boolean
  robust4Wk?: boolean
  robust12Wk?: boolean
  robust24Wk?: boolean
  robust52Wk?: boolean
}

export interface RawTreeData {
  id: string
  children: RawTreeData[]
  label: string
  robust4Wk?: boolean
  robust12Wk?: boolean
  robust24Wk?: boolean
  robust52Wk?: boolean
}

export const renderTreeRecursive = (data: RawTreeData): TreeData => {
  const { id: prefixedValue, label: cleanLabel, children, ...rest } = data

  if (children?.length && cleanLabel.length) {
    return {
      ...data,
      value: prefixedValue,
      label: cleanLabel,
      children: children
        .filter(children => !!children && !!children.label)
        .map(renderTreeRecursive)
    }
  }
  return {
    ...((rest as unknown) as TreeData),
    value: prefixedValue,
    label: cleanLabel
  }
}
