import { BORDER_RADIUS, COLOR, SPACE } from 'services/styles'
import { ReactComponent as MoreBlackSVG } from 'static/icons/more.svg'
import styled from 'styled-components'

export const CARD_HEIGHT = 450
export const MAX_IMAGE_HEIGHT = 250

export const HoverDiv = styled.div`
  border-radius: ${BORDER_RADIUS.medium}px;

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
`

export const TitleRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
  margin-top: -${SPACE.tiny}px;
  margin-right: -${SPACE.tiny}px;
  margin-bottom: 0px;
`

export const TitleText = styled.p`
  font-weight: 600;
  text-overflow: ellipsis;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
`

export const TitleSection = styled.div``

export const Image = styled.img`
  height: ${CARD_HEIGHT - 50}px;
  object-fit: contain;
  max-height: ${MAX_IMAGE_HEIGHT}px;
  padding: 10px;
  padding-top: ${SPACE.medium}px;
`

export const MoreBlackIcon = styled(MoreBlackSVG)`
  fill: ${COLOR.textGrey};

  &:hover {
    fill: ${COLOR.textDark};
  }
`
export const AbsoluteWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  position: absolute;
  top: 0;
  right: ${SPACE.tiny}px;
  background-color: transparent;
`

export const RightAlignWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`
