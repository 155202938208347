import { Select } from 'antd'
import ListWithCheckbox from 'components/elements/ListWithCheckbox'
import {
  SELECT_ALL_PRODUCT_KEY,
  selectAllProductItem
} from 'pages/reports/reports/common/reportHooks/usePreselectProduct'
import React from 'react'
import { SelectDataProps } from 'services/helpers/dropdownUtils'

interface Props {
  options: SelectDataProps[]
  value: SelectDataProps[]
  onChange: (newList: SelectDataProps[]) => void
}

const MultipleProductsSelector = ({ options, value, onChange }: Props) => {
  const handleOnCheckboxListChange = (newValue, updatedKey) => {
    const isAllChecked = newValue.find(i => i.key === SELECT_ALL_PRODUCT_KEY)

    if (updatedKey !== SELECT_ALL_PRODUCT_KEY) {
      if (newValue.length) {
        const [, ...newList] = newValue
        onChange(isAllChecked ? newList : newValue)
      } else {
        onChange([selectAllProductItem])
      }
    } else {
      onChange(
        isAllChecked
          ? [selectAllProductItem]
          : options.filter(i => i !== selectAllProductItem)
      )
    }
  }

  return (
    <div>
      <p>SELECT PRODUCTS</p>
      <Select
        style={{ minWidth: 250, maxWidth: 400 }}
        value={value.map(i => String(i.value)).toString()}
        options={options}
        dropdownMatchSelectWidth={false}
        dropdownRender={() => (
          <ListWithCheckbox
            data={options}
            value={value}
            onChange={handleOnCheckboxListChange}
          />
        )}
      />
    </div>
  )
}

export default MultipleProductsSelector
