import { OrganisationProps } from 'pages/types'
import { useQuery } from 'react-query'
import { QueryKeys } from 'services/api/keys'
import { getOrganisationsApi } from 'services/api/requests/organisation/organisations'

const useLoadData = user =>
  useQuery<OrganisationProps[]>(
    [QueryKeys.ORG_OVERVIEW, user],
    getOrganisationsApi,
    { initialData: [] }
  )

export default useLoadData
