import { worker } from '__mocks__/browser'
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import './index.css'
import * as serviceWorker from './serviceWorker'

if (process.env.NODE_ENV === 'test') {
  const { worker } = require('./__mocks__/browser')
  worker.start()
}

ReactDOM.render(<App />, document.getElementById('root'))

if (process.env.NODE_ENV !== 'test') {
  serviceWorker.unregister()
}
