import { StandardSelectableOptions } from 'pages/reports/reports/common/reportHooks/useStandardSelectable'
import { Dispatch, useEffect, useState } from 'react'
import {
  SelectDataProps,
  StringToSelectDataProps
} from 'services/helpers/dropdownUtils'

export const SELECT_ALL_PRODUCT_KEY = 'SUM OF SELECTION'
export const selectAllProductItem = StringToSelectDataProps(
  SELECT_ALL_PRODUCT_KEY
)

const usePreselectProducts = (
  selectable: StandardSelectableOptions
): [SelectDataProps[], Dispatch<SelectDataProps[]>] => {
  const [selectedProducts, setSelectedProducts] = useState<SelectDataProps[]>([
    selectAllProductItem
  ])

  useEffect(() => {
    setSelectedProducts([selectAllProductItem])
  }, [selectable.products])

  return [selectedProducts, setSelectedProducts]
}

export default usePreselectProducts
