import { textToPeriod } from 'services/helpers/toPeriod'
import { getter } from '../accessor'
import { isBlank, isPresent } from '../utils'

var compare = function(a, b, c) {
    if (c.toLowerCase().includes('period')) return textToPeriod(a) - textToPeriod(b)
    if (isBlank(a)) return a === b ? 0 : -1
    if (isBlank(b)) return 1
    if (a.localeCompare) return a.localeCompare(b)
    return a > b ? 1 : (a < b ? -1 : 0)
}
var compareDesc = function(a, b, c) { return compare(b, a, c) }
var descriptorAsFunc = function(descriptor) {
    var prop = getter(descriptor.field, true)
    return function(a, b, c) { return (descriptor.dir === 'asc' ? compare : compareDesc)(prop(a), prop(b), descriptor.field) }
}
var initial = function(_a, _b) { return 0 }
// tslint:disable:max-line-length
/**
 * Converts the `SortDescriptors` into a [`Comparer`]({% slug
 * api_kendo-data-query_comparer %}) function that can be used through
 * `Array.sort`. If multiple descriptors are provided, sorting is applied in a
 * right-to-left order.
 * @param {SortDescriptor[]} descriptors - The descriptors which will be
 *   converted.
 * @returns {Comparer} - The produced function.
 *
 * @example
 * ```ts-no-run
 * import { composeSortDescriptors } from '@progress/kendo-data-query';
 *
 * const data = [{ name: "Pork" }, { name: "Pepper" }, { name: "Beef" } ];
 * const comparer = composeSortDescriptors([{ field: "name", dir: "asc" }]);
 * const result = data.sort(comparer);
 * // output: [{ name: "Beef" }, { name: "Pepper" }, { name: "Pork" }];
 * ```
 */
// tslint:enable:max-line-length
export var composeSortDescriptors = function(descriptors) {
    return (descriptors
      .filter(function(x) { return isPresent(x.dir) })
      .map(function(descriptor) { return descriptorAsFunc(descriptor) })
      .reduce(function(acc, curr) { return function(a, b) { return acc(a, b) || curr(a, b) } }, initial))
}
