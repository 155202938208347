import { Grid, GridProps, GridSortSettings } from '@progress/kendo-react-grid'
import { Tooltip } from '@progress/kendo-react-tooltip'
import { orderBy } from 'forkedComponents/kendoDataQuery/array.operators'

import React, { useState } from 'react'

const mode: GridSortSettings = { mode: 'multiple' }

const DataGridSortable = (props: { children: React.ReactNode } & GridProps) => {
  const [sort, setSort] = useState([])
  const rawData = props.data as any[]
  const sortChange = event => setSort(event.sort)
  const sortedRawData = orderBy(rawData, sort)

  const renderCellWithLimit = (defaultRendering: any, cellProps) => {
    const value = cellProps.dataItem[cellProps.field]
    const formattedValue = defaultRendering.props.children
    return (
      <td {...defaultRendering.props}>
        {value > 400 ? '> 400' : formattedValue}
      </td>
    )
  }

  return (
    <Tooltip position={'top'} showCallout={false}>
      <Grid
        style={{
          textAlign: 'right',
          maxHeight: '40vh',
          overflow: 'auto'
        }}
        {...props}
        data={sortedRawData}
        sortable={mode}
        sort={sort}
        resizable
        onSortChange={sortChange}
        cellRender={renderCellWithLimit}
        headerCellRender={(defaultRendering: any, cellProps) => (
          <span {...defaultRendering.props} title={cellProps.title} />
        )}
      >
        {props.children}
      </Grid>
    </Tooltip>
  )
}

export default DataGridSortable
