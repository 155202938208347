enum WarningLevelEnum {
  LEVEL_0 = 0,
  LEVEL_1 = 1,
  LEVEL_2 = 2,
  LEVEL_3 = 3
}

export const WarningLevelIndicator = {
  [WarningLevelEnum.LEVEL_0]: '',
  [WarningLevelEnum.LEVEL_1]: '*',
  [WarningLevelEnum.LEVEL_2]: '**',
  [WarningLevelEnum.LEVEL_3]: '***'
}

export const changeTextIfLowNumber = (
  text: string,
  warningLevel: number
): string => text + WarningLevelIndicator[warningLevel]
