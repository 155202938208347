import { useEffect, useState } from 'react'
import { isLargeScreen } from 'services/helpers/screenSizeHelpers'

const useIsScreenLarge = () => {
  const [isScreenLarge, setIsScreenLarge] = useState(false)

  const checkScreenSize = () =>
    setIsScreenLarge(isLargeScreen(window.innerWidth))

  useEffect(() => {
    checkScreenSize()
    window.addEventListener('resize', checkScreenSize)

    return () => window.removeEventListener('resize', checkScreenSize)
  }, [])

  return isScreenLarge
}

export default useIsScreenLarge
