import { periodPreset } from 'components/charts/ReportParameterSelector/PeriodSelector/types'
import { AppReducerState } from 'data/reducers'
import { separator } from 'pages/reports/reports/common/helpers'
import { cleanBannersQuery } from 'pages/reports/utils/cleanBanners'
import { cleanProductQuery } from 'pages/reports/utils/cleanBody'
import { cleanBuyerGroupQuery } from 'pages/reports/utils/cleanBuyerGroup'
import { createSelector } from 'reselect'
import {
  filterProductsTree,
  periodTypeToRobustKey
} from 'services/api/requests/common/dataSelector'
import { periodToText, toSelectedKey } from 'services/helpers/toPeriod'

export const chartOptions = (state: AppReducerState) => state.chart.options

export const productsList = (state: AppReducerState) => {
  const fullProducts = state.chart.options.products

  const selectedPeriod = state.chart.selectedChartOptions.period
  const customPeriods = state.chart.customPeriods

  const selectedCustomPeriod = customPeriods.find(
    i => i.key === selectedPeriod.key
  )

  let periodType
  if (selectedCustomPeriod) {
    periodType = selectedCustomPeriod.periodType
  } else {
    const selections = selectedPeriod.key.split(separator)
    periodType = selections[1]
  }

  return filterProductsTree(fullProducts, periodTypeToRobustKey[periodType])
}
export const chainsList = (state: AppReducerState) => state.chart.options.chains

export const buyerGroupsList = (state: AppReducerState) =>
  state.chart.options.buyerGroups

export const periodsList = (state: AppReducerState) =>
  state.chart.options.periods

export const savedReportState = (state: AppReducerState) =>
  state.chart.savedReportState

export const customGroups = (state: AppReducerState) => state.chart.customGroups

export const selectedPeriod = (state: AppReducerState) =>
  state.chart.selectedChartOptions.period

export const customPeriods = (state: AppReducerState) =>
  state.chart.customPeriods

export const selectedPeriodKey = createSelector(
  selectedPeriod,
  customPeriods,
  (selectedPeriod, customPeriods) => {
    const selectablePeriodMenu = [
      ...periodPreset,
      ...customPeriods.map(i => {
        const selectionsKey = toSelectedKey(
          i.numberOfPeriods,
          i.periodType,
          i.rollingType,
          i.datePointer,
          i.dateMode
        )
        return {
          value: i.name,
          id: i.key,
          key: selectionsKey
        }
      })
    ]

    return selectablePeriodMenu.find(i =>
      'id' in i ? i?.id === selectedPeriod.key : i?.key === selectedPeriod.key
    )
  }
)

export const chartQuerySelector = createSelector(
  customGroups,
  selectedPeriod,
  (groups, selectedPeriod): ChartQuerySelectorProps[] =>
    groups.map(group => ({
      id: group.id,
      name: group.name,
      chains: cleanBannersQuery(group.chains),
      products: cleanProductQuery(group.products),
      buyerGroups: cleanBuyerGroupQuery(group.buyerGroups),
      periods: selectedPeriod.content
    }))
)

export const periodLabels = createSelector(chartQuerySelector, chartQuery =>
  chartQuery?.[0]?.periods?.map(periodToText)
)

export interface ChartQuerySelectorProps {
  id: string
  name: string
  chains: string[]
  products: string[]
  buyerGroups: string[]
  periods: number[][]
}
