import { ChartSelector } from 'data/selectors'
import { ReportMeta } from 'pages/reports/reportMeta'
import { useFilterQueries } from 'pages/reports/reports/common/helpers'
import { Dispatch, SetStateAction, useEffect } from 'react'
import { useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import dataFallback from 'services/api/requests/common/dataFallback'
import { getTrendedMetricsApi } from 'services/api/requests/reports/trendedMetrics'
import { SelectDataProps } from 'services/helpers/dropdownUtils'
import formatData, { TrendedMetricsFormatData } from './formatData'
import { TrendedMetricsEnum } from './types'

export const useLoadData = (
  setIsLoading: Dispatch<SetStateAction<boolean>>,
  metric: SelectDataProps,
  drillDown: SelectDataProps,
  selectedGroups: SelectDataProps[],
  selectedProducts: SelectDataProps[]
) => {
  const dispatch = useDispatch()

  const groups = useSelector(ChartSelector.customGroups)

  const queries = useFilterQueries(
    ReportMeta.TRENDED_METRICS.key,
    selectedGroups,
    {
      drillDown,
      selectedProducts
    }
  )

  const { isFetching, isLoading, data } = useQuery([queries], () =>
    getTrendedMetricsApi(queries, dispatch)
  )

  const fallbackData = dataFallback(data)

  useEffect(() => setIsLoading(isLoading || isFetching), [
    isLoading,
    isFetching
  ])

  const formattedData: TrendedMetricsFormatData = formatData(
    fallbackData,
    metric.key as TrendedMetricsEnum,
    groups,
    selectedProducts,
    drillDown
  )

  return [fallbackData, formattedData]
}
