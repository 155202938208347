import {
  NextButtonBackground,
  StepperContentTitle,
  StepperWrapper
} from 'components/dialogs/CustomGroup/common/style'
import StepperTitle from 'components/elements/SectionTitle/StepperTitle'
import { ChartSelector } from 'data/selectors'
import MultiCascader from 'forkedComponents/rsuite/MultiCascader/MultiCascader'
import { cleanProductQuery } from 'pages/reports/utils/cleanBody'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { SPACE } from 'services/styles'

interface Props {
  orgOptions?: any
  selectedProducts: string[]
  handleProductChange: (i: string[]) => void
}

const PRODUCTS_HEADERS = [
  { ALCOHOL: 'Categories' },
  {
    BEER: 'Manufacturers',
    CIDER: 'Manufacturers',
    RTD: 'Manufacturers',
    WINE: 'Varietals',
    SPIRIT: 'Varietals',
    'SPARKLING & CHAMPAGNE': 'Varietals',
    '0% ALCOHOL': 'Categories'
  },
  {
    BEER: 'Brands',
    CIDER: 'Brands',
    RTD: 'Brands',
    WINE: 'Manufacturers',
    SPIRIT: 'Manufacturers',
    'SPARKLING & CHAMPAGNE': 'Manufacturers',
    '0% ALCOHOL': 'Manufacturers'
  },
  {
    BEER: 'Sub brands',
    CIDER: 'Sub brands',
    RTD: '',
    WINE: 'Brands',
    SPIRIT: 'Brands',
    'SPARKLING & CHAMPAGNE': 'Brands',
    '0% ALCOHOL': 'Brands'
  }
]

export const StepperDropdown = ({
  orgOptions,
  selectedProducts,
  handleProductChange
}: Props) => {
  let products = useSelector(ChartSelector.productsList)

  if (orgOptions) {
    products = orgOptions?.products
  }

  const [stepperIndex, setStepperIndex] = useState<number>(0)

  const menuHeight = ((products?.length || 0) + 1) * 36
  const stepperContent = [
    {
      title: 'products',
      content: (
        <div style={{ display: stepperIndex !== 0 && 'none', width: '100%' }}>
          <NextButtonBackground>
            <MultiCascader
              menuWidth={335}
              menuHeight={menuHeight}
              menuAutoWidth
              labelKey={'label'}
              valueKey={'value'}
              data={products}
              headers={PRODUCTS_HEADERS}
              value={selectedProducts}
              inline
              menuStyle={{
                overflowX: 'scroll',
                overflowY: 'hidden',
                borderBottomLeftRadius: SPACE.tiny,
                borderBottomRightRadius: SPACE.tiny
              }}
              onChange={handleProductChange}
            />
          </NextButtonBackground>
        </div>
      ),
      selectedList: cleanProductQuery(selectedProducts)
    }
  ]

  return (
    <>
      {stepperContent.map((i, index) => (
        <StepperWrapper key={index}>
          <StepperContentTitle onClick={_ => setStepperIndex(index)}>
            <StepperTitle
              title={i.title}
              selectedList={i.selectedList}
              active={stepperIndex === index}
            />
          </StepperContentTitle>
          {i.content}
        </StepperWrapper>
      ))}
    </>
  )
}

// const listToProperCase = (array: string[]) =>
//   array.map(i => i.replace(/_/g, ' ')).map(toProperCase)
