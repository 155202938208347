import html2canvas from 'html2canvas'

export default (chartRef: any, fileName) => {
  const exportHTML = chartRef.current
  const hiddenTexts = exportHTML.querySelectorAll('.hidden-text')
  const hiddenClassName = ' hidden'
  hiddenTexts.forEach(
    hiddenText =>
      (hiddenText.className = hiddenText.className.replace(hiddenClassName, ''))
  )

  html2canvas(exportHTML, { backgroundColor: 'white' }).then(
    canvas => {
      const dataURL = canvas.toDataURL('image/png', 1.0)
      const link = document.createElement('a')
      if (typeof link.download === 'string') {
        link.href = dataURL
        link.download = fileName
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      } else {
        window.open(dataURL)
      }
    },
    reason => {}
  )

  hiddenTexts.forEach(
    hiddenText =>
      (hiddenText.className = hiddenText.className + hiddenClassName)
  )
}
