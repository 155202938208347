import React from 'react'
import { SPACE } from 'services/styles'

interface props {
  title: string
  legendWidth?: number
}

const MetricsChartTitle = ({ title, legendWidth }: props) => (
  <div style={{ display: 'flex' }}>
    <div style={{ width: SPACE.huge }} />
    <div style={{ paddingRight: legendWidth, width: '100%' }}>
      <h5 style={{ textAlign: 'center' }}>{title}</h5>
    </div>
  </div>
)

export default MetricsChartTitle
