import { periodPreset } from 'components/charts/ReportParameterSelector/PeriodSelector/types'
import { TreeData } from 'services/helpers/flatArrayToTree'
import { CombineChainsEnum } from 'services/helpers/toChain'

export enum ChartActionTypes {
  LOAD_OPTIONS_SUCCESSFUL = 'CHARTS/LOAD_OPTIONS_SUCCESSFUL',
  SET_PERIOD = 'CHARTS/SET_PERIOD',
  SET_REPORT_PERMISSIONS = 'CHARTS/SET_REPORT_PERMISSIONS',
  ADD_CUSTOM_PERIODS = 'CHARTS/ADD_CUSTOM_PERIODS',
  ADD_CUSTOM_PERIOD = 'CHARTS/ADD_CUSTOM_PERIOD',
  EDIT_CUSTOM_PERIOD = 'CHARTS/EDIT_CUSTOM_PERIOD',
  DELETE_CUSTOM_PERIOD = 'CHARTS/DELETE_CUSTOM_PERIOD',
  CLEAR_CUSTOM_PERIOD = 'CHARTS/CLEAR_CUSTOM_PERIOD',
  ADD_CUSTOM_GROUP = 'CHARTS/ADD_CUSTOM_GROUP',
  EDIT_CUSTOM_GROUP = 'CHARTS/EDIT_CUSTOM_GROUP',
  DELETE_CUSTOM_GROUP = 'CHARTS/DELETE_CUSTOM_GROUP',
  DUPLICATE_CUSTOM_GROUP = 'CHARTS/DUPLICATE_CUSTOM_GROUP',
  RESET_CHART_PARAMETERS = 'CHARTS/RESET_CHART_PARAMETERS',
  REHYDRATE_CHART_PARAMETERS = 'CHARTS/REHYDRATE_CHART_PARAMETERS'
}

export interface ChartOptionsState {
  chains: TreeData[]
  products: TreeData[]
  buyerGroups: TreeData[]
  periods: number[]
}

export type ChartCustomGroupState = CustomGroup[]

export interface CustomGroup {
  id: string
  name: string
  products: string[]
  chains: string[]
  buyerGroups: string[]
  color: string
  isEditable?: boolean
  type?: string
  user?: any
  sharedOrgs?: any[]
}

export type ChartCustomPeriods = CustomPeriod[]

export interface CustomPeriod {
  key: string
  periodType: number | string
  rollingType: number | string
  numberOfPeriods: number | string
  datePointer?: number | string
  dateMode?: string
  name?: string
}

export interface SelectedChartOptionsState {
  period: PeriodState
}

export interface PeriodState {
  content?: number[][]
  key?: string
}

export interface SavedReportState {
  canSave?: boolean
  canShare?: boolean
}

export const initialSavedReportState: SavedReportState = {
  canSave: true,
  canShare: false
}

export const initialCustomGroup: CustomGroup = {
  id: null,
  name: null,
  color: null,
  chains: [CombineChainsEnum.ALL_BANNERS],
  products: [],
  buyerGroups: ['ALL_BUYER_GROUP']
}

export const initialCustomPeriod: CustomPeriod = {
  key: null,
  periodType: 1,
  rollingType: 1,
  numberOfPeriods: 1,
  datePointer: null
}

export const initialChartOptions: ChartOptionsState = {
  chains: [],
  products: [],
  buyerGroups: [],
  periods: []
}

export const initialChartCustomGroups: ChartCustomGroupState = []

export const initialSelectedChartOptions: SelectedChartOptionsState = {
  period: {
    content: [],
    key: periodPreset?.[0].key
  }
}
