import { Menu } from 'antd'
import { SPACE } from 'services/styles'
import styled from 'styled-components'

export const TextWrapper = styled.div`
  padding: ${SPACE.tiny / 2}px ${SPACE.small}px;
`

export const UnclickableItem = styled(Menu.Item)`
  &:hover {
    cursor: default;
  }
`
