import { Text } from '@progress/kendo-drawing'
import { Point } from '@progress/kendo-drawing/geometry'

const IndexedVisual = (e: any) => {
  const rect = e.rect
  const { x, y } = rect.origin
  const { width, height } = rect.size
  let newText = e.dataItem.toFixed(0)
  if (newText.length === 2) newText = ` ${newText}`
  if (newText.length === 1) newText = `  ${newText}`

  return (
    +newText &&
    new Text(
      newText,
      new Point(Math.round(x + width / 4), Math.round(y + height / 3)),
      { font: 'bold 1rem DIN' }
    )
  )
}

export default IndexedVisual
