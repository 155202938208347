import color from 'color'

/** Color scheme */
const BASE_COLOR = {
  danger: '#E73D57',

  emphasis: '#27475D',
  primaryShade1: '#37747B',

  darkBlue: '#181E2A',
  dirtyWhite: '#f8f8f8',
  darkTransparent: '#00000030',
  blueButton: '#188ECB',
  seriesC: '#5cb85c'
}

const notificationColor = {
  success: '#84BF48',
  error: '#E73D57',
  warning: '#FAAA1D',
  info: '#0275d8'
}

const darkColorSet = {
  dark: '#252525',
  textDark: '#404040'
}

const greyColorSet = {
  grey: '#4A4A4A',
  lightGrey: '#a8a8a8',
  antdGrey: '#f0f2f5',
  textGrey: '#9B9B9B',
  descriptionGrey: '#525252',
  inputGrey: '#EBEBEB',
  disabled: 'rgba(0,0,0,0.25)'
}

const desaturateColor = (hexColor: string): string =>
  color(hexColor).desaturate(0.15).hex()

export const GraphColors = [
  '#3f51b5',
  '#03a9f4',
  '#009688',
  '#f44336',
  '#9c27b0',
  '#673ab7',
  '#4caf50',
  '#ffeb3b',
  '#ffc107',
  '#ff5722',
  '#795548',
  '#607d8b'
].map(desaturateColor)

export const COLOR = {
  ...BASE_COLOR,
  ...greyColorSet,
  ...darkColorSet,
  ...notificationColor
}
