import { Button } from 'antd'
import { COLOR, SPACE } from 'services/styles'
import { FontSize } from 'services/styles/misc'
import styled from 'styled-components'

export const HoverDiv = styled.div`
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: center;

  &:hover {
    text-decoration: none;
    cursor: pointer;
  }
`

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
`

export const OuterWrapper = styled.div`
  padding: 0 ${SPACE.large}px;
  margin-bottom: ${SPACE.medium}px;
`

export const ButtonRow = styled.div`
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
`

export const ButtonWithIcon = styled(Button)`
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 15px;
`

export const ReportDescription = styled.p`
  font-size: ${FontSize.SMALL}px;
  color: ${COLOR.descriptionGrey};
  margin: 0;
`
