import { ChartActionTypes } from './chart/types'
import { DialogActionTypes } from './dialog/types'
import { UserActionTypes } from './user/types'

enum GlobalActionTypes {
  APP_INIT = 'GLOBAL/APP_INIT'
}

export {
  GlobalActionTypes,
  UserActionTypes,
  DialogActionTypes,
  ChartActionTypes
}
