import { Layout } from 'antd'
import React, { FC, useEffect } from 'react'
import CopyrightText from 'services/helpers/copyrightText'
import { SPACE } from 'services/styles'
import logo from 'static/logo-with-name-black.png'
import {
  CopyrightTextComponent,
  LogoWrapper,
  PageTitle,
  Wrapper
} from './style'

const StandardPage: FC<IStandardPage> = props => {
  const { headerText, pageTitle, noSidePadding } = props
  useEffect(() => {
    document.title = pageTitle || 'Nielsen | The U Group & Co Limited.'
  }, [pageTitle])

  return (
    <Wrapper noSidePadding={noSidePadding}>
      {headerText && <PageTitle>{headerText}</PageTitle>}
      {props.children}
      <Layout.Footer
        style={{
          zIndex: 10,
          textAlign: 'center',
          padding: `${SPACE.small}px ${noSidePadding ? SPACE.large : 0}px`
        }}
      >
        <LogoWrapper>
          <img src={logo} alt="logo" style={{ height: 30 }} />
          <CopyrightTextComponent>{CopyrightText}</CopyrightTextComponent>
        </LogoWrapper>
      </Layout.Footer>
    </Wrapper>
  )
}

interface IStandardPage {
  headerText?: string
  pageTitle: string
  noSidePadding?: boolean
}

export default StandardPage
