const REPORTS_BASE_URL = '/reports/explore'
export default {
  main: () => `${REPORTS_BASE_URL}`,
  dow: () => `${REPORTS_BASE_URL}/dow`,
  tod: () => `${REPORTS_BASE_URL}/tod`,
  basketSize: () => `${REPORTS_BASE_URL}/basket-size`,
  penetration: () => `${REPORTS_BASE_URL}/penetration`,
  tableReport: () => `${REPORTS_BASE_URL}/table-report`,
  trendedMetrics: () => `${REPORTS_BASE_URL}/trended-metrics`,
  trendedMetricsMultiple: () =>
    `${REPORTS_BASE_URL}/trended-metrics-multiple-facts`,
  purchaseDrivers: () => `${REPORTS_BASE_URL}/purchase-drivers`,
  demographics: () => `${REPORTS_BASE_URL}/demographics`,
  crossPurchase: () => `${REPORTS_BASE_URL}/cross-purchase`,
  inBasket: () => `${REPORTS_BASE_URL}/in-basket`,
  repertoire: () => `${REPORTS_BASE_URL}/repertoire`
}
