import { periodPreset } from 'components/charts/ReportParameterSelector/PeriodSelector/types'
import { sagaErrorFallback } from 'data/sagaFallback'
import { ChartSelector } from 'data/selectors'
import { ChartActionTypes, UserActionTypes } from 'data/types'
import { separator } from 'pages/reports/reports/common/helpers'
import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects'
import { listParameters } from 'services/api/requests/common/dataSelector'
import { toRollingPeriod } from 'services/helpers/toPeriod'
import { loadOptionsSuccessful } from './actions'

function* loadOptions() {
  const data = yield call(listParameters)
  yield put(loadOptionsSuccessful(data))
  yield call(initPeriod)
}

function* initPeriod() {
  const periods = yield select(ChartSelector.periodsList)
  const selectedPeriod = yield select(ChartSelector.selectedPeriod)
  const customPeriods = yield select(ChartSelector.customPeriods)

  const selectedCustomPeriod = customPeriods.find(
    i => i.key === selectedPeriod.key
  )

  let rollingType, numberOfPeriod, periodType, datePointer
  if (selectedCustomPeriod) {
    rollingType = selectedCustomPeriod.rollingType
    numberOfPeriod = selectedCustomPeriod.numberOfPeriods
    periodType = selectedCustomPeriod.periodType
    datePointer = selectedCustomPeriod?.datePointer
  } else {
    const selections = selectedPeriod.key.split(separator)
    numberOfPeriod = selections[0]
    periodType = selections[1]
    rollingType = selections[2]
  }

  const rollingPeriod = toRollingPeriod({
    startDate: datePointer && +datePointer,
    descendingPeriods: periods,
    rollingType,
    numberOfPeriod,
    periodType
  })

  yield put({
    type: ChartActionTypes.SET_PERIOD,
    payload: {
      key: selectedCustomPeriod
        ? selectedCustomPeriod.key
        : selectedPeriod?.key || periodPreset[0].key,
      content: rollingPeriod.length ? rollingPeriod : periods.slice(0, 6)
    }
  })
}

function* watchUser() {
  yield takeEvery(
    [UserActionTypes.SET_USER, UserActionTypes.SET_VIEW_AS],
    sagaErrorFallback,
    loadOptions
  )
}

function* watchResetChart() {
  yield takeEvery(
    ChartActionTypes.RESET_CHART_PARAMETERS,
    sagaErrorFallback,
    initPeriod
  )
}

export default function* root() {
  yield all([fork(watchUser), fork(watchResetChart)])
}
