import * as Sentry from '@sentry/react'
import { ConfigProvider } from 'antd'
import { persistor, store } from 'data'
import { getAnalytics, logEvent, setCurrentScreen } from 'firebase/analytics'
// for kendo react charts
import 'hammerjs'
import { createBrowserHistory } from 'history'
import MainNavigation from 'navigation'
import React from 'react'
import { QueryClientProvider } from 'react-query'
import { Provider } from 'react-redux'
import { Router } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import 'services/api/requests/common/setupAxiosInterceptor'
import {
  load as loadIntercom,
  update as updateIntercom
} from 'services/intercom'
import queryClient from 'services/react-query'
import { initSentry } from 'services/sentry'
import './App.less'
import './App.scss'

export const browserHistory = createBrowserHistory()

const analytics = getAnalytics()

//  @ts-ignore
initSentry(browserHistory)
browserHistory.listen(location => {
  const pagePath = location.pathname + location.search
  setCurrentScreen(analytics, pagePath)
  logEvent(analytics, 'page_view', { page_path: pagePath })
  updateIntercom()
})

const App: React.FC = () => {
  loadIntercom()

  return (
    <QueryClientProvider client={queryClient}>
      <Router history={browserHistory}>
        <Provider store={store}>
          <PersistGate persistor={persistor} loading={null}>
            <ConfigProvider
              getPopupContainer={node => {
                if (node) return node.parentElement
                return document.body
              }}
            >
              <MainNavigation />
            </ConfigProvider>
          </PersistGate>
        </Provider>
      </Router>
    </QueryClientProvider>
  )
}

export default Sentry.withErrorBoundary(App, {
  fallback: 'An error has occurred'
})
