import { COLOR, SPACE } from 'services/styles'
import styled from 'styled-components'

export const LightTitle = styled.p`
  padding: 0px ${SPACE.large / 2.5}px;
  color: white;
`

export const LeftBorderWrapper = styled.div`
  border-left: 3px solid ${COLOR.blueButton};
`

export const BoldTitle = styled.h5`
  padding: 0px ${SPACE.tiny}px;
`

export const CenteredWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: ${SPACE.small}px 0px;
`

export const MediumTextWrapper = styled.span`
  font-size: 18px;
`

export const SmallTextWrapper = styled.div`
  font-size: 14px;
  color: ${COLOR.textGrey};
  padding: 0px ${SPACE.tiny}px;
`

export const FlexWrapper = styled.div`
  display: flex;
`

export const SupportTextWrapper = styled.p`
  margin-top: ${SPACE.tiny}px;
`
