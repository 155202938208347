import FlexContainer from 'components/elements/FlexContainer'
import SelectMenu from 'components/elements/SelectMenu'
import useDrillDownSelectable from 'pages/reports/reports/common/reportHooks/useDrillDownSelectable'
import { trendedMetricEnumList } from 'pages/reports/reports/trendedMetrics/types'
import React from 'react'
import { SPACE } from 'services/styles'
import { DrillByList, invalidDrillDownTooltip } from '../../common/types'

const ParamSelector = ({ metric, setMetric, drillDown, setDrillDown }) => {
  const isDrillDownSelectable = useDrillDownSelectable(setDrillDown)

  return (
    <FlexContainer alignItems="start">
      <div style={{ marginRight: SPACE.tiny }}>
        <p>DRILL DOWN</p>
        <SelectMenu
          disabled={isDrillDownSelectable}
          tooltip={isDrillDownSelectable && invalidDrillDownTooltip}
          selectableMenu={DrillByList}
          item={drillDown}
          setItem={setDrillDown}
        />
      </div>
      <div>
        <p>METRIC</p>
        <SelectMenu
          selectableMenu={trendedMetricEnumList}
          item={metric}
          setItem={setMetric}
        />
      </div>
    </FlexContainer>
  )
}

export default ParamSelector
