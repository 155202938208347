import React from 'react'

const RenderWrapCell = props => {
  return (
    <span style={{ whiteSpace: 'initial', wordBreak: 'break-word' }}>
      {props.title}
    </span>
  )
}

const WrapHeaderCell = props => <RenderWrapCell {...props} />

export default WrapHeaderCell
