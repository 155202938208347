import {
  findRetailerColor,
  formatTooltipData
} from 'pages/reports/reports/crossPurchase/helpers'
import React from 'react'
import {
  ChartTooltip,
  VennArc,
  VennDiagram,
  VennLabel,
  VennOuterLabel,
  VennSeries
} from 'reaviz'
import { ChainsEnumToCanonical } from 'services/helpers/toChain'
import { COLOR } from 'services/styles'

const VennChart = ({ item, setMousePosition, mousePosition }) => {
  return (
    <VennDiagram
      key={item.id}
      data={item.datasets}
      type="starEuler"
      series={
        <VennSeries
          colorScheme={data => findRetailerColor(data.key)}
          animated={false}
          arc={
            <VennArc
              onMouseEnter={e =>
                setMousePosition({
                  x: e.nativeEvent.clientX,
                  y: e.nativeEvent.clientY
                })
              }
              onMouseLeave={e =>
                setMousePosition({
                  x: e.nativeEvent.clientX,
                  y: e.nativeEvent.clientY
                })
              }
              tooltip={
                <ChartTooltip
                  content={d => formatTooltipData(d.x)}
                  placement={
                    mousePosition.y > window.innerHeight / 1.7
                      ? 'bottom'
                      : 'top'
                  }
                />
              }
            />
          }
          label={
            <VennLabel
              fill="white"
              labelType="value"
              showAll={true}
              fontSize={16}
              format={data => data.data.size + '%'}
            />
          }
          outerLabel={
            <VennOuterLabel
              fill={COLOR.textDark}
              fontSize={16}
              format={({ data }) => ChainsEnumToCanonical[data.key]}
            />
          }
        />
      }
    />
  )
}

export default VennChart
