import { PlusOutlined } from '@ant-design/icons'
import { Checkbox, Menu, Space, Tooltip } from 'antd'
import {
  filterGroups,
  isGroupsEqual
} from 'components/charts/CustomGroupsList/helpers'
import useLoadData from 'components/charts/CustomGroupsList/hooks'
import {
  ButtonWrapper,
  DeleteButton,
  PageHeader,
  SearchInput,
  SearchRowWrapper
} from 'components/charts/CustomGroupsList/style'
import {
  DBChartCustomGroup,
  SavedCustomGroupsProps,
  SortKey
} from 'components/charts/CustomGroupsList/type'
import {
  CreateOrEditCustomGroup,
  DialogKeys,
  DialogTypes,
  GenericDeleteDialog,
  WarningDialog
} from 'components/dialogs'
import { ColoredCircle as ColoredGroupCircle } from 'components/elements/ColoredCircle/style'
import CustomGroupTooltip from 'components/elements/CustomGroupTooltip'
import DropdownMenu from 'components/elements/DropdownMenu'
import EmptyData from 'components/elements/EmptyData'
import FlexContainer from 'components/elements/FlexContainer'
import LoadingRows from 'components/elements/LoadingRows'
import PageSectionTitle from 'components/elements/SectionTitle/PageSectionTitle'
import StandardButton from 'components/elements/StandardStyledButton'
import { PencilIcon } from 'components/elements/SvgIcon/styles'
import Table from 'components/elements/Table'
import { PageTitle } from 'components/page/StandardPage/style'
import { ChartActions, DialogActions } from 'data/actions'
import { CustomGroup, initialCustomGroup } from 'data/chart/types'
import { ChartSelector, UserSelector } from 'data/selectors'
import moment from 'moment'
import EmptyGroup from 'pages/groups/components/EmptyGroup'
import { RoleEnum } from 'pages/organisation/types'
import React, { useState } from 'react'
import { FaRegCopy, FaRegShareSquare, FaSortAlphaDown } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { QueryKeys } from 'services/api/keys'
import {
  createCustomGroupApi,
  deleteCustomGroupApi,
  shareCustomGroupApi,
  updateCustomGroupApi
} from 'services/api/requests/groups/customGroups'
import { useUpdateQuery } from 'services/hooks/useUpdateQuery'
import { Notification } from 'services/notification'
import { COLOR, SPACE } from 'services/styles'
import {
  ButtonHeight,
  FontWeight,
  PAGE_FOOTER_HEIGHT
} from 'services/styles/misc'

const CustomGroupModal = ({
  selectedGroups,
  handleCheck,
  isAnalysisPage
}: SavedCustomGroupsProps) => {
  const dispatch = useDispatch()

  const customGroups = useSelector(ChartSelector.customGroups)

  const [searchText, setSearchText] = useState('')
  const [sortKey, setSortKey] = useState<SortKey>(SortKey.MODIFIED)
  const user = useSelector(UserSelector.userSelector)

  const { isFetching, data } = useLoadData(user)

  const handleHideDialog = key => dispatch(DialogActions.hideDialog({ key }))

  const handleHideCreateOrEditDialog = () =>
    handleHideDialog(DialogKeys.CREATE_OR_EDIT_CUSTOM_GROUP)

  const handleShowDialog = dialog => dispatch(DialogActions.showDialog(dialog))

  const createCustomGroup = useUpdateQuery(
    QueryKeys.SAVED_GROUPS,
    createCustomGroupApi
  )

  const updateCustomGroup = useUpdateQuery(
    QueryKeys.SAVED_GROUPS,
    ({ id, newGroup }: { id: string; newGroup: CustomGroup }) =>
      updateCustomGroupApi(id, newGroup)
  )

  const deleteCustomGroup = useUpdateQuery(
    QueryKeys.SAVED_GROUPS,
    deleteCustomGroupApi
  )

  const shareCustomGroup = useUpdateQuery(
    QueryKeys.SAVED_GROUPS,
    ({ id, orgId }: { id: string; orgId: string }) =>
      shareCustomGroupApi(id, orgId)
  )

  const handleAddNewGroup = (prefilledGroup?: CustomGroup) => {
    const initialGroup = prefilledGroup
      ? { ...prefilledGroup, id: null, name: `Copy of ${prefilledGroup.name}` }
      : initialCustomGroup
    const modalTitle = prefilledGroup
      ? 'Create a copy of Saved Selection'
      : 'Create Selection'

    const handleCreateGroup = async (id, newGroup: CustomGroup) =>
      id
        ? await updateCustomGroup.mutateAsync(
            { id, newGroup },
            {
              onSuccess: () => {
                Notification({
                  type: 'success',
                  message: `Saved Selection "${newGroup.name}" updated`
                })
              }
            }
          )
        : await createCustomGroup.mutateAsync(newGroup, {
            onSuccess: () => {
              Notification({
                type: 'success',
                message: `Saved Selection  "${newGroup.name}" has been added`
              })
            }
          })

    handleShowDialog({
      key: DialogKeys.CREATE_OR_EDIT_CUSTOM_GROUP,
      component: (
        <CreateOrEditCustomGroup
          title={modalTitle}
          onCancel={handleHideCreateOrEditDialog}
          onComplete={newGroup => {
            if (isAnalysisPage) {
              dispatch(ChartActions.addCustomGroup(newGroup))
              handleHideCreateOrEditDialog()
              handleHideDialog(DialogKeys.GROUPS_BROWSER)
            } else {
              handleHideCreateOrEditDialog()
            }
          }}
          handleCreateGroup={handleCreateGroup}
          prefilledGroup={initialGroup}
        />
      )
    })
  }

  const handleDeleteSavedGroup = (group: CustomGroup) => {
    const { id, name } = group

    const handleOkClick = async () => {
      await deleteCustomGroup.mutateAsync(
        { id },
        {
          onSuccess: () => {
            Notification({
              type: 'success',
              message: `Selection "${name}" deleted`
            })
            dispatch(ChartActions.deleteCustomGroup(id))
            handleHideDialog(DialogKeys.DELETE_GENERIC)
          }
        }
      )
    }

    handleShowDialog({
      key: DialogKeys.DELETE_GENERIC,
      component: (
        <GenericDeleteDialog
          fromText={'from the saved selections'}
          name={name}
          onOk={handleOkClick}
          type={DialogTypes.SELECTION}
        />
      )
    })
  }

  const handleEditSavedGroup = (savedGroup: CustomGroup) => {
    const handleEditGroup = async (id, newGroup) => {
      await updateCustomGroup.mutateAsync(
        { id, newGroup },
        {
          onSuccess: () => {
            Notification({
              type: 'success',
              message: `Selection "${newGroup.name}" updated`
            })
            dispatch(ChartActions.editCustomGroup(id, newGroup))
          }
        }
      )
    }

    handleShowDialog({
      key: DialogKeys.CREATE_OR_EDIT_CUSTOM_GROUP,
      component: (
        <CreateOrEditCustomGroup
          title="Edit Saved Selection"
          onCancel={handleHideCreateOrEditDialog}
          onComplete={handleHideCreateOrEditDialog}
          prefilledGroup={savedGroup}
          handleEditGroup={handleEditGroup}
        />
      )
    })
  }

  const handleShareSavedGroup = async (groupId, orgId, groupName, orgName) => {
    const warningText = `Do you want to share "${groupName}" with your organisation? Everyone in your organisation will be able to see this selection.`

    const onConfirmClick = async () =>
      await shareCustomGroup.mutateAsync(
        {
          id: groupId,
          orgId
        },
        {
          onSuccess: () =>
            Notification({
              type: 'success',
              message: `Saved Selection "${groupName}" shared to "${orgName}"`
            })
        }
      )

    handleShowDialog({
      key: DialogKeys.WARNING,
      component: (
        <WarningDialog
          title="Confirmation"
          text={warningText}
          onOk={onConfirmClick}
        />
      )
    })
  }

  const renderGroups = ({
    title,
    groups,
    editable,
    canShare,
    canDuplicate
  }: IRenderGroupProps) => (
    <>
      <PageSectionTitle text={title} />
      {!groups.length ? (
        <EmptyData descriptionComponent={<h6>No result is found</h6>} />
      ) : (
        <Table>
          <Table.Header
            titles={['', 'Selection Name', 'Created By', 'Last Modified', '']}
            headerStyle={{
              backgroundColor: isAnalysisPage ? COLOR.antdGrey : 'transparent'
            }}
            sticky={isAnalysisPage}
          />
          <tbody>
            {groups.map(group => (
              <Table.Row
                key={group.id}
                tooltipProps={{
                  key: group.id,
                  title: <CustomGroupTooltip group={group} />,
                  placement: 'bottomLeft'
                }}
              >
                <Table.Cell
                  cellStyle={{ width: checkboxWidth + colorDotWidth }}
                  render={
                    <FlexContainer
                      style={{
                        margin: `0px ${SPACE.tiny}px 0px ${SPACE.small}px`
                      }}
                    >
                      {isAnalysisPage && (
                        <Checkbox
                          style={{ marginRight: SPACE.tiny, borderSpacing: 0 }}
                          onClick={() => handleCheck(group)}
                          disabled={
                            !!customGroups.find(v => isGroupsEqual(v, group))
                          }
                          checked={
                            !!selectedGroups.find(v => v.id === group.id) ||
                            !!customGroups.find(v => isGroupsEqual(v, group))
                          }
                        />
                      )}

                      <ColoredGroupCircle
                        bgColor={group.color}
                        clickable={isAnalysisPage}
                        onClick={() => isAnalysisPage && handleCheck(group)}
                      />
                    </FlexContainer>
                  }
                />
                <Table.Cell
                  onClick={() => isAnalysisPage && handleCheck(group)}
                  cellStyle={{
                    width: '40%',
                    cursor: isAnalysisPage ? 'pointer' : 'default'
                  }}
                  label={group?.name}
                />
                <Table.Cell label={group?.user?.email} />
                <Table.Cell
                  cellStyle={{ width: '15%' }}
                  label={moment(group.updatedAt).format('MM/DD/YYYY')}
                />
                <Table.Cell
                  cellStyle={{ width: '10%' }}
                  render={
                    <FlexContainer
                      justifyContent="flex-end"
                      style={{ marginRight: SPACE.small }}
                    >
                      {canDuplicate && (
                        <Tooltip
                          key={'duplicate-tooltip'}
                          placement="bottomRight"
                          title={'Duplicate'}
                        >
                          <ButtonWrapper
                            data-testid={'duplicate-group'}
                            onClick={() => handleAddNewGroup(group)}
                          >
                            <FaRegCopy />
                          </ButtonWrapper>
                        </Tooltip>
                      )}
                      {editable && (
                        <>
                          {canShare && (
                            <Tooltip
                              key={'transfer-tooltip'}
                              placement="bottomRight"
                              title={'Share'}
                            >
                              <ButtonWrapper
                                onClick={() =>
                                  handleShareSavedGroup(
                                    group.id,
                                    user.organisation.id,
                                    group.name,
                                    user.organisation.name
                                  )
                                }
                              >
                                <FaRegShareSquare style={{ fontSize: 16 }} />
                              </ButtonWrapper>
                            </Tooltip>
                          )}
                          <Tooltip
                            key={'edit-tooltip'}
                            placement="bottomRight"
                            title={'Edit'}
                          >
                            <ButtonWrapper
                              onClick={() => handleEditSavedGroup(group)}
                            >
                              <PencilIcon fill={COLOR.success} />
                            </ButtonWrapper>
                          </Tooltip>
                          <Tooltip
                            key={'delete-tooltip'}
                            placement="bottomRight"
                            title={'Delete'}
                          >
                            <ButtonWrapper
                              data-testid={'btn-delete-selection'}
                              onClick={() => handleDeleteSavedGroup(group)}
                              hoverColor={COLOR.danger}
                            >
                              <DeleteButton />
                            </ButtonWrapper>
                          </Tooltip>
                        </>
                      )}
                    </FlexContainer>
                  }
                />
              </Table.Row>
            ))}
          </tbody>
        </Table>
      )}
    </>
  )

  const menu = (
    <Menu>
      <Menu.Item
        key={'name'}
        onClick={() => setSortKey(SortKey.NAME)}
        icon={<FaSortAlphaDown style={{ marginRight: SPACE.tiny }} />}
      >
        Name
      </Menu.Item>
      <Menu.Item
        key={'last-modified'}
        onClick={() => setSortKey(SortKey.MODIFIED)}
      >
        Last modified
      </Menu.Item>
      <Menu.Item
        key={'last-created'}
        onClick={() => setSortKey(SortKey.CREATED)}
      >
        Last created
      </Menu.Item>
    </Menu>
  )

  const filteredOrgGroups =
    data?.org && filterGroups(data.org, searchText, sortKey)
  const filteredUserGroups =
    data?.user && filterGroups(data.user, searchText, sortKey)
  const checkboxWidth = 40 // checkbox(24) + side padding(16)
  const colorDotWidth = 25

  const isTotalEmpty = ![...data.org, ...data.user].length

  const transferPermission = user?.role?.name !== RoleEnum.MEMBER
  return (
    <div
      style={{
        minHeight: !isAnalysisPage && `calc(100% - ${PAGE_FOOTER_HEIGHT}px)`
      }}
    >
      <PageHeader>
        <PageTitle>Saved Selections</PageTitle>
        <StandardButton
          data-testid="btn-handleAddNewGroup"
          style={{ fontWeight: FontWeight.BOLD, height: ButtonHeight.MEDIUM }}
          text="New Selection"
          icon={<PlusOutlined />}
          onClick={() => handleAddNewGroup()}
        />
      </PageHeader>

      <SearchRowWrapper>
        <SearchInput
          value={searchText}
          onChange={e => setSearchText(e.target.value)}
          placeholder="Search saved selections"
        />

        <DropdownMenu menuComponent={menu} text={'Sort By'} />
      </SearchRowWrapper>
      {isFetching && <LoadingRows />}
      {!isFetching && isTotalEmpty && <EmptyGroup />}
      {!isFetching && !isTotalEmpty && (
        <Space
          style={{
            width: '100%',
            height: isAnalysisPage && '65vh',
            overflowY: 'auto'
          }}
          direction="vertical"
          size={SPACE.tiny}
        >
          {!!data.org.length &&
            renderGroups({
              title: 'Organisation Saved Selections',
              groups: filteredOrgGroups,
              canDuplicate: true
            })}
          <div style={{ height: SPACE.tiny }} />

          {!!data.user.length &&
            renderGroups({
              title: 'Your Saved Selections',
              groups: filteredUserGroups,
              editable: true,
              canShare: transferPermission
            })}
        </Space>
      )}
    </div>
  )
}

export default CustomGroupModal

interface IRenderGroupProps {
  title: string
  groups: DBChartCustomGroup[]
  editable?: boolean
  canShare?: boolean
  canDuplicate?: boolean
}
