import { Row } from 'antd'
import EmptyData from 'components/elements/EmptyData'
import React from 'react'
import { SPACE } from 'services/styles'
import { PAGE_FOOTER_HEIGHT, PAGE_HEADER_HEIGHT } from 'services/styles/misc'

const MinimumHeight = `calc(100% - ${
  PAGE_FOOTER_HEIGHT + SPACE.large + PAGE_HEADER_HEIGHT
}px)`

const EmptyReport = () => {
  return (
    <Row
      style={{
        marginTop: SPACE.small,
        minHeight: MinimumHeight
      }}
      gutter={SPACE.small}
      justify={'center'}
    >
      <EmptyData
        descriptionComponent={<h6>Your saved reports will show here</h6>}
      />
    </Row>
  )
}

export default EmptyReport
