import InvalidGroupsWarning from 'components/charts/CustomGroups/InvalidGroupsWarning'
import ReportParameterSelector from 'components/charts/ReportParameterSelector'
import {
  DialogKeys,
  DialogTypes,
  GenericSaveDialog,
  GroupsBrowser
} from 'components/dialogs'
import EmptyData from 'components/elements/EmptyData'
import StandardButton from 'components/elements/StandardStyledButton'
import { ReportContext } from 'components/page/ReportPage/context'
import excel from 'components/page/ReportPage/export/excel'
import png from 'components/page/ReportPage/export/png'
import { handleLoadReport, useLoadData } from 'components/page/ReportPage/hooks'
import { Divider } from 'components/page/ReportPage/style'
import { IReportMetadata } from 'components/page/ReportPage/types'
import StandardPage from 'components/page/StandardPage'
import { DialogActions } from 'data/actions'
import { ChartSelector } from 'data/selectors'
import html2canvas from 'html2canvas'
import moment from 'moment'
import { getReportKeyFromRoute } from 'pages/reports/reports'
import useAgeGroupsSelectable from 'pages/reports/reports/common/reportHooks/useAgeGroupsSelectable'
import React, { FC, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { QueryKeys } from 'services/api/keys'
import {
  createReportApi,
  updateReportApi
} from 'services/api/requests/reports/common'
import { useQueryParams } from 'services/hooks/useQueryParams'
import { useUpdateQuery } from 'services/hooks/useUpdateQuery'
import { Notification } from 'services/notification'
import { ButtonHeight } from 'services/styles/misc'
import ReportMetadata from './header'

const ReportPage: FC<IReportPage> = props => {
  const history = useHistory()
  const chartRef = useRef()
  const tableRef = useRef()
  const location = useLocation()
  const urlParams = useQueryParams()
  const queryReportId = urlParams.get('report_id')
  const { title } = props
  const [dataSource, setDataSource] = useState([{ '': '' }])
  const initialState = {
    reportId: queryReportId
  }

  const [reportMetadata, setReportMetadata] = useState<IReportMetadata>(
    initialState
  )

  const [isLoading, setIsLoading] = useState(false)
  const [exportFileName, setExportFileName] = useState<string>(
    `${title} - ${moment().format('DD-MM-YYYY')}`
  )

  const dispatch = useDispatch()
  const customGroups = useSelector(ChartSelector.customGroups)
  const params = useSelector(ChartSelector.chartQuerySelector)

  const periodsList = useSelector(ChartSelector.periodsList)
  const period = useSelector(ChartSelector.selectedPeriod)
  const customPeriods = useSelector(ChartSelector.customPeriods)
  const selectedPeriodKey = useSelector(ChartSelector.selectedPeriodKey)

  const {
    isAgeGroupsSelectable,
    customGroupsWithAgeGroups: invalidGroups
  } = useAgeGroupsSelectable()

  const createReport = useUpdateQuery(QueryKeys.SAVED_REPORT, createReportApi)
  const updateReport = useUpdateQuery(QueryKeys.SAVED_REPORT, updateReportApi)

  const [refetchData, isSavedLoading, isSavedFetching] = useLoadData(
    queryReportId,
    reportMetadata.name,
    setReportMetadata
  )

  const resetState = () => {
    setReportMetadata(initialState)
    setDataSource([{ '': '' }])
    setIsLoading(true)
    setTimeout(() => setIsLoading(false), 200)
  }

  const handleSaveClick = async () => {
    queryReportId && customGroups.length
      ? await handleSaveNewReport({
          reportId: queryReportId,
          name: reportMetadata.name,
          description: reportMetadata.description
        })
      : handleSaveAsNew()
  }

  const handleSaveAsNew = () =>
    handleDispatchDialog(
      DialogKeys.SAVE_GENERIC,
      <GenericSaveDialog
        onComplete={async (name, description) => {
          await handleSaveNewReport({ name, description })
          return handleHideDispatchDialog(DialogKeys.SAVE_GENERIC)
        }}
        onCancel={() => handleHideDispatchDialog(DialogKeys.SAVE_GENERIC)}
        type={DialogTypes.REPORT}
        defaultName={`${name || title} - ${moment().format('DD-MM-YYYY')}`}
        defaultDescription={description}
      />
    )

  const handleRenameClick = () => {
    handleDispatchDialog(
      DialogKeys.SAVE_GENERIC,
      <GenericSaveDialog
        title="Rename Report"
        type={DialogTypes.REPORT}
        onComplete={async (name, description) => {
          await handleSaveNewReport({
            reportId: queryReportId,
            name,
            description
          })
          await handleLoadReport(
            dispatch,
            refetchData,
            periodsList,
            queryReportId,
            reportMetadata.name,
            setReportMetadata
          )
          handleHideDispatchDialog(DialogKeys.SAVE_GENERIC)
        }}
        onCancel={() => handleHideDispatchDialog(DialogKeys.SAVE_GENERIC)}
        defaultName={name}
        defaultDescription={description}
      />
    )
  }

  const handleSaveNewReport = async ({
    reportId,
    name,
    description
  }: {
    reportId?: string
    name: string
    description: string
  }) => {
    const tempImage = await html2canvas(chartRef.current, {
      backgroundColor: 'transparent'
    })
    const image = tempImage.toDataURL('image/png', 0.5)
    const reportState = {
      name,
      description,
      reportKey: getReportKeyFromRoute(location.pathname),
      image,
      customGroups,
      period: { selected: period.key, custom: customPeriods }
    }

    const response = reportId
      ? await updateReport.mutateAsync({ rawData: reportState, reportId })
      : await createReport.mutateAsync({ rawData: reportState })
    const constNewReportId = response.id
    history.push(location.pathname + '?report_id=' + constNewReportId)
    Notification({
      type: 'success',
      message: `Report "${name}" saved`
    })
  }

  const handlePNGDownload = () =>
    handleDispatchDialog(
      DialogKeys.SAVE_GENERIC,
      <GenericSaveDialog
        onCancel={() => handleHideDispatchDialog(DialogKeys.SAVE_GENERIC)}
        onComplete={fileName => {
          png(chartRef, fileName)
          handleHideDispatchDialog(DialogKeys.SAVE_GENERIC)
        }}
        type={DialogTypes.FILE}
        defaultName={`${name || title} - ${moment().format('DD-MM-YYYY')}`}
      />
    )

  const handleCsvDownload = () => {
    handleDispatchDialog(
      DialogKeys.SAVE_GENERIC,
      <GenericSaveDialog
        onCancel={() => handleHideDispatchDialog(DialogKeys.SAVE_GENERIC)}
        onComplete={fileName => {
          setExportFileName(fileName)
          document.getElementById('csv-download').click()
          handleHideDispatchDialog(DialogKeys.SAVE_GENERIC)
        }}
        type={DialogTypes.FILE}
        defaultName={`${name || title} - ${moment().format('DD-MM-YYYY')}`}
      />
    )

    // @ts-ignore
    setDataSource(tableRef?.current?.getExportData().data)
  }

  const handleExcelDownload = () =>
    handleDispatchDialog(
      DialogKeys.SAVE_GENERIC,
      <GenericSaveDialog
        onCancel={() => handleHideDispatchDialog(DialogKeys.SAVE_GENERIC)}
        onComplete={fileName => {
          setExportFileName(fileName)
          excel(tableRef, params, selectedPeriodKey)
          handleHideDispatchDialog(DialogKeys.SAVE_GENERIC)
        }}
        type={DialogTypes.FILE}
        defaultName={`${name || title} - ${moment().format('DD-MM-YYYY')}`}
      />
    )

  const handleDispatchDialog = (key, component: React.ReactElement) =>
    dispatch(DialogActions.showDialog({ key, component }))

  const handleHideDispatchDialog = (key: string) =>
    dispatch(DialogActions.hideDialog({ key }))

  // const handlePDFDownload = () => pdf()

  //  const renderDeleteModal = () => {
  //     const originalUrl = getReportFromKey(reportMetadata.reportKey).path
  //
  //     const handleOK = async () => {
  //       await deleteReport({ id: queryReportId })
  //       history.push(originalUrl)
  //       handleHideDispatchDialog(DialogKeys.DELETE_GENERIC)
  //       // TODO - temporary fix to reload page
  //       window.location.reload()
  //     }
  //     handleDispatchDialog(
  //       DialogKeys.DELETE_GENERIC,
  //       <GenericDeleteDialog
  //         fromText={'from the saved reports'}
  //         onOk={handleOK}
  //         name={name}
  //         type={DialogTypes.REPORT}
  //       />
  //     )
  //   }

  const handleSetIsLoading = (newLoadingState: boolean) =>
    setIsLoading(newLoadingState)

  const openGroupsBrowser = () => {
    handleDispatchDialog(DialogKeys.GROUPS_BROWSER, <GroupsBrowser />)
  }

  const { name, description } = reportMetadata
  const Component = props.component

  const showInvalidMessage = !isAgeGroupsSelectable && !!invalidGroups.length
  return (
    <ReportContext.Provider
      value={{
        isLoading: isLoading || isSavedLoading || isSavedFetching,
        setIsLoading: handleSetIsLoading
      }}
    >
      <StandardPage pageTitle={name || title} noSidePadding>
        <ReportMetadata
          exportFileName={exportFileName}
          dataSource={dataSource}
          handleRenameClick={handleRenameClick}
          title={name || title}
          description={reportMetadata.description || props.description}
          reportMetadata={reportMetadata}
          isCustom={!!name || !!queryReportId}
          handleSaveClick={handleSaveClick}
          resetState={resetState}
          handleSaveAsNew={handleSaveAsNew}
          handleCsvClick={handleCsvDownload}
          handleExcelClick={handleExcelDownload}
          handlePNGClick={handlePNGDownload}
        />
        <ReportParameterSelector openGroupsBrowser={openGroupsBrowser} />
        <Divider />

        {!!showInvalidMessage && (
          <InvalidGroupsWarning invalidGroups={invalidGroups} />
        )}
        {!showInvalidMessage && !!customGroups.length && (
          <Component
            ref={{ chartRef, tableRef }}
            exportFileProps={{ fileName: exportFileName }}
          />
        )}

        {!customGroups.length && (
          <EmptyData>
            <StandardButton
              text="+ Select Selection"
              style={{ height: ButtonHeight.MEDIUM }}
              onClick={openGroupsBrowser}
            />
          </EmptyData>
        )}
      </StandardPage>
    </ReportContext.Provider>
  )
}

interface IReportPage {
  title: string
  component: React.ReactNode | any
  description?: string
}

export default ReportPage
