import { Modal } from 'antd'
import { DialogKeys } from 'components/dialogs'
import PurchasedCategoriesTable from 'components/elements/PurchasedCategoriesTable'
import { CancelIcon } from 'components/elements/SvgIcon/styles'
import { DialogActions } from 'data/actions'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { QueryKeys } from 'services/api/keys'
import { updatePurchasedCatApi } from 'services/api/requests/organisation/purchasedCategories'
import { useUpdateQuery } from 'services/hooks/useUpdateQuery'
import { Notification } from 'services/notification'
import { SPACE } from 'services/styles'
import { ButtonHeight } from 'services/styles/misc'

const PurchasedCategoriesDialog = ({ orgId, prefilledData, isDataLoading }) => {
  const dispatch = useDispatch()

  const [data, setData] = useState(prefilledData)

  const editBillingInfo = useUpdateQuery(
    QueryKeys.ORG_PURCHASED_CAT,
    updatePurchasedCatApi
  )

  const handleClose = () =>
    dispatch(DialogActions.hideDialog({ key: DialogKeys.PURCHASED_CATEGORIES }))

  const handleComplete = async () => {
    const response = await editBillingInfo.mutateAsync(
      { orgId, data },
      {
        onSuccess: () =>
          Notification({
            type: 'success',
            message: 'Purchased categories updated'
          })
      }
    )
    response && handleClose()
  }

  return (
    <Modal
      title={'Purchased Categories'}
      visible
      closeIcon={
        <CancelIcon
          width={ButtonHeight.SMALL}
          height={ButtonHeight.SMALL}
          style={{ marginTop: SPACE.tiny * 2.75 }}
        />
      }
      onOk={handleComplete}
      confirmLoading={editBillingInfo.isLoading}
      okText={'Done'}
      okButtonProps={{ style: { width: 125 } }}
      onCancel={handleClose}
      width={750}
      cancelButtonProps={{ hidden: true }}
    >
      <PurchasedCategoriesTable
        key={'editable-table'}
        isDataLoading={isDataLoading}
        onDataUpdate={setData}
        data={data}
        editable
        scroll={{ y: 300 }}
      />
    </Modal>
  )
}

export default PurchasedCategoriesDialog
