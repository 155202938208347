import { SPACE } from 'services/styles'
import styled from 'styled-components'

export const CustomColorBar = styled.div`
  width: ${SPACE.small}px;
  height: ${SPACE.small / 5}px;
  margin-right: ${SPACE.small}px;
`

export const TooltipItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

export const tooltipColor = 'white'
