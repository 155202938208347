import { Layout } from 'antd'
import PageOverlayComponents from 'components/elements/PageOverlayComponents'
import Content from 'components/page/layout/Content'
import Header from 'components/page/layout/Header'
import Sidebar from 'components/page/layout/Sidebar'
import ViewAsBanner from 'components/page/layout/ViewAsBanner'
import PageLoader from 'components/page/PageLoader'
import { UserSelector } from 'data/selectors'
import AuthPage from 'pages/auth'
import React, { FC, useState } from 'react'
import { useSelector } from 'react-redux'
import useAuthRouting from 'services/hooks/useAuthRouting'
import useCheckReadyApi from 'services/hooks/useCheckReadyApi'
import { SPACE } from 'services/styles'
import { SideBarContext } from './sidebarContext'

const MainNavigation: FC = () => {
  const rootUser = useSelector(UserSelector.rootUserSelector)
  const viewAs = useSelector(UserSelector.viewAsUserSelector)
  useCheckReadyApi()
  useAuthRouting()

  const [isOpen, setIsOpen] = useState<boolean>(true)

  return (
    <Layout
      style={{
        height: '100%',
        paddingTop: !!viewAs && !!rootUser && 25 + SPACE.small
      }}
    >
      <PageLoader />
      {!rootUser?.id ? (
        <AuthPage />
      ) : (
        <SideBarContext.Provider value={{ isOpen, setIsOpen }}>
          <>
            <Sidebar />
            <Layout>
              <Header />
              <Content />
            </Layout>
            {!!viewAs && !!rootUser && <ViewAsBanner />}
          </>

          <PageOverlayComponents>
            <PageOverlayComponents.Dialog />
          </PageOverlayComponents>
        </SideBarContext.Provider>
      )}
    </Layout>
  )
}

export default MainNavigation
