import { DBChartCustomGroup } from 'components/charts/CustomGroupsList/type'
import { UserProps } from 'pages/types'

export interface ISavedReport {
  customGroups: DBChartCustomGroup
  description: string
  id: string
  name: string
  periods: []
  period: { groupBy: number; period: any[] }
  reportKey: string
  createdAt?: string
  updatedAt?: string
  image: string
  user: UserProps
  isEditable: boolean
  isDeletable: boolean
  isCreator: boolean
  sharedEdit: any[]
  sharedView: any[]
}

export enum ReportProps {
  MODIFIED = 'updatedAt',
  CREATED = 'createdAt',
  NAME = 'name'
}

export const ReportPropsToText = {
  [ReportProps.MODIFIED]: 'Modified',
  [ReportProps.CREATED]: 'Created',
  [ReportProps.NAME]: 'Name'
}
