import { FontWeight } from 'services/styles/misc'
import styled from 'styled-components'

export const EllipsisTitle = styled.h6`
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
  font-weight: ${FontWeight.BOLD};
`
