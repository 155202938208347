import { Col, Row, Skeleton } from 'antd'
import { HoverDiv } from 'pages/savedReports/components/SavedReports/styles'
import React from 'react'
import { BORDER_RADIUS, SPACE } from 'services/styles'

const LoadingSavedReport = () => (
  <Col span={8}>
    <HoverDiv>
      <Skeleton.Avatar
        style={{
          width: '100%',
          height: 200,
          borderRadius: `${BORDER_RADIUS.medium}px ${BORDER_RADIUS.medium}px 0px 0px`
        }}
        active
        size={'small'}
        shape="square"
      />
      <div
        style={{
          margin: SPACE.tiny,
          borderRadius: `${BORDER_RADIUS.medium}px ${BORDER_RADIUS.medium}px 0px 0px`
        }}
      >
        <Skeleton.Input
          style={{ height: 15, width: '50%' }}
          active
          size="small"
        />
        <Skeleton.Input style={{ height: 8 }} active size="small" />
        <Skeleton.Input style={{ height: 8 }} active size="small" />
        <Skeleton.Button
          style={{ float: 'right' }}
          active
          size="default"
          shape="square"
        />
      </div>
    </HoverDiv>
  </Col>
)

const LoadingReports = () => (
  <>
    <Skeleton.Input style={{ height: 25, width: '40%' }} active size="small" />

    <Row gutter={SPACE.small} style={{ padding: `${SPACE.small}px 0px` }}>
      {[...Array(6)].map((e, i) => (
        <LoadingSavedReport key={i} />
      ))}
    </Row>
  </>
)

export default LoadingReports
