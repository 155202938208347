import lowerCase from 'lodash/lowerCase'
import startCase from 'lodash/startCase'

export const toStartCase = (text: string) => startCase(lowerCase(text))

export const toProperCase = (text: string) =>
  text?.replace(
    /\w\S*/g,
    txt => txt?.charAt(0)?.toUpperCase() + txt?.substr(1)?.toLowerCase()
  )

export const toSentenceCase = (text: string) =>
  text.charAt(0) + text.slice(1).toLowerCase()
