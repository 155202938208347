import FlexContainer from 'components/elements/FlexContainer'
import SelectMenu from 'components/elements/SelectMenu'
import startCase from 'lodash/startCase'
import useDrillDownSelectable from 'pages/reports/reports/common/reportHooks/useDrillDownSelectable'
import { TrendedMetricsMultipleEnum } from 'pages/reports/reports/trendedMetricsMultiple/types'
import React, { Dispatch } from 'react'
import { SelectDataProps } from 'services/helpers/dropdownUtils'
import { SPACE } from 'services/styles'
import { DrillByList, invalidDrillDownTooltip } from '../../common/types'

interface Props {
  leftMetric: SelectDataProps
  setLeftMetric: Dispatch<SelectDataProps>
  rightMetric: SelectDataProps
  setRightMetric: Dispatch<SelectDataProps>
  drillDown: SelectDataProps
  setDrillDown: Dispatch<SelectDataProps>
}

export default ({
  leftMetric,
  setLeftMetric,
  rightMetric,
  setRightMetric,
  drillDown,
  setDrillDown
}: Props) => {
  const isDrillDownSelectable = useDrillDownSelectable(setDrillDown)
  return (
    <FlexContainer alignItems="start">
      <div style={{ marginRight: SPACE.tiny }}>
        <p>DRILL DOWN</p>
        <SelectMenu
          disabled={isDrillDownSelectable}
          tooltip={isDrillDownSelectable && invalidDrillDownTooltip}
          selectableMenu={DrillByList}
          item={drillDown}
          setItem={setDrillDown}
        />
      </div>
      <div style={{ marginRight: SPACE.tiny }}>
        <p>METRIC</p>
        <SelectMenu
          selectableMenu={Object.values(TrendedMetricsMultipleEnum).map(i => ({
            key: i,
            value: startCase(i.toLowerCase())
          }))}
          item={leftMetric}
          setItem={setLeftMetric}
          disabledIds={[TrendedMetricsMultipleEnum.NONE]}
        />
      </div>

      <div>
        <p>METRIC</p>
        <SelectMenu
          selectableMenu={Object.values(TrendedMetricsMultipleEnum).map(i => ({
            key: i,
            value: startCase(i.toLowerCase())
          }))}
          item={rightMetric}
          setItem={setRightMetric}
        />
      </div>
    </FlexContainer>
  )
}
