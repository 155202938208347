import config, { ENVIRONMENT } from 'config'
import { useEffect } from 'react'
import { usePageVisibility } from 'react-page-visibility'
import { isReadyApi } from 'services/api/requests/common/isReady'

const useCheckReadyApi = () => {
  const isVisible = usePageVisibility()
  // trigger to restart snowflake systems when user comes back to the app
  useEffect(() => {
    if (isVisible) {
      if (
        config.environment !== ENVIRONMENT.Staging &&
        config.environment !== ENVIRONMENT.Development
      ) {
        isReadyApi()
      }
    }
  }, [isVisible])
}

export default useCheckReadyApi
