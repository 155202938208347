import React from 'react'
import { Notification } from 'services/notification/index'
import { COLOR } from 'services/styles'

const POPUP_WIDTH = 250
const PADDING_HORIZONTAL = 24 * 2

interface PeriodNotificationProps {
  periodText: string
  onClose: () => void
}

export const PERIOD_UPDATE_NOTIFICATION = 'PERIOD_UPDATE_NOTIFICATION'

const PeriodNotification = ({ periodText, onClose }: PeriodNotificationProps) =>
  Notification({
    // @ts-ignore
    type: 'open',
    key: PERIOD_UPDATE_NOTIFICATION,
    placement: 'bottomLeft',
    duration: null,
    maxCount: 1,
    description: `${periodText} has been added to the dashboard.`,
    message: (
      <span style={{ fontWeight: 'bold', color: 'white' }}>
        Latest period updated
      </span>
    ),
    btn: <CustomCloseButton onClose={onClose} />,
    closeIcon: <span />,
    style: {
      backgroundColor: COLOR.info,
      color: 'white',
      maxWidth: POPUP_WIDTH,
      whiteSpace: 'initial'
    }
  })

const CustomCloseButton = ({ onClose }) => (
  <div
    style={{
      width: POPUP_WIDTH - PADDING_HORIZONTAL,
      display: 'flex',
      justifyContent: 'flex-start'
    }}
  >
    <span
      style={{ textDecoration: 'underline', cursor: 'pointer' }}
      onClick={onClose}
    >
      Dismiss
    </span>
  </div>
)

export default PeriodNotification
