import { Tooltip } from 'antd'
import FlexContainer from 'components/elements/FlexContainer'
import {
  BoldTitle,
  LeftBorderWrapper,
  SupportTextWrapper
} from 'components/elements/SectionTitle/styles'
import React, { ReactNode } from 'react'
import { ButtonHeight } from 'services/styles/misc'
import StandardButton from '../StandardStyledButton'

interface props {
  text: string
  subtitle?: ReactNode | string
  showButton?: boolean
  disabled?: boolean
  buttonTooltipText?: string
  buttonText: string
  onClick: () => void
}

const SectionTitleWithButton = ({
  text,
  subtitle,
  showButton,
  disabled,
  buttonTooltipText,
  buttonText,
  onClick
}: props) => (
  <FlexContainer>
    <div>
      <LeftBorderWrapper>
        <BoldTitle>{text}</BoldTitle>
      </LeftBorderWrapper>
      <SupportTextWrapper>{subtitle}</SupportTextWrapper>
    </div>

    {showButton && (
      <Tooltip
        key={'section-button-tooltip'}
        placement="bottom"
        title={buttonTooltipText || ''}
      >
        <div>
          <StandardButton
            text={buttonText}
            onClick={() => !disabled && onClick()}
            style={{
              height: ButtonHeight.MEDIUM,
              minWidth: 125
            }}
            disabled={disabled}
          />
        </div>
      </Tooltip>
    )}
  </FlexContainer>
)

export default SectionTitleWithButton
