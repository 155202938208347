import { DBGroups } from 'components/charts/CustomGroupsList/type'
import { useQuery } from 'react-query'
import { QueryKeys } from 'services/api/keys'
import { getCustomGroupApi } from 'services/api/requests/groups/customGroups'

const useLoadData = user =>
  useQuery<DBGroups>(
    [QueryKeys.SAVED_GROUPS, user],
    () => getCustomGroupApi(),
    {
      initialData: {
        user: [],
        org: []
      }
    }
  )
export default useLoadData
