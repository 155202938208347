import axios from 'axios'
import config from 'config'
import pathToApi from 'services/helpers/pathToApi'

export const registerApi = async ({
  firstName,
  lastName,
  email,
  organisationId,
  organisationName,
  password,
  code
}) => {
  const formattedProps = {
    email,
    code,
    organisationId,
    firstName,
    lastName,
    password,
    companyName: organisationName
  }

  const url = pathToApi(config.dashboardBaseUrl)
  const data = await axios.post(url('/user/register'), formattedProps)
  return data
}
