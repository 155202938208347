import { Button } from 'antd'
import { BORDER_RADIUS, COLOR } from 'services/styles'
import { ButtonHeight } from 'services/styles/misc'
import styled from 'styled-components'

export const GroupingRow = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

export const AddGroupButton = styled(Button)`
  height: ${ButtonHeight.MEDIUM}px;
  border-width: 2px;
  background-color: transparent;
  border-radius: ${BORDER_RADIUS.standard * 3}px;
`

export const TextGreyWrapper = styled.h6`
  color: ${COLOR.textGrey};
`

export const GridContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, minmax(calc(100% / 4), 1fr));
  grid-gap: 0.3em;
`
