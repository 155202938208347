import SelectMenu from 'components/elements/SelectMenu'
import React from 'react'
import { SelectDataProps } from 'services/helpers/dropdownUtils'

interface Props {
  options: SelectDataProps[]
  value: SelectDataProps
  onChange: (newValue: SelectDataProps) => void
}

const SingleProductSelector = ({ options, value, onChange }: Props) => (
  <div>
    <p>SELECT PRODUCT</p>
    <SelectMenu
      style={{ minWidth: 250, maxWidth: 400 }}
      dropdownMatchSelectWidth={false}
      selectableMenu={options}
      item={value}
      setItem={onChange}
    />
  </div>
)

export default SingleProductSelector
