import StandardPage from 'components/page/StandardPage'
import ReportCard from 'pages/overview/reportCard'
import { Container } from 'pages/overview/style'
import ReportList from 'pages/reports/reports'
import React from 'react'

const Overview = () => (
  <StandardPage headerText="Report Overview" pageTitle="Report Overview">
    <Container gutter={16}>
      <>
        {ReportList.map(item => (
          <ReportCard item={item} key={item.path} />
        ))}
      </>
    </Container>
  </StandardPage>
)

export default Overview
