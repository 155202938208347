import { SPACE } from 'services/styles'
import styled from 'styled-components'

export const HighlightText = styled.h6`
  color: white;
`

export const BreakLine = styled.div`
  height: ${SPACE.tiny}px;
`

export const Wrapper = styled.div`
  padding: ${SPACE.tiny}px;
  width: 100%;
`
