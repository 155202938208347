import { IOrgChart, PurchaseDriversResponse } from './types'

export default ({
  benchmark,
  target
}: {
  benchmark?: PurchaseDriversResponse
  target?: PurchaseDriversResponse
}): IOrgChart => {
  //  @ts-ignore
  if (!target || !benchmark) return {}
  const purchaseValueChange =
    ((benchmark.purchaseValue / target.purchaseValue - 1) * 100).toFixed(1) +
    ' %'
  const purchaseUnitChange =
    ((benchmark.purchaseUnits / target.purchaseUnits - 1) * 100).toFixed(1) +
    ' %'
  const buyingHouseholdsChange =
    ((benchmark.buyingHouseholds / target.buyingHouseholds - 1) * 100).toFixed(
      1
    ) + ' %'
  const occassionsChange =
    (
      (benchmark.purchaseOccasions / target.purchaseOccasions - 1) *
      100
    ).toFixed(1) + ' %'
  const unitsPerOccasion =
    ((benchmark.unitsPerOccasion / target.unitsPerOccasion - 1) * 100).toFixed(
      1
    ) + ' %'
  const pricePerUnit =
    ((benchmark.pricePerUnit / target.pricePerUnit - 1) * 100).toFixed(1) + ' %'

  return {
    id: 'totalValue',
    name: 'Total Value',
    value: purchaseValueChange,
    children: [
      {
        id: 'units',
        name: 'Units',
        value: purchaseUnitChange,
        children: [
          {
            id: 'buyers',
            name: 'Buyers',
            value: buyingHouseholdsChange
          },
          {
            id: 'occasions',
            name: 'Occasions',
            value: occassionsChange,
            children: [
              {
                id: 'unitsPerOccasion',
                name: 'Units Per Occasion',
                value: unitsPerOccasion
              }
            ]
          }
        ]
      },
      {
        id: 'pricePerUnit',
        name: 'Price Per Unit',
        value: pricePerUnit
      }
    ]
  }
}
