import {
  ChartActionTypes,
  ChartCustomPeriods,
  CustomGroup,
  CustomPeriod,
  PeriodState,
  SavedReportState
} from 'data/chart/types'
import { createAction } from 'redux-actions'

export const loadOptionsSuccessful = (options: object) =>
  createAction(ChartActionTypes.LOAD_OPTIONS_SUCCESSFUL)(options)

export const addCustomGroup = (group: CustomGroup) =>
  createAction(ChartActionTypes.ADD_CUSTOM_GROUP)(group)

export const editCustomGroup = (id: string, group: object) =>
  createAction(ChartActionTypes.EDIT_CUSTOM_GROUP)({ id, group })

export const deleteCustomGroup = (id: string) =>
  createAction(ChartActionTypes.DELETE_CUSTOM_GROUP)({ id })

export const addCustomPeriods = (periods: ChartCustomPeriods) =>
  createAction(ChartActionTypes.ADD_CUSTOM_PERIODS)(periods)

export const addCustomPeriod = (period: CustomPeriod) =>
  createAction(ChartActionTypes.ADD_CUSTOM_PERIOD)(period)

export const editCustomPeriod = (id: string, period: CustomPeriod) =>
  createAction(ChartActionTypes.EDIT_CUSTOM_PERIOD)({ id, period })

export const deleteCustomPeriod = (id: string) =>
  createAction(ChartActionTypes.DELETE_CUSTOM_PERIOD)({ id })

export const clearCustomPeriods = () =>
  createAction(ChartActionTypes.CLEAR_CUSTOM_PERIOD)()

export const duplicateCustomGroup = (groupId: string) =>
  createAction(ChartActionTypes.DUPLICATE_CUSTOM_GROUP)(groupId)

export const setReportPermissions = (permission: SavedReportState) =>
  createAction(ChartActionTypes.SET_REPORT_PERMISSIONS)(permission)

export const setPeriod = (periodState: PeriodState) =>
  createAction(ChartActionTypes.SET_PERIOD)(periodState)

export const rehydrateChartParameters = (data: any) =>
  createAction(ChartActionTypes.REHYDRATE_CHART_PARAMETERS)(data)

export const resetChartParameters = () =>
  createAction(ChartActionTypes.RESET_CHART_PARAMETERS)()
