import { CopyOutlined } from '@ant-design/icons'
import { Button, Input, Modal, Tooltip } from 'antd'
import { DialogKeys } from 'components/dialogs/index'
import { SectionWrapper } from 'components/dialogs/Organisation/ResendInvitation/style'
import FlexContainer from 'components/elements/FlexContainer'
import {
  LeftBorderWrapper,
  MediumTextWrapper
} from 'components/elements/SectionTitle/styles'
import StandardButton from 'components/elements/StandardStyledButton'
import { CancelIcon } from 'components/elements/SvgIcon/styles'
import { DialogActions } from 'data/actions'
import React, { CSSProperties } from 'react'
import { useDispatch } from 'react-redux'
import { QueryKeys } from 'services/api/keys'
import { sendInvitationApi } from 'services/api/requests/organisation/users'
import { useUpdateQuery } from 'services/hooks/useUpdateQuery'
import { Notification } from 'services/notification'
import { BORDER_RADIUS, SPACE } from 'services/styles'
import { ButtonHeight, FontWeight } from 'services/styles/misc'

const MODAL_WIDTH = 600
const RESEND_BUTTON_WIDTH = MODAL_WIDTH / 3

interface ResendInvitationProps {
  orgId: string
  email: string
  role: string
  inviteLink: string
}

const ResendInvitation = ({
  orgId,
  role,
  email,
  inviteLink
}: ResendInvitationProps) => {
  const dispatch = useDispatch()

  const inviteUser = useUpdateQuery(QueryKeys.ORG_DATA, sendInvitationApi)

  const handleClose = () =>
    dispatch(DialogActions.hideDialog({ key: DialogKeys.RESEND_INVITATION }))

  const handleResendClick = async () => {
    const _handleInviteUser = async ({ email, role }) => {
      await inviteUser.mutateAsync(
        { orgId, email, role },
        {
          onSuccess: () =>
            Notification({
              type: 'success',
              message: 'Resend invitation successfully'
            })
        }
      )
    }

    await _handleInviteUser({ email, role })
    handleClose()
  }

  const handleCopyToClipboard = async () => {
    await navigator.clipboard.writeText(inviteLink)
  }
  return (
    <Modal
      title={'Resend invitation'}
      visible
      closeIcon={
        <CancelIcon
          width={ButtonHeight.SMALL}
          height={ButtonHeight.SMALL}
          style={{ marginTop: SPACE.tiny * 2.75 }}
        />
      }
      okButtonProps={{ hidden: true }}
      cancelButtonProps={{ hidden: true }}
      onCancel={handleClose}
      width={MODAL_WIDTH}
    >
      <SectionWrapper>
        <SectionTitle text="Resend invitation to" />
        <FlexContainer alignItems="center">
          <ReadOnlyInput text={email} />
          <StandardButton
            isLoading={inviteUser.isLoading}
            text="Resend"
            style={{
              height: '100%',
              width: RESEND_BUTTON_WIDTH,
              fontSize: 14,
              marginTop: SPACE.tiny,
              padding: SPACE.tiny / 1.5
            }}
            onClick={handleResendClick}
          />
        </FlexContainer>
      </SectionWrapper>

      <SectionWrapper>
        <SectionTitle text="Copy invitation link" />
        <div style={{ position: 'relative', width: '100%' }}>
          <ReadOnlyInput
            text={inviteLink}
            style={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              paddingRight: SPACE.medium
            }}
          />

          <Tooltip title="Copied to clipboard" trigger={'click'}>
            <Button
              onClick={handleCopyToClipboard}
              icon={<CopyOutlined />}
              style={{
                background: 'transparent',
                position: 'absolute',
                right: 0,
                top: SPACE.tiny,
                margin: 0,
                padding: 0,
                border: 0
              }}
            />
          </Tooltip>
        </div>
      </SectionWrapper>
    </Modal>
  )
}

interface SectionTitleProps {
  text: string
}

const SectionTitle = ({ text }: SectionTitleProps) => (
  <LeftBorderWrapper>
    <MediumTextWrapper
      style={{ paddingLeft: SPACE.tiny * 1.5, fontWeight: FontWeight.BOLD }}
    >
      {text}
    </MediumTextWrapper>
  </LeftBorderWrapper>
)

interface ReadOnlyInputProps {
  text: string
  style?: CSSProperties
}

const ReadOnlyInput = ({ text, style }: ReadOnlyInputProps) => (
  <Input
    type="text"
    value={text}
    disabled
    style={{
      padding: `${SPACE.tiny / 1.5}px ${SPACE.small}px`,
      margin: `${SPACE.tiny}px ${SPACE.tiny}px 0px 0px `,
      borderRadius: BORDER_RADIUS.small,
      backgroundColor: 'white',
      ...style
    }}
  />
)

export default ResendInvitation
