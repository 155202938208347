import { BORDER_RADIUS, SPACE } from 'services/styles'
import styled from 'styled-components'

export const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: ${SPACE.tiny}px;
  border-radius: ${BORDER_RADIUS.medium}px;
  background-color: white;
  font-size: 16px;
  padding: ${SPACE.small}px;
`
