import { StandardSelectableOptions } from 'pages/reports/reports/common/reportHooks/useStandardSelectable'
import { Dispatch, useEffect, useState } from 'react'
import { SelectDataProps } from 'services/helpers/dropdownUtils'

const usePreselectGroups = (
  selectable: StandardSelectableOptions
): [SelectDataProps[], Dispatch<SelectDataProps[]>] => {
  const [selectedGroups, setSelectedGroups] = useState<SelectDataProps[]>([])

  useEffect(() => {
    setSelectedGroups(selectable.groups)
  }, [selectable.groups])

  return [selectedGroups, setSelectedGroups]
}

export default usePreselectGroups
