import { CustomGroup } from 'data/chart/types'
import { NoneDrillDown } from 'pages/reports/reports/common/types'
import { SelectDataProps } from 'services/helpers/dropdownUtils'
import { getCleanGroupSignature } from '../common/helpers'
import { SELECT_ALL_PRODUCT_KEY } from '../common/reportHooks/usePreselectProduct'
import {
  TrendedMetricsEnum,
  TrendedMetricsResponse,
  TrendedMetricToKey
} from './types'

export default (
  rawData: TrendedMetricsResponse[],
  rawSelectedMetric: TrendedMetricsEnum,
  groups: CustomGroup[],
  selectedProducts: SelectDataProps[],
  drillDown?: SelectDataProps
): TrendedMetricsFormatData => {
  const periodLabels = Array.from(
    new Set(rawData.reduce((acc, data) => [...acc, data.period], []))
  )
  const selectedMetric = TrendedMetricToKey[rawSelectedMetric]

  const formatData = rawData.reduce(
    (acc, curr) => ({
      ...acc,
      [curr.key]: {
        data: [...(acc[curr.key]?.data || []), curr[selectedMetric]],
        warningLevels: [
          ...(acc[curr.key]?.warningLevels || []),
          curr.warningLevel
        ]
      }
    }),
    {}
  )

  const datasets = Object.entries<{
    data: number[]
    warningLevels: number[]
  }>(formatData).map(([key, value]) => {
    const isProductDrillDownEnabled = !selectedProducts.find(
      i => i.key === SELECT_ALL_PRODUCT_KEY
    )

    const isBannersDrillDownEnabled = drillDown.key !== NoneDrillDown

    const [name, color] = getCleanGroupSignature(
      groups,
      key,
      isBannersDrillDownEnabled,
      isProductDrillDownEnabled
    )
    return {
      ...value,
      color:
        isProductDrillDownEnabled || isBannersDrillDownEnabled
          ? undefined
          : color,
      name,
      label: key
    }
  })

  return {
    datasets,
    labels: periodLabels
  }
}

export interface TrendedMetricsFormatData {
  datasets: TrendedMetricsFormattedDataset[]
  labels: string[]
}

export const DefaultTrendedMetricsFormatData = {
  datasets: [],
  labels: []
}

export interface TrendedMetricsFormattedDataset {
  label: string
  data: number[]
  warningLevels: number[]
  color: string
  name: string
}
