import { notification } from 'antd'
import { ArgsProps as NotificationArgProps } from 'antd/lib/notification'
import React from 'react'
import { NotificationProps } from 'services/notification/types'
import { BORDER_RADIUS } from 'services/styles'

export const Notification = ({
  type,
  message,
  style,
  ...rest
}: NotificationArgProps & NotificationProps) =>
  notification[type]({
    placement: 'bottomRight',
    message: message,
    style: {
      whiteSpace: 'nowrap',
      width: '100%',
      borderRadius: BORDER_RADIUS.standard * 3,
      ...style
    },
    ...rest
  })
