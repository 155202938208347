const handleProductChange = (
  previousProducts: string[],
  newProducts: string[],
  onCompleteCallBack: any
) => {
  const currentProducts = previousProducts
  if (newProducts.length > currentProducts.length) {
    const difference = newProducts.filter(x => !currentProducts.includes(x))
    if (difference.length === 1) {
      const singleDifference = difference[0]
      if (
        !singleDifference.includes('TOTAL') &&
        !singleDifference.includes('SSB') &&
        !singleDifference.includes('SB') &&
        (!singleDifference.includes('RTD') ||
          !singleDifference.includes('SPIRIT') ||
          !singleDifference.includes('WINE'))
      ) {
        const removedArray = newProducts.map(x =>
          x === singleDifference ? `TOTAL ${x}` : x
        )
        return onCompleteCallBack(removedArray)
      }
    }
  } else if (newProducts.length === currentProducts.length) {
    const difference = newProducts.filter(x => !currentProducts.includes(x))
    if (difference.length === 1) {
      const singleDifference = difference[0]
      return onCompleteCallBack(newProducts.filter(x => x !== singleDifference))
    }
  }
  return onCompleteCallBack(newProducts)
}

export default handleProductChange
