import { Col, Row } from 'antd'
import AnalysisWrapper from 'components/charts/AnalysisWrapper'
import { SectionWrapper } from 'components/charts/AnalysisWrapper/style'
import ChartRef from 'components/charts/ChartRef'
import SampleSizeWarningText from 'components/charts/SampleSizeWarningText'
import FlexContainer from 'components/elements/FlexContainer'
import TableTitle from 'components/elements/SectionTitle/TableTitle'
import SelectMenu from 'components/elements/SelectMenu'
import { ReportContext } from 'components/page/ReportPage/context'
import { ChartSelector } from 'data/selectors'
import { ReportMeta, ReportNames } from 'pages/reports/reportMeta'
import MultipleProductsSelector from 'pages/reports/reports/common/productSelector/multiple'
import useDrillDownSelectable from 'pages/reports/reports/common/reportHooks/useDrillDownSelectable'
import useStandardSelectable from 'pages/reports/reports/common/reportHooks/useStandardSelectable'
import { useLoadData } from 'pages/reports/reports/crossPurchase/hooks'
import MemoizedRenderTable from 'pages/reports/reports/crossPurchase/table'
import React, { useContext, useState } from 'react'
import { useSelector } from 'react-redux'
import { SelectDataProps } from 'services/helpers/dropdownUtils'
import { ChartHeight, SPACE } from 'services/styles'
import {
  DrillByList,
  initialDrillDownState,
  invalidDrillDownTooltip
} from '../common/types'
import VennChart from './components/chart'
import { ChartTitle } from './style'

const CrossPurchase = React.forwardRef((props, ref) => {
  // @ts-ignore
  const { chartRef, tableRef } = ref
  //  @ts-ignore
  const { fileName } = props.exportFileProps

  const selectedPeriodKey = useSelector(ChartSelector.selectedPeriodKey)

  const [mousePosition, setMousePosition] = useState({
    x: undefined,
    y: undefined
  })

  const { isLoading, setIsLoading } = useContext(ReportContext)

  const [drillDown, setDrillDown] = useState<SelectDataProps>(
    initialDrillDownState
  )

  const {
    selectableViewOptions,
    selectedPeriod,
    setSelectedPeriod,
    selectedGroups,
    selectedProducts,
    setSelectedProducts
  } = useStandardSelectable()

  const isDrillDownSelectable = useDrillDownSelectable(setDrillDown)

  const data = useLoadData(setIsLoading, {
    drillDown,
    selectedPeriod,
    selectedGroups,
    selectedProducts
  })

  const renderParamSelector = () => (
    <FlexContainer alignItems="start">
      <div style={{ marginRight: SPACE.tiny }}>
        <p>DRILL DOWN</p>
        <SelectMenu
          disabled={isDrillDownSelectable}
          tooltip={isDrillDownSelectable && invalidDrillDownTooltip}
          selectableMenu={DrillByList}
          item={drillDown}
          setItem={setDrillDown}
        />
      </div>
      <div>
        <p>PERIOD</p>
        <div>
          <SelectMenu
            selectableMenu={selectableViewOptions.timePeriods}
            item={selectedPeriod}
            setItem={setSelectedPeriod}
          />
        </div>
      </div>
    </FlexContainer>
  )

  const renderProductsDrillDown = () => (
    <MultipleProductsSelector
      options={selectableViewOptions.products}
      value={selectedProducts}
      onChange={setSelectedProducts}
    />
  )

  return (
    <>
      <AnalysisWrapper
        isLoading={isLoading}
        productsSelector={renderProductsDrillDown()}
        parameterSelector={renderParamSelector()}
      >
        <SectionWrapper ref={chartRef}>
          <ChartRef style={{ height: isLoading && ChartHeight.STANDARD }}>
            <div className="hidden-text hidden">
              <h5 style={{ textAlign: 'center' }}>
                {ReportMeta[ReportNames.CROSS_PURCHASE].title}
              </h5>
            </div>
            {data.datasets?.map(
              (item, index) =>
                item?.datasets?.length && (
                  <Row
                    key={item.id}
                    justify="center"
                    style={{
                      marginBottom: index < selectedGroups.length - 1 && '12vh'
                    }}
                  >
                    <Col span={24}>
                      <ChartTitle style={{ color: item.color }}>
                        {item.name}
                      </ChartTitle>
                    </Col>

                    <Col
                      xl={{ span: 16 }}
                      lg={{ span: 24 }}
                      style={{
                        width: '50vw',
                        height: '40vh',
                        padding: `0px ${SPACE.large}px`
                      }}
                    >
                      <VennChart
                        item={item}
                        setMousePosition={setMousePosition}
                        mousePosition={mousePosition}
                      />
                    </Col>
                    <Col xl={{ span: 8 }} lg={{ span: 24 }}>
                      <TableTitle />
                      <MemoizedRenderTable
                        key={item.id}
                        data={item.datasets}
                        dataTableRef={tableRef}
                        fullData={data.datasetsTable}
                        fileName={fileName}
                      />
                    </Col>
                  </Row>
                )
            )}
            <div className="hidden-text hidden">
              <p style={{ textAlign: 'center' }}>
                Period ({selectedPeriodKey?.value})
              </p>
              {selectedPeriod?.key && (
                <p style={{ textAlign: 'center' }}>{selectedPeriod.value}</p>
              )}
            </div>
            <SampleSizeWarningText />
            <div style={{ height: SPACE.tiny * 3 }} />
          </ChartRef>
        </SectionWrapper>
      </AnalysisWrapper>
    </>
  )
})

export default CrossPurchase
