import { Col, Radio } from 'antd'
import { RowWrapper, StyledRadio } from 'components/dialogs/CustomPeriod/style'
import { AdvancedMode, ToggleEnum } from 'components/dialogs/CustomPeriod/types'
import SelectMenu from 'components/elements/SelectMenu'
import React from 'react'
import {
  SelectDataProps,
  StringToSelectDataProps
} from 'services/helpers/dropdownUtils'
import useToggle from 'services/hooks/useToggle'
import { SPACE } from 'services/styles'

const ParamSelectors = ({
  periodType,
  setPeriodType,
  numberOfPeriodList,
  selectedNumberOfPeriod,
  setSelectedNumberOfPeriod,
  rollingType,
  setRollingType
}) => {
  const [showAdvance, setShowAdvance] = useToggle(rollingType !== periodType)

  const handleRollingTypeChange = e => setRollingType(e.target.value)

  const handlePeriodTypeChange = e => {
    const newValue = e.target.value
    if (newValue < rollingType || !showAdvance) {
      setRollingType(newValue)
    }
    setPeriodType(newValue)
  }
  const showCustomRollingType = periodType >= 3
  return (
    <>
      <RowWrapper gutter={16} style={{ marginTop: SPACE.small }}>
        <Col span={8}>Select the period type</Col>
        <Col>
          <Radio.Group value={periodType} onChange={handlePeriodTypeChange}>
            {[1, 3, 6, 13].map(i => (
              <StyledRadio key={i} value={i}>{`${i * 4} Weeks`}</StyledRadio>
            ))}
          </Radio.Group>
        </Col>
      </RowWrapper>
      <RowWrapper
        gutter={16}
        style={{ marginBottom: !showCustomRollingType && SPACE.small }}
      >
        <Col span={8}>Select the number of periods</Col>
        <Col>
          <SelectMenu
            customClass="bg-white"
            selectableMenu={numberOfPeriodList}
            item={selectedNumberOfPeriod}
            setItem={setSelectedNumberOfPeriod}
          />
        </Col>
      </RowWrapper>
      {showCustomRollingType && (
        <RowWrapper
          gutter={16}
          style={{ marginBottom: !showAdvance && SPACE.small }}
        >
          <Col span={8}>Do you want to roll dates?</Col>
          <Col>
            <SelectMenu
              customClass="bg-white"
              selectableMenu={AdvancedMode}
              item={
                showAdvance
                  ? StringToSelectDataProps(ToggleEnum.YES)
                  : StringToSelectDataProps(ToggleEnum.NO)
              }
              setItem={(value: SelectDataProps) => {
                value.key === ToggleEnum.NO && setRollingType(periodType)
                setShowAdvance()
              }}
            />
          </Col>
        </RowWrapper>
      )}
      {showAdvance && periodType >= 3 && (
        <RowWrapper gutter={16} style={{ marginBottom: SPACE.small }}>
          <Col span={8}>Select the period rolling type</Col>
          <Col>
            <Radio.Group value={rollingType} onChange={handleRollingTypeChange}>
              {[1, 3].map(
                i =>
                  i < periodType && (
                    <StyledRadio key={i} value={i}>{`${
                      i * 4
                    } Weeks`}</StyledRadio>
                  )
              )}
            </Radio.Group>
          </Col>
        </RowWrapper>
      )}
    </>
  )
}

export default ParamSelectors
