import { SPACE } from 'services/styles'
import styled from 'styled-components'

export const ChartTitle = styled.h3`
  text-align: center;
  font-size: 18px;
`

export const ChartContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: ${SPACE.small * 1.5}px;
`
