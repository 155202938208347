import { DownArrowIcon } from 'components/dialogs/CustomGroup/common/style'
import {
  FlexWrapper,
  LeftBorderWrapper,
  MediumTextWrapper,
  SmallTextWrapper
} from 'components/elements/SectionTitle/styles'
import React from 'react'
import { SPACE } from 'services/styles'
import { FontWeight } from 'services/styles/misc'

interface props {
  title: string
  selectedList: string[]
  active: boolean
}

const StepperTitle = ({ title, selectedList, active }: props) => {
  return (
    <>
      <LeftBorderWrapper>
        <MediumTextWrapper style={{ paddingLeft: SPACE.tiny }}>
          {title === 'demographics'
            ? 'Target people who fit into these'
            : 'Target people who have purchased these'}
        </MediumTextWrapper>{' '}
        <MediumTextWrapper style={{ fontWeight: FontWeight.BOLD }}>
          {title}
        </MediumTextWrapper>
      </LeftBorderWrapper>
      <FlexWrapper>
        {selectedList.length && !active ? (
          <SmallTextWrapper>
            <span>{selectedList[0]}</span>
            {selectedList.length > 1 && (
              <span>{` and ${selectedList.length - 1} more`}</span>
            )}
          </SmallTextWrapper>
        ) : null}
        {active ? null : <DownArrowIcon />}
      </FlexWrapper>
    </>
  )
}

export default StepperTitle
