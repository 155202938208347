import { Tooltip } from 'antd'

import { ButtonWrapper } from 'components/charts/CustomGroupsList/style'
import {
  CreateOrEditCustomGroup,
  DialogKeys,
  DialogTypes,
  GenericDeleteDialog
} from 'components/dialogs'
import CustomGroupTooltip from 'components/elements/CustomGroupTooltip'
import { CancelIcon, PencilIcon } from 'components/elements/SvgIcon/styles'
import { ChartActions, DialogActions } from 'data/actions'
import { CustomGroup } from 'data/chart/types'
import { Dialog } from 'data/dialog/types'
import { ChartSelector } from 'data/selectors'
import cloneDeep from 'lodash/cloneDeep'
import React, { CSSProperties } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateCustomGroupApi } from 'services/api/requests/groups/customGroups'
import useHover from 'services/hooks/useHover'
import { Notification } from 'services/notification'
import { COLOR, SPACE } from 'services/styles'
import { GroupTagWrapper, TextWidthEllipsis } from './styles'

interface SvgButtonProps {
  style?: CSSProperties
  hoverColor: string
  onClick: () => void
  Icon: any
}

const SvgButton = ({ style, hoverColor, onClick, Icon }: SvgButtonProps) => {
  const [hoverRef, isHovered] = useHover()
  return (
    <ButtonWrapper
      ref={hoverRef}
      style={style}
      onClick={e => {
        e.stopPropagation()
        onClick()
      }}
    >
      <Icon style={{ fill: isHovered ? hoverColor : '#fff' }} />
    </ButtonWrapper>
  )
}

interface TagProps {
  group: CustomGroup
  width?: number | string
}

const CustomGroupTag = ({ group, width }: TagProps) => {
  const dispatch = useDispatch()
  const groups = useSelector(ChartSelector.customGroups)
  const { canSave: isSavedReportEditable } = useSelector(
    ChartSelector.savedReportState
  )

  const editable = isSavedReportEditable && group.type === 'user'

  const handleHideDialog = (key: string) =>
    dispatch(DialogActions.hideDialog({ key }))

  const handleShowDialog = (dialog: Dialog) =>
    dispatch(DialogActions.showDialog(dialog))

  const handleEditGroup = (key: string) => {
    const chartCustomGroup = cloneDeep(groups)
    const foundGroup = chartCustomGroup.find(group => group.id === key)

    const handleEditGroup = async (id, newGroup) => {
      await updateCustomGroupApi(id, newGroup)
      const updatedGroup = {
        id,
        ...newGroup
      }
      dispatch(ChartActions.editCustomGroup(id, updatedGroup))
      Notification({
        type: 'success',
        message: `Selection "${newGroup.name}" updated`
      })
    }

    handleShowDialog({
      key: DialogKeys.CREATE_OR_EDIT_CUSTOM_GROUP,
      component: (
        <CreateOrEditCustomGroup
          title="Edit Saved Selection"
          prefilledGroup={foundGroup}
          handleEditGroup={handleEditGroup}
        />
      )
    })
  }

  const handleDeleteGroup = (groupId: string) => {
    const chartCustomGroup = cloneDeep(groups)
    const foundGroup = chartCustomGroup.find(group => group.id === groupId)
    const handleOK = () => {
      dispatch(ChartActions.deleteCustomGroup(groupId))
      handleHideDialog(DialogKeys.DELETE_GENERIC)
    }
    handleShowDialog({
      key: DialogKeys.DELETE_GENERIC,
      component: (
        <GenericDeleteDialog
          type={DialogTypes.SELECTION}
          onOk={handleOK}
          name={foundGroup.name}
        />
      )
    })
  }

  return (
    <Tooltip placement="bottom" title={<CustomGroupTooltip group={group} />}>
      <GroupTagWrapper
        onClick={() => editable && handleEditGroup(group.id)}
        style={{
          width,
          backgroundColor: group.color,
          cursor: !editable && 'default'
        }}
      >
        <TextWidthEllipsis>{group.name}</TextWidthEllipsis>
        {editable && (
          <SvgButton
            onClick={() => handleEditGroup(group.id)}
            hoverColor={COLOR.textGrey}
            Icon={PencilIcon}
          />
        )}

        {isSavedReportEditable && (
          <SvgButton
            style={{ padding: SPACE.tiny, marginRight: SPACE.tiny }}
            onClick={() => handleDeleteGroup(group.id)}
            hoverColor={COLOR.danger}
            Icon={CancelIcon}
          />
        )}
      </GroupTagWrapper>
    </Tooltip>
  )
}

export default CustomGroupTag
