import { ExcelExport } from '@progress/kendo-react-excel-export'
import { GridColumn as DataColumn } from '@progress/kendo-react-grid/dist/npm/GridColumn'
import DataGridSortable from 'components/charts/DataGridSortable'
import { CenteredText } from 'components/elements/TableCells'
import { ChartSelector } from 'data/selectors'
import React, { Ref } from 'react'
import { useSelector } from 'react-redux'
import { changeTextIfLowNumber } from 'services/helpers/checkIsLowNumber'
import { FormattedData, IOrgChart } from '../types'

const flattenTree = (tree: IOrgChart) => {
  if (tree?.children?.length) {
    return [
      tree,
      ...tree.children.reduce((acc, item) => [...acc, ...flattenTree(item)], [])
    ]
  }
  return [tree]
}

interface RenderDataTableProps {
  data: FormattedData[]
  dataTableRef: Ref<any>
  fileName: any
}

const RenderDataTable = ({
  data,
  dataTableRef,
  fileName
}: RenderDataTableProps) => {
  const groups = useSelector(ChartSelector.customGroups)
  const tableData = data.map(i =>
    flattenTree(i.tree).map(({ name, value }) => ({
      name,
      [i.group.key]: value
    }))
  )

  const outputData = tableData.reduce((acc, array) => {
    array.forEach((data, i) => {
      acc[i] = { ...acc[i], ...data }
    })
    return acc
  }, [])

  // use function call instead of render directly to ensure refs get updated
  const renderDataColumns = () => [
    <DataColumn
      key={'name-description'}
      field="name"
      title="Description"
      cell={CenteredText}
    />,
    ...data.map(item => (
      <DataColumn
        key={item.group.key}
        field={item.group.key}
        title={changeTextIfLowNumber(
          groups.find(i => i.id === item.group.key)?.name ||
            String(item.group.value),
          item.warningLevel
        )}
      />
    ))
  ]
  return (
    <ExcelExport data={outputData} ref={dataTableRef} fileName={fileName}>
      <DataGridSortable data={outputData}>
        {renderDataColumns()}
      </DataGridSortable>
    </ExcelExport>
  )
}

const MemoizedRenderTable = React.memo(RenderDataTable)
export default MemoizedRenderTable
