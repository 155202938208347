import { Card, Col } from 'antd'
import { EllipsisTitle } from 'components/elements/CardComponent/styles'
import { Image } from 'pages/savedReports/components/SavedReports/styles'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { BORDER_RADIUS, SPACE } from 'services/styles'

interface props {
  to: string
  item: any
  footerMinHeight: number
  ActionComponent?: React.ReactNode
  DescriptionComponent?: React.ReactNode
}

const CardComponent = ({
  to,
  item,
  footerMinHeight,
  ActionComponent,
  DescriptionComponent
}: props) => {
  const history = useHistory()

  return (
    <Col
      sm={{ span: 24 }}
      md={{ span: 12 }}
      lg={{ span: 8 }}
      xxl={{ span: 6 }}
      style={{ marginBottom: SPACE.small }}
    >
      <Card
        onClick={() => history.push(to)}
        hoverable
        style={{ borderRadius: BORDER_RADIUS.medium }}
        bodyStyle={{
          minHeight: footerMinHeight,
          padding: SPACE.tiny * 2.75,
          paddingTop: 0,
          borderRadius: `0px 0px ${BORDER_RADIUS.medium}px ${BORDER_RADIUS.medium}px`
        }}
        cover={
          <>
            <Image
              alt={item.title}
              src={item.image}
              style={{
                position: 'relative',
                borderRadius: `${BORDER_RADIUS.medium}px ${BORDER_RADIUS.medium}px 0px 0px`
              }}
            />
            {ActionComponent}
          </>
        }
      >
        <Card.Meta
          title={<EllipsisTitle>{item.title}</EllipsisTitle>}
          description={DescriptionComponent}
        />
      </Card>
    </Col>
  )
}

export default CardComponent
