import { Divider } from 'antd'
import { COLOR, SPACE } from 'services/styles'
import styled from 'styled-components'

export const StyledImage = styled.img`
  width: 100%;
  height: 64px;
  background-color: ${COLOR.darkBlue};
  object-fit: contain;
  padding: ${SPACE.small * 1.25}px 0;
`

export const StyledDividerWrapper = styled(Divider)`
  border: 0;
  padding: 0;
  margin: 30px 0 10px 0;
`
