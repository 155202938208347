import { DownOutlined, UpOutlined } from '@ant-design/icons'
import { Divider } from 'antd'
import {
  GreyButton,
  IconWrapper
} from 'components/dialogs/CustomPeriod/components/style'
import FlexContainer from 'components/elements/FlexContainer'
import React from 'react'
import { SPACE } from 'services/styles'

const CollapseDivider = ({ collapsed, setCollapsed }) => {
  return (
    <>
      <Divider orientation="left" style={{ marginBottom: SPACE.small }} />
      <FlexContainer justifyContent="flex-start" style={{ margin: '0px 11px' }}>
        <GreyButton onClick={setCollapsed}>
          Summary
          <IconWrapper>
            {collapsed ? <UpOutlined /> : <DownOutlined />}
          </IconWrapper>
        </GreyButton>
      </FlexContainer>
    </>
  )
}

export default CollapseDivider
