import React from 'react'

const PermissionMessage = () => (
  <p>
    <span>You can only view this report</span>
    <br />
    <span>Please contact the owner to gain edit access</span>
  </p>
)

export const getTooltipMessage = ({
  type,
  requirements,
  permission
}: {
  type: 'SAVE' | 'SHARE' | 'DOWNLOAD'
  requirements?: boolean
  permission?: boolean
}) => {
  const RequirementsGuide = {
    SAVE: 'Please start an analysis to save report',
    SHARE: 'To share reports, please save the report first',
    DOWNLOAD: 'Please start an analysis to download data'
  }
  return requirements
    ? RequirementsGuide[type]
    : !!permission && PermissionMessage()
}
