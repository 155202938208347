import CustomGroupTag from 'components/charts/CustomGroups/CustomGroupTag'
import { Container } from 'components/charts/CustomGroups/InvalidGroupsWarning/style'
import React from 'react'
import { SPACE } from 'services/styles'

const InvalidGroupsWarning = ({ invalidGroups }) => {
  return (
    <Container>
      <h6 style={{ marginBottom: SPACE.small }}>
        You cannot select a custom age group in this report. <br />
        Please edit/remove the following groups from the analysis:
      </h6>
      <ul>
        {invalidGroups.map(i => (
          <li key={i.id}>
            <CustomGroupTag group={i} width={'60%'} />
          </li>
        ))}
      </ul>
    </Container>
  )
}

export default InvalidGroupsWarning
