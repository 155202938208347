import React from 'react'
import styled from 'styled-components'

const RenderCenteredNoWarning = props => (
  <td>
    <CenteredWrapper>{props.dataItem[props.field]}</CenteredWrapper>
  </td>
)

const CenteredWrapper = styled.span`
  text-align: center !important;
  margin: -8px -12px;
  padding: 8px 12px;
  line-height: inherit;
  display: block;
  white-space: initial;
  word-break: break-word;
  outline: 0;
`

const CenteredText = props => <RenderCenteredNoWarning {...props} />

export default CenteredText
