import ForgotPasswordComponent from 'components/elements/ForgotPasswordComponent'
import LoginComponent from 'components/elements/LoginComponent'
import RegisterComponent from 'components/elements/RegistrationComponent'
import ResetComponent from 'components/elements/ResetComponent'
import React from 'react'
import { Route } from 'react-router-dom'
import routes from 'routes'
import { InnerWrapper, Wrapper } from './style'

const AuthPage = () => {
  return (
    <Wrapper>
      <InnerWrapper>
        <Route exact path={routes.auth.login()} component={LoginComponent} />
        <Route
          exact
          path={routes.auth.register()}
          component={RegisterComponent}
        />
        <Route
          exact
          path={routes.auth.resetPassword()}
          component={ResetComponent}
        />
        <Route
          exact
          path={routes.auth.forgotPassword()}
          component={ForgotPasswordComponent}
        />
      </InnerWrapper>
    </Wrapper>
  )
}

export default AuthPage
