import { getter } from './accessor'
import { compileFilter } from './filtering/filter-expression.factory'
import { normalizeFilters } from './filtering/filter.operators'
import { compose } from './funcs'
import { groupBy, normalizeGroups } from './grouping/group.operators'
import { sort } from './sorting/sort'
import { composeSortDescriptors } from './sorting/sort-array.operator'
import { concat, exec, filter, skip, take } from './transducers'
import { isPresent, isString } from './utils'

/**
 * Orders the specified array according to the provided sort descriptors.
 *
 * @param {T[]} data - The data to be sorted.
 * @param {SortDescriptor[]} descriptors - The descriptors by which the data
 *   will be sorted.
 * @returns {T[]} - The sorted data.
 *
 * @example
 * ```ts-no-run
 * import { orderBy } from '@progress/kendo-data-query';
 *
 * const data = [
 *     { name: "Pork", category: "Food", subcategory: "Meat" },
 *     { name: "Pepper", category: "Food", subcategory: "Vegetables" },
 *     { name: "Beef", category: "Food", subcategory: "Meat" }
 * ];
 *
 * const result = orderBy(data, [{ field: "name", dir: "asc" }]);
 * ```
 */
export var orderBy = function(data, descriptors) {
  if (descriptors.some(function(x) { return isPresent(x.dir) })) {
    data = data.slice(0)
    var comparer = composeSortDescriptors(descriptors)
    sort(data, 0, data.length, comparer)
  }
  return data
}
var defaultComparer = function(a, b) { return a === b }
var normalizeComparer = function(comparer) {
  if (isString(comparer)) {
    //  @ts-ignore
    var accessor_1 = getter(comparer)
    comparer = function(a, b) { return accessor_1(a) === accessor_1(b) }
  }
  return comparer
}
var _distinct = function(data, comparer) {
  return data.filter(function(x, idx, xs) { return xs.findIndex(comparer.bind(null, x)) === idx })
}
/**
 * Reduces the provided array so it contains only unique values.
 *
 * @param {T[]} data - The array that will be reduced.
 * @param {(Comparer | string)} comparer - An optional custom comparer function
 *   or the field name that will be used for comparison.
 * @returns {T[]} - The reduced data.
 *
 * @example
 * ```ts-no-run
 * import { distinct } from '@progress/kendo-data-query';
 *
 * const data = [
 *     { name: "Pork", category: "Food", subcategory: "Meat" },
 *     { name: "Pepper", category: "Food", subcategory: "Vegetables" },
 *     { name: "Beef", category: "Food", subcategory: "Meat" }
 * ];
 *
 * const result = distinct(data, "subcategory");
 *
 * // output:
 * // result => [
 * //     { name: "Pork", category: "Food", subcategory: "Meat" },
 * //     { name: "Pepper", category: "Food", subcategory: "Vegetables" }
 * // ];
 * ```
 */
export var distinct = function(data, comparer) {
  if (comparer === void 0) { comparer = defaultComparer }
  return _distinct(data, normalizeComparer(comparer))
}
/**
 * @hidden
 */
export var count = function(data, predicate) {
  var counter = 0
  for (var idx = 0, length_1 = data.length; idx < length_1; idx++) {
    if (predicate(data[idx])) {
      counter++
    }
  }
  return counter
}
/**
 * @hidden
 */
export var limit = function(data, predicate) {
  if (predicate) {
    return data.filter(predicate)
  }
  return data
}
/**
 * Applies the specified operation descriptors to the data.
 *
 * @param {T[]} data - The data to be processed.
 * @param {State} state - The operation descriptors that will be applied to the
 *   data.
 * @returns {DataResult} - The processed data.
 *
 * @example
 * ```ts-no-run
 *
 * const result = process(data, {
 *     skip: 10,
 *     take: 20,
 *     group: [{
 *       field: 'category.categoryName',
 *             aggregates: [
 *                   { aggregate: "sum", field: "unitPrice" },
 *                   { aggregate: "sum", field: "unitsInStock" }
 *             ]
 *       }],
 *     sort: [{ field: 'productName', dir: 'desc' }],
 *     filter: {
 *         logic: "or",
 *         filters: [
 *           { field: "discontinued", operator: "eq", value: true },
 *           { field: "unitPrice", operator: "lt", value: 22 }
 *         ]
 *     }
 * });
 *
 * ```
 */
export var process = function(data, state) {
  var skipCount = state.skip, takeCount = state.take,
    filterDescriptor = state.filter, sort = state.sort, group = state.group
  var sortDescriptors = normalizeGroups(group || []).concat(sort || [])
  if (sortDescriptors.length) {
    data = orderBy(data, sortDescriptors)
  }
  var hasFilters = isPresent(filterDescriptor) && filter.length
  var hasGroups = isPresent(group) && group.length
  if (!hasFilters && !hasGroups) {
    return {
      data: takeCount ? data.slice(skipCount, skipCount + takeCount) : data,
      total: data.length
    }
  }
  var total
  var transformers = []
  var predicate
  if (hasFilters) {
    predicate = compileFilter(normalizeFilters(filterDescriptor))
    total = count(data, predicate)
    transformers.push(filter(predicate))
  } else {
    total = data.length
  }
  if (isPresent(skipCount) && isPresent(takeCount)) {
    transformers.push(skip(skipCount))
    transformers.push(take(takeCount))
  }
  if (transformers.length) {
    var transform = compose.apply(void 0, transformers)
    var result = hasGroups ?
      groupBy(data, group, transform, limit(data, predicate)) :
      exec(transform(concat), [], data)
    return { data: result, total: total }
  }
  return {
    //  @ts-ignore
    data: hasGroups ? groupBy(data, group) : data,
    total: total
  }
}
