import DefaultLoader from 'components/elements/DefaultLoader'
import FlexContainer from 'components/elements/FlexContainer'
import PageSectionTitle from 'components/elements/SectionTitle/PageSectionTitle'
import React from 'react'
import { LoaderBackground, LoaderWrapper, Wrapper } from './style'

export default (props: any) => {
  return (
    <Wrapper>
      <div style={{ position: 'relative' }}>
        <PageSectionTitle text={'Analysis'} />
        <FlexContainer flexDirection="row" style={{ width: '100%' }}>
          {props?.productsSelector}
          {props?.parameterSelector}
        </FlexContainer>
        {props.children}
        {props.isLoading && (
          <>
            <LoaderBackground />
            <LoaderWrapper>
              <DefaultLoader withLoadingText />
            </LoaderWrapper>
          </>
        )}
      </div>
    </Wrapper>
  )
}
