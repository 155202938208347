export const BORDER_RADIUS = {
  standard: 2,
  small: 4,
  medium: 8,
  large: 10
}

export const ChartHeight = {
  STANDARD: 520
}

export const GridLineWidth = {
  MINOR: 0.3
}

export const FontWeight = {
  LIGHT: 400,
  MEDIUM: 500,
  BOLD: 700,
  BLACK: 900
}

export const FontSize = {
  SMALL: 14,
  MEDIUM: 16,
  LARGE: 24,
  HUGE: 35
}

export const ButtonHeight = {
  SMALL: 20,
  MEDIUM: 40,
  LARGE: 50,
  HUGE: 60
}

export const PAGE_HEADER_HEIGHT = 64
export const PAGE_FOOTER_HEIGHT = 84
