import FlexContainer from 'components/elements/FlexContainer'
import Table from 'components/elements/Table'
import { BillingInfoProps } from 'pages/types'
import React from 'react'
import { FaChevronRight } from 'react-icons/fa'
import { useHistory } from 'react-router-dom'
import routes from 'routes'
import { SPACE } from 'services/styles'

const Organisations = ({ data }) => {
  const history = useHistory()

  return (
    <Table>
      <Table.Header
        titles={['', 'Name', 'Seats', 'Purchased Categories', '']}
      />
      <tbody>
        {data &&
          data.map((i, idx) => {
            const filteredPurchasedCategories = Object.entries(
              i.purchasedCat
            ).filter(([, values]) => {
              const purchasedCategory = values as BillingInfoProps
              return purchasedCategory.all || purchasedCategory.total
            })
            return (
              <Table.Row
                key={`${i.id}_${idx}`}
                rowStyle={{ cursor: 'pointer' }}
                onClick={() => {
                  history.push({
                    pathname: routes.organisation() + '/' + i.id,
                    state: i
                  })
                }}
              >
                <Table.Cell
                  cellStyle={{ width: SPACE.medium }}
                  render={
                    <span style={{ margin: `0px ${SPACE.small}px` }}>
                      {idx + 1}
                    </span>
                  }
                />
                <Table.Cell label={i.name} />
                <Table.Cell
                  cellStyle={{ width: '25%' }}
                  label={`${i.usedSeats + '/' + i.purchasedSeats || 0} Seats`}
                />
                <Table.Cell
                  cellStyle={{ width: '25%' }}
                  label={`${filteredPurchasedCategories.length} Categories`}
                />
                <Table.Cell
                  cellStyle={{ width: '10%' }}
                  render={
                    <FlexContainer
                      justifyContent="flex-end"
                      style={{ marginRight: SPACE.small }}
                    >
                      <FaChevronRight />
                    </FlexContainer>
                  }
                />
              </Table.Row>
            )
          })}
      </tbody>
    </Table>
  )
}

export default Organisations
