import color from 'color'
import { COLOR } from 'services/styles'
import styled from 'styled-components'

export const ReadMoreText = styled.span`
  color: ${COLOR.blueButton};

  &:hover {
    color: ${color(COLOR.blueButton).lighten(0.5).hex()};
  }
`
