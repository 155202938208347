import { ExcelExport } from '@progress/kendo-react-excel-export'
import { GridColumn as DataColumn } from '@progress/kendo-react-grid/dist/npm/GridColumn'
import DataGridSortable from 'components/charts/DataGridSortable'
import {
  BulletCell,
  CenteredText,
  CenteredTextWithWarning,
  InvisibleCell
} from 'components/elements/TableCells'
import { NoneDrillDown } from 'pages/reports/reports/common/types'
import {
  ChartOptions,
  DemographicsApiMetricResponse,
  DemographicsApiResponse,
  SelectedMetricsState
} from 'pages/reports/reports/demographics/types'
import React, { Ref } from 'react'
import { toProperCase } from 'services/helpers/canonicalization'
import { toStartCaseAddSpace } from 'services/helpers/casing'
import { SelectDataProps } from 'services/helpers/dropdownUtils'
import {
  ChainsEnumToCanonical,
  CombineChainsEnum
} from 'services/helpers/toChain'

const RenderDataTable = ({
  drillDown,
  selectedMetrics,
  data,
  dataTableRef,
  fileName
}: {
  drillDown: SelectDataProps
  selectedMetrics: SelectedMetricsState
  data: DemographicsApiResponse
  dataTableRef: Ref<any>
  fileName: any
}) => {
  if (!data) return null

  const isRetailerDrillDownEnabled = drillDown.key !== NoneDrillDown

  const excelOutput = Object.entries(data)
    .map(renameKeysInResult)
    .reduce((acc, item) => [...acc, ...item], [])
    .map(i => ({
      ...i,
      selectedBanner: isRetailerDrillDownEnabled
        ? selectedMetrics[ChartOptions.GROUP].value
        : ChainsEnumToCanonical[CombineChainsEnum.ALL_BANNERS],
      selectedProduct: selectedMetrics[ChartOptions.PRODUCT].value,
      period: selectedMetrics[ChartOptions.PERIOD].value
    }))

  return (
    <ExcelExport data={excelOutput} ref={dataTableRef} fileName={fileName}>
      <DataGridSortable data={excelOutput}>
        <DataColumn
          field="period"
          title="Period"
          width={0}
          cell={InvisibleCell}
        />
        <DataColumn
          field="selectedBanner"
          title="Selected Banner"
          width={0}
          cell={InvisibleCell}
        />
        <DataColumn
          field="selectedProduct"
          title="Selected Product"
          width={0}
          cell={InvisibleCell}
        />
        <DataColumn
          field="breakdownBy"
          title="Breakdown by"
          cell={CenteredTextWithWarning}
        />
        <DataColumn
          field="metric"
          title="Metric"
          cell={CenteredTextWithWarning}
        />
        <DataColumn field="category" title="Category" cell={CenteredText} />
        <DataColumn field="current" title="Selected Group" format="{0:n0} %" />
        <DataColumn field="benchmark" title="Benchmark" format="{0:n0} %" />
        <DataColumn field="indexed" title="Indexed Value" format="{0:n0}" />
        <DataColumn field="indexed" title="Indexed" cell={BulletCell} />
      </DataGridSortable>
    </ExcelExport>
  )
}

const renameKeysInResult = props => {
  const [breakdownBy, dataList] = props as [
    string,
    DemographicsApiMetricResponse[]
  ]

  return dataList.reduce((acc, item) => {
    const newRows = item.data.reduce((newRowAcc, dataItem) => {
      const newRow = {
        breakdownBy: toStartCaseAddSpace(breakdownBy).replace('By', ''),
        metric: toProperCase(item.metric),
        category: dataItem.value,
        current: dataItem.current,
        benchmark: dataItem.benchmark,
        indexed: dataItem.indexed,
        warningLevel: dataItem.warningLevel
      }
      return [...newRowAcc, newRow]
    }, [])
    return [...acc, ...newRows]
  }, [])
}

const MemoizedTable = React.memo(RenderDataTable)
export default MemoizedTable
