import axios from 'axios'
import { DBGroups } from 'components/charts/CustomGroupsList/type'
import config from 'config'
import { CustomGroup } from 'data/chart/types'
import pathToApi from 'services/helpers/pathToApi'

export const getCustomGroupApi = async (groupId?: string) => {
  const url = pathToApi(config.dashboardBaseUrl)
  const { data } = await axios.get(
    url(`/user/saved-groups${groupId ? `?group_id=${groupId}` : ''}`)
  )
  return data as DBGroups
}
export const createCustomGroupApi = async (newGroup: CustomGroup) => {
  const url = pathToApi(config.dashboardBaseUrl)
  const { data } = await axios.post(url('/user/saved-groups'), newGroup)
  return data
}

export const updateCustomGroupApi = async (
  id: string,
  newGroup: CustomGroup
) => {
  const url = pathToApi(config.dashboardBaseUrl)
  const { data } = await axios.put(url(`/user/saved-groups/${id}`), newGroup)
  return data
}

export const shareCustomGroupApi = async (groupId: string, orgId: string) => {
  const url = pathToApi(config.dashboardBaseUrl)
  const { data } = await axios.put(url(`/user/saved-groups/share/${groupId}`), {
    sharedOrgs: [orgId]
  })
  return data
}

export const deleteCustomGroupApi = async ({ id }) => {
  const url = pathToApi(config.dashboardBaseUrl)
  const { data } = await axios.delete(url(`/user/saved-groups/${id}`))
  return data
}
