import { selectAllProductItem } from 'pages/reports/reports/common/reportHooks/usePreselectProduct'
import {
  DefaultDropdownState,
  SelectDataProps
} from 'services/helpers/dropdownUtils'

export enum DemographicsBreakdownBy {
  BY_BUYERS = 'byBuyers',
  BY_BUYERS_PANEL = 'byBuyersPanel',
  BY_SPEND = 'bySpend',
  BY_UNITS = 'byUnits'
}

export const DemographicsBreakdownMap = {
  [DemographicsBreakdownBy.BY_BUYERS]: 'Total Buyers (vs alcohol panel)',
  [DemographicsBreakdownBy.BY_BUYERS_PANEL]: 'Total Buyers (vs total panel)',
  [DemographicsBreakdownBy.BY_SPEND]: 'Total Spend (vs alcohol panel)',
  [DemographicsBreakdownBy.BY_UNITS]: 'Total Units (vs alcohol panel)'
}

export enum ChartOptions {
  PERIOD = 'periods',
  GROUP = 'group',
  BREAKDOWN_BY = 'breakdownBy',
  PRODUCT = 'product'
}

export interface SelectableMetrics {
  [ChartOptions.PERIOD]: SelectDataProps[]
  [ChartOptions.GROUP]: SelectDataProps[]
  [ChartOptions.BREAKDOWN_BY]: SelectDataProps[]
  [ChartOptions.PRODUCT]: SelectDataProps[]
}

export interface SelectedMetrics {
  [ChartOptions.PERIOD]: SelectDataProps
  [ChartOptions.GROUP]: SelectDataProps
  [ChartOptions.BREAKDOWN_BY]: SelectDataProps
  [ChartOptions.PRODUCT]: SelectDataProps
}

export const DefaultBreakdownBySelectable: SelectDataProps[] = Object.values(
  DemographicsBreakdownBy
).map(i => ({
  key: i,
  value: DemographicsBreakdownMap[i]
}))

export const DefaultSelectableMetricsState = {
  [ChartOptions.PERIOD]: [DefaultDropdownState],
  [ChartOptions.GROUP]: [DefaultDropdownState],
  [ChartOptions.BREAKDOWN_BY]: DefaultBreakdownBySelectable,
  [ChartOptions.PRODUCT]: [selectAllProductItem]
}

export const DefaultSelectedMetricsState: SelectedMetricsState = {
  [ChartOptions.PERIOD]: DefaultDropdownState,
  [ChartOptions.GROUP]: DefaultDropdownState,
  [ChartOptions.BREAKDOWN_BY]: DefaultBreakdownBySelectable[0],
  [ChartOptions.PRODUCT]: selectAllProductItem
}

export interface SelectedMetricsState {
  [ChartOptions.PERIOD]: SelectDataProps
  [ChartOptions.GROUP]: SelectDataProps
  [ChartOptions.BREAKDOWN_BY]: SelectDataProps
  [ChartOptions.PRODUCT]: SelectDataProps
}

export enum ChartTypes {
  HOUSEHOLD_SIZE = 'householdSize',
  INCOME = 'income',
  STATE = 'state',
  CHILDREN = 'children'
  // AGE_GROUP = 'AGE_GROUP'
}

export interface ChartData {
  indexed: number
  selected: number
  target: number
  label: string
  visible: boolean
}

export interface DemographicsApiResponse {
  [key: string]: DemographicsApiMetricResponse
}

export interface DemographicsApiMetricResponse {
  metric: string
  data: {
    value: any
    warningLevel: boolean
    indexed: number
    current: number
    benchmark: number
  }[]
}
