import { Dispatch } from 'react'

const handleBuyerGroupChange = (
  newChange: string[],
  setSelectedBuyerGroups: Dispatch<string[]>,
  setDisabledItemValues: Dispatch<any[]>
) => {
  const disabledList = []

  if (newChange.length === 0) {
    setSelectedBuyerGroups([])
    return setDisabledItemValues(disabledList)
  }
  if (newChange.includes('ALL_BUYER_GROUP')) {
    newChange = ['ALL_BUYER_GROUP']
  }

  const filteredList = newChange.filter(i => i.includes('/'))
  let prefix

  if (filteredList.length === 0) prefix = newChange[0]
  else prefix = filteredList[0].split(' / ')[0]

  for (const eachEntry of ['HOUSEHOLD_SIZE', 'INCOME', 'AGE_GROUP']) {
    if (prefix !== eachEntry) disabledList.push(eachEntry)
  }

  setDisabledItemValues(Array.from(new Set(disabledList)))
  setSelectedBuyerGroups(newChange)
}

export default handleBuyerGroupChange
