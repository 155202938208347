import { Input, Modal } from 'antd'
import { Label } from 'components/dialogs/Generic/styles'
import { DialogKeys } from 'components/dialogs/index'
import SelectMenu from 'components/elements/SelectMenu'
import { CancelIcon } from 'components/elements/SvgIcon/styles'
import { DialogActions } from 'data/actions'
import { roleToText } from 'pages/organisation/types'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { QueryKeys } from 'services/api/keys'
import { changeRoleApi } from 'services/api/requests/organisation/users'
import { toStartCase } from 'services/helpers/canonicalization'
import { SelectDataProps } from 'services/helpers/dropdownUtils'
import { useUpdateQuery } from 'services/hooks/useUpdateQuery'
import { Notification } from 'services/notification'
import { SPACE } from 'services/styles'
import { ButtonHeight } from 'services/styles/misc'

const EditRoleDialog = ({
  orgId,
  userName,
  userId,
  currentRole,
  availableRoles
}) => {
  const dispatch = useDispatch()

  const changeRole = useUpdateQuery(QueryKeys.ORG_DATA, changeRoleApi)

  const [role, setRole] = useState<SelectDataProps>({
    key: currentRole,
    value: roleToText[availableRoles.find(i => i.id === currentRole).name]
  })

  const handleClose = () =>
    dispatch(DialogActions.hideDialog({ key: DialogKeys.EDIT_ROLE }))
  const handleComplete = async () => {
    await changeRole.mutateAsync(
      { orgId, userId, id: role.key },
      {
        onSuccess: () => {
          Notification({
            type: 'success',
            message: `"${userName}" role updated`
          })
        }
      }
    )
    handleClose()
  }

  const rolesMenu = availableRoles
    .slice(1)
    .filter(i => i.id !== currentRole)
    .map(i => ({
      key: i.id,
      value: toStartCase(roleToText[i.name])
    }))

  return (
    <Modal
      title={'Change Role'}
      visible
      closeIcon={
        <CancelIcon
          width={ButtonHeight.SMALL}
          height={ButtonHeight.SMALL}
          style={{ marginTop: SPACE.tiny * 2.75 }}
        />
      }
      onOk={handleComplete}
      confirmLoading={changeRole.isLoading}
      okText={'Change'}
      okButtonProps={{
        style: { width: '100%' },
        disabled: currentRole === role.key
      }}
      onCancel={handleClose}
      width={500}
      cancelButtonProps={{ hidden: true }}
    >
      <Label>Current Role</Label>
      <Input
        type="text"
        prefix={roleToText[availableRoles.find(i => i.id === currentRole).name]}
        disabled
        style={{ marginBottom: SPACE.small }}
      />

      <Label>New Role</Label>
      <SelectMenu
        customClass="bg-white"
        placeholder="Please select a role"
        selectableMenu={rolesMenu}
        item={role}
        setItem={setRole}
        style={{
          backgroundColor: 'white',
          borderRadius: 5,
          marginBottom: SPACE.small
        }}
      />
    </Modal>
  )
}

export default EditRoleDialog
