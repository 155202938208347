import { GroupMeta } from 'pages/groups/type'
import React from 'react'
import { FaChartPie, FaStar, FaUsers } from 'react-icons/fa'
import routes from 'routes'

const MyAccountMenuItems: IMenuItem[] = [
  {
    title: 'Overview',
    path: routes.dashboard(),
    icon: <FaChartPie />
  },
  {
    title: GroupMeta.TITLE,
    path: routes.groups(),
    icon: <FaUsers />
  },
  {
    title: 'Saved Reports',
    path: routes.savedReports(),
    icon: <FaStar />
  }
]

export interface IMenuItem {
  icon?: any
  title?: string
  path?: string
  items?: IMenuItem[]
  customComponent?: any
}

export default MyAccountMenuItems
