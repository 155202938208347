import Form from 'antd/lib/form'
import StandardButton from 'components/elements/StandardStyledButton'
import camelCase from 'lodash/camelCase'
import {
  MessageBlock,
  PasswordInput,
  StyledForm,
  StyledInput
} from 'pages/auth/style'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { registerApi } from 'services/api/requests/register'
import { firebaseLogin } from 'services/firebase'
import { passwordValidationRules } from 'services/helpers/validationRules'
import { useQueryParams } from 'services/hooks/useQueryParams'
import { SPACE } from 'services/styles'
import { ButtonHeight } from 'services/styles/misc'
import logo from 'static/logo-with-name-black.png'
import Header from '../RegisterHeader'

const RegisterComponent = () => {
  const history = useHistory()
  const urlParams = useQueryParams()
  const key = urlParams.get('key')

  const [error, setError] = useState<string>('')
  const [isLoading, setLoading] = useState<boolean>(false)
  const [prefilled, setPrefilled] = useState(null)

  const singleLevelCamelCase = (data: object) =>
    Object.entries(data).reduce((acc, entries) => {
      const [key, value] = entries
      acc[camelCase(key)] = value
      return acc
    }, {})

  useEffect(() => {
    if (key) {
      const decodedKey = atob(key)
      setPrefilled(singleLevelCamelCase(JSON.parse(decodedKey)))
    }
  }, [key])

  const handleRegister = async ({ firstName, lastName, password }) => {
    setError('')
    setLoading(true)
    try {
      const response = await registerApi({
        firstName,
        lastName,
        password,
        ...prefilled
      })
      if (response) {
        await firebaseLogin(prefilled?.email, password)
        history.push('/')
      }
    } catch (e) {
      setError(e.message)
    }
    setLoading(false)
  }

  return (
    <>
      <img src={logo} style={{ height: 60, margin: SPACE.large }} alt="logo" />
      <Header title={'Register'} />

      <StyledForm
        key={'register-form'}
        name="basic"
        labelAlign="left"
        initialValues={{
          email: prefilled?.email,
          organisation: prefilled?.organisationId
        }}
        onFinish={handleRegister}
        style={{ maxWidth: 400 }}
      >
        <div style={{ display: 'flex' }}>
          <Form.Item
            key={'first-name'}
            label="First Name"
            name="firstName"
            labelCol={{ span: 24, style: { padding: 0 } }}
            wrapperCol={{ span: 24, style: { paddingRight: 5 } }}
            style={{ marginBottom: SPACE.small }}
          >
            <StyledInput autoComplete="off" />
          </Form.Item>

          <Form.Item
            key={'last-name'}
            label="Last Name"
            name="lastName"
            labelCol={{ span: 24, style: { padding: 0 } }}
            wrapperCol={{ span: 24, style: { padding: 0 } }}
            style={{ marginBottom: SPACE.small }}
          >
            <StyledInput autoComplete="off" />
          </Form.Item>
        </div>
        <Form.Item
          key={'register-email'}
          label="Email"
          name="email"
          labelCol={{ span: 24, style: { padding: 0 } }}
          style={{ marginBottom: SPACE.small }}
        >
          <StyledInput prefix={prefilled?.email} disabled />
        </Form.Item>

        <Form.Item
          key={'register-organisation'}
          label="Organisation"
          name="organisation"
          labelCol={{ span: 24, style: { padding: 0 } }}
          style={{ marginBottom: SPACE.small }}
        >
          <StyledInput prefix={prefilled?.organisationName} disabled />
        </Form.Item>
        <Form.Item
          key={'register-password'}
          label="Password"
          name="password"
          validateFirst
          labelCol={{ span: 24, style: { padding: 0 } }}
          rules={passwordValidationRules}
        >
          <PasswordInput
            autoComplete={'new-password'}
            onChange={() => setError('')}
          />
        </Form.Item>

        <Form.Item
          wrapperCol={{
            offset: 8,
            span: 24
          }}
        >
          <StandardButton
            style={{
              height: ButtonHeight.MEDIUM,
              width: 130
            }}
            text="Register"
            htmlType="submit"
            isLoading={isLoading}
          />
        </Form.Item>
        <MessageBlock>{error}</MessageBlock>
      </StyledForm>
    </>
  )
}

export default RegisterComponent
