import { DialogKeys, PurchasedCategoriesDialog } from 'components/dialogs'
import PurchasedCategoriesTable from 'components/elements/PurchasedCategoriesTable'
import SectionTitleWithButton from 'components/elements/SectionTitle/SectionTitleWithButton'
import { DialogActions } from 'data/actions'
import useLoadPurchasedCategories from 'pages/organisation/details/components/purchasedCategories/hooks'
import React from 'react'
import { useDispatch } from 'react-redux'
import { SPACE } from 'services/styles'

const OrgPurchasedCategories = ({ orgId, permission }) => {
  const dispatch = useDispatch()
  const handleShowDialog = (key, component) =>
    dispatch(DialogActions.showDialog({ key, component }))

  const { isLoading, data } = useLoadPurchasedCategories(orgId)
  return (
    <div>
      <SectionTitleWithButton
        text={'Purchased Categories'}
        subtitle={
          'To update your categories, please contact your Nielsen representatives.'
        }
        buttonText={'Edit Billing'}
        showButton
        disabled={!permission}
        buttonTooltipText={
          !permission &&
          'Please contact your licence representative to purchase new categories'
        }
        onClick={() =>
          handleShowDialog(
            DialogKeys.PURCHASED_CATEGORIES,
            <PurchasedCategoriesDialog
              isDataLoading={isLoading}
              orgId={orgId}
              prefilledData={data}
            />
          )
        }
      />
      <div style={{ margin: `${SPACE.tiny}px 0px` }}>
        <PurchasedCategoriesTable
          key={'display-table'}
          isDataLoading={isLoading}
          data={data}
          editable={false}
        />
      </div>
    </div>
  )
}

export default OrgPurchasedCategories
