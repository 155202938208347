import { CustomGroup } from 'data/chart/types'

export const AlertMessage = {
  groupNameExist: 'That selection name exist, please select a new name',
  missingGroupName: 'Please fill in a selection name',
  noBannerSelected: 'Please select at least one banner',
  noBuyerGroupSelected: 'Please select at least one buyer group'
}

export interface CreateCustomGroupProps {
  title: string
  onCancel?: () => void
  onComplete?: (newGroup?: CustomGroup) => void
  prefilledGroup?: CustomGroup
  handleEditGroup?: (oldGroup, newGroup) => Promise<void>
  handleCreateGroup?: (id, newGroup) => any
  selectableOptions?: any
  isFetching?: boolean
}
