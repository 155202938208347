const SCREENS = {
  small: 760,
  large: 1280
}

export const isLargeScreen = (screenWidth: number) =>
  screenWidth >= SCREENS.large

export const isMediumScreen = (screenWidth: number) =>
  SCREENS.large >= screenWidth && screenWidth >= SCREENS.small

export const isSmallScreen = (screenWidth: number) =>
  screenWidth <= SCREENS.small
