import { SPACE } from 'services/styles'
import { FontWeight } from 'services/styles/misc'
import styled from 'styled-components'

const kendoChartTitleFontSize = 16
export const TableTitle = styled.p`
  text-align: center;
  font-weight: ${FontWeight.MEDIUM};
  font-size: ${kendoChartTitleFontSize}px;
  margin: ${SPACE.small / 1.5}px;
  margin-top: ${SPACE.tiny}px;
`
