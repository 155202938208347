import {
  isAvailablePeriodsSufficient,
  isSelectedStartDateValid
} from 'components/dialogs/CustomPeriod/helpers'
import {
  DateModeEnum,
  initialNumberOfPeriod
} from 'components/dialogs/CustomPeriod/types'
import { useEffect, useState } from 'react'
import { StringToSelectDataProps } from 'services/helpers/dropdownUtils'

export const useSelectNumberOfPeriod = (
  periods,
  periodType,
  rollingType,
  periodsMenu,
  setPeriodsMenu,
  numberOfPeriod,
  setNumberOfPeriod
) => {
  useEffect(() => {
    const availableNumberOfPeriod = Math.trunc(
      (periods.length - periodType) / rollingType + 1
    )

    setPeriodsMenu(
      Array.from(Array(availableNumberOfPeriod).keys()).map(i =>
        StringToSelectDataProps(i + 1)
      )
    )
  }, [periodType, periods.length, rollingType])

  useEffect(() => {
    if (periodsMenu[periodsMenu.length - 1].key === initialNumberOfPeriod) {
      return
    }
    if (Number(numberOfPeriod.key) > periodsMenu.length) {
      setNumberOfPeriod(periodsMenu[periodsMenu.length - 1])
    }
  }, [periodsMenu.length])
}

export const useIsStartDateValid = (
  mode,
  ascendingPeriods,
  startDate,
  setStartDate,
  rollingType,
  selectedNumberOfPeriod,
  periodType
) => {
  const descendingPeriods = ascendingPeriods.slice().reverse()
  const [error, setError] = useState('')
  const isValid = isSelectedStartDateValid(
    ascendingPeriods,
    startDate,
    selectedNumberOfPeriod,
    periodType,
    rollingType
  )

  useEffect(() => {
    !isValid
      ? setError(
          'The selected start date is invalid. Please select a valid date.'
        )
      : setError('')
    if (setStartDate) {
      if (!error.length) return
      const availablePeriods =
        mode === DateModeEnum.END ? ascendingPeriods : descendingPeriods
      const firstAvailableIndex = availablePeriods.findIndex(
        i =>
          isAvailablePeriodsSufficient(
            mode,
            ascendingPeriods,
            i,
            periodType,
            selectedNumberOfPeriod,
            rollingType
          ) && i
      )
      firstAvailableIndex !== -1 &&
        setStartDate(descendingPeriods[firstAvailableIndex])
    }
  }, [error, startDate, selectedNumberOfPeriod, periodType, rollingType, mode])

  return error
}

export const useScrollToLastCell = () => {
  useEffect(() => {
    document.getElementById('scrollable-table')?.scrollTo({
      behavior: 'smooth',
      left: 999
    })
  }, [])
}
