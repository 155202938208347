import { Layout } from 'antd'
import { UserSelector } from 'data/selectors'
import CustomGroupsPage from 'pages/groups'
import OrganisationPage from 'pages/organisation'
import AnalyticsPage from 'pages/overview'
import ReportList from 'pages/reports/reports'
import SavedReportsPage from 'pages/savedReports'
import React, { useRef } from 'react'
import { useSelector } from 'react-redux'
import { Route, Switch } from 'react-router-dom'
import routes from 'routes'
import useCheckKnownPeriods from 'services/hooks/useCheckKnownPeriods'
import useGetDivDimension from 'services/hooks/useGetDivDimension'
import { boot as bootIntercom } from 'services/intercom'
import { PAGE_HEADER_HEIGHT } from 'services/styles/misc'

const Content = () => {
  const ref = useRef()

  const user = useSelector(UserSelector.rootUserSelector)

  bootIntercom(user)

  useGetDivDimension(ref)

  useCheckKnownPeriods()

  return (
    <Layout.Content
      style={{
        overflowY: 'scroll',
        height: `calc(100vh - ${PAGE_HEADER_HEIGHT}px)`
      }}
    >
      <div ref={ref}>
        <Switch>
          <Route exact path={routes.dashboard()} component={AnalyticsPage} />
          <Route exact path={routes.groups()} component={CustomGroupsPage} />
          <Route
            exact
            path={routes.savedReports()}
            component={SavedReportsPage}
          />
          <Route path={routes.organisation()} component={OrganisationPage} />
          {ReportList.map(i => (
            <Route exact path={i.path} component={i.component} key={i.path} />
          ))}
        </Switch>
      </div>
    </Layout.Content>
  )
}

export default Content
