import { Col, Row } from 'antd'
import AnalysisWrapper from 'components/charts/AnalysisWrapper'
import { SectionWrapper } from 'components/charts/AnalysisWrapper/style'
import ChartRef from 'components/charts/ChartRef'
import SampleSizeWarningText from 'components/charts/SampleSizeWarningText'
import TableTitle from 'components/elements/SectionTitle/TableTitle'
import { ReportContext } from 'components/page/ReportPage/context'
import { ChartSelector } from 'data/selectors'
import { ReportMeta, ReportNames } from 'pages/reports/reportMeta'
import MultipleProductsSelector from 'pages/reports/reports/common/productSelector/multiple'
import useStandardSelectable from 'pages/reports/reports/common/reportHooks/useStandardSelectable'
import { initialDrillDownState } from 'pages/reports/reports/common/types'
import React, { useContext, useState } from 'react'
import { useSelector } from 'react-redux'
import { SelectDataProps } from 'services/helpers/dropdownUtils'
import { ChartHeight } from 'services/styles'
import './components/chart/override.scss'
import TreeChart from './components/chart/TreeChart'
import ParameterSelector from './components/parameterSelector'
import MemoizedRenderTable from './components/table'
import { useAutoSelectPeriod, useLoadData } from './hooks'
import { ChartContainer } from './style'

const PurchaseDrivers = React.forwardRef((props, ref) => {
  //  @ts-ignore
  const { chartRef, tableRef } = ref
  //  @ts-ignore
  const { fileName } = props.exportFileProps
  const { isLoading, setIsLoading } = useContext(ReportContext)
  const selectedPeriodKey = useSelector(ChartSelector.selectedPeriodKey)

  const {
    selectableViewOptions,
    selectedGroups,
    selectedProducts,
    setSelectedProducts
  } = useStandardSelectable()

  const [drillDown, setDrillDown] = useState<SelectDataProps>(
    initialDrillDownState
  )

  const [
    leftMetric,
    setLeftMetric,
    rightMetric,
    setRightMetric
  ] = useAutoSelectPeriod(selectableViewOptions.timePeriods)

  const data = useLoadData(setIsLoading, {
    selectedGroups,
    leftMetric,
    rightMetric,
    drillDown,
    selectedProducts
  })

  const renderParamSelector = () => (
    <ParameterSelector
      selectableViewOptions={selectableViewOptions}
      leftMetric={leftMetric}
      setLeftMetric={setLeftMetric}
      rightMetric={rightMetric}
      setRightMetric={setRightMetric}
      drillDown={drillDown}
      setDrillDown={setDrillDown}
    />
  )
  const renderProductsDrillDown = () => (
    <MultipleProductsSelector
      options={selectableViewOptions.products}
      value={selectedProducts}
      onChange={setSelectedProducts}
    />
  )

  return (
    <>
      <AnalysisWrapper
        isLoading={isLoading}
        productsSelector={renderProductsDrillDown()}
        parameterSelector={renderParamSelector()}
      >
        <SectionWrapper ref={chartRef}>
          <ChartRef>
            <div className="hidden-text hidden">
              <h5 style={{ textAlign: 'center' }}>
                {ReportMeta[ReportNames.PURCHASE_DRIVERS].title}
              </h5>
            </div>
            <ChartContainer
              style={{ height: isLoading && ChartHeight.STANDARD }}
            >
              {leftMetric?.key && rightMetric?.key && (
                <Row justify="start">
                  {data.map((group, idx) => (
                    <Col
                      key={idx}
                      xxl={{ span: 24 / Math.min(3, data.length) }}
                      xl={{ span: 24 / Math.min(2, data.length) }}
                      md={{ span: 24 }}
                    >
                      <TreeChart data={group} index={idx} />
                    </Col>
                  ))}
                </Row>
              )}
            </ChartContainer>
            <div className="hidden-text hidden">
              <p style={{ textAlign: 'center' }}>
                Period ({selectedPeriodKey?.value})
              </p>
              {leftMetric?.key && rightMetric?.key && (
                <p style={{ textAlign: 'center' }}>
                  {leftMetric.value} vs {rightMetric.value}
                </p>
              )}
            </div>
          </ChartRef>
        </SectionWrapper>

        <SectionWrapper>
          <TableTitle />

          <Row justify="center">
            <Col
              lg={{ span: Math.min(24, 7 * data.length) }}
              md={{ span: Math.min(24, 12 * data.length) }}
              xs={{ span: 24 }}
            >
              <MemoizedRenderTable
                data={data}
                dataTableRef={tableRef}
                fileName={fileName}
              />
            </Col>
          </Row>

          <SampleSizeWarningText />
        </SectionWrapper>
      </AnalysisWrapper>
    </>
  )
})

export default PurchaseDrivers
