import { isPresent } from '../utils'
import { isCompositeFilterDescriptor } from './filter-descriptor.interface'
import { transformCompositeFilter } from './filter-no-eval'
import { normalizeFilters } from './filter.operators'
// tslint:disable:max-line-length
/**
 * Creates a [`Predicate`]({% slug api_kendo-data-query_predicate %}) function
 * for the specified [`CompositeFilterDescriptor`]({% slug
 * api_kendo-data-query_compositefilterdescriptor %}).
 *
 * @param {CompositeFilterDescriptor} descriptor - The descriptor for which the
 *   predicate is created.
 * @returns {Predicate} - The created function instance.
 *
 * @example
 * ```ts-no-run
 * import { compileFilter } from '@progress/kendo-data-query';
 *
 * const data = [{ name: "Pork" }, { name: "Pepper" }, { name: "Beef" } ];
 * const predicate = compileFilter({ logic: "and", filters: [{ field: "name",
 *   operator: "startswith", value: "P" }] }); const result =
 *   data.filter(predicate);
 *
 * ```
 */
// tslint:enable:max-line-length
export var compileFilter = function(descriptor) {
    if (!descriptor || descriptor.filters.length === 0) {
        return function() { return true }
    }
    return transformCompositeFilter(descriptor)
}
// tslint:disable:max-line-length
/**
 * Filters the provided array according to the specified
 * [`CompositeFilterDescriptor`]({% slug
 * api_kendo-data-query_compositefilterdescriptor %}).
 *
 * @param {T[]} data - The data that will be filtered.
 * @param {(CompositeFilterDescriptor | FilterDescriptor)} descriptor - The
 *   filter criteria that will be applied.
 * @returns {T[]} - The filtered data.
 *
 * @example
 * ```ts-no-run
 * import { filterBy } from '@progress/kendo-data-query';
 *
 * const data = [
 *  { name: "Pork", category: "Food", subcategory: "Meat" },
 *  { name: "Pepper", category: "Food", subcategory: "Vegetables" },
 *  { name: "Beef", category: "Food", subcategory: "Meat" }
 * ];
 *
 * const result = filterBy(data, {
 *     logic: 'and',
 *     filters: [
 *           { field: "name", operator: "startswith", value: "p", ignoreCase:
 *   true },
 *           { field: "subcategory", operator: "eq", value: "Meat" },
 *     ]
 * });
 *
 * // output:
 * // [{ "name": "Pork", "category": "Food", "subcategory": "Meat" }]
 * ```
 */
// tslint:enable:max-line-length
export var filterBy = function(data, descriptor) {
    if (!isPresent(descriptor) || (isCompositeFilterDescriptor(descriptor) && descriptor.filters.length === 0)) {
        return data
    }
    return data.filter(compileFilter(normalizeFilters(descriptor)))
}
