import { store } from 'data'
import { UserActions } from 'data/actions'
import { firebaseAuthStateObserver } from 'services/firebase'

const handleFirebaseReady = () =>
  store.dispatch(UserActions.setFirebaseIsReady())

const handleFirebaseAuthStateChange = () =>
  store.dispatch(UserActions.firebaseAuthStateChange())

firebaseAuthStateObserver({
  handleFirebaseReady,
  handleFirebaseAuthStateChange
})
