import {
  geometry,
  Group,
  Path,
  Rect as RectShape,
  Text
} from '@progress/kendo-drawing'
import { LegendItemVisualArgs } from '@progress/kendo-react-charts/dist/npm/common/property-types'
import React from 'react'
import limitWordsPerLine from 'services/helpers/limitWordsPerLine'

const { Point, Rect, Size } = geometry

interface Props extends LegendItemVisualArgs {
  active?: boolean
  numberOfWordsPerLine?: number
}

const LegendVisual = (props: Props) => {
  const rectOptions = {
    stroke: { width: 2, color: '#fff' },
    fill: { color: '#fff' }
  }
  const rectGeometry = new Rect(new Point(0, 3), new Size(10, 10))
  const rect = new RectShape(rectGeometry, rectOptions)

  const pathColor = props.options.markers.border.color
  const path = new Path({
    stroke: {
      color: pathColor,
      width: 3
    }
  })

  path.moveTo(0, 10).lineTo(15, 10).close()

  const labelText = limitWordsPerLine(
    props.series.name,
    props?.numberOfWordsPerLine
  )
  const labelFont = props.options.labels.font
  const fontColor = props.options.labels.color
  const textOptions = {
    font: labelFont,
    fill: { color: fontColor }
  }

  const textArray = labelText
    .split('\n')
    .map(
      (text, index) => new Text(text, new Point(27, index * 15), textOptions)
    )

  const group = new Group({ cursor: 'pointer' })

  group.append(rect, path, ...textArray)
  if (!props.active) {
    group.opacity(0.5)
  }

  return group
}

export default LegendVisual
