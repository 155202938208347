import { ChartSelector } from 'data/selectors'
import { initialDrillDownState } from 'pages/reports/reports/common/types'
import { Dispatch, SetStateAction, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { SelectDataProps } from 'services/helpers/dropdownUtils'
import { CombineChainsEnum } from 'services/helpers/toChain'

const useDrillDownSelectable = (
  setDrillDown: Dispatch<SetStateAction<SelectDataProps>>
): boolean => {
  const loadedGroups = useSelector(ChartSelector.customGroups)

  const invalidGroups = loadedGroups.filter(
    item => !item.chains.includes(CombineChainsEnum.ALL_BANNERS)
  )

  useEffect(() => {
    if (invalidGroups.length) {
      setDrillDown(initialDrillDownState)
    }
  }, [invalidGroups.length])

  return !!invalidGroups.length
}

export default useDrillDownSelectable
