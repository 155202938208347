import {
  CreateOrEditCustomGroup,
  DialogKeys,
  DialogTypes,
  GenericDeleteDialog
} from 'components/dialogs'
import EmptyData from 'components/elements/EmptyData'
import LoadingRows from 'components/elements/LoadingRows'
import SectionTitleWithButton from 'components/elements/SectionTitle/SectionTitleWithButton'
import { ChartActions, DialogActions } from 'data/actions'
import { initialCustomGroup } from 'data/chart/types'
import { ChartSelector } from 'data/selectors'
import OrgGroupsTable from 'pages/organisation/details/components/groups/components/OrgGroupsTable'
import {
  useLoadOrgGroups,
  useLoadOrgOptions
} from 'pages/organisation/details/components/groups/hooks'
import { separator } from 'pages/reports/reports/common/helpers'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { QueryKeys } from 'services/api/keys'
import {
  filterProductsTree,
  periodTypeToRobustKey
} from 'services/api/requests/common/dataSelector'
import { deleteCustomGroupApi } from 'services/api/requests/groups/customGroups'
import {
  createOrgGroupApi,
  updateOrgGroupApi
} from 'services/api/requests/organisation/groups'
import { useUpdateQuery } from 'services/hooks/useUpdateQuery'
import { Notification } from 'services/notification'

const OrgGroups = ({ orgId, permission }) => {
  const dispatch = useDispatch()

  const selectedPeriod = useSelector(ChartSelector.selectedPeriod)
  const customPeriods = useSelector(ChartSelector.customPeriods)
  const selectedCustomPeriod = customPeriods.find(
    i => i.key === selectedPeriod.key
  )

  let periodType
  if (selectedCustomPeriod) {
    periodType = selectedCustomPeriod.periodType
  } else {
    const selections = selectedPeriod.key.split(separator)
    periodType = selections[1]
  }

  const handleShowDialog = (key, component) =>
    dispatch(DialogActions.showDialog({ key, component }))
  const handleHideDialog = key => dispatch(DialogActions.hideDialog({ key }))

  const { isLoading: isOrgGroupsLoading, data: orgGroups } = useLoadOrgGroups(
    orgId
  )

  const {
    data: orgOptions,
    isFetching: isOrgOptionsFetching
  } = useLoadOrgOptions(orgId)

  const createGroup = useUpdateQuery(QueryKeys.ORG_GROUPS, createOrgGroupApi)
  const updateGroup = useUpdateQuery(QueryKeys.ORG_GROUPS, updateOrgGroupApi)
  const deleteGroup = useUpdateQuery(QueryKeys.ORG_GROUPS, deleteCustomGroupApi)

  const filteredOptions = {
    ...orgOptions,
    products: filterProductsTree(
      orgOptions?.products || [],
      periodTypeToRobustKey[periodType]
    )
  }

  const _handleEditGroup = async (id, newGroup) => {
    await updateGroup.mutateAsync(
      {
        orgId,
        id,
        newGroup
      },
      {
        onSuccess: () => {
          Notification({
            type: 'success',
            message: `Selection "${newGroup.name}" updated`
          })
          dispatch(ChartActions.editCustomGroup(id, newGroup))
        }
      }
    )
  }

  const handleCreateOrgGroup = async (id, newGroup) => {
    if (!id) {
      return await createGroup.mutateAsync(
        {
          orgId,
          id,
          newGroup
        },
        {
          onSuccess: () => {
            Notification({
              type: 'success',
              message: `Add "${newGroup.name}" to organisation`
            })
          }
        }
      )
    } else {
      return await _handleEditGroup(id, newGroup)
    }
  }
  const handleEditOrgGroup = item => {
    handleShowDialog(
      DialogKeys.CREATE_OR_EDIT_CUSTOM_GROUP,
      <CreateOrEditCustomGroup
        title="Edit Saved Selection"
        onComplete={() =>
          handleHideDialog(DialogKeys.CREATE_OR_EDIT_CUSTOM_GROUP)
        }
        prefilledGroup={item}
        handleEditGroup={_handleEditGroup}
        selectableOptions={filteredOptions}
        isFetching={isOrgOptionsFetching}
      />
    )
  }

  const handleDeleteOrgGroup = group => {
    const handleOkClick = async item => {
      const { id, name } = item
      await deleteGroup.mutateAsync(
        { id },
        {
          onSuccess: () => {
            Notification({
              type: 'success',
              message: `Selection "${name}" deleted`
            })
            dispatch(ChartActions.deleteCustomGroup(id))
            handleHideDialog(DialogKeys.DELETE_GENERIC)
          }
        }
      )
    }

    handleShowDialog(
      DialogKeys.DELETE_GENERIC,
      <GenericDeleteDialog
        type={DialogTypes.SELECTION}
        onOk={() => handleOkClick(group)}
        fromText="from this organisation"
        name={group.name}
      />
    )
  }

  const isEmpty = !isOrgGroupsLoading && !orgGroups?.length
  return (
    <div>
      <SectionTitleWithButton
        text={'Organisation Saved Selections'}
        subtitle={
          'Everyone in this organisation will see these pre-set Saved Selections'
        }
        buttonText={'Add new'}
        showButton={permission}
        onClick={() =>
          handleShowDialog(
            DialogKeys.CREATE_OR_EDIT_CUSTOM_GROUP,
            <CreateOrEditCustomGroup
              title="Create Selection"
              prefilledGroup={initialCustomGroup}
              handleCreateGroup={handleCreateOrgGroup}
              selectableOptions={filteredOptions}
              isFetching={isOrgOptionsFetching}
            />
          )
        }
      />

      {isOrgGroupsLoading ? (
        <LoadingRows />
      ) : (
        <OrgGroupsTable
          data={orgGroups}
          onEdit={handleEditOrgGroup}
          onDelete={handleDeleteOrgGroup}
          permission={permission}
        />
      )}

      {isEmpty && (
        <EmptyData
          descriptionComponent={<h6>No groups added to this organisation</h6>}
        />
      )}
    </div>
  )
}

export default OrgGroups
