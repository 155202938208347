import axios from 'axios'
import config from 'config'
import {
  formatBuyerGroup,
  formatProducts
} from 'services/api/requests/common/dataSelector'
import pathToApi from 'services/helpers/pathToApi'

export const getPurchasedCatApi = async orgId => {
  const url = pathToApi(config.dashboardBaseUrl)
  const { data } = await axios.get(
    url(`/organisation/${orgId}/purchased-categories`)
  )
  return data.map((i, index) => ({ ...i, key: index }))
}

export const updatePurchasedCatApi = async ({ orgId, data: postData }) => {
  const url = pathToApi(config.dashboardBaseUrl)
  const { data } = await axios.put(
    url(`/organisation/${orgId}/purchased-categories`),
    postData
  )
  return data
}

export const getPurchasedOptionsApi = async orgId => {
  if (!orgId) return undefined
  const url = pathToApi(config.dashboardBaseUrl)
  const { data } = await axios.get(url(`/organisation/${orgId}/options`))
  const { retailer, period, products, income, householdSize, ageGroup } = data
  const productsData = formatProducts(products)
  const buyersData = formatBuyerGroup(income, householdSize, ageGroup)

  return {
    periods: period
      .map(i => parseInt(i))
      .slice()
      .sort((a, b) => b - a),
    products: productsData,
    retailers: retailer.filter(i => i !== 'VINTAGE_CELLARS'),
    buyersGroups: buyersData
  }
}
