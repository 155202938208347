import { Form, Input } from 'antd'
import { BORDER_RADIUS, COLOR, SPACE } from 'services/styles'
import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
`

export const InnerWrapper = styled.div`
  background-color: white;
  padding: ${SPACE.large}px ${SPACE.small}px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const MessageBlock = styled.span`
  display: block;
  width: 300px;
  color: ${COLOR.danger};
  line-height: 1.5;
  font-size: 14px;
  box-sizing: border-box;
`

export const StyledForm = styled(Form)`
  display: flex;
  justify-content: center;
  max-width: 300px;
  flex-direction: column;
`

export const StyledInput = styled(Input)`
  height: 36px;
  margin-right: ${SPACE.small}px;
  border-radius: ${BORDER_RADIUS.standard * 3}px;

  &::placeholder {
    color: ${COLOR.textGrey};
  }
`

export const PasswordInput = styled(Input.Password)`
  height: 36px;
  margin-right: ${SPACE.small}px;
  border-radius: ${BORDER_RADIUS.standard * 3}px;

  &::placeholder {
    color: ${COLOR.textGrey};
  }
`
