import { DeleteOutlined, EyeOutlined, MailOutlined } from '@ant-design/icons'
import { Dropdown, Menu } from 'antd'
import {
  DialogKeys,
  DialogTypes,
  EditRoleDialog,
  GenericDeleteDialog,
  QueryErrorDialog as ErrorDialog,
  ResendInvitationDialog
} from 'components/dialogs'
import FlexContainer from 'components/elements/FlexContainer'
import Table from 'components/elements/Table'
import { DialogActions, UserActions } from 'data/actions'
import { DropdownButton } from 'pages/organisation/details/styles'
import { roleToText } from 'pages/organisation/types'
import { MoreBlackIcon } from 'pages/savedReports/components/SavedReports/styles'
import { UserProps } from 'pages/types'
import React, { ReactNode } from 'react'
import { FaSyncAlt } from 'react-icons/fa'
import { useDispatch } from 'react-redux'
import { QueryKeys } from 'services/api/keys'
import { deleteUserApi } from 'services/api/requests/organisation/users'
import { useUpdateQuery } from 'services/hooks/useUpdateQuery'
import { Notification } from 'services/notification'
import { SPACE } from 'services/styles'

const UsersTable = ({ orgState, loginUser, data, roles, permission }) => {
  const dispatch = useDispatch()

  const handleShowDialog = (key, component) =>
    dispatch(DialogActions.showDialog({ key, component }))

  const handleHideDialog = key => dispatch(DialogActions.hideDialog({ key }))

  const deleteUser = useUpdateQuery(QueryKeys.ORG_DATA, deleteUserApi)

  const handleDeleteUser = async user => {
    await deleteUser.mutateAsync(
      {
        orgId: orgState.id,
        userId: user.id
      },
      {
        onSuccess: () => {
          Notification({
            type: 'success',
            message: `User "${user.fullName}" deleted`
          })
        }
      }
    )
    handleHideDialog(DialogKeys.DELETE_GENERIC)
  }

  const handleViewAs = async user =>
    dispatch(
      UserActions.setViewAs({
        ...user,
        role: roles.find(i => i.id === user.role),
        organisation: orgState
      })
    )

  const dropdownMenu = (user: UserProps) => {
    const handleShowWarning = () => {
      if (user.email === loginUser.email) {
        return handleShowDialog(
          DialogKeys.QUERY_ERROR,
          <ErrorDialog errorMessage="You cannot delete your own account" />
        )
      }

      handleShowDialog(
        DialogKeys.DELETE_GENERIC,
        <GenericDeleteDialog
          type={DialogTypes.USER}
          onOk={() => handleDeleteUser(user)}
          name={user.fullName}
          fromText={'from the organisation'}
        />
      )
    }

    const handleChangeRoleClick = () =>
      handleShowDialog(
        DialogKeys.EDIT_ROLE,
        <EditRoleDialog
          availableRoles={roles}
          orgId={orgState.id}
          userName={user.fullName}
          userId={user.id}
          currentRole={user.role}
        />
      )

    const handleResendInvitationClick = () =>
      handleShowDialog(
        DialogKeys.RESEND_INVITATION,
        <ResendInvitationDialog
          role={user.role}
          orgId={orgState.id}
          email={user.email}
          inviteLink={user.inviteLink}
        />
      )

    return (
      <Menu>
        <MenuItem
          key="view-as"
          onClick={() => handleViewAs(user)}
          disabled={!user?.isActive}
        >
          <EyeOutlined /> View As
        </MenuItem>
        <MenuItem key="delete-item" onClick={handleShowWarning}>
          <DeleteOutlined /> Delete
        </MenuItem>
        <MenuItem key="change-role" onClick={handleChangeRoleClick}>
          <FaSyncAlt style={{ marginRight: SPACE.tiny / 2 }} />
          Change Role
        </MenuItem>
        {!user.isActive && (
          <MenuItem
            key="resend-invitation"
            onClick={handleResendInvitationClick}
          >
            <MailOutlined style={{ marginRight: SPACE.tiny / 2 }} />
            Resend Invite
          </MenuItem>
        )}
      </Menu>
    )
  }

  const renderDropdown = (user: UserProps) => (
    <Dropdown trigger={['click']} overlay={dropdownMenu(user)}>
      <DropdownButton onClick={e => e.stopPropagation()}>
        <MoreBlackIcon />
      </DropdownButton>
    </Dropdown>
  )
  return (
    <Table>
      <Table.Header titles={['', 'Name', 'Email', 'Role', 'Status', '']} />
      <tbody>
        {data &&
          data.map((item: UserProps, idx) => (
            <Table.Row key={`${item.id}_${idx}`}>
              <Table.Cell
                cellStyle={{ width: SPACE.medium }}
                render={
                  <span style={{ margin: `0px ${SPACE.small}px` }}>
                    {idx + 1}
                  </span>
                }
              />

              <Table.Cell cellStyle={{ width: '30%' }} label={item.fullName} />
              <Table.Cell label={item.email} />
              <Table.Cell
                cellStyle={{ width: '10%' }}
                label={roleToText[roles.find(i => i.id === item.role)?.name]}
              />
              <Table.Cell
                cellStyle={{ width: '10%' }}
                label={item.isActive ? 'Active' : 'Invited'}
              />
              <Table.Cell
                cellStyle={{ width: '10%' }}
                visible={permission}
                render={
                  <FlexContainer
                    justifyContent="flex-end"
                    style={{ marginRight: SPACE.small }}
                  >
                    {renderDropdown(item)}
                  </FlexContainer>
                }
              />
            </Table.Row>
          ))}
      </tbody>
    </Table>
  )
}

interface MenuItemProps {
  key: string
  onClick: () => void
  disabled?: boolean
  children: ReactNode
}

const MenuItem = ({ children, ...restProps }: MenuItemProps) => (
  <Menu.Item
    style={{ padding: `${SPACE.tiny}px ${SPACE.small}px` }}
    {...restProps}
  >
    {children}
  </Menu.Item>
)

export default UsersTable
