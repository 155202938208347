import FlexContainer from 'components/elements/FlexContainer'
import SelectMenu from 'components/elements/SelectMenu'
import useDrillDownSelectable from 'pages/reports/reports/common/reportHooks/useDrillDownSelectable'
import React, { Dispatch } from 'react'
import { SelectDataProps } from 'services/helpers/dropdownUtils'
import { SPACE } from 'services/styles'
import { DrillByList, invalidDrillDownTooltip } from '../../common/types'
import { SelectError, SelectLabel } from './style'

interface Props {
  selectableViewOptions: {
    timePeriods: SelectDataProps[]
    groups: SelectDataProps[]
  }
  leftMetric: SelectDataProps
  setLeftMetric: (i) => void
  rightMetric: SelectDataProps
  setRightMetric: (i) => void
  drillDown: SelectDataProps
  setDrillDown: Dispatch<SelectDataProps>
}

const renderSelect = (
  selected: SelectDataProps,
  handleChange: (any: any) => void,
  errorLabel: string,
  dropdownTitle: string,
  data: SelectDataProps[]
) => (
  <div style={{ marginLeft: SPACE.tiny }}>
    <SelectLabel>{dropdownTitle}</SelectLabel>
    <SelectMenu selectableMenu={data} item={selected} setItem={handleChange} />
    {!selected.key && <SelectError>{errorLabel}</SelectError>}
  </div>
)

export default ({
  selectableViewOptions,
  leftMetric,
  setLeftMetric,
  rightMetric,
  setRightMetric,
  drillDown,
  setDrillDown
}: Props) => {
  const isDrillDownSelectable = useDrillDownSelectable(setDrillDown)

  const selectablePeriods = selectableViewOptions.timePeriods
  const selectedLeft = selectablePeriods.find(i => i.key === leftMetric.key)
  const selectedRight = selectablePeriods.find(i => i.key === rightMetric.key)
  return (
    <>
      <FlexContainer alignItems="start">
        <div style={{ marginRight: SPACE.tiny }}>
          <p>DRILL DOWN</p>
          <SelectMenu
            disabled={isDrillDownSelectable}
            tooltip={isDrillDownSelectable && invalidDrillDownTooltip}
            selectableMenu={DrillByList}
            item={drillDown}
            setItem={setDrillDown}
          />
        </div>

        {renderSelect(
          { key: leftMetric.key, value: selectedLeft?.value },
          setLeftMetric,
          'Please select a period',
          'CURRENT PERIOD',
          selectablePeriods
        )}
        {renderSelect(
          {
            key: rightMetric.key,
            value: selectedRight?.value
          },
          setRightMetric,
          'Please select a period',
          'COMPARISON PERIOD',
          selectablePeriods
        )}
      </FlexContainer>
    </>
  )
}
