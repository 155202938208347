import { Input } from 'antd'
import { FaTrashAlt } from 'react-icons/fa'
import { BORDER_RADIUS, COLOR, SPACE } from 'services/styles'
import styled from 'styled-components'

export const LoaderWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  padding-top: 30vh;
  justify-content: center;
  align-items: flex-start;
  z-index: 1;
  top: 0;
  left: 0;
`

export const LoaderBackground = styled(LoaderWrapper)`
  opacity: 0.9;
  background-color: ${COLOR.antdGrey};
`

export const GroupingRow = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

export const SearchRowWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0;
  margin: ${SPACE.small}px 0px ${SPACE.tiny * 3}px 0px;
`

export const SearchInput = styled(Input)`
  margin-right: ${SPACE.small}px;
  height: ${SPACE.large}px;
  border-radius: ${BORDER_RADIUS.standard * 3}px;
  font-size: 16px;

  &::placeholder {
    color: ${COLOR.textGrey};
  }
`

export const RowHeaderWrapper = styled.div`
  top: 0;
  z-index: 5;
`

export const GroupsWrapper = styled.div``

export const DeleteButton = styled(FaTrashAlt)``

interface HoverButtonProps {
  hoverColor?: string
}

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: ${SPACE.tiny}px;
  color: ${COLOR.textGrey};

  &:hover {
    cursor: pointer;
    color: ${(props: HoverButtonProps) => props?.hoverColor};
  }
`

export const PageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`
