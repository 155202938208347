import { UserSelector } from 'data/selectors'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import AuthRoutes from 'routes/auth'

const authRoutes = Object.values(AuthRoutes).map(i => i())

const useAuthRouting = () => {
  const history = useHistory()
  const { pathname } = useLocation()
  const isAppReady = useSelector(UserSelector.isAppReady)
  const rootUser = useSelector(UserSelector.rootUserSelector)
  const isOnAuthRoute = authRoutes.some(i => pathname.startsWith(i))
  useEffect(() => {
    if (isAppReady) {
      if (rootUser) {
        if (isOnAuthRoute) {
          return history.push('/')
        }
      } else {
        if (!isOnAuthRoute) {
          history.push('/login')
        }
      }
    }
  }, [isAppReady])
}
export default useAuthRouting
