import * as Sentry from '@sentry/react'
import { UserActions } from 'data/actions'
import { sagaErrorFallback } from 'data/sagaFallback'
import { UserActionTypes } from 'data/user/types'
import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import { getCurrentUserApi } from 'services/api/requests/common/userInfo'
import { firebaseGetCurrentUser, firebaseLogout } from 'services/firebase'
import {
  boot as bootIntercom,
  shutdown as shutdownIntercom
} from 'services/intercom'

function* handleAuthStateChanged() {
  const firebaseUser = yield call(firebaseGetCurrentUser)
  if (firebaseUser) {
    const rawAttributes = firebaseUser?.reloadUserInfo?.customAttributes || {}
    const customAttributes = JSON.parse(rawAttributes)
    if (customAttributes.id) {
      yield put(UserActions.setIsFetchingUser(true))
      const user: any = yield call(getCurrentUserApi)
      yield put(UserActions.setUser(user))
      Sentry.setUser({
        id: user?.id,
        email: user?.email,
        firstName: user?.firstName,
        lastName: user?.lastName
      })
      bootIntercom(user)
      yield put(UserActions.setIsFetchingUser(false))
    }
  }
}

function* handleUserLogout() {
  yield call(firebaseLogout)
  shutdownIntercom()
  yield put(UserActions.setUser(null))
  Sentry.configureScope(scope => scope.setUser(null))
}

function* watchFirebaseAuthStateChange() {
  yield takeEvery(
    UserActionTypes.FIREBASE_AUTH_STATE_CHANGE,
    sagaErrorFallback,
    handleAuthStateChanged
  )
}

function* watchUserLogout() {
  yield takeEvery(UserActionTypes.LOGOUT, sagaErrorFallback, handleUserLogout)
}

function* userSagaRoot() {
  yield all([fork(watchFirebaseAuthStateChange), fork(watchUserLogout)])
}

export default userSagaRoot
