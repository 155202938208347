import startCase from 'lodash/startCase'
import { ReportMeta } from 'pages/reports/reportMeta'
import { useFilterQueries } from 'pages/reports/reports/common/helpers'
import { TrendedMetricsMultipleEnum } from 'pages/reports/reports/trendedMetricsMultiple/types'
import { Dispatch, useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useDispatch } from 'react-redux'
import dataFallback from 'services/api/requests/common/dataFallback'
import { getTrendedMetricsMultipleApi } from 'services/api/requests/reports/trendedMetricsMultiple'
import { SelectDataProps } from 'services/helpers/dropdownUtils'

export const useLoadData = (
  setIsLoading: (boolean) => void,
  selectedGroups: SelectDataProps[],
  selectedProducts: SelectDataProps[],
  drillDown?: SelectDataProps
) => {
  const query = useFilterQueries(
    ReportMeta.TRENDED_METRICS_MULTIPLE.key,
    selectedGroups,
    {
      drillDown,
      selectedProducts
    }
  )

  const dispatch = useDispatch()

  const { isLoading, isFetching, data } = useQuery(query, () =>
    getTrendedMetricsMultipleApi(query, dispatch)
  )
  const fallbackData = dataFallback(data)

  useEffect(() => setIsLoading(isLoading || isFetching), [
    isLoading,
    isFetching
  ])

  return fallbackData
}

export const useLoadMetrics = (): [
  SelectDataProps,
  Dispatch<SelectDataProps>,
  SelectDataProps,
  Dispatch<SelectDataProps>
] => {
  const generateInitialState = (
    metricsMultipleEnum: TrendedMetricsMultipleEnum
  ) => ({
    value: startCase(metricsMultipleEnum.toLowerCase()),
    key: metricsMultipleEnum
  })
  const [leftMetric, setLeftMetric] = useState<SelectDataProps>(
    generateInitialState(TrendedMetricsMultipleEnum.BUYERS)
  )
  const [rightMetric, setRightMetric] = useState<SelectDataProps>(
    generateInitialState(TrendedMetricsMultipleEnum.NONE)
  )

  return [leftMetric, setLeftMetric, rightMetric, setRightMetric]
}
