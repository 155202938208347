import { Button, Row } from 'antd'
import Table from 'components/elements/Table'
import { BORDER_RADIUS, COLOR, SPACE } from 'services/styles'
import { FontWeight } from 'services/styles/misc'
import styled from 'styled-components'

export const VerticalCellWrapper = styled.span`
  padding: ${SPACE.small}px ${SPACE.tiny}px;
  width: 50px;
  height: 140px;
  display: inline-block;
  text-align: center;
  line-height: 1;
  font-size: 16px;
  cursor: pointer;
`

export const VerticalText = styled.span`
  display: block;
  margin-top: ${SPACE.large / 2}px;
  transform: rotate(90deg);
  transform-origin: top;
  white-space: nowrap;
  width: 50px;
  font-weight: ${FontWeight.MEDIUM};
`

export const IndicatorCell = styled.td`
  color: white;
`

export const ScrollableTable = styled(Table)`
  display: block;
  overflow-x: scroll;
  white-space: nowrap;
  width: auto;
  margin-right: 11px;
  margin-left: 11px;
  border-spacing: 0;
`

export const SummaryContainer = styled(Row)`
  margin-top: ${SPACE.small}px;
  margin-right: 11px !important;
  margin-left: 11px !important;
`

export const GreyButton = styled(Button)`
  display: flex;
  align-items: center;
  border-radius: ${BORDER_RADIUS.standard * 3}px;
  background-color: ${COLOR.antdGrey};
`

export const IconWrapper = styled.span`
  padding-left: ${SPACE.tiny}px;
  padding-top: 2px;
`
