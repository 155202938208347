import Form from 'antd/lib/form'
import FlexContainer from 'components/elements/FlexContainer'
import StandardButton from 'components/elements/StandardStyledButton'
import {
  MessageBlock,
  PasswordInput,
  StyledForm,
  StyledInput
} from 'pages/auth/style'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import routes from 'routes'
import { firebaseLogin } from 'services/firebase'
import {
  emailValidationRules,
  passwordValidationRules
} from 'services/helpers/validationRules'
import { SPACE } from 'services/styles'
import { ButtonHeight } from 'services/styles/misc'

import logo from 'static/logo-with-name-black.png'

export default () => {
  const [error, setError] = useState<string>('')
  const [isLoading, setLoading] = useState<boolean>(false)

  const handleLogin = async ({ username, password }) => {
    setError('')
    setLoading(true)
    try {
      await firebaseLogin(username, password)
    } catch (e) {
      setError(e.message)
    }
    setLoading(false)
  }

  return (
    <>
      <img src={logo} style={{ height: 60, margin: SPACE.large }} alt="logo" />

      <StyledForm
        key={'login-form'}
        name="basic"
        labelAlign="left"
        onFinish={handleLogin}
      >
        <Form.Item
          key={'user-input'}
          label="Username"
          name="username"
          labelCol={{ span: 24, style: { padding: 0 } }}
          rules={emailValidationRules}
        >
          <StyledInput />
        </Form.Item>

        <Form.Item
          key={'password-input'}
          label="Password"
          name="password"
          validateFirst
          labelCol={{ span: 24, style: { padding: 0 } }}
          rules={passwordValidationRules}
        >
          <PasswordInput />
        </Form.Item>

        <Form.Item
          wrapperCol={{
            offset: 7,
            span: 24
          }}
        >
          <StandardButton
            text="Login"
            htmlType="submit"
            isLoading={isLoading}
            style={{
              height: ButtonHeight.MEDIUM,
              width: 130
            }}
          />
        </Form.Item>
        <FlexContainer justifyContent="center">
          <Link to={routes.auth.forgotPassword()}>Forgot password?</Link>
        </FlexContainer>
        <MessageBlock>{error}</MessageBlock>
      </StyledForm>
    </>
  )
}
