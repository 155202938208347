import axios from 'axios'
import { DialogKeys, QueryErrorDialog } from 'components/dialogs'
import config from 'config'
import { DialogActions } from 'data/actions'
import React from 'react'
import { cancelQuery } from 'services/api/requests/common/cancelQuery'
import pathToApi from 'services/helpers/pathToApi'
import { v4 as uuidv4 } from 'uuid'

const CancelToken = axios.CancelToken
let cancel

export const getRepertoireApi = async (props: any[], dispatch: any) => {
  const newQueryId = uuidv4()
  try {
    cancel && cancel()
    const formattedProps = props.map(prop => ({
      ...prop,
      name: prop?.id || newQueryId,
      products: prop.products.filter(i => i !== 'ALCOHOL') // exclude
      // total alcohol
    }))
    const url = pathToApi(config.dashboardBaseUrl)
    const { data } = await axios.post(
      url(`/query/reports/repertoire?query_id=${newQueryId}`),
      formattedProps,
      {
        cancelToken: new CancelToken(c => (cancel = c))
      }
    )
    return data
  } catch (e) {
    if (axios.isCancel(e)) {
      await cancelQuery(newQueryId)
      return null
    }
    dispatch(
      DialogActions.showDialog({
        key: DialogKeys.QUERY_ERROR,
        component: <QueryErrorDialog error={e} />
      })
    )
    return []
  }
}
