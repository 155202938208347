import { COLOR, SPACE } from 'services/styles'
import styled from 'styled-components'

export const RowWrapper = styled.div`
  padding: 0px ${SPACE.large}px;
  border-radius: 5px;
`

export const BreakLine = styled.div`
  height: ${SPACE.small}px;
`

export const SelectorLabel = styled.p`
  color: ${COLOR.textDark};
  margin-bottom: ${SPACE.tiny}px;
`
