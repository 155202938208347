import startCase from 'lodash/startCase'
import { toStartCase } from 'services/helpers/canonicalization'

export interface TrendedMetricsMultipleResponse {
  period: string
  penetration: number
  buyers: number
  pricePerPack: number
  purchaseValue: number
  purchaseUnits: number
  shareByValue: number
  shareByOccasions: number
  averageOccasions: number
  spendPerOccasion: number
  unitsPerOccasion: number
  unitAwop: number
  valueAwop: number
  warningLevel: number
  key: string
}

export interface FormattedData {
  key: TrendedMetricsMultipleEnum | string
  period: string
  value: any
  warningLevel: number
}

export enum TrendedMetricsAxisEnum {
  LEFT_AXIS = 'LEFT_AXIS',
  RIGHT_AXIS = 'RIGHT_AXIS'
}

export enum TrendedMetricsMultipleEnum {
  PENETRATION = 'PENETRATION',
  BUYERS = 'BUYERS',
  PRICE_PER_PACK = 'PRICE_PER_PACK',
  PURCHASE_VALUE = 'PURCHASE_VALUE',
  PURCHASE_UNITS = 'PURCHASE_UNITS',
  SHARE_BY_VALUE = 'SHARE_BY_VALUE',
  SHARE_BY_OCCASIONS = 'SHARE_BY_OCCASIONS',
  AVERAGE_OCCASIONS = 'AVERAGE_OCCASIONS',
  SPEND_PER_OCCASION = 'SPEND_PER_OCCASION',
  UNITS_PER_OCCASION = 'UNITS_PER_OCCASION',
  UNIT_AWOP = 'UNIT_AWOP',
  VALUE_AWOP = 'VALUE_AWOP',
  NONE = 'NONE'
}

export const TrendedMetricsEnumToUnit = {
  [TrendedMetricsMultipleEnum.PENETRATION]: 'Percent',
  [TrendedMetricsMultipleEnum.BUYERS]: 'Index',
  [TrendedMetricsMultipleEnum.PRICE_PER_PACK]: 'Dollar Value',
  [TrendedMetricsMultipleEnum.PURCHASE_VALUE]: 'Index',
  [TrendedMetricsMultipleEnum.PURCHASE_UNITS]: 'Index',
  [TrendedMetricsMultipleEnum.SHARE_BY_VALUE]: 'Percent',
  [TrendedMetricsMultipleEnum.SHARE_BY_OCCASIONS]: 'Percent',
  [TrendedMetricsMultipleEnum.AVERAGE_OCCASIONS]: 'Occasions',
  [TrendedMetricsMultipleEnum.SPEND_PER_OCCASION]: 'Dollar Value',
  [TrendedMetricsMultipleEnum.UNITS_PER_OCCASION]: 'Units',
  [TrendedMetricsMultipleEnum.UNIT_AWOP]: 'Units',
  [TrendedMetricsMultipleEnum.VALUE_AWOP]: 'Dollar Value',
  [TrendedMetricsMultipleEnum.NONE]: ''
}

export const TrendedMetricsEnumToKey = {
  [TrendedMetricsMultipleEnum.PENETRATION]: 'penetration',
  [TrendedMetricsMultipleEnum.BUYERS]: 'buyers',
  [TrendedMetricsMultipleEnum.PRICE_PER_PACK]: 'pricePerPack',
  [TrendedMetricsMultipleEnum.PURCHASE_VALUE]: 'purchaseValue',
  [TrendedMetricsMultipleEnum.PURCHASE_UNITS]: 'purchaseUnits',
  [TrendedMetricsMultipleEnum.SHARE_BY_VALUE]: 'shareByValue',
  [TrendedMetricsMultipleEnum.SHARE_BY_OCCASIONS]: 'shareByOccasions',
  [TrendedMetricsMultipleEnum.AVERAGE_OCCASIONS]: 'averageOccasions',
  [TrendedMetricsMultipleEnum.SPEND_PER_OCCASION]: 'spendPerOccasion',
  [TrendedMetricsMultipleEnum.UNITS_PER_OCCASION]: 'unitsPerOccasion',
  [TrendedMetricsMultipleEnum.UNIT_AWOP]: 'unitAwop',
  [TrendedMetricsMultipleEnum.VALUE_AWOP]: 'valueAwop',
  [TrendedMetricsMultipleEnum.NONE]: 'none'
}

export const notNone = (id: string): boolean =>
  id && id !== TrendedMetricsMultipleEnum.NONE

export const createTitle = (id1: string, id2: string): string =>
  id1 === id2
    ? startCase(id1.toLowerCase())
    : [id1, id2].filter(notNone).map(toStartCase).join(' vs ')
