import { CustomGroup } from 'data/chart/types'
import { SELECT_ALL_PRODUCT_KEY } from 'pages/reports/reports/common/reportHooks/usePreselectProduct'
import { NoneDrillDown } from 'pages/reports/reports/common/types'
import {
  IFormattedData,
  ParamsSelectors,
  TopBrandsGroupByEnum
} from 'pages/reports/reports/topBrands/types'
import { toProperCase } from 'services/helpers/canonicalization'
import { changeTextIfLowNumber } from 'services/helpers/checkIsLowNumber'
import { getCleanGroupSignature, separator } from '../common/helpers'

const showLimit = 10

const getMaxOrLimit = (currentLength, limit = showLimit) => {
  if (currentLength < limit) return currentLength
  return limit
}

export const formatData = (
  data,
  customGroups: CustomGroup[],
  {
    selectedMetric,
    selectedGroupBy,
    selectedGroups,
    drillDown,
    selectedProducts
  }: Partial<ParamsSelectors>
): IFormattedData[] => {
  const isRetailersDrillDownEnabled = drillDown.key !== NoneDrillDown

  const isProductsDrillDownEnabled = !selectedProducts.find(
    i => i.key === SELECT_ALL_PRODUCT_KEY
  )

  return data
    .filter(i => {
      const dataKey =
        isRetailersDrillDownEnabled || isProductsDrillDownEnabled
          ? i.key.split('|')[0].split(separator)[0]
          : i.key.split('|')[0]
      const filterGroup = selectedGroups.find(group => group.key === dataKey)
      const filterMetric = i.key.includes(selectedMetric.key)
      const filterGroupBy = i.key.includes(selectedGroupBy.key)
      return filterGroup && filterMetric && filterGroupBy
    })
    .map(i => {
      const group = customGroups.find(group => i.key.includes(group.id))
      const dataKey = i.key.split('|BY_')[0]
      const [dataName] = getCleanGroupSignature(
        customGroups,
        dataKey,
        isRetailersDrillDownEnabled,
        isProductsDrillDownEnabled
      )

      return {
        ...group,
        name: dataName,
        id: dataKey,
        warningLevels: i.data.map(i => i.warningLevel),
        dataValue: i.data
          .slice(0, getMaxOrLimit(i.data))
          .map(item => item.percentage),
        dataCategories: i.data
          .slice(0, getMaxOrLimit(i.data))
          .map(item =>
            toProperCase(
              changeTextIfLowNumber(
                item?.[
                  selectedGroupBy.key === TopBrandsGroupByEnum.BY_BRAND
                    ? 'brand'
                    : 'category'
                ],
                item?.warningLevel
              )
            )
          ),
        tableData: i.data.map(item => ({
          group: group.name,
          ...item
        }))
      }
    })
}
