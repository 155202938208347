import { ChartSelector } from 'data/selectors'
import { ReportMeta } from 'pages/reports/reportMeta'
import { useFilterQueries } from 'pages/reports/reports/common/helpers'
import {
  formatData,
  formatTooltipData
} from 'pages/reports/reports/crossPurchase/helpers'
import {
  FormatDatasets,
  ParamsSelectors
} from 'pages/reports/reports/crossPurchase/types'
import React, { Dispatch, useEffect } from 'react'
import { useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import dataFallback from 'services/api/requests/common/dataFallback'
import { getCrossPurchaseApi } from 'services/api/requests/reports/crossPurchase'
import { changeTextIfLowNumber } from 'services/helpers/checkIsLowNumber'

export const useLoadData = (
  setIsLoading: Dispatch<boolean>,
  selections: ParamsSelectors
): FormatDatasets => {
  const {
    drillDown,
    selectedGroups,
    selectedPeriod,
    selectedProducts
  } = selections

  const dispatch = useDispatch()
  const customGroups = useSelector(ChartSelector.customGroups)

  const queries = useFilterQueries(
    ReportMeta.CROSS_PURCHASE.key,
    selectedGroups,
    {
      hasPeriodFilter: true,
      selectedPeriod,
      drillDown,
      selectedProducts
    }
  )

  const { isLoading, isFetching, data } = useQuery(queries, () =>
    getCrossPurchaseApi(queries, dispatch)
  )
  const fallbackData = dataFallback(data)

  useEffect(() => setIsLoading(isLoading || isFetching), [
    isLoading,
    isFetching
  ])

  const formattedData = formatData(fallbackData, customGroups, {
    selectedGroups,
    selectedProducts
  })

  return {
    datasets: formattedData,
    datasetsTable: formattedData.reduce(
      (acc, curr) => [
        ...acc,
        ...curr.datasets.map(i => ({
          ...i,
          key: changeTextIfLowNumber(
            formatTooltipData(i.key.join('|')),
            i.warningLevel
          )
        }))
      ],
      []
    )
  }
}
