import axios from 'axios'
import config from 'config'
import pathToApi from 'services/helpers/pathToApi'

export const getOrgGroupsApi = async orgId => {
  const url = pathToApi(config.dashboardBaseUrl)

  const { data } = await axios.get(url(`/organisation/${orgId}/saved-groups`))
  return data
}

export const createOrgGroupApi = async ({ orgId, newGroup }) => {
  const url = pathToApi(config.dashboardBaseUrl)
  const { data } = await axios.post(
    url(`/organisation/${orgId}/saved-groups`),
    newGroup
  )
  return data
}

export const updateOrgGroupApi = async ({ orgId, id, newGroup }) => {
  const url = pathToApi(config.dashboardBaseUrl)
  const { data } = await axios.put(
    url(`/organisation/${orgId}/saved-groups/${id}`),
    newGroup
  )
  return data
}
