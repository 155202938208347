import React, { CSSProperties } from 'react'
import { SPACE } from 'services/styles'

interface Props {
  title: string
  subtitle?: string
  style?: CSSProperties
}

const Header = ({ title, subtitle, style }: Props) => {
  return (
    <div style={{ width: 400 }}>
      <h4 style={{ wordSpacing: 0, ...style }}>{title}</h4>
      <p style={{ marginBottom: SPACE.small }}>{subtitle}</p>
    </div>
  )
}

export default Header
