import { useCallback, useContext } from 'react'
import { CustomContext, CustomValue } from '../CustomProvider/CustomProvider'
import defaultLocale from '../locales/default'
import { format as formatFns } from '../utils/dateUtils'

const mergeObject = (list: any[]) =>
  list.reduce((a, b) => {
    a = { ...a, ...b }
    return a
  }, {})

const getDefaultRTL = () =>
  typeof window !== 'undefined' && (document.body.getAttribute('dir') || document.dir) === 'rtl'

/**
 * A hook to get custom configuration of `<CustomProvider>`
 * @param keys
 */
function useCustom<T = any> (keys: string | string[], overrideLocale?): CustomValue<T> {
  const {
    locale = defaultLocale,
    rtl = getDefaultRTL(),
    formatDate: format = formatFns
  } = useContext(CustomContext)

  let componentLocale: T = {
    // Public part locale
    ...locale?.common,
    // Part of the locale of the component itself
    ...(typeof keys === 'string' ? locale?.[keys] : mergeObject(keys.map(key => locale?.[key])))
  }

  // Component custom locale
  if (overrideLocale) {
    componentLocale = mergeObject([componentLocale, overrideLocale])
  }

  const formatDate = useCallback((date: Date, formatStr: string) => format(date, formatStr), [
    format
  ])

  return {
    locale: componentLocale,
    rtl,
    formatDate
  }
}

export default useCustom
