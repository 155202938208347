import { Modal } from 'antd'
import { DialogKeys } from 'components/dialogs/index'
import FlexContainer from 'components/elements/FlexContainer'
import ListWithCheckbox from 'components/elements/ListWithCheckbox'
import { DialogActions } from 'data/actions'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { toStartCase } from 'services/helpers/canonicalization'
import { SPACE } from 'services/styles'

const DrillDownDialog = ({ data, setSelectedItems }) => {
  const [items, setItems] = useState([])
  const dispatch = useDispatch()

  const handleCloseDialog = () => {
    setSelectedItems(items)
    dispatch(
      DialogActions.hideDialog({ key: DialogKeys.DRILL_DOWN_SELECT_RETAILER })
    )
  }

  const formattedData = data.map(banner => ({
    key: banner,
    value: toStartCase(banner),
    disabled: items.length >= 3 && !items.find(i => i.key === banner)
  }))

  return (
    <Modal
      title={'Drill Down Selection'}
      visible
      onOk={handleCloseDialog}
      okText={'Continue'}
      closable={false}
      okButtonProps={{ disabled: !items.length }}
      cancelButtonProps={{ hidden: true }}
      width={275}
    >
      <FlexContainer flexDirection="column">
        <p style={{ fontSize: 18 }}>{'Select up to 3 retailers:'}</p>
      </FlexContainer>
      <ListWithCheckbox
        data={formattedData}
        value={items}
        onChange={setItems}
        liStyle={{ marginTop: SPACE.tiny / 2 }}
      />
    </Modal>
  )
}

export default DrillDownDialog
