import { Input } from 'antd'
import FlexContainer from 'components/elements/FlexContainer'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'
import { BORDER_RADIUS, COLOR, SPACE } from 'services/styles'
import { FontWeight } from 'services/styles/misc'
import styled from 'styled-components'

export const ColumnWrapper = styled.div``

export const SectionTitle = styled.p`
  margin-right: ${SPACE.small}px;
  margin-bottom: ${SPACE.tiny}px;
  font-size: 18px;
  font-weight: ${FontWeight.BOLD};
`

export const TagWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  border: ${COLOR.dark} 1px solid;
`

export const GroupNameInput = styled(Input)`
  height: ${SPACE.large}px;
  border-radius: ${BORDER_RADIUS.standard * 3}px;

  &::placeholder {
    color: ${COLOR.textGrey};
  }
`

export const NextButtonBackground = styled.div`
  background-color: transparent;
`

export const StepperContentTitle = styled(FlexContainer)`
  align-items: center;
  font-size: 16px;
  padding: ${SPACE.small}px;

  &:hover {
    cursor: pointer;
  }
`

export const DownArrowIcon = styled(FaChevronDown)`
  font-size: 150%;
  margin-left: ${SPACE.small}px;
`

export const UpArrowIcon = styled(FaChevronUp)`
  margin-right: ${SPACE.tiny}px;
  margin-top: ${SPACE.tiny}px;
`

export const BreakLine = styled.div`
  margin-bottom: ${SPACE.small * 1.5}px;
`

export const WarningText = styled.p`
  color: ${COLOR.danger};
  margin-left: ${SPACE.large}px;
`

export const StepperWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: ${SPACE.tiny}px;
  border-radius: ${BORDER_RADIUS.medium}px;
  background-color: white;
`
