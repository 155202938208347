import FlexContainer from 'components/elements/FlexContainer'
import { UserActions } from 'data/actions'
import { UserSelector } from 'data/selectors'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { COLOR, SPACE } from 'services/styles'

const ViewAsBanner = () => {
  const dispatch = useDispatch()
  const viewAsUser = useSelector(UserSelector.userSelector)

  const exitViewAs = () => dispatch(UserActions.setViewAs(null))

  return (
    <FlexContainer
      style={{
        width: '100%',
        backgroundColor: COLOR.blueButton,
        alignItems: 'center',
        justifyContent: 'space-between',
        position: 'fixed',
        top: 0,
        height: 25 + SPACE.small,
        padding: SPACE.small / 2,
        paddingLeft: SPACE.small
      }}
    >
      <span style={{ color: COLOR.antdGrey }}>
        You are currently viewing this page as: {viewAsUser.email}
        <span
          onClick={exitViewAs}
          style={{
            color: COLOR.inputGrey,
            fontWeight: 800,
            textDecoration: 'underline',
            marginLeft: SPACE.small,
            cursor: 'pointer'
          }}
        >
          Exit view
        </span>
      </span>
    </FlexContainer>
  )
}
export default ViewAsBanner
