import axios from 'axios'
import config from 'config'
import pathToApi from 'services/helpers/pathToApi'

export const cancelQuery = async (queryId: string) => {
  const url = pathToApi(config.dashboardBaseUrl)
  try {
    await axios.get(url(`/query/reports/cancel?query_id=${queryId}`))
  } catch (e) {
    // silently fail
  }
}
