import { DialogActionTypes, DialogState } from 'data/dialog/types'
import produce from 'immer'
import { combineReducers } from 'redux'
import { handleActions } from 'redux-actions'

const initialDialogState: DialogState = {
  dialogs: []
}

const dialogData = handleActions<DialogState, any>(
  {
    [DialogActionTypes.SHOW_DIALOG]: (state, { payload }) => {
      return produce<DialogState>(state, draftState => {
        draftState.dialogs = [payload, ...draftState.dialogs]
        return draftState
      })
    },
    [DialogActionTypes.HIDE_DIALOG]: (state, { payload }) => {
      return produce<DialogState>(state, draftState => {
        draftState.dialogs = draftState.dialogs.filter(
          dialog => dialog.key !== payload.key
        )
        return draftState
      })
    }
  },
  initialDialogState
)

const reducer = combineReducers<CombinedDialogState>({
  dialogData
})

export interface CombinedDialogState {
  readonly dialogData: DialogState
}

export default reducer
