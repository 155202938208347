import { periodPreset } from 'components/charts/ReportParameterSelector/PeriodSelector/types'
import { ChartSelector } from 'data/selectors'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { SelectDataPropsWithId } from 'services/helpers/dropdownUtils'
import { toSelectedKey } from 'services/helpers/toPeriod'

export const useUpdateSelectionsMenu = () => {
  const customPeriods = useSelector(ChartSelector.customPeriods)

  const [selectionMenu, setSelectionMenu] = useState<SelectDataPropsWithId[]>(
    periodPreset
  )

  useEffect(() => {
    setSelectionMenu([
      ...periodPreset,
      ...customPeriods.map(i => {
        const selectionsKey = toSelectedKey(
          i.numberOfPeriods,
          i.periodType,
          i.rollingType,
          i.datePointer,
          i.dateMode
        )
        return {
          value: i.name,
          id: i.key,
          key: selectionsKey
        }
      })
    ])
  }, [customPeriods])

  return selectionMenu
}
