import { Empty } from 'antd'
import React from 'react'
import { SPACE } from 'services/styles'

const EmptyData = props => {
  return (
    <Empty
      style={{ padding: SPACE.huge, margin: `${SPACE.huge}px auto` }}
      imageStyle={{ height: 100 }}
      description={
        props.descriptionComponent || (
          <>
            <h6>
              Analysis will show here.
              <br />
              Please select a Selection first.
            </h6>
          </>
        )
      }
    >
      {props.children}
    </Empty>
  )
}

export default EmptyData
