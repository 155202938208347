import { Breadcrumb } from 'antd'
import { COLOR, SPACE } from 'services/styles'
import { FontWeight } from 'services/styles/misc'
import styled from 'styled-components'

export const DropCap = styled.span`
  color: ${COLOR.blueButton};
  margin-right: ${SPACE.tiny / 2}px;
  font-weight: ${FontWeight.BOLD};
`

export const DropdownButton = styled.div`
  padding: 0 ${SPACE.tiny}px;
  padding-bottom: ${SPACE.tiny / 2}px;
  cursor: pointer;
`

export const StyledBreadcrumb = styled(Breadcrumb)`
  font-size: 14px;
  margin-top: ${SPACE.small}px;
  margin-bottom: ${SPACE.medium}px;
`
