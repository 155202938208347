import { Popover } from 'antd'
import React, { FC, useState } from 'react'
import { CompactPicker } from 'react-color'
import { AbsoluteIcon, ColorBox, Container, Wrapper } from './style'

interface CustomColorPickerProps {
  color: string
  setColor?: (newColor: string) => void
}

const CustomColorPicker: FC<CustomColorPickerProps> = ({
  color = '#93a299',
  setColor
}) => {
  const [isOpen, setIsOpen] = useState(false)

  const handlePaletteClick = ({ hex }) => {
    setColor(hex)
    setIsOpen(!isOpen)
  }
  return (
    <Popover
      content={<CompactPicker color={color} onChange={handlePaletteClick} />}
      trigger="click"
      visible={isOpen}
      onVisibleChange={setIsOpen}
      placement="bottomLeft"
    >
      <Wrapper>
        <Container style={{ position: 'relative' }}>
          <ColorBox color={color} />
          <AbsoluteIcon color={'white'} />
        </Container>
      </Wrapper>
    </Popover>
  )
}

export default CustomColorPicker
