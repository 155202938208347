import { separator } from 'pages/reports/reports/common/helpers'
import { SelectDataProps } from 'services/helpers/dropdownUtils'

export const presetWeeks = [
  {
    text: 'Last 4 weeks',
    weeks: 1
  },
  {
    text: 'Last 12 weeks',
    weeks: 3
  },
  {
    text: 'Last 24 weeks',
    weeks: 6
  },
  {
    text: 'Last 52 weeks',
    weeks: 13
  }
]

export const periodPreset: SelectDataProps[] = [
  {
    value: '12 Latest 4 Weeks',
    key: `12${separator}1${separator}1`
  },
  {
    value: '20 Latest 4 Weeks',
    key: `20${separator}1${separator}1`
  },
  {
    value: '4 Latest 12 Weeks',
    key: `4${separator}3${separator}3`
  },
  {
    value: '6 Latest 12 Weeks',
    key: `6${separator}3${separator}3`
  },
  {
    value: '2 Latest 52 Weeks',
    key: `2${separator}13${separator}13`
  }
]
