import { ChartSelector } from 'data/selectors'
import { disabledAgeGroupsReportRoutes } from 'pages/reports/reportMeta'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

const useAgeGroupsSelectable = () => {
  const pathname = useLocation().pathname
  const customGroups = useSelector(ChartSelector.customGroups)

  const isAgeGroupsSelectable = !disabledAgeGroupsReportRoutes.some(i =>
    pathname.startsWith(i)
  )
  const customGroupsWithAgeGroups = customGroups.filter(i =>
    i.buyerGroups.some(j => j.startsWith('AGE_GROUP'))
  )

  return { isAgeGroupsSelectable, customGroupsWithAgeGroups }
}

export default useAgeGroupsSelectable
