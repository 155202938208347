import {
  BoldTitle,
  LeftBorderWrapper
} from 'components/elements/SectionTitle/styles'
import React from 'react'

interface props {
  text: string
  textColor?: string
}

const PageSectionTitle = ({ text, textColor }: props) => {
  return (
    <LeftBorderWrapper>
      <BoldTitle style={{ color: textColor }}>{text}</BoldTitle>
    </LeftBorderWrapper>
  )
}

export default PageSectionTitle
