import auth from './auth'
import reports from './reports'

export default {
  dashboard: () => '/',
  groups: () => '/saved-selections',
  savedReports: () => '/reports/saved',
  organisation: () => '/organisation',
  reports,
  account: () => '/account',
  settings: () => '/settings',
  auth
}
