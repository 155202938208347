import {
  ButtonWrapper,
  DeleteButton
} from 'components/charts/CustomGroupsList/style'
import { ColoredCircle as ColoredGroupCircle } from 'components/elements/ColoredCircle/style'
import CustomGroupTooltip from 'components/elements/CustomGroupTooltip'
import FlexContainer from 'components/elements/FlexContainer'
import { PencilIcon } from 'components/elements/SvgIcon/styles'
import Table from 'components/elements/Table'
import moment from 'moment'
import React from 'react'
import { COLOR, SPACE } from 'services/styles'

const OrgGroupsTable = ({ data, onEdit, onDelete, permission }) => {
  return (
    <Table>
      <Table.Header
        titles={['', 'Selection Name', 'Created By', 'Last Modified', '']}
      />
      <tbody>
        {data.map(group => (
          <Table.Row
            key={group.id}
            tooltipProps={{
              key: group.id,
              title: <CustomGroupTooltip group={group} />,
              placement: 'bottomLeft'
            }}
          >
            <Table.Cell
              cellStyle={{ width: SPACE.medium }}
              render={
                <FlexContainer style={{ margin: `0px ${SPACE.small}px` }}>
                  <ColoredGroupCircle bgColor={group.color} clickable={false} />
                </FlexContainer>
              }
            />
            <Table.Cell cellStyle={{ width: '40%' }} label={group?.name} />
            <Table.Cell label={group?.user?.email} />
            <Table.Cell
              cellStyle={{ width: '15%' }}
              label={moment(group.updatedAt).format('MM/DD/YYYY')}
            />
            <Table.Cell
              visible={permission}
              cellStyle={{ width: '10%' }}
              render={
                <FlexContainer
                  justifyContent="flex-end"
                  style={{ marginRight: SPACE.small }}
                >
                  <ButtonWrapper onClick={() => onEdit(group)}>
                    <PencilIcon fill={COLOR.success} />
                  </ButtonWrapper>
                  <ButtonWrapper
                    onClick={() => onDelete(group)}
                    hoverColor={COLOR.danger}
                  >
                    <DeleteButton />
                  </ButtonWrapper>
                </FlexContainer>
              }
            />
          </Table.Row>
        ))}
      </tbody>
    </Table>
  )
}

export default OrgGroupsTable
