import { COLOR } from 'services/styles'
import styled from 'styled-components'

interface ColoredCircleProps {
  bgColor: string
  clickable?: boolean
}

export const ColoredCircle = styled.span`
  height: 25px;
  width: 25px;
  background-color: ${(props: ColoredCircleProps) =>
    props.bgColor ? props.bgColor : COLOR.antdGrey};
  border-radius: 50%;
  display: inline-block;

  &:hover {
    cursor: ${(props: ColoredCircleProps) => props.clickable && 'pointer'};
  }
`
