import SavedCustomGroups from 'components/charts/CustomGroupsList'
import StandardPage from 'components/page/StandardPage'
import { GroupMeta } from 'pages/groups/type'
import React from 'react'

const CustomGroupsPage = () => {
  return (
    <StandardPage pageTitle={GroupMeta.TITLE}>
      <div
        style={{
          flex: 1,
          width: '100%',
          padding: 0
        }}
      >
        <SavedCustomGroups isAnalysisPage={false} />
      </div>
    </StandardPage>
  )
}

export default CustomGroupsPage
