import React from 'react'
import { SelectDataProps } from 'services/helpers/dropdownUtils'
import { SPACE } from 'services/styles'
import { createTitle, notNone } from '../types'

interface Props {
  leftMetric: SelectDataProps
  rightMetric: SelectDataProps
  legendWidth?: number
}

const MetricsMultipleChartTitle = ({
  leftMetric,
  rightMetric,
  legendWidth
}: Props) => (
  <div style={{ display: 'flex' }}>
    <div style={{ width: SPACE.huge }} />
    <div style={{ width: '100%', paddingRight: legendWidth }}>
      <h5 style={{ textAlign: 'center' }}>
        {createTitle(leftMetric.key, rightMetric.key)}
      </h5>
    </div>
    {notNone(rightMetric.key) && <div style={{ width: SPACE.huge }} />}
  </div>
)

export default MetricsMultipleChartTitle
