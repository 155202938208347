import {
  Chart,
  ChartArea,
  ChartAxisDefaults,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartSeries,
  ChartSeriesItem,
  ChartTitle,
  ChartValueAxis,
  ChartValueAxisItem
} from '@progress/kendo-react-charts'
import { Col, Row } from 'antd'
import AnalysisWrapper from 'components/charts/AnalysisWrapper'
import { SectionWrapper } from 'components/charts/AnalysisWrapper/style'
import ChartRef from 'components/charts/ChartRef'
import SampleSizeWarningText from 'components/charts/SampleSizeWarningText'
import FlexContainer from 'components/elements/FlexContainer'
import SelectMenu from 'components/elements/SelectMenu'
import { ReportContext } from 'components/page/ReportPage/context'
import { ChartSelector } from 'data/selectors'
import { ReportMeta, ReportNames } from 'pages/reports/reportMeta'
import MultipleProductsSelector from 'pages/reports/reports/common/productSelector/multiple'
import useDrillDownSelectable from 'pages/reports/reports/common/reportHooks/useDrillDownSelectable'
import React, { useContext, useState } from 'react'
import { useSelector } from 'react-redux'
import { getInBasketApi } from 'services/api/requests/reports/inBasket'
import { SelectDataProps } from 'services/helpers/dropdownUtils'
import { ChartHeight, COLOR, SPACE } from 'services/styles'
import { getFontStyle } from 'services/styles/font'
import useStandardSelectable from '../../common/reportHooks/useStandardSelectable'
import {
  DrillByList,
  initialDrillDownState,
  invalidDrillDownTooltip
} from '../../common/types'
import { useLoadData } from '../hooks'
import { TableTitle } from '../style'
import MemoizedRenderTable from '../table'
import {
  InitialTopBrandsGroupByState,
  InitialTopBrandsMetricState,
  TopBrandsGroupByEnumList,
  TopBrandsMetricEnumList
} from '../types'

const InBasket = React.forwardRef((props, ref) => {
  // @ts-ignore
  const { chartRef, tableRef } = ref
  //  @ts-ignore
  const { fileName } = props.exportFileProps
  const { isLoading, setIsLoading } = useContext(ReportContext)

  const [drillDown, setDrillDown] = useState<SelectDataProps>(
    initialDrillDownState
  )

  const [metric, setMetric] = useState<SelectDataProps>(
    InitialTopBrandsMetricState
  )

  const [groupBy, setGroupBy] = useState<SelectDataProps>(
    InitialTopBrandsGroupByState
  )

  const selectedPeriodKey = useSelector(ChartSelector.selectedPeriodKey)

  const {
    selectableViewOptions,
    selectedPeriod,
    setSelectedPeriod,
    selectedGroups,
    selectedProducts,
    setSelectedProducts
  } = useStandardSelectable()

  const isDrillDownSelectable = useDrillDownSelectable(setDrillDown)

  const data = useLoadData(
    {
      key: 'in-basket',
      apiFunc: getInBasketApi,
      handleLoading: setIsLoading
    },
    {
      drillDown,
      selectedGroups,
      selectedPeriod,
      selectedGroupBy: groupBy,
      selectedMetric: metric,
      selectedProducts
    }
  )

  const renderParameterSelector = () => (
    <FlexContainer alignItems="start">
      <div style={{ marginRight: SPACE.tiny }}>
        <p>DRILL DOWN</p>
        <SelectMenu
          disabled={isDrillDownSelectable}
          tooltip={isDrillDownSelectable && invalidDrillDownTooltip}
          selectableMenu={DrillByList}
          item={drillDown}
          setItem={setDrillDown}
        />
      </div>
      <div style={{ marginLeft: SPACE.tiny }}>
        <p>GROUP BY</p>
        <SelectMenu
          selectableMenu={TopBrandsGroupByEnumList}
          item={groupBy}
          setItem={setGroupBy}
        />
      </div>
      <div style={{ marginLeft: SPACE.tiny }}>
        <p>METRIC</p>
        <SelectMenu
          selectableMenu={TopBrandsMetricEnumList}
          item={metric}
          setItem={setMetric}
        />
      </div>
      <div style={{ marginLeft: SPACE.tiny }}>
        <p>PERIOD</p>
        <SelectMenu
          selectableMenu={selectableViewOptions.timePeriods}
          item={selectedPeriod}
          setItem={setSelectedPeriod}
        />
      </div>
    </FlexContainer>
  )

  const renderProductsDrillDown = () => (
    <MultipleProductsSelector
      options={selectableViewOptions.products}
      value={selectedProducts}
      onChange={setSelectedProducts}
    />
  )

  const chartAxisFont = getFontStyle(14)
  return (
    <>
      <AnalysisWrapper
        isLoading={isLoading}
        productsSelector={renderProductsDrillDown()}
        parameterSelector={renderParameterSelector()}
      >
        <SectionWrapper ref={chartRef}>
          <ChartRef style={{ height: isLoading && ChartHeight.STANDARD }}>
            <div className="hidden-text hidden">
              <h5 style={{ textAlign: 'center' }}>
                {ReportMeta[ReportNames.IN_BASKET].title}
              </h5>
            </div>
            {!isLoading &&
              data.datasets.map(item => (
                <Row
                  key={item.id}
                  justify="center"
                  style={{
                    flex: 1,
                    marginBottom: SPACE.small * 1.5
                  }}
                >
                  <Col span={24}>
                    <Chart transitions={false}>
                      <ChartTitle text={item.name} />
                      <ChartArea background={'transparent'} />
                      <ChartCategoryAxis>
                        <ChartCategoryAxisItem
                          labels={chartAxisFont}
                          categories={item.dataCategories}
                        />
                      </ChartCategoryAxis>
                      <ChartValueAxis>
                        <ChartValueAxisItem
                          type="numeric"
                          labels={{ ...chartAxisFont, format: '{0:n1} %' }}
                        />
                      </ChartValueAxis>
                      <ChartAxisDefaults
                        majorGridLines={{ color: COLOR.lightGrey + '60' }}
                      />
                      <ChartSeries>
                        <ChartSeriesItem
                          key={item.id}
                          type="bar"
                          data={item.dataValue}
                          labels={{
                            ...chartAxisFont,
                            format: '{0:n1} %',
                            visible: true,
                            position: 'insideEnd',
                            background: 'transparent'
                          }}
                          color={item.color}
                        />
                      </ChartSeries>
                    </Chart>
                  </Col>
                </Row>
              ))}
            <div className="hidden-text hidden">
              <p style={{ textAlign: 'center' }}>
                Period ({selectedPeriodKey?.value})
              </p>
              {selectedPeriod?.key && (
                <p style={{ textAlign: 'center' }}>{selectedPeriod.value}</p>
              )}
            </div>
          </ChartRef>
        </SectionWrapper>

        <SectionWrapper>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              margin: `${SPACE.small}px 0px`
            }}
          >
            <p>TABLE</p>
          </div>
          <Row justify={'center'} gutter={10}>
            {data.datasets.map(item => (
              <Col lg={{ span: 12 }} md={{ span: 24 }} key={item.id}>
                <TableTitle style={{ color: item.color }}>
                  {item.name}
                </TableTitle>
                <MemoizedRenderTable
                  reportName={ReportNames.IN_BASKET}
                  fileName={fileName}
                  data={item.tableData}
                  dataTableRef={tableRef}
                  groupBy={groupBy}
                  fullData={data.datasetsTable}
                />
              </Col>
            ))}
          </Row>
          <SampleSizeWarningText />
        </SectionWrapper>
      </AnalysisWrapper>
    </>
  )
})

export default InBasket
