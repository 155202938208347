import { DialogKeys, DrillDownDialog } from 'components/dialogs'
import { DialogActions } from 'data/actions'
import { CustomGroup } from 'data/chart/types'
import { ChartSelector, DialogSelector } from 'data/selectors'
import { ReportMeta } from 'pages/reports/reportMeta'
import { SELECT_ALL_PRODUCT_KEY } from 'pages/reports/reports/common/reportHooks/usePreselectProduct'
import { NoneDrillDown } from 'pages/reports/reports/common/types'
import { cleanProductQuery } from 'pages/reports/utils/cleanBody'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SelectDataProps } from 'services/helpers/dropdownUtils'
import { CombineChainsMapping } from 'services/helpers/toChain'

export const separator = '[SEP]'

export const getCleanGroupSignature = (
  customGroups: CustomGroup[],
  key: string,
  drillDownEnabled?: boolean,
  productsDrillDownEnabled?: boolean
): [name: string, color?: string] => {
  if (drillDownEnabled || productsDrillDownEnabled) {
    const [cleanId, name, ...productAndChain] = key.split(separator)

    return [
      `${productAndChain
        .map(i => `[${cleanProductQuery([i])[0].replace(/_/g, ' ')}]`)
        .join(' ')}`,
      cleanId
    ]
  } else {
    const name = SELECT_ALL_PRODUCT_KEY
    const color = customGroups.find(i => i.id === key)?.color
    return [name, color]
  }
}

export const paramsToPeriodsMatrix = (params: any[]): any[] => {
  const periods = params.reduce(
    (acc, param) => [...acc, ...param.periods],
    []
  ) as string[]
  const uniquePeriods = Array.from(new Set(periods))

  // generate queries for all possible group/period combinations
  return params.reduce((queryAcc, eachQuery) => {
    const { id, chains, buyerGroups, products } = eachQuery
    const queryForPeriod = uniquePeriods.reduce((acc, eachPeriod) => {
      return [
        ...acc,
        {
          id,
          name: `${id}${separator}${eachPeriod}`,
          chains,
          buyerGroups,
          periods: [eachPeriod.split('_').map(i => parseInt(i))],
          products
        }
      ]
    }, [])
    return [...queryAcc, ...queryForPeriod]
  }, [])
}

type useFilterQueriesOptions = {
  hasPeriodFilter?: boolean
  selectedPeriod?: SelectDataProps
  drillDown?: SelectDataProps
  selectedProducts?: SelectDataProps[]
}

export const useFilterQueries = (
  key: string,
  selectedGroups: SelectDataProps[],
  options?: useFilterQueriesOptions
) => {
  const dispatch = useDispatch()
  const [selectedBanners, setSelectedBanners] = useState([])
  const {
    hasPeriodFilter,
    selectedPeriod,
    drillDown,
    selectedProducts
  } = options

  const isProductsDrillDown =
    selectedProducts &&
    !selectedProducts.find(i => i.key === SELECT_ALL_PRODUCT_KEY)

  const isRetailersDrillDownEnabled =
    drillDown?.key && drillDown.key !== NoneDrillDown

  const chartQuery = useSelector(ChartSelector.chartQuerySelector)

  const dialogs = useSelector(DialogSelector.dialogSelector)
  const shownDrillDownDialog = dialogs.find(
    i => i.key === DialogKeys.DRILL_DOWN_SELECT_RETAILER
  )

  let selectedGroupQueries = chartQuery.filter(item =>
    selectedGroups.find(group => group.key === item.id)
  )

  if (!selectedGroupQueries.length || (hasPeriodFilter && !selectedPeriod)) {
    selectedGroupQueries = []
  }

  useEffect(() => setSelectedBanners([]), [drillDown?.key])

  const requireUserRetailerSelection =
    key === ReportMeta.CROSS_PURCHASE.key &&
    CombineChainsMapping?.[drillDown?.key]?.length > 3

  useEffect(() => {
    if (
      isRetailersDrillDownEnabled &&
      requireUserRetailerSelection &&
      !selectedBanners.length &&
      !shownDrillDownDialog
    ) {
      dispatch(
        DialogActions.showDialog({
          key: DialogKeys.DRILL_DOWN_SELECT_RETAILER,
          component: (
            <DrillDownDialog
              data={CombineChainsMapping[drillDown.key]}
              setSelectedItems={setSelectedBanners}
            />
          )
        })
      )
    }
  }, [
    requireUserRetailerSelection,
    selectedBanners.length,
    shownDrillDownDialog,
    isRetailersDrillDownEnabled
  ])

  if (isProductsDrillDown) {
    const groupQuery = selectedGroupQueries[0]
    selectedGroupQueries = selectedProducts.map(product => ({
      ...groupQuery,
      products: cleanProductQuery([product.key]),
      id: `${groupQuery?.id}${separator}${groupQuery?.name}${separator}${product?.key}`
    }))
  }

  if (isRetailersDrillDownEnabled) {
    selectedGroupQueries = selectedGroupQueries.map(group => ({
      ...group,
      chains: requireUserRetailerSelection
        ? selectedBanners.length
          ? selectedBanners.map(i => i.key)
          : CombineChainsMapping[drillDown.key].slice(0, 3)
        : CombineChainsMapping[drillDown.key]
    }))

    // normal report
    if (key !== ReportMeta.CROSS_PURCHASE.key) {
      selectedGroupQueries = selectedGroupQueries.reduce(
        (acc, group) => [
          ...acc,
          ...(group.chains || []).map(chain => ({
            ...group,
            chains: [chain],
            id: `${group.id}${
              isProductsDrillDown ? '' : `${separator}${group.name}`
            }${separator}${chain}`
          }))
        ],
        []
      )
    }

    if (key === ReportMeta.DEMOGRAPHICS.key) {
      selectedGroupQueries = chartQuery.reduce((acc, item) => {
        const [id, , chain] = selectedGroups?.[0]?.key?.split(separator) || []
        return item.id === id
          ? [
              ...acc,
              {
                ...item,
                chains: [chain],
                products: isProductsDrillDown
                  ? item.products.filter(i =>
                      cleanProductQuery(
                        selectedProducts.map(i => i.key)
                      ).includes(i)
                    )
                  : item.products
              }
            ]
          : acc
      }, [])
    }
  }

  // set period query
  if (hasPeriodFilter) {
    return selectedGroupQueries.map(i => ({
      ...i,
      key,
      periods: [selectedPeriod?.key?.split('_') || []]
    }))
  } else {
    return selectedGroupQueries.map(i => ({ ...i, key }))
  }
}
