import { Spin } from 'antd'
import FlexWrapper from 'components/elements/DefaultLoader/styles'
import React from 'react'
import { SPACE } from 'services/styles'

export default ({ withLoadingText, id }: Props) => (
  <FlexWrapper id={id}>
    <Spin size="large" />
    {withLoadingText && <p style={{ marginTop: SPACE.small }}>Loading...</p>}
  </FlexWrapper>
)

interface Props {
  withLoadingText?: boolean
  id?: string
}
