import { ReadMoreText } from 'components/elements/EllipsisText/styles'
import React, { useState } from 'react'
import LinesEllipsis from 'react-lines-ellipsis'
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC'

const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis)

const EllipsisText = ({ text, maxLine }) => {
  const [isClamped, setIsClamped] = useState(true)

  const handleExpandText = e => {
    e.stopPropagation()
    setIsClamped(false)
  }

  return (
    <ResponsiveEllipsis
      text={text || ''}
      maxLine={isClamped ? maxLine : 9999}
      ellipsis={
        <span>
          ... <ReadMoreText onClick={handleExpandText}>read more</ReadMoreText>
        </span>
      }
      trimRight
      basedOn="words"
    />
  )
}

export default EllipsisText
