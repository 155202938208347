import { DateModeEnum } from 'components/dialogs/CustomPeriod/types'
import { dateToWeekEnding, monthMMtoMMM } from 'services/helpers/toPeriod'

export const formatTableData = data => {
  const periodsToText = data.map(i => dateToWeekEnding[i])

  const years = periodsToText.reduce((acc, curr) => {
    const year = curr.split('/')[2]
    const monthsInYear = [...(acc?.[year]?.monthsInYear || []), curr]
    return {
      ...acc,
      [year]: {
        text: `20${year}`,
        monthsInYear
      }
    }
  }, {})

  const months = data.map(i => monthMMtoMMM[dateToWeekEnding[i].split('/')[1]])

  return { years, months }
}

export const isSelectedStartDateValid = (
  ascendingPeriods,
  selectedStartDate,
  numberOfPeriods,
  selectedPeriodType,
  selectedRollingType
) => {
  const selectedIndex = ascendingPeriods.findIndex(i => i === selectedStartDate)
  const noneSelected = selectedIndex === -1
  const endOfGroup = selectedIndex + selectedPeriodType
  const selectedPositionStart = selectedIndex === -1 ? 0 : endOfGroup

  return (
    noneSelected ||
    (selectedPositionStart >= selectedPeriodType &&
      ascendingPeriods.length - selectedPositionStart >=
        selectedRollingType * (numberOfPeriods - 1))
  )
}

export const isAvailablePeriodsSufficient = (
  mode,
  ascendingPeriods,
  currentPeriod,
  selectedPeriodType,
  selectedNumberOfPeriod,
  selectedRollingType
) => {
  const selectedIndex = ascendingPeriods.findIndex(i => i === currentPeriod)
  const selectedPositionEnd = selectedIndex === -1 ? 0 : selectedIndex
  const selectedPositionStart =
    selectedIndex === -1 ? ascendingPeriods.length - 1 : selectedIndex
  const minimumBlocks =
    (selectedNumberOfPeriod - 1) * selectedRollingType + selectedPeriodType

  return mode === DateModeEnum.END
    ? selectedPositionStart + 1 >= minimumBlocks
    : ascendingPeriods.length - selectedPositionEnd >= minimumBlocks
}
