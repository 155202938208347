import React from 'react'
import { changeTextIfLowNumber } from 'services/helpers/checkIsLowNumber'
import { SPACE } from 'services/styles'
import styled from 'styled-components'

const RenderLeftWithWarning = props => (
  <LeftWrapper>
    {changeTextIfLowNumber(
      props.dataItem[props.field],
      props.dataItem.warningLevel
    )}
  </LeftWrapper>
)

const LeftWrapper = styled.td`
  padding: ${SPACE.tiny}px;
  text-align: left !important;
`

const LeftTextWithWarning = props => <RenderLeftWithWarning {...props} />

export default LeftTextWithWarning
