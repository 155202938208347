export enum AtomicChainsEnum {
  DAN_MURPHYS = 'DAN_MURPHYS',
  BWS = 'BWS',
  LIQUORLAND = 'LIQUORLAND',
  FIRST_CHOICE_LIQUOR = 'FIRST_CHOICE_LIQUOR',
  DAN_MURPHYS_ONLINE = 'DAN_MURPHYS_ONLINE',
  BWS_ONLINE = 'BWS_ONLINE',
  LIQUORLAND_ONLINE = 'LIQUORLAND_ONLINE',
  FIRST_CHOICE_LIQUOR_ONLINE = 'FIRST_CHOICE_LIQUOR_ONLINE',
  VINTAGE_CELLARS = 'VINTAGE_CELLARS'
}

export enum CombineChainsEnum {
  ALL_BANNERS = 'ALL_BANNERS',
  ONLINE = 'ONLINE',
  BRICK_AND_MORTAR = 'BRICK_AND_MORTAR',
  ENDEAVOUR = 'ENDEAVOUR',
  COLES_LIQUOR = 'COLES_LIQUOR',
  SINGLE_BANNER = 'SINGLE_BANNER',
  RETAILER_GROUP = 'RETAILER_GROUP',
  CHANNEL = 'CHANNEL'
}

interface ICombineChainsMapping {
  [key: string]: (AtomicChainsEnum | CombineChainsEnum)[]
}

export const CombineChainsMapping: ICombineChainsMapping = {
  [CombineChainsEnum.ALL_BANNERS]: [
    AtomicChainsEnum.DAN_MURPHYS,
    AtomicChainsEnum.BWS,
    AtomicChainsEnum.LIQUORLAND,
    AtomicChainsEnum.FIRST_CHOICE_LIQUOR,
    AtomicChainsEnum.DAN_MURPHYS_ONLINE,
    AtomicChainsEnum.BWS_ONLINE,
    AtomicChainsEnum.LIQUORLAND_ONLINE,
    AtomicChainsEnum.FIRST_CHOICE_LIQUOR_ONLINE
    // AtomicChainsEnum.VINTAGE_CELLARS
  ],
  [CombineChainsEnum.SINGLE_BANNER]: [
    AtomicChainsEnum.DAN_MURPHYS,
    AtomicChainsEnum.BWS,
    AtomicChainsEnum.LIQUORLAND,
    AtomicChainsEnum.FIRST_CHOICE_LIQUOR
    // AtomicChainsEnum.VINTAGE_CELLARS
  ],
  [CombineChainsEnum.ONLINE]: [
    AtomicChainsEnum.DAN_MURPHYS_ONLINE,
    AtomicChainsEnum.BWS_ONLINE,
    AtomicChainsEnum.LIQUORLAND_ONLINE,
    AtomicChainsEnum.FIRST_CHOICE_LIQUOR_ONLINE
  ],
  [CombineChainsEnum.BRICK_AND_MORTAR]: [
    AtomicChainsEnum.DAN_MURPHYS,
    AtomicChainsEnum.BWS,
    AtomicChainsEnum.LIQUORLAND,
    AtomicChainsEnum.FIRST_CHOICE_LIQUOR
    // AtomicChainsEnum.VINTAGE_CELLARS
  ],
  [CombineChainsEnum.ENDEAVOUR]: [
    AtomicChainsEnum.BWS,
    AtomicChainsEnum.DAN_MURPHYS,
    AtomicChainsEnum.BWS_ONLINE,
    AtomicChainsEnum.DAN_MURPHYS_ONLINE
  ],
  [CombineChainsEnum.COLES_LIQUOR]: [
    AtomicChainsEnum.LIQUORLAND,
    AtomicChainsEnum.FIRST_CHOICE_LIQUOR,
    // AtomicChainsEnum.VINTAGE_CELLARS,
    AtomicChainsEnum.LIQUORLAND_ONLINE,
    AtomicChainsEnum.FIRST_CHOICE_LIQUOR_ONLINE
  ],
  [CombineChainsEnum.CHANNEL]: [
    CombineChainsEnum.ONLINE,
    CombineChainsEnum.BRICK_AND_MORTAR
  ],
  [CombineChainsEnum.RETAILER_GROUP]: [
    CombineChainsEnum.ENDEAVOUR,
    CombineChainsEnum.COLES_LIQUOR
  ]
}

export const ChainsEnumToCanonical = {
  [AtomicChainsEnum.DAN_MURPHYS]: "Dan Murphy's",
  [AtomicChainsEnum.BWS]: 'BWS',
  [AtomicChainsEnum.LIQUORLAND]: 'Liquorland',
  [AtomicChainsEnum.FIRST_CHOICE_LIQUOR]: 'First Choice',
  [AtomicChainsEnum.DAN_MURPHYS_ONLINE]: "Dan Murphy's (Online)",
  [AtomicChainsEnum.BWS_ONLINE]: 'BWS (Online)',
  [AtomicChainsEnum.LIQUORLAND_ONLINE]: 'Liquorland (Online)',
  [AtomicChainsEnum.FIRST_CHOICE_LIQUOR_ONLINE]: 'First Choice (Online)',
  [AtomicChainsEnum.VINTAGE_CELLARS]: 'Vintage Cellars',
  [CombineChainsEnum.ALL_BANNERS]: 'All Banners',
  [CombineChainsEnum.SINGLE_BANNER]: 'Single Banner',
  [CombineChainsEnum.RETAILER_GROUP]: 'Retailer Group',
  [CombineChainsEnum.CHANNEL]: 'Channel',
  [CombineChainsEnum.ONLINE]: 'Online',
  [CombineChainsEnum.BRICK_AND_MORTAR]: 'Brick & Mortar',
  [CombineChainsEnum.ENDEAVOUR]: 'Endeavour',
  [CombineChainsEnum.COLES_LIQUOR]: 'Coles Liquor'
}

export const RetailerGroup = [
  CombineChainsEnum.ENDEAVOUR,
  CombineChainsEnum.COLES_LIQUOR
]

export const Channel = [
  CombineChainsEnum.ONLINE,
  CombineChainsEnum.BRICK_AND_MORTAR
]

export const CombineChainsEnumToPosition = {
  [CombineChainsEnum.ALL_BANNERS]: 0,
  [CombineChainsEnum.SINGLE_BANNER]: 1,
  [CombineChainsEnum.RETAILER_GROUP]: 2,
  [CombineChainsEnum.CHANNEL]: 3
}
