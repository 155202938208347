export const cleanBannersQuery = (selectedList: string[]): string[] => {
  const filteredList = selectedList.filter(i => i.includes('/'))
  return filteredList.length === 0
    ? selectedList
    : filteredList.map(i => i.split('/')[1].trim())
}

export const getBannerPrefix = (selectedList: string[]): string => {
  const filteredList = selectedList.filter(i => i.includes('/'))
  return filteredList.length !== 0 && filteredList[0].split('/')[0].trim()
}
