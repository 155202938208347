import { SPACE } from 'services/styles'
import styled from 'styled-components'

export const ChartBackground = styled.div`
  position: absolute;
  bottom: ${SPACE.small}px;
  right: ${SPACE.small}px;
  overflow: hidden;
`

export const RefWrapper = styled.div``
