import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UserOutlined
} from '@ant-design/icons'
import { Avatar, Button, Dropdown, Layout, Menu } from 'antd'
import FlexContainer from 'components/elements/FlexContainer'
import { TextWrapper } from 'components/page/layout/Header/style'
import { ChartActions, UserActions } from 'data/actions'
import { UserSelector } from 'data/selectors'

import { SideBarContext } from 'navigation/sidebarContext'
import { RoleEnum } from 'pages/organisation/types'
import React, { useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import routes from 'routes'
import { firebaseLogout } from 'services/firebase'
import { toProperCase } from 'services/helpers/canonicalization'
import { SPACE } from 'services/styles'
import { FontWeight } from 'services/styles/misc'

const triggerStyle = { fontSize: 18 }
const Header = () => {
  const { isOpen, setIsOpen } = useContext(SideBarContext)
  const history = useHistory()
  const dispatch = useDispatch()
  const user = useSelector(UserSelector.userSelector)
  const rootUser = useSelector(UserSelector.rootUserSelector)
  const to =
    user?.role?.name === RoleEnum.SUPER_ADMIN
      ? routes.organisation()
      : `${routes.organisation()}/${user?.organisation?.id}`

  const handleLogout = async () => {
    dispatch(UserActions.userLogout())
    dispatch(ChartActions.resetChartParameters())
    await firebaseLogout()
    history.push('/login')
  }

  const handleOrganisation = () => {
    history.push({
      pathname: to,
      state: user?.organisation
    })
  }

  const menu = (
    <Menu>
      <Menu.Item key={'userName'} style={{ pointerEvents: 'none' }}>
        <TextWrapper>
          {'Signed in as '}{' '}
          <span style={{ fontWeight: FontWeight.BOLD }}>
            {toProperCase(rootUser?.fullName)}
          </span>
          <br />
          <p>({rootUser?.email})</p>
        </TextWrapper>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key={'nav-org'} onClick={handleOrganisation}>
        <TextWrapper>Organisation</TextWrapper>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key={'nav-logout'} onClick={handleLogout}>
        <TextWrapper>Logout</TextWrapper>
      </Menu.Item>
    </Menu>
  )

  return (
    <Layout.Header
      style={{
        backgroundColor: 'white',
        paddingLeft: SPACE.tiny
      }}
    >
      <FlexContainer style={{ height: '100%' }}>
        <Button
          type="text"
          onClick={() => setIsOpen(!isOpen)}
          style={{ backgroundColor: 'white' }}
        >
          {!isOpen ? (
            <MenuUnfoldOutlined style={triggerStyle} />
          ) : (
            <MenuFoldOutlined style={triggerStyle} />
          )}
        </Button>
        <Dropdown overlay={menu} trigger={['click']} placement="bottomRight">
          <Button type="text" style={{ padding: 0, height: '100%' }}>
            <Avatar size="large" icon={<UserOutlined />} />
          </Button>
        </Dropdown>
      </FlexContainer>
    </Layout.Header>
  )
}
export default Header
