import { config } from 'dotenv'

config()

export interface Config {
  firebaseConfig: FirebaseConfig
  dashboardBaseUrl: string
  environment: string
  sentryDsn?: string
}

export interface FirebaseConfig {
  apiKey: string
  authDomain: string
  projectId: string
  storageBucket: string
  messagingSenderId: string
  appId: string
  measurementId: string
}

export enum ENVIRONMENT {
  Production = 'PRODUCTION',
  Staging = 'STAGING',
  Development = 'DEVELOPMENT'
}

const baseConfig = {
  environment: process.env.NODE_ENV
}

const getConfig = (environment: string | undefined): Config => {
  if (environment === ENVIRONMENT.Production) {
    return {
      ...baseConfig,
      firebaseConfig: {
        apiKey: 'AIzaSyAi59eOxNbVQGH_P_3W4OF-PE6zWxUh5Fc',
        authDomain: 'alcohol-dashboard-prod.firebaseapp.com',
        projectId: 'alcohol-dashboard-prod',
        storageBucket: 'alcohol-dashboard-prod.appspot.com',
        messagingSenderId: '1019511963807',
        appId: '1:1019511963807:web:e1066007cf8c06f53122aa',
        measurementId: 'G-JCD96EL4R6'
      },
      sentryDsn:
        'https://0b8540ddece64435b481e24d0891b822@o78584.ingest.sentry.io/5745206',
      dashboardBaseUrl: 'https://dashboard.api.niqdata.com',
      environment: ENVIRONMENT.Production
    }
  } else if (environment === ENVIRONMENT.Development) {
    return {
      ...baseConfig,
      firebaseConfig: {
        apiKey: 'AIzaSyC0apO9i3GOfAi_RCneGk8Ius3RgByN0Ns',
        authDomain: 'alcohol-dashboard-dev.firebaseapp.com',
        projectId: 'alcohol-dashboard-dev',
        storageBucket: 'alcohol-dashboard-dev.appspot.com',
        messagingSenderId: '969015778762',
        appId: '1:969015778762:web:6d4e552e9303cb7aa10333',
        measurementId: 'G-PBMLT17KVD'
      },
      sentryDsn:
        'https://dbab3a8f4bd34398b27542637b152310@o78584.ingest.sentry.io/5745207',
      dashboardBaseUrl: 'https://dashboard.api.dev.niqdata.com',
      environment: ENVIRONMENT.Development
    }
  }
  return {
    ...baseConfig,
    firebaseConfig: {
      apiKey: 'AIzaSyChBakRZFFtJ3WTa0-xICwEgKiAbFBVnO8',
      authDomain: 'alcohol-dashboard-staging.firebaseapp.com',
      projectId: 'alcohol-dashboard-staging',
      storageBucket: 'alcohol-dashboard-staging.appspot.com',
      messagingSenderId: '510516734584',
      appId: '1:510516734584:web:aea9281b9a25d9ee051b8d',
      measurementId: 'G-W1GDBJVP0B'
    },
    dashboardBaseUrl: 'https://dashboard.api.staging.niqdata.com',
    environment: ENVIRONMENT.Staging
  }
}

const environmentConfig = getConfig(process.env.REACT_APP_ENV)

export default environmentConfig
