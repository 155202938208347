import React from 'react'
import { COLOR } from 'services/styles'

export default ({ nodeData }) => {
  return (
    <>
      <p style={{ display: 'inline-block' }}>{nodeData.name}</p>
      <p
        style={{
          color: nodeData?.value?.includes('-') ? COLOR.danger : COLOR.success
        }}
      >
        {nodeData.value}
      </p>
    </>
  )
}
