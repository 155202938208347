import snakeCase from 'lodash/snakeCase'

type SnakeCase<
  S extends string
> = S extends `${infer P1}_${infer P2}${infer P3}`
  ? `${Lowercase<P1>}${Uppercase<P2>}${SnakeCase<P3>}`
  : Lowercase<S>

type KeysToCamelCase<T> = {
  [K in keyof T as SnakeCase<string & K>]: T[K] extends Array<infer U>
    ? U extends {}
      ? Array<KeysToCamelCase<U>>
      : T[K]
    : T[K] extends {}
    ? KeysToCamelCase<T[K]>
    : T[K]
}

const isDate = function (obj) {
  return Object.prototype.toString.call(obj) === '[object Date]'
}

const isRegex = function (obj) {
  return Object.prototype.toString.call(obj) === '[object RegExp]'
}

function walk(obj): any {
  if (!obj || typeof obj !== 'object') return obj
  if (isDate(obj) || isRegex(obj)) return obj
  if (Array.isArray(obj)) return obj.map(walk)

  return Object.keys(obj).reduce((res, key) => {
    const camel = snakeCase(key)
    res[camel] = walk(obj[key])
    return res
  }, {})
}

export default function snakeize<T>(
  obj: T
): T extends String ? string : KeysToCamelCase<T> {
  return typeof obj === 'string' ? snakeCase(obj) : walk(obj)
}
